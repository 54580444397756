import React, { useRef, useEffect, useState } from 'react';
import {
  string, func, arrayOf, object, bool
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import Button from '../../../Button';
import Loader from '../../../Loader';
import PreviewHint from '../TemplateItem/PreviewHint';
import './style.scss';
import { getDeviceType } from '../../getDeviceType';
import SimilarTemplatesItem from './SimilarTemplatesItem';

const TableTemplatePreviewContent = ({
  template, categoryData, modalBodyRef, frameSrc,
  setAdditionalWrapperClasses, setCustomHandleCloseFunction,
  similarTemplates, setModalContentLoading, modalContentLoading, username, onTemplateClone
}) => {
  let timeoutReference;

  const templateType = frameSrc.split('/')[1];
  const isMobile = getDeviceType() === 'mobile';

  const demoPreviewRef = useRef();
  const previewImageRef = useRef();
  const [previewMode, setPreviewMode] = useState('image');
  const [containerHeight, setContainerHeight] = useState();

  const isEnterpriseCheck = isEnterprise();

  const resetComponentState = () => {
    if (isMobile) return;
    clearTimeout(timeoutReference);
    setContainerHeight();
  };

  const updateContainerHeight = () => {
    const currentImageRef = previewImageRef?.current;
    if (currentImageRef !== null) {
      setContainerHeight(currentImageRef.clientHeight);
    }
  };

  const handleWindowResize = () => {
    if (isMobile) return;
    if (window.innerWidth < 1100) {
      updateContainerHeight();
    }
  };

  useEffect(() => {
    if (isMobile) return;
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
      resetComponentState();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(categoryData).length > 0) {
      setModalContentLoading(false);
    }
  }, [categoryData]);

  const {
    id,
    _id,
    _slug,
    _imageUrl,
    _title = '',
    _previewImageUrl,
    _description = '',
    thumbnailBackgroundColor
  } = template;

  const handleImageLoad = () => {
    timeoutReference = setTimeout(() => {
      if (!isMobile) {
        updateContainerHeight();
      }
      setModalContentLoading(false);
    }, 500);
  };

  const getContainerHeight = () => {
    if (isMobile) return 'auto';
    if (containerHeight) return `${containerHeight}px`;
    if (window.innerWidth < 580) return '100px';
    if (window.innerWidth < 768) return '200px';
    return '300px';
  };

  const handleChangeViewMode = newMode => {
    setPreviewMode(newMode);
    setModalContentLoading(true);
    setAdditionalWrapperClasses(newMode === 'demo' ? ['previewMode'] : []);
    window.standaloneTemplatesLogger({
      actor: username,
      target: `${newMode}PreviewMode`,
      action: `${getDeviceType()}:previewModeChanged`
    });
    setCustomHandleCloseFunction(
      newMode === 'demo' ? () => handleChangeViewMode('image') : null
    );
  };

  return (
    <div className="jfModal-content">
      {
        previewMode === 'demo' ? (
          <div className='modal-template-area'>
            <Loader />
            <object
              type="text/html"
              id="table-demo-preview"
              data={`/${templateType}/demo/${_slug}`}
              onLoad={() => {
                const node = demoPreviewRef?.current;
                if (node && node !== null && node?.contentWindow) {
                  const demoFrame = node;
                  demoFrame.contentWindow.backToImagePreviewMode = () => handleChangeViewMode('image');
                }
                setModalContentLoading(false);
              }}
              className={`demoFrame${modalContentLoading ? '' : ' demoFrameLoaded'}`}
              ref={demoPreviewRef}
            />
          </div>
        ) : (
          <>
            <div className={`modal-template-area ${isMobile ? 'is-mobile' : ''}`} style={{ height: getContainerHeight() }}>
              <Loader />
              <div
                id='modal-template-area'
                className='modal-template-area-content'
                style={{ backgroundColor: isMobile ? thumbnailBackgroundColor : '#fff' }}
                onClick={() => handleChangeViewMode('demo')}
              >
                {isEnterpriseCheck && (
                  <img
                    alt={_title}
                    className="enterprise-logo-layer"
                    src="https://cdn.jotfor.ms/assets/img/templates/tables-enterprise-logo-layer.png"
                  />
                )}
                <img
                  alt={_title}
                  ref={previewImageRef}
                  onLoad={handleImageLoad}
                  src={getDeviceType() === 'mobile' ? _imageUrl : _previewImageUrl}
                />
                {
                  !modalContentLoading && (
                    <div className='table-hover'>
                      <PreviewHint ctaText="See Demo" />
                    </div>

                  )
                }
              </div>
            </div>
            {
              (Object.keys(template).length > 0) && (
                <div className='content-area'>
                  {!isEnterprise() && (
                    <>
                      <div className='jfWizard-modal-detail'>
                        <div className='description-area' dangerouslySetInnerHTML={{ __html: _description }} />
                        <div className='button-wrapper'>
                          <Button className='forSeeDemo' onClick={() => handleChangeViewMode('demo')}>
                            {t('See Demo')}
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                  <SimilarTemplatesItem
                    parentTemplateID={_id || id}
                    templateType={templateType}
                    modalBodyRef={modalBodyRef}
                    categoryData={categoryData}
                    onTemplateClone={onTemplateClone}
                    similarTemplates={similarTemplates}
                    selectTemplateCallback={resetComponentState}
                    setModalContentLoading={setModalContentLoading}
                  />
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
};

TableTemplatePreviewContent.defaultProps = {
  template: {},
  username: '',
  frameSrc: '/',
  categoryData: null,
  similarTemplates: [],
  modalBodyRef: undefined,
  onTemplateClone: f => f,
  modalContentLoading: true,
  setAdditionalWrapperClasses: f => f,
  setCustomHandleCloseFunction: f => f
};

TableTemplatePreviewContent.propTypes = {
  frameSrc: string,
  template: object,
  username: string,
  categoryData: object,
  modalBodyRef: object,
  onTemplateClone: func,
  modalContentLoading: bool,
  setAdditionalWrapperClasses: func,
  similarTemplates: arrayOf(object),
  setCustomHandleCloseFunction: func,
  setModalContentLoading: func.isRequired
};

export default TableTemplatePreviewContent;
