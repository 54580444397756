import requestLayer from './requestLayer';
import APP_CONFIG from '../../constants/appConfig';
import { FEATURE_NAMES } from '../../constants/features';
import { isFeatureEnabled } from '../../utils/features/helper';
import { base64toBlob } from '../Builder/components/AssetGenerator/utils';
import { ITEM_PROP_TYPE_VERSION, ITEM_TYPES } from '../../constants/itemTypes';

export function fetchUser() {
  return requestLayer.get('user/combinedinfo?campaign=1');
}

export function fetchPortal(portalID) {
  return requestLayer.get(`portal/${portalID}`);
}

export function fetchPortalPublicInfo(portalID) {
  return requestLayer.get(`portal/${portalID}/public`);
}

export function fetchEnvironmentVariables() {
  return requestLayer.get('env');
}

export function fetchForms(filters = {}) {
  return requestLayer.get(
    `user/forms?filter=${JSON.stringify({ 'status:ne': 'DELETED', ...filters })}&limit=2000&order_by=created_at&includeSharedForms=0`,
    { headers: { 'jf-team-id': false } }
  );
}

export function fetchTables(filters = {}, params) {
  return requestLayer.get(
    `user/sheets?filter=${JSON.stringify({ 'status:ne': 'TRASHED', ...filters })}&limit=2000&order_by=created_at`,
    {
      includeShare: 0,
      ...params
    },
    { headers: { 'jf-team-id': false } }
  );
}

export function fetchReports(filters = {}) {
  return requestLayer.get(
    `user/reports?filter=${JSON.stringify({ 'status:ne': 'DELETED', ...filters })}&limit=2000&order_by=created_at&includeShare=0`,
    { headers: { 'jf-team-id': false } }
  );
}

export function fetchSigns(portalID) {
  return requestLayer.get(`portal/${portalID}/get-all-sign-documents`);
}

export function updatePortal(portalID, properties) {
  return requestLayer.post(`portal/${portalID}`, properties);
}

export function changeItemProp(portalID, itemID, prop) {
  return requestLayer.post(`portal/${portalID}/item/${itemID}`, { ...prop, version: ITEM_PROP_TYPE_VERSION });
}

export function createPortal(formIDs, appProperties, widgetDefaults, page) {
  const _data = { appProperties, formIDs, widgetDefaults };
  const data = isFeatureEnabled(FEATURE_NAMES.SortPages) && page ? { page, ..._data } : _data;
  const qsPart = isFeatureEnabled(FEATURE_NAMES.FormResource) ? '?useFormResource=1' : '';
  return requestLayer.post(`user/portals${qsPart}`, data);
}

const addVersionParamForNewDBUse = items => {
  const newItems = items.map(i => {
    const newItem = { ...i };
    if (i.type && i.type === ITEM_TYPES.DONATION) {
      newItem.version = 2;
    }
    return newItem;
  });
  return newItems;
};

// TODO :: how to test :: move up/down item
export function addItemToPortal(portalID, items, order) {
  const qsPart = isFeatureEnabled(FEATURE_NAMES.FormResource) ? '?useFormResource=1' : '';
  return requestLayer.post(`portal/${portalID}/items${qsPart}`, { items: addVersionParamForNewDBUse(items), order });
}

export const addPageToPortal = (portalID, page) => requestLayer.post(`portal/${portalID}/pages`, { page });

export function removePageFromPortal(portalID, pageID, _deleteItems = false) {
  const deleteItems = _deleteItems ? '?deleteItems=1' : '';
  return requestLayer.delete(`portal/${portalID}/page/${pageID}${deleteItems}`);
}

export function updatePage(portalID, pageID, props) {
  return requestLayer.post(`portal/${portalID}/page/${pageID}`, props);
}

export function removeItemsFromPortal(portalID, itemIDList) {
  return requestLayer.delete(`portal/${portalID}/items`, { data: itemIDList });
}

export function bulkUpdateItems(portalID, items) {
  return requestLayer.put(`portal/${portalID}/items`, { items: addVersionParamForNewDBUse(items) });
}

export function getShareList(portalID) {
  return requestLayer.get(`share/app/${portalID}`);
}

export function bulkSharePortal(portalID, users, message) {
  const params = new URLSearchParams();
  params.append('message', message);
  users.forEach((user, index) => params.append(`users[${index}]`, user));
  return requestLayer.post(`share/app/${portalID}/bulkShare`, params);
}

export function bulkDeleteSharePortal(resourceShareIDList) {
  const params = new URLSearchParams();
  resourceShareIDList.forEach((resourceShareID, index) => params.append(`resourceShareIDs[${index}]`, resourceShareID));
  return requestLayer.post('share/bulkDelete', params);
}

export function resendShareInvitation(resourceShareID) {
  return requestLayer.get(`share/${resourceShareID}/resendInvitation`);
}

export function cloneTemplate(templateType, cloneData) {
  const cloneDataEntries = Object.entries(cloneData);
  const params = new URLSearchParams();
  cloneDataEntries.forEach(([key, value]) => params.append(key, value));
  return requestLayer.post(`/${templateType}-templates/api`, params);
}

export function generateNewResourceShareLink(resourceID) {
  return requestLayer.post(`share/${APP_CONFIG.basePath}/${resourceID}/link`);
}

export function getDrafts(appID) {
  return requestLayer.get(`portal/${appID}/drafts`);
}

export function getLinkItemPreviewDetails({ portalID, itemID, URL }) {
  return requestLayer.get(`portal/${portalID}/item/${itemID}/linkPreview?url=${URL}`);
}

export function sendFeedbackSubmission(feedbackData) {
  // eslint-disable-next-line no-undef
  const formData = new FormData();
  Object.keys(feedbackData).forEach(key => {
    formData.append(key, feedbackData[key]);
  });
  return requestLayer.post(`https://submit.jotformpro.com/submit/${feedbackData.formID}/`, formData, { withCredentials: false });
}

export function fetchUserPortals() {
  return requestLayer.get('user/portals?filter={"status:ne":"TRASHED"}&limit=1000');
}

export const fetchPortalsByFormID = formID => requestLayer.get(`form/${formID}/portals`);

export const restartProgressManually = portalID => {
  return requestLayer.post(`portaluser/portal/${portalID}/properties`, { progressManualRestartDate: true });
};

export const updatePortalUserProperty = (portalID, data = {}) => {
  return requestLayer.post(`portaluser/portal/${portalID}/properties`, data);
};

export const shareAsTemplate = (appID, data = { share: '0' }) => {
  // eslint-disable-next-line no-undef
  const formData = new FormData();
  formData.append('appID', appID);

  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return requestLayer.post('app-template', formData);
};

export function fetchWidgets(username) {
  const path = `apw/get-all/${username}`;
  return requestLayer.get(path, { headers: { disableTeamPrefix: true } });
}

export function getTemplateCategories() {
  return requestLayer.get('app-template/categories');
}

export function getTemplateLanguages() {
  return requestLayer.get(`https://${window.location.hostname}/app-templates/api/languages`);
}

export function isSlugAvailable(slug) {
  return requestLayer.post('portal/is-slug-available', { slug });
}

export function fetchCDNConfig() {
  return requestLayer.get(`https://${window.location.hostname}/server.php?action=getConfigs`);
}

export function updateUserSettings(username, settings) {
  // eslint-disable-next-line no-undef
  const formData = new FormData();
  Object.keys(settings).forEach(key => {
    formData.append(key, settings[key]);
  });
  return requestLayer.post(`user/${username}/settings`, formData);
}

export const getUserSlug = username => {
  return requestLayer.get(`slug/users/${username}`);
};

export function bulkUpdatePages(portalID, pages) {
  return requestLayer.put(`portal/${portalID}/pages`, { pages });
}

export function cloneApp(portalID, appProperties = {}, cloneForms = false) {
  const _cloneForms = cloneForms ? '1' : '0';
  return requestLayer.post(`portal/${portalID}/clone?cloneForms=${_cloneForms}`, { appProperties });
}

export function addImageToProductList(formID, data) {
  return requestLayer.post(`form/${formID}/files?disableJotFormNormalize`, data);
}

export function updateProductListSettings(data) {
  return Promise.resolve(data);
}

export const claimGuestAccount = portalID => requestLayer.post(`portal/${portalID}/claim-guest-account`);

export const updateCart = (portalID, cartProducts) => {
  return requestLayer.post(`portaluser/portal/${portalID}/update-cart`, { cartProducts });
};

export const getStorePropertiesOfUser = portalID => {
  return requestLayer.get(`portaluser/portal/${portalID}/store-properties`);
};

// TEAMS

export async function getUserTeams() {
  return requestLayer.get('team/user/me?returnTeamProperties=1', { headers: { disableTeamPrefix: true } });
}

export const fetchUserPermissions = () => requestLayer.get('user/permissions');

export function getTeamResources({
  teamID, resourceType, filters = {}, params
}) {
  const filterString = JSON.stringify({ 'status:ne': ['DELETED', 'PURGED'], ...filters });
  return requestLayer.get(`team/${teamID}/assets/${resourceType}?filter=${filterString}&limit=2000&order_by=created_at`, { ...params }, { headers: { 'jf-team-id': teamID } });
}

export function getTeamIDOfAsset({ resourceID, resourceType }) {
  return requestLayer.get(`team/asset/${resourceID}/${resourceType}`);
}

export function updateAppLogo({ appID, iconProps: { asset, ...props } }) {
  const formData = new global.FormData();
  if (asset) {
    // eslint-disable-next-line no-undef
    const blob = (asset instanceof Blob) ? asset : base64toBlob(asset.replace('data:image/png;base64,', ''), 'image/png');
    formData.append('asset', blob, appID);
  }
  Object.entries(props).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return requestLayer.post(`portal/${appID}/update-app-logo`, formData);
}

export const teamLog = (appID, type) => requestLayer.post(`portal/${appID}/team-log`, { type });

export const getAssetIsBusy = appID => requestLayer.get(`team/asset/${appID}/portal/get-is-resource-busy`);

export const replaceFormItem = ({ appID, formID, itemID }) => requestLayer.post(`portal/${appID}/replace-form`, { formID, itemID });

export const sendDonationBoxBetaAgreementSubmission = user => {
  const formData = new FormData();
  formData.append('formID', '230893968018973');
  formData.append('q9_fullName', user.name || '');
  formData.append('q8_email', user.email || '');
  formData.append('q10_username', user.username || '');
  formData.append('q14_betaAgreement', '1.2');
  return requestLayer.post('https://www.jotform.com/submit.php', formData, { withCredentials: false });
};

export const createPaymentQuestion = portalID => requestLayer.post(`portal/${portalID}/create-payment-question`);

export const logClientEvents = (appID, payload) => requestLayer.post(`portal/${appID}/log-client-events`, payload);

export const sendPushNotification = ({ appID, title, message }) => requestLayer.post(`web-push/portal/${appID}/send`, {
  title,
  message
});

export const getNotificationHistory = appID => requestLayer.get(`web-push/portal/${appID}/campaigns`);

export const getNotificationStats = appID => requestLayer.get(`web-push/portal/${appID}/stats`);

export const fetchIsAllowedZoomWidget = () => requestLayer.get('zoom-support/me/is-allowed');

/// Data Source
export const fetchColumns = ({ resourceID, viewID }) => requestLayer.get(`portal/sheet/${resourceID}/columns`, viewID ? { viewID } : {}, null);

export const fetchRow = ({
  appID, itemID, rowID = '', offset, limit
}) => requestLayer.get(`portal/${appID}/item/${itemID}/detail${rowID ? `/${rowID}` : ''}`, { offset, limit }, { shouldCache: false });

export const fetchTableTabs = sheetID => requestLayer.get(`sheet/${sheetID}/views`);

export const viewTable = ({ appID, itemID }) => requestLayer.get(`portal/${appID}/item/${itemID}/viewTable`);
export const createTable = () => requestLayer.post('sheet');

export const checkLiveChatSupport = () => requestLayer.post(`https://${window.location.hostname}/server/check-olark-chat-support?from=app-builder`);

export const aiFetchChatMessages = appID => requestLayer.get(`/ai-builder/portal/messages/${appID}`);

export const aiAddChatMessage = ({ appID, message }) => requestLayer.post(`/ai-builder/portal/messages/${appID}`, { message });

export const fetchUserManagementUsers = appID => requestLayer.get(`portal/${appID}/users`);

export const portalUserAddCurrentUser = appID => requestLayer.get(`portaluser/portal/${appID}/add-current-user`);
