import React from 'react';
import { translationRenderer } from '@jotforminc/translation';
import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Stripe ACH gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlStripeACH: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_stripeACH: {
    copyUrl: {
      type: 'copyUrl',
      steps: [
        translationRenderer('Log into your [1[{text}]] account')({
          renderer1: () => <b className="color-white">Stripe</b>
        }),
        translationRenderer('Go to [1[{text}]] section')({
          renderer1: () => <b className="color-white">Developers</b>
        }),
        translationRenderer('Click [1[{text}]]')({
          renderer1: () => <b className="color-white">Webhooks</b>
        }),
        translationRenderer('Click [1[{text}]] button')({
          renderer1: () => <b className="color-white">Add endpoint</b>
        }),
        translationRenderer('Set [1[{text}]] to the URL below')({
          renderer1: () => <b className="color-white">Payment Response URL</b>
        })
      ],
      url: 'ipn.jotform.com/ipns/stripeACH_Plaid.php',
      urlEnterprise: `${window.location.hostname}/ipns/stripeACH_Plaid.php`,
      resources: ['FORM', 'APP']
    }
  }
};

export default controlStripeACH;
