import React, { useEffect } from 'react';
import { Badge } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import { IconExclamationCircleFilled } from '@jotforminc/svg-icons';
import ComponentRenderer from './ComponentRenderer';
import { TBuilderDropdown } from '../../../../types/common';
import usePaymentPropsPanel from '../Context/usePaymentProperties';
import { SCROLLABLE_CONTAINER_CLASSLIST } from '../Constants';

interface PROPS {
  BuilderDropdown: TBuilderDropdown;
  BuilderImageUpload: React.ComponentType;
  BuilderToggle: React.ComponentType;
}

const GatewayResourceProperties = ({
  BuilderDropdown,
  BuilderImageUpload,
  BuilderToggle
}: PROPS): JSX.Element => {
  const {
    gatewayResourceProps,
    invalidPropNames,
    scrollBottomForError,
    setScrollBottomForError,
    resource
  } = usePaymentPropsPanel();

  useEffect(() => {
    const scrollableContainer = document.querySelector(SCROLLABLE_CONTAINER_CLASSLIST[resource]);
    if (
      invalidPropNames.length > 0
      && scrollBottomForError
      && scrollableContainer
    ) {
      scrollableContainer.scroll({
        top: 1000,
        behavior: 'smooth'
      });
      setScrollBottomForError(false);
    }
  }, [invalidPropNames]);

  return (
    <>
      {
        Object.entries(gatewayResourceProps).map(item => {
          return (
            <ComponentRenderer
              key={item[0]}
              name={item[0]}
              props={item[1]}
              BuilderDropdown={BuilderDropdown}
              BuilderToggle={BuilderToggle}
              BuilderImageUpload={BuilderImageUpload}
            />
          );
        })
      }
      {
        invalidPropNames.length > 0 && (
          <Badge
            rounded="false"
            colorStyle="error"
            className="flex justify-center mx-4 mb-6 text-sm tracking-lg xs:py-4 py-5 h-0"
            icon={() => <IconExclamationCircleFilled className="w-4 h-4 shrink-0 xs:mr-0 mr-2" />}
          >
            <span className='wrap-balance'>
              {t('Please fill all the missing fields before continuing.')}
            </span>
          </Badge>
        )
      }
    </>
  );
};

export default GatewayResourceProperties;
