/* eslint-disable complexity, camelcase */
import React, { useEffect, useState } from 'react';
import isPlainObject from 'lodash/isPlainObject';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { IconInfoCircleFilled, IconBuildings } from '@jotforminc/svg-icons';
import { t, translationRenderer } from '@jotforminc/translation';

import { MODE_INDICATORS } from '../../Constants';
import usePaymentPropsPanel from '../../Context/usePaymentProperties';
import { CONNECTION_LIST_DATA } from '../../../../../types/common';

// COMPONENTS
import AddConnectionButton from './AddConnectionButton';
import SectionHeader from './SectionHeader';
import ProductListConnectionWarning from './ProductListConnectionWarning';
import GatewayNotConnectedWarning from './GatewayNotConnectedWarning';
import ConnectionInfo from './ConnectionInfo';
import ConnectionStatusWarning from './ConnectionStatusWarning';
import ConnectionOwnerIndicator from './ConnectionOwnerIndicator';
import ConnectionModalButton from './ConnectionModalButton';
import RemoveConnectionButton from './RemoveConnectionButton';
import ConnectionGatewayInfo from './ConnectionGatewayInfo';

const Divider = () => <div className='h-px bg-gray-500 mt-3 mb-3' />;

const PaymentConnectionSection = (): JSX.Element => {
  const {
    questionProperties: {
      type,
      nameAPM,
      paymentConnectionID
    },
    getConnectionInfo,
    hasConnection,
    gatewayType,
    isCollaborator,
    isJotformerAccessingUserResource,
    user,
    isInTeamAsset
  } = usePaymentPropsPanel();

  const hasPaymentGateway = gatewayType !== 'control_payment';

  const [connectionInfo, setConnectionInfo] = useState<CONNECTION_LIST_DATA>({
    title: null,
    sandbox: null,
    hasConnectionInfo: false,
    status: '0',
    scope: null,
    scope_name: null,
    scope_fullname: null,
    is_gateway_disabled: false
  });

  useEffect(() => {
    if (hasConnection) {
      const setConnectionInfoHandler = async () => {
        const data = await getConnectionInfo();
        if (isPlainObject(data)) {
          const {
            title,
            sandbox,
            status,
            scope,
            scope_fullname,
            scope_name,
            is_gateway_disabled = false
          } = data;
          setConnectionInfo({
            title,
            sandbox,
            hasConnectionInfo: true,
            status,
            scope,
            scope_fullname,
            scope_name,
            is_gateway_disabled
          });
        }
      };

      setConnectionInfoHandler();
    }
  }, [paymentConnectionID]);

  const modeIndicator = MODE_INDICATORS.find(item => item.sandbox === connectionInfo.sandbox);
  const disabledBtnClass = 'opacity-40 cursor-not-allowed';

  const isProductList = type === 'control_payment' && !nameAPM;
  const showPLConnectionWarning = !hasConnection && isProductList;

  const isConnectionFailed = connectionInfo.status === '3'; // || connectionInfo.status === '5'

  // connection owner indicator
  const hasConnScope = connectionInfo.scope && connectionInfo.scope_fullname;
  const notConnOwner = (hasConnScope && user.username !== connectionInfo.scope_name) || false;
  const isUserConnectionOwner = (hasConnScope && connectionInfo.scope === 'user' && user.username === connectionInfo.scope_name) || false;
  const showConnOwnerIndicator = hasConnection && hasConnScope && (isInTeamAsset || notConnOwner);

  const resetConnectionInfo = () => setConnectionInfo({
    title: null,
    sandbox: null,
    hasConnectionInfo: false,
    status: '0',
    scope: null,
    scope_fullname: null,
    scope_name: null,
    is_gateway_disabled: false
  });

  return (
    <div className='ml-4 mr-4 mt-6'>
      <SectionHeader />
      {
        hasPaymentGateway ? (
          <div className="bg-gray-700 p-4 radius">
            <div className="flex flex-row h-10 items-center justify-between">
              <ConnectionGatewayInfo />
              <div className='shrink-0'>
                <ConnectionModalButton
                  disabledBtnClass={disabledBtnClass}
                />
                <RemoveConnectionButton
                  resetConnectionInfo={resetConnectionInfo}
                  disabledBtnClass={disabledBtnClass}
                />
              </div>
            </div>
            {
              !hasConnection && !isCollaborator && !isJotformerAccessingUserResource && (
                <GatewayNotConnectedWarning />
              )
            }
            {
              hasConnection && modeIndicator && (
                <>
                  <Divider />
                  <ConnectionInfo
                    modeIndicator={modeIndicator}
                    connectionInfo={connectionInfo}
                    disabled={isConnectionFailed}
                  />
                  {isEnterprise() && connectionInfo.is_gateway_disabled === true && (
                    <div className='bg-gray-500 mt-3 p-2 radius text-xs'>
                      <div className='color-white flex justify-start gap-1 items-center'>
                        <IconInfoCircleFilled className='shrink-0 w-4 h-4' />
                        {t('Gateway disabled!')}
                      </div>
                      <div className='flex items-center gap-1 mt-1 ml-5 color-gray-100'>
                        {
                          translationRenderer('This gateway is disabled by your [1[{organization}]]')({
                            renderer1: () => (
                              <span className='color-orange-400 flex items-center gap-1'>
                                <IconBuildings className="color-current shrink-0 w-4 h-4" />
                                {' '}
                                Organization
                              </span>
                            )
                          })
                        }
                      </div>
                    </div>
                  )}
                </>
              )
            }
            {
              isConnectionFailed && (
                <ConnectionStatusWarning
                  isUserConnectionOwner={isUserConnectionOwner}
                  notConnOwner={notConnOwner}
                  connectionInfo={connectionInfo}
                />
              )
            }
            {
              showConnOwnerIndicator && (
                <ConnectionOwnerIndicator
                  connectionInfo={connectionInfo}
                />
              )
            }
          </div>
        ) : (
          <AddConnectionButton disabledBtnClass={disabledBtnClass} />
        )
      }
      { showPLConnectionWarning && <ProductListConnectionWarning /> }
    </div>
  );
};

export default PaymentConnectionSection;
