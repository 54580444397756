export const loadWidgetImageInParentWindow = imageUrl => {
  let url;
  try {
    url = new URL(imageUrl, window.location.origin);
    url.hostname = window.location.hostname;
    url = url.toString();
  } catch (e) {
    url = imageUrl;
  }
  return new Promise((resolve, reject) => {
    var xhr = new window.XMLHttpRequest();

    xhr.open('GET', url, true);
    xhr.withCredentials = true;
    xhr.responseType = 'blob';
    xhr.onerror = reject;
    xhr.onload = function () {
      var reader = new global.FileReader();
      reader.onloadend = function () {
        if (typeof reader.result === 'string' && (reader.result.substr(0, 10) === 'data:image' || reader.result.substr(0, 29) === 'data:application/octet-stream')) {
          resolve(reader.result.replace('data:application/octet-stream', 'data:image/png'));
        } else {
          reject(new Error('Blob is not an image'));
        }
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.send();
  });
};

export const isBase64LoadedField = (msg, clientId) => (!!msg.enterprise || msg.isHipaa) && [
  '529467003477f3512000001f', // Smooth Signature
  '533a8c19a3f5fec35d00009a', // Initials
  '5d493b3b3ecd623d69000045', // Annotate Picture
  '535a49d40a05fdff5200002b' // Draw on Image
].includes(clientId);
