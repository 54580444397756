/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';

const ClassicFormIcon = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="20" y="16" width="24" height="32" rx="4" fill="white" />
      <rect x="24" y="25" width="16" height="3" rx="1.5" fill="#C8CEED" />
      <rect x="24" y="30.5" width="16" height="3" rx="1.5" fill="#C8CEED" />
      <rect x="24" y="36" width="16" height="3" rx="1.5" fill="#C8CEED" />
      <rect className="anim blue" x="24" y="25" width="8" height="3" rx="1.5" fill="#0099FF" opacity="0" />
      <rect className="anim orange" x="24" y="30.5" width="6" height="3" rx="1.5" fill="#FF6100" opacity="0" />
      <rect className="anim yellow" x="24" y="36" width="3" height="3" rx="1.5" fill="#FFB629" opacity="0" />
    </svg>
  );
};

export default ClassicFormIcon;
