import React from 'react';
import type { GATEWAY_QUESTION_PROPERTIES } from '@jotforminc/payment-gateways';
import { PaymentPropsPanelProvider } from './Context/usePaymentProperties';
import {
  PaymentConnectionSection,
  GatewayResourceProperties,
  PaymentTypeDropdown,
  CurrencyDropdown,
  FooterContainer
} from './Components';
import '../../../styles/editor.scss';
import {
  RESOURCES,
  TBuilderDropdown,
  T_ONPAYMENTTYPECHANGE,
  T_ONSAVEGATEWAY,
  T_ONDETACHCONNECTION,
  T_ONAUTOSAVEGATEWAY,
  T_GETQIDONADDQUESTION,
  T_TOGGLECONNECTIONMODAL,
  TFormType,
  IUser
} from '../../../types/common';

const Divider = () => (<div className="h-px bg-gray-700 mt-6 mb-6" />);

interface IPaymentPropertiesPanel {
  checkoutFormID: string;
  products: object[],
  formType: TFormType;
  questionProperties: GATEWAY_QUESTION_PROPERTIES;
  BuilderDropdown: TBuilderDropdown;
  BuilderImageUpload: React.ComponentType;
  BuilderToggle: React.ComponentType;
  resource: RESOURCES;
  formQuestions: [];
  user: IUser;
  userIsResourceOwner: boolean;
  onPaymentTypeChange: T_ONPAYMENTTYPECHANGE;
  onSaveGateway: T_ONSAVEGATEWAY;
  onDetachConnection: T_ONDETACHCONNECTION;
  onAutosaveGateway: T_ONAUTOSAVEGATEWAY;
  getQidOnAddQuestion: T_GETQIDONADDQUESTION;
  togglePaymentConnectionModal: T_TOGGLECONNECTIONMODAL;
  // eslint-disable-next-line react/require-default-props
  hasConnectionToTheGatewayType?: (val: boolean) => void;
}

const PaymentPropertiesPanel = ({
  checkoutFormID,
  products,
  formType,
  questionProperties,
  BuilderDropdown,
  BuilderToggle,
  BuilderImageUpload,
  resource,
  formQuestions,
  user,
  userIsResourceOwner,
  onPaymentTypeChange,
  onSaveGateway,
  onDetachConnection,
  onAutosaveGateway,
  getQidOnAddQuestion,
  togglePaymentConnectionModal,
  hasConnectionToTheGatewayType
}: IPaymentPropertiesPanel): JSX.Element => {
  const { isPaymentStoreInBasicFields = true } = questionProperties;
  const showPaymentTypeDD = ['FORM'].includes(resource) && !isPaymentStoreInBasicFields;
  return (
    <PaymentPropsPanelProvider
      questionProperties={questionProperties}
      formQuestions={formQuestions}
      checkoutFormID={checkoutFormID}
      products={products}
      formType={formType}
      resource={resource}
      onPaymentTypeChange={onPaymentTypeChange}
      onSaveGateway={onSaveGateway}
      onDetachConnection={onDetachConnection}
      onAutosaveGateway={onAutosaveGateway}
      getQidOnAddQuestion={getQidOnAddQuestion}
      togglePaymentConnectionModal={togglePaymentConnectionModal}
      hasConnectionToTheGatewayType={hasConnectionToTheGatewayType}
      user={user}
      userIsResourceOwner={userIsResourceOwner}
    >
      <div className={`paymentPropertiesPanel ${resource.toLowerCase()}`}>
        <PaymentConnectionSection />
        <Divider />
        {
          showPaymentTypeDD && (
            <PaymentTypeDropdown
              BuilderDropdown={BuilderDropdown}
              resource={resource}
            />
          )
        }
        <CurrencyDropdown />
        <Divider />
        <GatewayResourceProperties
          BuilderDropdown={BuilderDropdown}
          BuilderImageUpload={BuilderImageUpload}
          BuilderToggle={BuilderToggle}
        />
        <FooterContainer />
      </div>
    </PaymentPropsPanelProvider>
  );
};

export default PaymentPropertiesPanel;
