import React, {
  useEffect,
  useState,
  ChangeEvent,
  useMemo
} from 'react';
import { IconInfoCircleFilled } from '@jotforminc/svg-icons';
import { gatewayFieldToName } from '@jotforminc/payment-constants';
import type { SUPPORTED_CURRENCIES } from '@jotforminc/payment-constants';
import type { DROPDOWN_COMPONENT_PROPERTIES } from '@jotforminc/payment-gateways';
import { t, translationRenderer } from '@jotforminc/translation';
import usePaymentPropsPanel from '../Context/usePaymentProperties';
import { TBuilderDropdown } from '../../../../types/common';
import { getMollieProfiles, getSquareLocations } from '../../../../api';
import Dropdown from '../../../fields/selectbox';

interface IDropdown {
  BuilderDropdown: TBuilderDropdown;
  onChangeWithData: (name: string) => void;
  ariaLabel: string;
  className: string;
  testId: string;
  value: string;
  propName: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
}

type WARNING_SETTINGS = {
  isVisible: boolean;
  propText: string | null;
  valueText: string | null;
};

const TEXT_BUSINESS_LOCATION = 'business location';
const TEXT_PROFILEID = 'profile ID';
const PROP_TEXTS = {
  control_square: TEXT_BUSINESS_LOCATION,
  control_applePayGooglePay: TEXT_BUSINESS_LOCATION,
  control_cashApp: TEXT_BUSINESS_LOCATION,
  control_afterpay: TEXT_BUSINESS_LOCATION,
  control_clearpay: TEXT_BUSINESS_LOCATION,
  control_mollie: TEXT_PROFILEID
};

const DynamicDropdown: React.FunctionComponent<IDropdown & DROPDOWN_COMPONENT_PROPERTIES> = ({
  propName,
  onChangeWithData,
  BuilderDropdown,
  default: defaultValue,
  ariaLabel,
  className,
  testId,
  value,
  onChange
}) => {
  const {
    checkoutFormID,
    hasConnection,
    getConnectionInfo,
    setGatewayProperty,
    setMultipleGatewayProperties,
    gatewayType,
    resource
  } = usePaymentPropsPanel();

  const DEFAULT_OPTION = [{ id: '0', key: '0', text: t(`Connect to ${gatewayFieldToName[gatewayType]} first`) }];

  const [options, setOptions] = useState([]);
  const [warningSettings, setWarningSettings] = useState<WARNING_SETTINGS>({ isVisible: false, propText: null, valueText: null });

  useEffect(() => {
    if (hasConnection) {
      const handleNotOptionFound = (isNotFound: boolean, val: string, valueText: string, currency?: SUPPORTED_CURRENCIES) => {
        if (isNotFound) {
          setWarningSettings({ ...warningSettings, ...{ isVisible: true, propText: PROP_TEXTS[gatewayType], valueText } });
          setGatewayProperty(propName, val, true); // autosave
        } else if (!value || value === '') {
          setMultipleGatewayProperties({
            [propName]: val,
            ...(currency && { isMerchantAccountCurrency: 'Yes', currency })
          }, true); // autosave
        }
      };

      switch (gatewayType) {
        case 'control_mollie':
          const setMollieOptions = async () => {
            const res = await getMollieProfiles(checkoutFormID);

            if (res) {
              const parsed = JSON.parse(res);
              const profileIds = parsed.map((option: { id: string, name: string }) => {
                return { id: option.id, key: option.id, text: option.name };
              });
              setOptions(profileIds);

              const { id: val, name: valueText } = parsed[0];
              handleNotOptionFound(parsed.notFound === 1, val, valueText);
            }
          };
          setMollieOptions();
          break;
        case 'control_square':
        case 'control_applePayGooglePay':
        case 'control_cashApp':
        case 'control_afterpay':
        case 'control_clearpay':
          const setSquareOptions = async () => {
            const { sandbox } = await getConnectionInfo();
            const res = await getSquareLocations(sandbox === '1' ? 'true' : 'false', checkoutFormID);

            const locations = res && res.message && res.message.locations;
            if (locations) {
              const locationIds = locations.map((option: { id: string, name: string }) => {
                return { id: option.id, key: option.id, text: option.name };
              });
              setOptions(locationIds);

              const { id: val, name: valueText, currency } = locations[0];
              handleNotOptionFound(res.message.notFound === 1, val, valueText, currency);
            }
          };
          setSquareOptions();
          break;
        default:
          break;
      }
    }
  }, [hasConnection]);

  const appOpts = useMemo(() => {
    return options ? options.reduce((prev: Array<{ value: string, text: string }>, acc: { key: string, text: string }) => {
      return [...prev, { value: acc.key, text: acc.text }];
    }, []) : [];
  }, [options]);

  const WarningBox = () => {
    return (
      <div className='color-yellow-500 flex flex-row mt-2'>
        <IconInfoCircleFilled className='min-w-4 h-4 mr-1' />
        <div className='text-xs'>
          {
            translationRenderer(`Your [1[${warningSettings.propText}]] is set to “[2[${warningSettings.valueText}]]” due to the changes you have made in your gateway account.`)({
              renderer1: (text: string) => <span>{text}</span>,
              renderer2: (text: string) => <span className='color-white'>{text}</span>
            })
          }
        </div>
      </div>
    );
  };

  if (resource !== 'FORM') {
    return (
      <>
        <Dropdown
          disabled={appOpts.length === 0}
          defaultValue={value}
          options={appOpts.length > 0 ? appOpts : DEFAULT_OPTION}
          onChange={onChangeWithData}
        />
        {warningSettings.isVisible && <WarningBox />}
      </>
    );
  }

  return (
    <>
      <BuilderDropdown
        aria-label={ariaLabel}
        disabled={options.length === 0}
        className={className}
        testId={testId}
        options={options.length > 0 ? options : DEFAULT_OPTION}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
      />
      {warningSettings.isVisible && <WarningBox />}
    </>
  );
};

export default DynamicDropdown;
