import React from 'react';
import {
  func, arrayOf, object, string
} from 'prop-types';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { AppPreviewBody } from '../../../AppPreview';

import '../../../AppPreview/styles/appPreview.scss';
import './style.scss';

import SimilarTemplatesItem from './SimilarTemplatesItem';

const PageTemplatePreviewContent = ({
  template, categoryData, onTemplateClone,
  similarTemplates, setModalContentLoading, modalBodyRef, frameSrc
}) => {
  const templateType = frameSrc.split('/')[1];
  const { id = '' } = template;

  const { description = '' } = template;

  const pageIsReady = Object.keys(categoryData).length > 0;

  return (
    <div className="jfModal-content">
      <AppPreviewBody
        currentThemeId={id}
        template={template}
        showAsTemplatePreview={true}
        forStandalonePageTemplate={true}
        modalContentLoading={pageIsReady}
      />
      {
        (Object.keys(template).length > 0) && (
          <div className='content-area'>
            {!isEnterprise() && (
              <>
                <div className='jfWizard-modal-detail'>
                  <div className='description-area' dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              </>
            )}
            <SimilarTemplatesItem
              skeletonCount={2}
              parentTemplateID={id}
              templateType={templateType}
              modalBodyRef={modalBodyRef}
              categoryData={categoryData}
              onTemplateClone={onTemplateClone}
              similarTemplates={similarTemplates}
              setModalContentLoading={setModalContentLoading}
            />
          </div>
        )
      }
    </div>
  );
};

PageTemplatePreviewContent.defaultProps = {
  template: {},
  frameSrc: '/',
  categoryData: null,
  similarTemplates: [],
  modalBodyRef: undefined,
  onTemplateClone: () => { }
};

PageTemplatePreviewContent.propTypes = {
  template: object,
  frameSrc: string,
  categoryData: object,
  modalBodyRef: object,
  onTemplateClone: func,
  similarTemplates: arrayOf(object),
  setModalContentLoading: func.isRequired
};

export default PageTemplatePreviewContent;
