import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Moneris gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlMoneris: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_moneris: {
    askBillingInformation: {
      title: 'Ask Billing Information to Customer',
      description: 'Automate collecting billing information with form fields.',
      type: 'toggle',
      selectedCondition: settings => settings.askBillingInformation === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM']
    },
    emailField: {
      title: 'Customer Email Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    billToAddress: {
      title: 'Billing Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      addressSubfields: 'state|zip|st1|city|st2',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    shipToName: {
      title: 'Shipping Name',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_fullname'],
      questionTypeToAdd: 'control_fullname',
      addressSubfields: 'state|zip|st1|city|st2',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    shipToAddress: {
      title: 'Shipping Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    customerField: {
      title: 'Customer Descriptor',
      type: 'dropdown',
      subType: 'formQuestion',
      questionTypesAsOption: ['control_textbox'],
      default: 'none',
      resources: ['FORM'],
      renderCondition: formQuestions => Array.isArray(formQuestions) && formQuestions.filter(q => ['control_textbox'].includes(q.type)).length > 0
    },
    orderIDDescriptor: {
      title: 'Order ID Descriptor',
      type: 'dropdown',
      subType: 'formQuestion',
      questionTypesAsOption: ['control_textbox'],
      default: 'none',
      resources: ['FORM'],
      renderCondition: formQuestions => Array.isArray(formQuestions) && formQuestions.filter(q => ['control_textbox'].includes(q.type)).length > 0
    },
    orderIDPrefix: {
      title: 'Order Prefix',
      type: 'input',
      infoText: 'This prefix will be appended to the beginning of the Order ID that\'s sent to Moneris.',
      maxLength: 10,
      default: 'Credit Card',
      resources: ['FORM', 'APP']
    },
    ccTitle: {
      title: 'Credit Card Label Text',
      type: 'input',
      default: 'Credit Card',
      resources: ['FORM', 'APP']
    }
  }
};

export default controlMoneris;
