import {
  call, put, select, takeEvery
} from 'redux-saga/effects';
import * as ACTION_TYPES from '../actionTypes';
import SELECTORS from '../selectors';
import * as API from '../../modules/api';
import { isFeatureEnabled } from '../../utils/features/helper';
import { FEATURE_NAMES } from '../../constants/features';
import { AiChangesdList } from './helper';
import { AB_TESTS, logAbTestActionFor } from '../../utils/abtests';

const AIMessages = {
  SOMETHING_WENT_WRONG: 'Sorry, something went wrong. Please try again later.'
};

function* fetchAIChatMessages() {
  const appID = yield select(SELECTORS.getAppID);

  try {
    const { messages } = yield call(API.aiFetchChatMessages, appID);
    if (!messages) {
      console.error('Expected messages on fetch chat messages but received: ', messages);
      return;
    }
    yield put({ type: ACTION_TYPES.AI_SET_MESSAGES, payload: messages });
  } catch (err) {
    console.log(err);
  }
}

function* addChatMessage({ payload }) {
  const appID = yield select(SELECTORS.getAppID);
  const { prompt: message } = payload;

  try {
    const { messages, portal, changes } = yield call(API.aiAddChatMessage, { appID, message });
    if (!messages) {
      console.error('Expected messages on add chat message but received: ', messages);
      yield put({ type: ACTION_TYPES.AI_SHOW_ERROR_MESSAGE, payload: AIMessages.SOMETHING_WENT_WRONG });
      return;
    }
    yield put({ type: ACTION_TYPES.AI_SET_MESSAGES, payload: messages });
    if (portal) {
      yield put({ type: ACTION_TYPES.UPDATE_PORTAL.SUCCESS, payload: portal });
    }
    if (changes) {
      const newMessages = AiChangesdList(messages, changes);
      if (newMessages) {
        yield put({ type: ACTION_TYPES.AI_SET_MESSAGES, payload: newMessages });
      }
    }
    document.querySelector('.ai-chat-input')?.focus();
  } catch (err) {
    console.error({ err });
    yield put({ type: ACTION_TYPES.AI_SHOW_ERROR_MESSAGE, payload: AIMessages.SOMETHING_WENT_WRONG });
  }
}

function* aiAssistantFlow({ payload }) {
  const { aiIsShow } = payload;
  const isBuilder = yield select(SELECTORS.getIsBuilder);
  const isAsisstantEnabledUser = isFeatureEnabled(FEATURE_NAMES.AiAssistant);
  if (isAsisstantEnabledUser && isBuilder && aiIsShow) {
    yield fetchAIChatMessages();
  }
}

function logAbTestAction(params) {
  return function* logActionFor() {
    yield call(logAbTestActionFor, AB_TESTS.AiAssistant, params);
  };
}

export default function* aiAssistant() {
  yield takeEvery(ACTION_TYPES.AI_SHOW_ASSISTANT, aiAssistantFlow);
  yield takeEvery(ACTION_TYPES.AI_ADD_CHAT_MESSAGE, addChatMessage);

  yield takeEvery(ACTION_TYPES.AI_SHOW_ASSISTANT, logAbTestAction({ action: 'click', target: 'copilot-button' }));
  yield takeEvery(ACTION_TYPES.ADD_PORTAL_ITEMS.REQUEST, logAbTestAction({ action: 'click', target: 'add-element' }));
  yield takeEvery(ACTION_TYPES.REMOVE_PORTAL_ITEMS.REQUEST, logAbTestAction({ action: 'click', target: 'remove-element' }));
  yield takeEvery(ACTION_TYPES.ADD_NEW_PAGE.REQUEST, logAbTestAction({ action: 'click', target: 'add-page' }));
  yield takeEvery(ACTION_TYPES.AI_ADD_CHAT_MESSAGE, logAbTestAction({ action: 'click', target: 'send-message' }));
}
