import React, { useEffect, useState, useMemo } from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';
import { IconInfoCircleFilled } from '@jotforminc/svg-icons';

/** Context */
import { useGatewayConnection } from '../../../context';

/** utils */
import { isMobile } from '../../../utils/functions/isMobile';
import { isJotformMobileApp } from '../../../utils/functions/isJotformMobileApp';

type MANAGE_CONNECTION_ROLE = 'default' | 'teamAdmin' | 'orgAdmin';

const MANAGE_CONNECTIONS = {
  default: {
    text: t('My Account'),
    link: '/myaccount/connections'
  },
  teamAdmin: {
    text: t('Team Settings'),
    link: `/myaccount/team/${window.teamID}/connections`
  },
  orgAdmin: {
    text: t('Admin Console'),
    link: '/enterprise-admin/'
  }
};

export const ModalFooter = (): any => {
  const {
    currentlyStep,
    currentlyGateway,
    currentlyFlow,
    currentlyGatewayProperties,
    currentlyConnection,
    modalSource,
    isWaitingNextStep,
    isTeamMemberAndCreator,
    isTeamAdmin,
    isOrganizationAdmin,
    nextStep,
    backStep,
    connectedId,
    selectedConnectionId,
    selectedChildGatewayType,
    connectionError,
    connectionState,
    showOrHideDiscardNewConnectionDialog
  } = useGatewayConnection();
  const [disableNextOrUseButton, setDisableNextOrUseButton] = useState<boolean>(false);
  const [isShowBackButton, setIsShowBackButton] = useState<boolean>(true);

  useEffect(() => {
    /**
     * Determines whether the "Next" or "Use" button should be enabled based on the current step and selected gateway.
     * @returns {boolean} True if the button should be enabled, false otherwise.
     */
    const isDisableNextOrUseButton = (): boolean => {
      switch (currentlyStep) {
        case 'gatewayPicker':
          return !currentlyGateway;
        case 'apmGatewayPicker':
          if (currentlyGateway && currentlyGatewayProperties) {
            const { isApm, isChildTypes, childTypes } = currentlyGatewayProperties;
            if (isApm === true && isChildTypes === true && childTypes.length > 0) {
              return !selectedChildGatewayType;
            }
          }

          return false;
        case 'gatewayConnection':
          if (connectionError || connectionState !== 'connected' || !connectedId) {
            return true;
          }
          return false;
        case 'connectionPicker':
          if (selectedConnectionId) {
            return false;
          }
          return true;
        default:
          return false;
      }
    };

    /**
     * Determines whether to show the back button for the current step.
     * @returns {boolean} True if the back button should be shown, false otherwise.
     */
    const isShowBackButtonForThisStep = (): boolean => {
      switch (currentlyFlow) {
        case 'GATEWAY_TO_CONNECTION':
          if (currentlyStep === 'gatewayPicker') {
            return false;
          }

          if (currentlyStep === 'connectionPicker' && connectedId && connectionState === 'connected') {
            return false;
          }
          return true;
        case 'CONNECTTED_ACCOUNT_TO_CONNECTION':
          if (currentlyStep === 'connectionPicker') {
            return false;
          }

          return true;
        case 'EDIT_CONNECTION':
        case 'RE_CONNECT':
          return false;
        default:
          return true;
      }
    };

    setDisableNextOrUseButton(isDisableNextOrUseButton());
    setIsShowBackButton(isShowBackButtonForThisStep());
  }, [
    currentlyStep,
    currentlyGateway,
    currentlyFlow,
    currentlyGatewayProperties,
    isWaitingNextStep,
    connectedId,
    selectedConnectionId,
    selectedChildGatewayType,
    connectionError,
    connectionState
  ]);

  /**
   * Determines the text to be displayed on the "Next" button based on the current step and flow.
   * @returns {string} The text to be displayed on the "Next" button.
   */
  const NextText = (): string => {
    switch (currentlyStep) {
      case 'connectionPicker':
        if (currentlyConnection && currentlyConnection.inUse) {
          return t('Switch');
        }

        if (modalSource && modalSource === 'MY_ACCOUNT' && currentlyFlow === 'GATEWAY_TO_CONNECTION') {
          return t('Save');
        }

        return 'Use';
      case 'gatewayConnection':
        if (modalSource
          && modalSource === 'MY_ACCOUNT'
          && (currentlyFlow === 'GATEWAY_TO_CONNECTION' || currentlyFlow === 'EDIT_CONNECTION' || currentlyFlow === 'RE_CONNECT')
        ) {
          return t('Save');
        }
        return t('Next');
      default:
        return t('Next');
    }
  };

  /**
   * Determines the text to be displayed on the "Back" button based on the current step.
   * @returns {string} The text to be displayed on the "Back" button.
   */
  const BackText = (): string => {
    switch (currentlyStep) {
      default:
        return t('Back');
    }
  };

  const { text, link } = useMemo(() => {
    let role: MANAGE_CONNECTION_ROLE = 'default';
    if (isOrganizationAdmin || isTeamAdmin) {
      role = 'teamAdmin';
    }
    return MANAGE_CONNECTIONS[role];
  }, [isOrganizationAdmin, isTeamAdmin]);

  return (
    <>
      {
        isShowBackButton && (
          <Button
            colorStyle="secondary"
            variant="outline"
            className='w-24'
            onClick={() => {
              if (currentlyStep === 'gatewayConnection' && connectionState === 'connected') {
                showOrHideDiscardNewConnectionDialog('backOrClose');
                return;
              }
              backStep();
            }}
            data-test-id="back_button"
          >
            {BackText()}
          </Button>
        )
      }
      {
        !isMobile() && !isJotformMobileApp() && currentlyStep === 'connectionPicker' && !isShowBackButton && !isTeamMemberAndCreator && (
          <div>
            <div className='flex justify-start items-center gap-1'>
              <IconInfoCircleFilled className='w-4 h-4 color-navy-300 shrink-0' />
              <span className='color-navy-300 text-sm line-height-md'>
                {
                  translationRenderer('Manage your payment connections from [1[{link}]].')({
                    renderer1: () => (
                      <a
                        href={link}
                        className='color-blue-500 font-medium cursor-pointer hover:underline'
                        target='_blank'
                        data-test-id="go_to_myaccount"
                      >
                        {text}
                      </a>
                    )
                  })
                }
              </span>
            </div>
          </div>
        )
      }
      {(currentlyFlow === 'GATEWAY_TO_CONNECTION' || currentlyFlow === 'EDIT_CONNECTION' || currentlyFlow === 'RE_CONNECT') && !isShowBackButton && (<span />)}
      <Button
        colorStyle="success"
        variant="filled"
        className="w-24"
        disabled={disableNextOrUseButton}
        onClick={() => {
          if (
            currentlyStep === 'connectionPicker'
              && currentlyConnection
              && currentlyConnection.inUse
              && selectedConnectionId
              && currentlyConnection.id !== selectedConnectionId
          ) {
            showOrHideDiscardNewConnectionDialog('switchGateway');
            return;
          }
          nextStep();
        }}
        loader={isWaitingNextStep}
        data-test-id="next_button"
      >
        {NextText()}
      </Button>
    </>
  );
};
