import {
  useEffect,
  useState,
  useRef
} from 'react';
import { ABTestManager } from '@jotforminc/abtest-manager';

export const useABTestManager = options => {
  const [isLoading, setIsLoading] = useState(true);
  const [isTestVariant, setIsTestVariant] = useState(false);
  const abTestActionRef = useRef(f => f);

  useEffect(() => {
    const abTestManager = new ABTestManager(options);
    abTestManager.isTestVariant().then(setIsTestVariant).finally(() => setIsLoading(false));
    abTestActionRef.current = abTestManager.registerABTestAction;
  }, []);

  return [isLoading, isTestVariant, abTestActionRef.current];
};

export const useOutsideClick = callback => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('mouseup', handleClickOutside);
    document.addEventListener('touchend', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, [callback]);

  return ref;
};
