import {
  call, take, takeLatest, delay, race, put
} from 'redux-saga/effects';
import { SET_API_REQUESTS_COMPLETED } from '../actionTypes';
import { COLLABORATION } from '../../constants';
import { showGenericModalAction } from '../actionCreators';
import { MODALS } from '../../constants/modals';

function* collaborationFlow() {
  yield takeLatest(SET_API_REQUESTS_COMPLETED, function* () {
    yield race({
      task: call(function* () {
        yield delay((COLLABORATION.TIMEOUT_SECOND - COLLABORATION.COUNTDOWN_SECOND) * 1000);
        yield put(showGenericModalAction({ name: MODALS.TEAM_STILL_EDITING }));
      }),
      cancel: take(SET_API_REQUESTS_COMPLETED)
    });
  });
}

export default collaborationFlow;
