/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { bool, func, string } from 'prop-types';
import { getDomainURL } from '@jotforminc/request-layer';
import { RESPONSIVE_MODES } from '../core/constants';
import Loader from '../../Loader';

const SinglePreview = ({
  title,
  onLoad,
  viewMode,
  appDetails,
  splashBgColor,
  currentThemeId,
  modalContentLoading,
  forStandalonePageTemplate
}) => {
  let loadingTimeout;
  let loadedObjectStatusTimeout;

  const [loadedObjectStatus, setLoadedObjectStatus] = useState(false);
  const [loadedAnimationStatus, setLoadedAnimationStatus] = useState(false);
  const [loadingAnimationStatus, setLoadingAnimationStatus] = useState(false);

  useEffect(() => {
    if (currentThemeId) {
      setLoadedAnimationStatus(false);
      setLoadingAnimationStatus(false);
      setLoadedObjectStatus(false);
      if (viewMode !== RESPONSIVE_MODES.MOBILE) return;
      loadingTimeout = setTimeout(() => {
        setLoadingAnimationStatus(true);
      }, 2000);
    }
    return () => {
      clearTimeout(loadingTimeout);
      clearTimeout(loadedObjectStatusTimeout);
    };
  }, forStandalonePageTemplate ? [currentThemeId] : [currentThemeId, viewMode]);

  let iconURL = '';
  let fontColor = '';

  try {
    const parsedData = JSON.parse(appDetails);
    iconURL = parsedData?.appIconURL;
    fontColor = parsedData?.appFontColor;
  } catch (e) {
    console.log(e);
  }

  let previewURL = `${getDomainURL()}/app-templates/preview/app/${currentThemeId}?mode=${viewMode}`;
  let previewFrameTitle = 'App Preview';

  if (forStandalonePageTemplate) {
    previewFrameTitle = 'Page Preview';
    previewURL = `${getDomainURL()}/page-templates/preview/${currentThemeId}`;
  }

  if (forStandalonePageTemplate) {
    return (
      <>
        <div className={`page-template-loader${loadedObjectStatus && modalContentLoading ? ' loaded' : ''}`}>
          <Loader />
        </div>
        <object
          className={loadedObjectStatus ? 'active' : ''}
          title={previewFrameTitle}
          key={currentThemeId}
          onLoad={() => {
            loadedObjectStatusTimeout = setTimeout(() => {
              setLoadedObjectStatus(true);
            }, 350);
          }}
          data={previewURL}
        >
          {previewFrameTitle}
        </object>
      </>
    );
  }

  return (
    <>
      <div className={`app-template-loader${loadingAnimationStatus ? ' loading' : ''} ${loadedAnimationStatus ? ' loaded' : ''}`}>
        <div className="app-template-loader-appicon" style={{ backgroundColor: splashBgColor }}>
          {!modalContentLoading && (
            <img loading="lazy" src={iconURL} alt={title} />
          )}
        </div>
        <div className="app-template-loader-name" style={{ color: fontColor }}>{title}</div>
        <div className="app-template-loader-appbg" style={{ backgroundColor: splashBgColor }} />
      </div>
      {
        viewMode === RESPONSIVE_MODES.MOBILE ? (
          loadingAnimationStatus ? (
            <object
              className={loadedObjectStatus ? 'active' : ''}
              title={previewFrameTitle}
              key={currentThemeId}
              onLoad={() => {
                setLoadedAnimationStatus(true);
                loadedObjectStatusTimeout = setTimeout(() => {
                  setLoadedObjectStatus(true);
                }, 350);
              }}
              data={previewURL}
            >
              {previewFrameTitle}
            </object>
          ) : (
            <object title={previewFrameTitle}>
              {previewFrameTitle}
            </object>
          )
        ) : (
          <object
            className='active'
            title={previewFrameTitle}
            key={currentThemeId}
            onLoad={onLoad}
            data={previewURL}
          >
            {previewFrameTitle}
          </object>
        )
      }
    </>
  );
};

SinglePreview.propTypes = {
  onLoad: func,
  title: string,
  viewMode: string.isRequired,
  appDetails: string.isRequired,
  forStandalonePageTemplate: bool,
  splashBgColor: string.isRequired,
  currentThemeId: string.isRequired,
  modalContentLoading: bool.isRequired
};

SinglePreview.defaultProps = {
  onLoad: f => f,
  title: '',
  forStandalonePageTemplate: false
};

export default SinglePreview;
