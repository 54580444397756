import {
  gatewayFieldToName,
  gatewaySupportedCurrencies,
  gatewayResourceSupportedPaymentTypes,
  gatewayListSortedByCountry
} from '../gateway';
import type { GATEWAY_CONNECTION_PROPS } from '../types';

/**
 * Control Authorize.Net gateway connection properties.
 * @type {GATEWAY_CONNECTION_PROPS}
 */
export const controlAuthnet: GATEWAY_CONNECTION_PROPS = {
  gateway_type: 'control_authnet',
  name: gatewayFieldToName.control_authnet,
  supportedCurrencies: gatewaySupportedCurrencies.control_authnet,
  supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_authnet,
  sortByCountry: gatewayListSortedByCountry.control_authnet,

  /** APM's */
  isApm: false,
  isApmSupport: false,
  supportedApm: {},
  isChildTypes: false,
  childTypes: [],
  isReferenceParentGatewayType: false,
  parentType: null,
  /** APM's: End */

  /** Env Settings */
  env: 'production',
  allowNewConnection: true,
  allowResourceTypes: ['APP', 'FORM'],
  isDeprecated: false,

  /** Connection Properties */
  connection: {
    type: 'credentials',
    allowSwitchMode: true,
    mode: {
      name: 'testmodeAuth',
      options: [
        { value: 'Yes', text: 'Test Mode', mappedValue: '1' },
        { value: 'No', text: 'Live Mode', mappedValue: '0' }
      ]
    },
    propReferences: {
      apiLoginId: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'API Login ID' } },
      transactionKey: { isSecret: 'Yes', isRequired: 'Yes', inputLabels: { label: 'Transaction Key' } },
      testmodeAuth: { isSecret: 'No', isRequired: 'Yes' }
    },
    uniquePropNames: ['apiLoginId', 'transactionKey'],
    rateLimit: {
      timeframe: 60, /** in second */
      numberOfRequest: 5, /** number of attempts */
      blockDuration: 180 /** block seconds */
    }
  }
};
