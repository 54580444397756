import {
  all,
  call, put, putResolve, select, take, takeEvery, takeLatest, throttle
} from 'redux-saga/effects';
import { t } from '@jotforminc/translation';
import isEmpty from 'lodash/isEmpty';
import * as ACTION_CREATORS from '../../actionCreators';
import SELECTORS from '../../selectors';
import * as ACTION_TYPES from '../../actionTypes';
import * as API from '../../../modules/api';
import { DESTINATION_TYPES } from '../../../constants/navigation';
import { generateDetailDefaultElements, generateNewPropsForPresentationItem } from './constants';
import { CARD_ACTION_VALUES, CARD_LAYOUT } from '../../../modules/Builder/components/HomePage/RightPanel/constants';
import { ITEM_TYPES } from '../../../constants/itemTypes';
import { DS_ITEM_LOAD_TYPES, DS_ITEM_OFFSET } from '../../reducers/dataSource/constants';
import { pageActions } from '../../watchers/pageActions';
import { safeWorker } from '../../utils';
import { checkMobilePhone } from '../../../utils';
import { MODALS } from '../../../constants/modals';

function* fetchColumns({ payload: { resourceID, viewID } }) {
  if (!resourceID) {
    return;
  }

  try {
    const { columns, title } = yield call(API.fetchColumns, {
      resourceID,
      viewID
    });

    if (columns) {
      yield put(ACTION_CREATORS.dsFetchColumnsSuccess({
        resourceID, viewID, columns, title
      }));
    }
  } catch (err) {
    console.error('NOT IMPLEMENTED', err);
  }
}

function* fetchSourceItemData({
  payload: {
    itemID,
    offset,
    isButtonRoleUpdate = false
  }
}) {
  try {
    const item = yield select(SELECTORS.getPortalItemByIDSelector(itemID));
    if (!itemID || !item) {
      return;
    }

    const appID = yield select(SELECTORS.getAppID);
    const isBuilder = yield select(SELECTORS.getIsBuilder);
    const isMobile = checkMobilePhone();
    const { cardLayout = CARD_LAYOUT.HORIZONTAL } = yield select(SELECTORS.getPortalItemByIDSelector(item.presentationItemID));

    const limit = (() => {
      if (!isBuilder) {
        return DS_ITEM_OFFSET;
      }

      if (isMobile && cardLayout === CARD_LAYOUT.HORIZONTAL) {
        return 3;
      }

      return 4;
    })();

    const itemData = yield call(API.fetchRow, {
      appID, itemID, limit, offset
    });

    if (isBuilder) {
      yield putResolve(ACTION_CREATORS.dsFetchRowSuccess({
        itemID, itemData
      }));

      if (isButtonRoleUpdate) {
        yield put(ACTION_CREATORS.dsSetLoading({ itemID, loadType: DS_ITEM_LOAD_TYPES.INIT, isLoading: false }));
      }
    } else {
      yield put(ACTION_CREATORS.dsFetchSourceItemDataSuccess({
        itemID, itemData
      }));
    }
  } catch (err) {
    yield put(ACTION_CREATORS.dsFetchSourceItemDataError({ itemID }));
    console.error('NOT IMPLEMENTED', err);
  }
}

function* fetchRow({
  payload: {
    itemID, rowID
  }
}) {
  const item = yield select(SELECTORS.getPortalItemByIDSelector(itemID));

  if (!itemID || !item) {
    return;
  }

  try {
    const appID = yield select(SELECTORS.getAppID);
    const itemData = yield call(API.fetchRow, {
      appID, itemID, rowID
    });

    yield put(ACTION_CREATORS.dsFetchRowSuccess({
      itemID, itemData: itemData?.[0]
    }));
  } catch (err) {
    console.error('NOT IMPLEMENTED', err);
  }
}

function* fetchDetailPage({
  payload: {
    pageID,
    divideByItemID
  }
}) {
  try {
    const { linkedItemID } = yield select(SELECTORS.getPageByID(pageID)) ?? {};

    if (!linkedItemID) {
      return;
    }
    const appID = yield select(SELECTORS.getAppID);
    const rowID = yield select(SELECTORS.dsGetActiveListRowID(linkedItemID));
    const data = yield call(API.fetchRow, { appID, itemID: pageID, rowID });
    yield put(ACTION_CREATORS.dsFetchPageDetailSuccess({ pageID, data, divideByItemID }));
  } catch (err) {
    console.error('NOT IMPLEMENTED', err);
  }
}

function* setActiveRowAndNavigateToDetailsPage({ payload: { id, rowID } }) {
  yield put(ACTION_CREATORS.dsSetActiveListRowIDSuccess({ id, rowID }));
  const dsPageProps = yield select(SELECTORS.getDataSourcePage(id));
  if (!dsPageProps) {
    return;
  }

  const isBuilder = yield select(SELECTORS.getIsBuilder);

  const { id: detailsPageID = null } = dsPageProps;
  if (!isBuilder && detailsPageID) {
    yield put(ACTION_CREATORS.navigateToAction({ to: DESTINATION_TYPES.SPECIAL_PAGE, pageID: `details/${detailsPageID}/${rowID}` }));
  }
}

function* createAndLinkDetailsPage({
  payload: {
    itemID, columns, resourceID, viewID
  }
}) {
  yield take(ACTION_TYPES.DS_FETCH_RESOURCE_COLUMNS.SUCCESS);
  const { page } = yield select(SELECTORS.getPortalItemByIDSelector(itemID));
  const { title = '' } = yield select(SELECTORS.dsGetColumnsByResourceID({ resourceID, viewID }));
  const oldDetailPage = yield select(SELECTORS.getDataSourcePage(itemID));

  if (oldDetailPage?.id) {
    yield call(pageActions, { type: ACTION_TYPES.DELETE_PAGE.UNDOABLE, payload: { pageID: oldDetailPage.id, deleteItems: true }, dontStack: true });
  }

  const { pageOrder } = yield select(SELECTORS.getPageByID(page));
  yield call(pageActions, {
    type: ACTION_TYPES.ADD_NEW_PAGE.UNDOABLE,
    payload: {
      newPageOrder: Number(pageOrder) + 1,
      dontSelect: true,
      pageProps: {
        showPageOnNavigation: false,
        name: t(title ? `${title} Detail Page` : 'Details Page')
      },
      detailPageProps: {
        linkedItemID: itemID
      }
    }
  });

  yield put(ACTION_CREATORS.dsInitializeItems({
    itemID,
    columns,
    oldDetailPage
  }));
}

function* initializeDsItemsWatcher(action) {
  const { itemID, columns: selectedColumns } = action.payload;

  const { resourceID, viewID } = yield select(SELECTORS.getPortalItemByIDSelector(itemID));
  const { columns: resourceColumns } = yield select(SELECTORS.dsGetColumnsByResourceID({ resourceID, viewID }));
  const { id: pageID } = yield select(SELECTORS.getDataSourcePage(itemID));

  const columns = selectedColumns ?? resourceColumns ?? [];

  yield putResolve(ACTION_CREATORS.dsInitializePropsWithColumns({ ...action.payload, columns, pageID }));
  yield putResolve(ACTION_CREATORS.dsCreateDetailPageDefaults({ ...action.payload, columns, pageID }));
}

function* initializeDsItemWithColumns({ payload: { itemID, columns, pageID } }) {
  const { presentationItemID } = yield select(SELECTORS.getPortalItemByIDSelector(itemID));

  const defaultPresentationItemProps = generateNewPropsForPresentationItem(columns);

  yield put(ACTION_CREATORS.updateItemPropAction({
    itemID: presentationItemID,
    prop: {
      ...defaultPresentationItemProps,
      buttonRole: CARD_ACTION_VALUES.NAVIGATION,
      buttonValue: pageID
    },
    bypassDebounce: true,
    linkedItemID: itemID
  }));

  yield put({ type: ACTION_TYPES.DS_INITIALIZE_PROPS_WITH_COLUMNS.SUCCESS });
}

function* createDefaultElements({ payload: { pageID, columns, itemID } }) {
  yield take(ACTION_TYPES.DS_INITIALIZE_PROPS_WITH_COLUMNS.SUCCESS);

  yield put(ACTION_CREATORS.toastAction({
    message: t('The detail page has been prepared automatically. You can click any item to see how the dynamic values change on the detail page'),
    options: {
      autoClose: 4e3
    }
  }));

  if (columns.length < 1) {
    return;
  }

  const newItems = generateDetailDefaultElements({ columns, pageID });

  // Unfortunately i have passed undefined to not to lose default parameters (because it is written in this way)
  // This action could be refactored to accept object instead of args like this
  yield put(ACTION_CREATORS.addPortalItemAction(newItems, 1, undefined, undefined, itemID));
}

function* handleDetailPageDeletionOnListRemoval({ payload: { item } }) {
  const detailPage = yield select(SELECTORS.getDataSourcePage(item.id));
  if (detailPage?.id) {
    yield call(pageActions, { type: ACTION_TYPES.DELETE_PAGE.UNDOABLE, payload: { pageID: detailPage.id, deleteItems: true }, dontStack: true });
  }
}

function* watchViewDefaultTable({ payload: { itemID } }) {
  try {
    const isLoading = yield select(SELECTORS.dsGetItemIsLoading(itemID, DS_ITEM_LOAD_TYPES.TABLE_CLONE));
    if (isLoading) {
      return;
    }

    const appID = yield select(SELECTORS.getAppID);
    yield put(ACTION_CREATORS.dsSetLoading({ itemID, isLoading: true, loadType: DS_ITEM_LOAD_TYPES.TABLE_CLONE }));
    const {
      sheetID: newResourceID, viewId: viewID, isClone = false, updatedAt
    } = yield call(API.viewTable, { appID, itemID });

    if (isClone) {
      yield put(ACTION_CREATORS.updateItemPropAction({
        itemID: itemID,
        prop: {
          resourceID: newResourceID
        }
      }));
    }

    yield put(ACTION_CREATORS.showGenericModalAction({
      name: MODALS.TABLE_PRODUCT_MODAL,
      resourceID: newResourceID,
      viewID: viewID !== newResourceID ? viewID : null,
      updatedAt
    }));
  } catch (err) {
    console.error('not implemented', err);
  } finally {
    yield put(ACTION_CREATORS.dsSetLoading({ itemID, isLoading: false, loadType: DS_ITEM_LOAD_TYPES.TABLE_CLONE }));
  }
}

export function* dsFetchColumnsFlow() {
  const isBuilder = yield select(SELECTORS.getIsBuilder);
  if (!isBuilder) {
    return;
  }

  const items = yield select(SELECTORS.getPortalItems);
  const listItems = items.filter(item => item.type === ITEM_TYPES.LIST);
  if (!isEmpty(listItems)) {
    yield all(listItems.reduce((prev, { resourceID, viewID }) => {
      if (!resourceID) {
        return prev;
      }

      return [...prev, put(ACTION_CREATORS.dsFetchColumnsRequest({ resourceID, viewID }))];
    }, []));
  }
}

// TODO: TEMPORARY FIX for mobile builder version c, instead of improve the update iframe logic
function* setUpdatedAtOnRowSuccess() {
  yield put(ACTION_CREATORS.setAppUpdatedAt(true));
}

function* createTable({ payload: { setFetchKey } }) {
  yield call(API.createTable);
  setFetchKey(prev => prev + 1);
}

function* detailPageOrderNormalizer({ payload }) {
  const { data = [] } = payload;

  const pages = yield select(SELECTORS.getPortalPages);

  yield all(
    data.reduce((acc, item) => {
      if (item.type === ITEM_TYPES.LIST && item.page) {
        const { pageOrder } = pages.find(({ id }) => id === item.page);
        if (pageOrder) {
          return [...acc, put(ACTION_CREATORS.changePageOrderAction({ oldIndex: pageOrder, newIndex: pageOrder }))];
        }
        return acc;
      }

      return acc;
    }, [])
  );
}

function* fetchAllResourceLinkedItems({ payload }) {
  const { resourceID, viewID } = payload;
  const items = yield select(SELECTORS.getPortalItems);
  const listItems = items.filter(({ type }) => type === ITEM_TYPES.LIST);
  const resourceLinkedItems = listItems.filter(listItem => (listItem.resourceID === resourceID && viewID ? listItem.viewID === viewID : true));
  const pages = yield select(SELECTORS.getPortalPages);
  const linkedPages = pages.filter(({ linkedItemID }) => listItems.some(({ id }) => id === linkedItemID));

  const fetchSourceItemActions = resourceLinkedItems.map(({ id }) => put(ACTION_CREATORS.dsFetchSourceItemData({ itemID: id })));
  const fetchPageActions = linkedPages.map(({ id }) => put(ACTION_CREATORS.dsFetchDetailPage({ pageID: id, divideByItemID: true })));

  yield all([...fetchSourceItemActions, ...fetchPageActions]);
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeEvery(ACTION_TYPES.DS_CREATE_DETAIL_PAGE, safeWorker(createAndLinkDetailsPage));
  yield takeEvery(ACTION_TYPES.DS_FETCH_RESOURCE_COLUMNS.REQUEST, fetchColumns);
  yield throttle(5000, ACTION_TYPES.DS_FETCH_RESOURCE_COLUMNS.WITH_THROTTLE, fetchColumns);
  yield takeEvery(ACTION_TYPES.DS_FETCH_SOURCE_ITEM_DATA.REQUEST, fetchSourceItemData);
  yield takeEvery(ACTION_TYPES.DS_FETCH_RESOURCE_ROW.REQUEST, fetchRow);
  yield takeEvery(ACTION_TYPES.DS_LIST_SET_ACTIVE_ROW_ID.REQUEST, safeWorker(setActiveRowAndNavigateToDetailsPage));
  yield takeEvery(ACTION_TYPES.DS_DELETE_DETAIL_PAGE, safeWorker(handleDetailPageDeletionOnListRemoval));

  // initialize ds item & detail page
  yield takeEvery(ACTION_TYPES.DS_INITIALIZE_ITEMS, initializeDsItemsWatcher);
  yield takeEvery(ACTION_TYPES.DS_CREATE_DETAIL_PAGE_DEFAULTS, safeWorker(createDefaultElements));
  yield takeEvery(ACTION_TYPES.DS_INITIALIZE_PROPS_WITH_COLUMNS.REQUEST, initializeDsItemWithColumns);

  // detail page data for preview
  yield takeLatest(ACTION_TYPES.DS_FETCH_DETAIL_PAGE.REQUEST, fetchDetailPage);

  yield takeLatest(ACTION_TYPES.DS_VIEW_DEFAULT_TABLE.REQUEST, watchViewDefaultTable);

  yield takeLatest([ACTION_TYPES.DS_FETCH_SOURCE_ITEM_DATA.SUCCESS, ACTION_TYPES.DS_FETCH_RESOURCE_ROW.SUCCESS], safeWorker(setUpdatedAtOnRowSuccess));
  yield takeLatest(ACTION_TYPES.DS_CREATE_TABLE.REQUEST, safeWorker(createTable));
  yield takeLatest(ACTION_TYPES.UPDATE_ORDER.UNDOABLE, safeWorker(detailPageOrderNormalizer));
  yield takeLatest(ACTION_TYPES.DS_FETCH_ALL_RESOURCE_LINKED_ITEMS, safeWorker(fetchAllResourceLinkedItems));
}
