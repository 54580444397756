/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
export const fixUTF = rawString => {
  let str = rawString;
  if (typeof str !== 'string') {
    return str;
  }

  var lowerCase = {
    '': '2028', a: '00E1:0103:01CE:00E2:00E4:0227:1EA1:0201:00E0:1EA3:0203:0101:0105:1D8F:1E9A:00E5:1E01:2C65:00E3:0251:1D90', b: '1E03:1E05:0253:1E07:1D6C:1D80:0180:0183', c: '0107:010D:00E7:0109:0255:010B:0188:023C', d: '010F:1E11:1E13:0221:1E0B:1E0D:0257:1E0F:1D6D:1D81:0111:0256:018C', e: '00E9:0115:011B:0229:00EA:1E19:00EB:0117:1EB9:0205:00E8:1EBB:0207:0113:2C78:0119:1D92:0247:1EBD:1E1B', f: '1E1F:0192:1D6E:1D82', g: '01F5:011F:01E7:0123:011D:0121:0260:1E21:1D83:01E5', h: '1E2B:021F:1E29:0125:2C68:1E27:1E23:1E25:0266:1E96:0127', i: '0131:00ED:012D:01D0:00EE:00EF:1ECB:0209:00EC:1EC9:020B:012B:012F:1D96:0268:0129:1E2D', j: '01F0:0135:029D:0249', k: '1E31:01E9:0137:2C6A:A743:1E33:0199:1E35:1D84:A741', l: '013A:019A:026C:013E:013C:1E3D:0234:1E37:2C61:A749:1E3B:0140:026B:1D85:026D:0142:0269:1D7C', m: '1E3F:1E41:1E43:0271:1D6F:1D86', n: '0144:0148:0146:1E4B:0235:1E45:1E47:01F9:0272:1E49:019E:1D70:1D87:0273:00F1', o: '00F3:014F:01D2:00F4:00F6:022F:1ECD:0151:020D:00F2:1ECF:01A1:020F:A74B:A74D:2C7A:014D:01EB:00F8:00F5', p: '1E55:1E57:A753:01A5:1D71:1D88:A755:1D7D:A751', q: 'A759:02A0:024B:A757', r: '0155:0159:0157:1E59:1E5B:0211:027E:0213:1E5F:027C:1D72:1D89:024D:027D', s: '015B:0161:015F:015D:0219:1E61:1E63:0282:1D74:1D8A:023F', t: '0165:0163:1E71:021B:0236:1E97:2C66:1E6B:1E6D:01AD:1E6F:1D75:01AB:0288:0167', u: '00FA:016D:01D4:00FB:1E77:00FC:1E73:1EE5:0171:0215:00F9:1EE7:01B0:0217:016B:0173:1D99:016F:0169:1E75:1D1C:1D7E', v: '2C74:A75F:1E7F:028B:1D8C:2C71:1E7D', w: '1E83:0175:1E85:1E87:1E89:1E81:2C73:1E98', x: '1E8D:1E8B:1D8D', y: '00FD:0177:00FF:1E8F:1EF5:1EF3:01B4:1EF7:1EFF:0233:1E99:024F:1EF9', z: '017A:017E:1E91:0291:2C6C:017C:1E93:0225:1E95:1D76:1D8E:0290:01B6:0240', ae: '00E6:01FD:01E3', dz: '01F3:01C6', 3: '0292:01EF:0293:1D9A:01BA:01B7:01EE'
  };
  var upperCase = {
    A: '00C1:0102:01CD:00C2:00C4:0226:1EA0:0200:00C0:1EA2:0202:0100:0104:00C5:1E00:023A:00C3', B: '1E02:1E04:0181:1E06:0243:0182', C: '0106:010C:00C7:0108:010A:0187:023B', D: '010E:1E10:1E12:1E0A:1E0C:018A:1E0E:0110:018B', E: '00C9:0114:011A:0228:00CA:1E18:00CB:0116:1EB8:0204:00C8:1EBA:0206:0112:0118:0246:1EBC:1E1A', F: '1E1E:0191', G: '01F4:011E:01E6:0122:011C:0120:0193:1E20:01E4:0262:029B', H: '1E2A:021E:1E28:0124:2C67:1E26:1E22:1E24:0126', I: '00CD:012C:01CF:00CE:00CF:0130:1ECA:0208:00CC:1EC8:020A:012A:012E:0197:0128:1E2C:026A:1D7B', J: '0134:0248', K: '1E30:01E8:0136:2C69:A742:1E32:0198:1E34:A740', L: '0139:023D:013D:013B:1E3C:1E36:2C60:A748:1E3A:013F:2C62:0141:029F:1D0C', M: '1E3E:1E40:1E42:2C6E', N: '0143:0147:0145:1E4A:1E44:1E46:01F8:019D:1E48:0220:00D1', O: '00D3:014E:01D1:00D4:00D6:022E:1ECC:0150:020C:00D2:1ECE:01A0:020E:A74A:A74C:014C:019F:01EA:00D8:00D5', P: '1E54:1E56:A752:01A4:A754:2C63:A750', Q: 'A758:A756', R: '0154:0158:0156:1E58:1E5A:0210:0212:1E5E:024C:2C64', S: '015A:0160:015E:015C:0218:1E60:1E62', T: '0164:0162:1E70:021A:023E:1E6A:1E6C:01AC:1E6E:01AE:0166', U: '00DA:016C:01D3:00DB:1E76:00DC:1E72:1EE4:0170:0214:00D9:1EE6:01AF:0216:016A:0172:016E:0168:1E74', V: 'A75E:1E7E:01B2:1E7C', W: '1E82:0174:1E84:1E86:1E88:1E80:2C72', X: '1E8C:1E8A', Y: '00DD:0176:0178:1E8E:1EF4:1EF2:01B3:1EF6:1EFE:0232:024E:1EF8', Z: '0179:017D:1E90:2C6B:017B:1E92:0224:1E94:01B5', AE: '00C6:01FC:01E2', DZ: '01F1:01C4'
  };
  str = str.toString();

  for (var lk in lowerCase) {
    var lvalue = `\\u${lowerCase[lk].split(':').join('|\\u')}`;
    str = str.replace(new RegExp(lvalue, 'gm'), lk);
  }
  // remove unicode whitespace (b#1140035)
  str = str.replace('\u2029', '');

  for (var uk in upperCase) {
    var uvalue = `\\u${upperCase[uk].split(':').join('|\\u')}`;
    str = str.replace(new RegExp(uvalue, 'gm'), uk);
  }

  return str;
};

/**
 *
 * @param {Object} str
*/
export const capitalize = str => {
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
};

export const capitalizeAccToLocale = (str, lang = 'en-US') => {
  const lang_ = lang || 'en-US';
  if (!str) return '';
  return str.toLocaleUpperCase(lang_);
};

/**
 * capitalizeFirstLetter
 * @function
 * @param {String} str to capitalizeFirstLetter
 */
export const capitalizeFirstLetter = (str = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeEachLetter = (str = '') => {
  const words = str.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(' ');
};

export const capitalizeFirstLetterAccToLocale = (str, lang = 'en-US') => {
  const lang_ = lang || 'en-US';
  if (!str) return '';
  return str.charAt(0).toLocaleUpperCase(lang_) + str.toLocaleLowerCase(lang_).slice(1);
};

export function stripSlashes(str) {
  return (str.toString()).replace(/\\(.?)/g, (s, n1) => {
    switch (n1) {
      case '\\':
        return '\\';
      case '0':
        return '\u0000';
      case '':
        return '';
      default:
        return n1;
    }
  });
}

export function escapeHTML(str) {
  if (typeof str === 'string') {
    return str.replace(/^\s+/g, '')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }
  return str;
}

export function getStringByteLength(str) {
  // encode the text in UTF-8 format. Because backend is saving the text in UTF-8 format.
  const latin1EncodedText = unescape(encodeURIComponent(str));

  // get byte
  const encoder = new TextEncoder('utf-8');
  const encodedData = encoder.encode(latin1EncodedText);
  const byteSize = encodedData.length;

  return byteSize;
}

/**
 * Equilevent of deprecated escape() function.
 *
 * @param {*} str
 * @returns {string}
 */
export const escapeString = str => {
  if (!str || typeof str !== 'string') return '';

  const allowed = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@*_+-./,';
  const len = str.length;
  let R = '';
  let k = 0;
  let S;
  let chr;
  let ord;
  while (k < len) {
    chr = str[k];
    if (allowed.indexOf(chr) !== -1) {
      S = chr;
    } else {
      ord = str.charCodeAt(k);
      if (ord < 256) {
        S = `%${(`00${ord.toString(16)}`).toUpperCase().slice(-2)}`;
      } else {
        S = `%u${(`0000${ord.toString(16)}`).toUpperCase().slice(-4)}`;
      }
    }
    R += S;
    k++;
  }
  return R;
};
