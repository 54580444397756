import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Paypal Pro gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlPaypalPro: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_paypalpro: {
    paymentMethod: {
      title: 'Default Payment Method',
      type: 'dropdown',
      options: [
        { id: 'paypal', key: 'paypal', text: 'PayPal' },
        { id: 'cc', key: 'cc', text: 'Credit Card' }
      ],
      default: 'paypal',
      resources: ['FORM', 'APP']
    },
    acceptedCc: {
      title: 'Accepted Credit Card',
      type: 'checkboxGroup',
      resources: ['FORM', 'APP'],
      default: 'visa|mc|amex|dc',
      options: [
        { id: 'visa', key: 'visa', text: 'Visa' },
        { id: 'mc', key: 'mc', text: 'Mastercard' },
        { id: 'amex', key: 'amex', text: 'American Express' },
        { id: 'dc', key: 'dc', text: 'Discover' }
      ],
      delimiter: '|'
    },
    ccTitle: {
      title: 'Credit Card Label Text',
      type: 'input',
      default: '',
      resources: ['FORM', 'APP']
    },
    addressTitle: {
      title: 'Billing Address',
      type: 'input',
      default: 'Billing Address',
      resources: ['FORM']
    },
    showPaypalStandard: {
      title: 'Allow PayPal Standard Payments',
      type: 'toggle',
      selectedCondition: settings => settings.showPaypalStandard === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'Yes',
      resources: ['FORM', 'APP']
    },
    paypalLink: {
      title: 'Show PayPal Link',
      type: 'toggle',
      selectedCondition: settings => settings.paypalLink === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP']
    },
    authOnly: {
      title: 'Authorization Only',
      type: 'toggle',
      selectedCondition: settings => settings.authOnly === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'Yes',
      resources: ['FORM', 'APP']
    },
    shipToAddress: {
      title: 'Use Field as Billing Address',
      type: 'dropdown',
      subType: 'formQuestion',
      options: [{ id: 'billing', key: 'billing', text: 'Same as billing' }], // static option
      questionTypesAsOption: ['control_address'],
      default: 'none',
      resources: ['FORM'],
      requiredCondition: () => true,
      renderCondition: formQuestions => Array.isArray(formQuestions) && formQuestions.filter(q => ['control_address'].includes(q.type)).length > 0
    },
    pagestyle: {
      title: 'Redirected PayPal Page Style',
      type: 'input',
      default: '',
      resources: ['FORM', 'APP']
    },
    checkoutLogo: {
      title: 'Checkout Logo',
      type: 'imageUpload',
      default: '',
      resources: ['FORM', 'APP']
    },
    cartColor: {
      title: 'Checkout Cart Color',
      type: 'input',
      subType: 'color',
      default: '#CCCCCC',
      resources: ['FORM', 'APP']
    }
  }
};

export default controlPaypalPro;
