import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Paypal Invoicing gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlPaypalInvoicing: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_paypalInvoicing: {
    emailField: {
      title: 'Bill to',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email', 'control_widget'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      requiredCondition: () => true,
      autoSave: true
    },
    infoline: {
      type: 'infoline',
      title: 'Your Business Details',
      subTitle: 'Your business information will be included on PayPal invoices.',
      resources: ['FORM', 'APP']
    },
    softDescriptor: {
      title: 'Company Name',
      placeholder: 'Your Company Name',
      type: 'input',
      default: '',
      resources: ['FORM', 'APP'],
      requiredCondition: () => true
    },
    merchantName: {
      title: 'Name',
      placeholder: 'Your Name',
      type: 'input',
      default: '',
      resources: ['FORM', 'APP'],
      requiredCondition: () => true
    },
    companyAddress: {
      title: 'Address',
      placeholder: 'Your Address',
      type: 'textarea',
      default: '',
      resources: ['FORM', 'APP']
    },
    merchantPhone: {
      title: 'Phone',
      placeholder: 'Your Phone',
      type: 'input',
      default: '',
      resources: ['FORM', 'APP']
    },
    infoline_2: {
      type: 'infoline',
      title: 'Customer Details',
      resources: ['FORM', 'APP']
    },
    billToName: {
      title: 'Billing Name',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_fullname'],
      questionTypeToAdd: 'control_fullname',
      default: 'none',
      resources: ['FORM'],
      autoSave: true
    },
    billToAddress: {
      title: 'Billing Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      default: 'none',
      resources: ['FORM'],
      autoSave: true
    },
    useAsShipping: {
      title: 'Shipping Information',
      type: 'toggle',
      selectedCondition: settings => settings.useAsShipping === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP']
    },
    shipToName: {
      title: 'Shipping Name',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_fullname'],
      questionTypeToAdd: 'control_fullname',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.useAsShipping === 'Yes',
      autoSave: true
    },
    shipToAddress: {
      title: 'Shipping Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.useAsShipping === 'Yes',
      autoSave: true
    },
    allowPartial: {
      title: 'Allow Partial Payment',
      description: 'Let the customer pay in installments that you can set a minimum amount.',
      type: 'toggle',
      selectedCondition: settings => settings.allowPartial === 'enabled',
      valueTrue: 'enabled',
      valueFalse: 'disabled',
      default: 'disabled',
      resources: ['FORM', 'APP']
    },
    minAmount: {
      title: 'Minimum Amount',
      placeholder: 'Enter minimum amount',
      type: 'input',
      subType: 'number',
      default: '0',
      resources: ['FORM', 'APP'],
      requiredCondition: () => true,
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.allowPartial === 'enabled'
    }
  }
};

export default controlPaypalInvoicing;
