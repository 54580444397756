import { ScheduleDropdownOptions } from '@jotforminc/constants';

const isValidTimeZone = tz => {
  return !!ScheduleDropdownOptions.time.timeZone.reduce((acc, current) => {
    return [...acc, ...current.options];
  }, [])
    .find(opt => opt.value === tz);
};

export const guessTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimeZone = (preferredTZ, fallbackTZ = 'America/Los_Angeles') => {
  if (isValidTimeZone(preferredTZ)) return preferredTZ;
  const guessedTZ = guessTimezone(); // Somehow guessTimeZone returns invalid record :/

  return isValidTimeZone(guessedTZ) ? guessedTZ : fallbackTZ;
};

export const getNextSharpHour = () => {
  const date = new Date();

  date.setHours(date.getHours() + 1);
  date.setMinutes(0);

  return date;
};

export const formatRawTime = time => {
  const delimeter = ':';
  if (!time || !time.includes(delimeter)) return false;

  const splittedTime = time.split(delimeter);
  let hours = Number(splittedTime[0]);
  const minutesAndRest = splittedTime[1];

  if (!minutesAndRest) return false;

  const splittedRest = splittedTime[1].split(' ');
  const minutes = Number(splittedRest[0]);
  const amPm = splittedRest[1];

  if (amPm === 'pm') {
    hours += 12;
  }

  return (
    !amPm
    || (amPm !== 'am' && amPm !== 'pm')
    || hours < 0
    || hours > 23
    || minutes < 0
    || minutes > 59
  ) ? false : {
      hours,
      minutes
    };
};

export const formatRawDate = (date, delimeter = '/') => {
  if (!date || !date.includes(delimeter)) return false;

  const splittedDate = date.split(delimeter);
  const month = Number(splittedDate[0]);
  const day = Number(splittedDate[1]);
  const year = Number(splittedDate[2]);

  return (
    month < 0 || month > 12
    || day < 0 || day > 31
    || year < (new Date()).getFullYear()
  ) ? false : {
      month,
      day,
      year
    };
};

export const getLocalTimeLabel = (duration, unitLabel, t) => {
  const locales = {
    minutes: { singular: t('Minute'), plural: t('Minutes') },
    hours: { singular: t('Hour'), plural: t('Hours') },
    days: { singular: t('Day'), plural: t('Days') }
  };
  return locales[unitLabel][duration === 1 ? 'singular' : 'plural'];
};

export const getPossibleTimeZone = () => {
  let possibleTimeZone = 'UTC';
  try {
    possibleTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (err) {
    // If user's country is using DST(Daylight Saving Time), then we need to get standard timeoffset.
    const dateInJan = new Date();
    dateInJan.setMonth(0);
    dateInJan.setDate(1);
    let offset = dateInJan.getTimezoneOffset() / 60;
    // GMT notations are reversed in PHP. For example New York's standard timezone is GMT-5, but
    // When we are using GMT notation in PHP we need to change the sign.
    offset = `${offset > 0 ? 'Etc/GMT+' : 'Etc/GMT'}${offset}`;
    possibleTimeZone = offset;
  }

  return possibleTimeZone;
};

export const getCurrentTime = () => {
  const date = new Date();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
};

/**
 * @function prettyDate
 * @desc Creates a formatted date string with options for displaying the time of day and adjusting date for timezones.
 * @param {Date|number|string} date
 * @param {boolean} showTime
 * @param {boolean} ignoreUserTz
 * @param {boolean} ignoreCompanyTz
 * @param {DateTimeFormatOptions} options
 * @param {string} language
 * @returns {string|{formattedDate: string, tokens: string[]}}
 */
export const prettyDate = (date = new Date(), showTime, ignoreUserTz, ignoreCompanyTz, options, language) => {
  // Test for invalid dates, e.g. form.last_submission returns '0000-00-00 00:00:00'
  if (!date || Number.isNaN(new Date(date).valueOf())) return '';
  const intlDateInParts = new Intl.DateTimeFormat(language || window.navigator.language, {
    // Setting false for timeZone triggers an exception, so explicitly set it to undefined
    timeZone: (!ignoreUserTz && window.userTimeZone) || (!ignoreCompanyTz && window.companyTimeZone) || undefined,
    hour: '2-digit',
    minute: '2-digit',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: true,
    ...options
  }).formatToParts(new Date(date));

  // Convert Intl Date parts into an object which is easier to work with to create a readable string
  const datePartsObject = Object.fromEntries(
    intlDateInParts.reduce((previousValue, currentValue) => (
      [...previousValue, [currentValue.type, currentValue.value]]
    ), [])
  );

  const dateString = `${datePartsObject.month} ${datePartsObject.day}, ${datePartsObject.year}`;
  const timeString = `${datePartsObject.hour}:${datePartsObject.minute} ${datePartsObject.dayPeriod}`;

  if (!showTime) return dateString;

  return {
    formattedDate: `${dateString} ${timeString}`,
    tokens: [dateString, timeString]
  };
};

export const milisecondToDay = milisecond => {
  if (!milisecond || milisecond === null) return undefined;
  return parseInt(milisecond / (1000 * 60 * 60 * 24), 10);
};

const addzero = date => {
  return date.toString().padStart(2, '0');
};

export const getFormatedDate = date => {
  if (!date) return '';
  return `${(date.getFullYear())}-${addzero(date.getMonth() + 1)}-${addzero(date.getDate())}`;
};
