import React, { useEffect, useState } from 'react';
import {
  number, func, arrayOf, shape, bool, string, object, oneOfType
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { getCurrentTime } from '@jotforminc/utils';

import { RESPONSIVE_MODES } from '../core/constants';
import texts from '../core/texts';
import ViewMode from './ViewMode';
import MobileBorder from './MobileBorder';
import TabletBorder from './TabletBorder';
import MobilePageBorder from './MobilePageBorder';
import TabletPageBorder from './TabletPageBorder';
import { addMobileHeadSpacer } from '../core/utils';
import MultiplePreview from './MultiplePreview';
import SinglePreview from './SinglePreview';
import TryArrow from '../assets/svg/tryArrow.svg';

const AppPreviewBody = ({
  themes,
  template,
  customTryText,
  currentThemeId,
  previewQueryParams,
  modalContentLoading,
  showAsTemplatePreview,
  forStandalonePageTemplate,
  showThemeChangeTransition,
  onViewModeChange
}) => {
  const [viewMode, setViewMode] = useState(forStandalonePageTemplate ? RESPONSIVE_MODES.DESKTOP : RESPONSIVE_MODES.MOBILE);
  const [themesWithLoadAllowed, setThemesWithLoadAllowed] = useState([]);
  const areThemesLoading = themes.length === 0;

  const {
    title = '',
    splashBgColor = '',
    phoneNotchClass = 'black',
    JSON: appDetails = '{}'
  } = template;

  useEffect(() => {
    if (areThemesLoading) return;

    let intialThemes = themes.map(theme => ({ ...theme, allowLoading: false }));
    intialThemes = [{ ...intialThemes[0], allowLoading: true }, ...intialThemes.slice(1)];
    setThemesWithLoadAllowed(intialThemes);
  }, [themes]);

  const handleViewModeChange = selectedMode => {
    setViewMode(selectedMode);
    onViewModeChange(selectedMode);
  };

  // allow the next items loading
  const allowNextLoadingAfter = lastLoadedTheme => {
    const indexOfLoaded = themesWithLoadAllowed.indexOf(lastLoadedTheme);
    const updatedThemes = themesWithLoadAllowed.map((theme, index) => ((indexOfLoaded + 1) === index ? { ...theme, allowLoading: true } : { ...theme }));
    setThemesWithLoadAllowed(updatedThemes);
  };

  // find the last loaded item and trigger the next item's loading
  const handleOnLoad = (event, loadedTheme, loadedThemeIndex) => {
    const lastLoadedItemIndex = themesWithLoadAllowed.map(theme => theme.allowLoading).lastIndexOf(true);
    if (lastLoadedItemIndex === loadedThemeIndex) {
      allowNextLoadingAfter(loadedTheme);
    }

    // add custom css to the object el's document
    addMobileHeadSpacer(event.target);
  };
  return (
    <div className={`app-preview ${showThemeChangeTransition ? 'transitionState' : ''}`}>
      {/* preview frames: mobile, tablet, desktop */}
      <div className={`app-preview-device-wrapper ${viewMode}`}>
        <div className='device mobile'>
          {!forStandalonePageTemplate ? (
            <>
              <div className={`phone-notch ${phoneNotchClass}`}>
                <div className="l">
                  <span>{getCurrentTime()}</span>
                </div>
                <div className="r">
                  <span className="icons" />
                </div>
              </div>
              <MobileBorder splashBgColor={splashBgColor} />
            </>
          ) : (
            <MobilePageBorder />
          )}
        </div>
        <div className="device tablet">
          {!forStandalonePageTemplate ? (
            <TabletBorder splashBgColor={splashBgColor} />
          ) : (
            <TabletPageBorder />
          )}
        </div>
        <div className="preview-area">
          {/* standard preview */}
          {!showAsTemplatePreview && !areThemesLoading && (
            <MultiplePreview
              onLoad={handleOnLoad}
              currentThemeId={currentThemeId}
              previewQueryParams={previewQueryParams}
              themesWithLoadAllowed={themesWithLoadAllowed}
            />
          )}
          {/* app template preview */}
          {showAsTemplatePreview && (
            <SinglePreview
              title={title}
              viewMode={viewMode}
              onLoad={handleOnLoad}
              appDetails={appDetails}
              splashBgColor={splashBgColor}
              currentThemeId={currentThemeId}
              modalContentLoading={modalContentLoading}
              forStandalonePageTemplate={forStandalonePageTemplate}
            />
          )}
        </div>
        {
          !forStandalonePageTemplate && (
            <div className='try-me-section'>
              <span className='try-me-text'>{customTryText !== null ? t(customTryText) : texts.TRY_ME}</span>
              <div className='try-me-arrow'><TryArrow /></div>
            </div>
          )
        }
      </div>
      {/* responsive controls */}
      <ViewMode viewMode={viewMode} onViewModeChange={handleViewModeChange} />
    </div>
  );
};

AppPreviewBody.propTypes = {
  template: object,
  themes: arrayOf(shape()),
  currentThemeId: (oneOfType([number, string])),
  onViewModeChange: func,
  showThemeChangeTransition: bool,
  showAsTemplatePreview: bool,
  forStandalonePageTemplate: bool,
  previewQueryParams: string,
  customTryText: string,
  modalContentLoading: bool
};

AppPreviewBody.defaultProps = {
  onViewModeChange: f => f,
  themes: [],
  currentThemeId: 0,
  customTryText: null,
  modalContentLoading: false,
  showThemeChangeTransition: false,
  forStandalonePageTemplate: false,
  previewQueryParams: '',
  showAsTemplatePreview: false,
  template: {
    splashBgColor: 'rgba(243, 243, 254, 1)',
    phoneNotchClass: 'white'
  }
};

export default AppPreviewBody;
