import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Pagseguro gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlPagseguro: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_pagseguro: {
    emailField: {
      title: 'Customer Email Field',
      type: 'dropdown',
      subType: 'formQuestion',
      questionTypesAsOption: ['control_email', 'control_widget'],
      default: 'none',
      resources: ['FORM'],
      requiredCondition: () => true,
      renderCondition: formQuestions => Array.isArray(formQuestions) && formQuestions.filter(q => ['control_email', 'control_widget'].includes(q.type)).length > 0
    },
    authInvoiceField: {
      title: 'Invoice Number Field',
      type: 'dropdown',
      subType: 'formQuestion',
      questionTypesAsOption: ['control_textbox', 'control_hidden', 'control_autoincrement'],
      default: 'none',
      resources: ['FORM'],
      renderCondition: formQuestions => Array.isArray(formQuestions) && formQuestions.filter(q => ['control_textbox', 'control_hidden', 'control_autoincrement'].includes(q.type)).length > 0
    },
    shipToAddress: {
      title: 'Shipping Address Field',
      type: 'dropdown',
      subType: 'formQuestion',
      questionTypesAsOption: ['control_address'],
      default: 'none',
      resources: ['FORM'],
      renderCondition: formQuestions => Array.isArray(formQuestions) && formQuestions.filter(q => ['control_address'].includes(q.type)).length > 0
    },
    ccTitle: {
      title: 'Credit Card Label Text',
      type: 'input',
      default: 'Credit Card',
      resources: ['FORM']
    },
    addressTitle: {
      title: 'Billing Address Label Text',
      type: 'input',
      default: 'Billing Address',
      resources: ['FORM']
    }
  }
};

export default controlPagseguro;
