import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Payjunction gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlPayjunction: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_payjunction: {
    avsMatchType: {
      title: 'AVS Match Type',
      type: 'dropdown',
      options: [
        { id: 'NO', key: 'NO', text: 'Disabled' },
        { id: 'BYPASS', key: 'BYPASS', text: 'Bypass' },
        { id: 'ADDRESS', key: 'ADDRESS', text: 'Address' },
        { id: 'ADDRESS_AND_ZIP', key: 'ADDRESS_AND_ZIP', text: 'Address and Zip' },
        { id: 'ADDRESS_OR_ZIP', key: 'ADDRESS_OR_ZIP', text: 'Address or Zip' },
        { id: 'ZIP', key: 'ZIP', text: 'Zip' }
      ],
      infoText: 'AVS Match Type should match with the AVS settings assigned in your PayJunction merchant account.',
      default: 'NO',
      resources: ['FORM', 'APP']
    },
    ccTitle: {
      title: 'Credit Card Label Text',
      type: 'input',
      default: 'Credit Card',
      resources: ['FORM', 'APP']
    },
    addressTitle: {
      title: 'Billing Address Label Text',
      type: 'input',
      default: 'Billing Address',
      resources: ['FORM']
    }
  }
};

export default controlPayjunction;
