import React, { useState } from 'react';
import {
  object, string, func, bool
} from 'prop-types';
import { getDeviceType } from '../../getDeviceType';
import { templateConstants } from '../../templateConstants';

const TemplateItemContainer = ({
  type, template, templateTheme, setSelectedTemplate, handleTemplateClone, username, source, context, logAbTestAction, liteMode, useSlider
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const templateTitle = template._title || template.title;
  const handlePreviewTemplate = (target, selectedTemplate = template) => {
    setSelectedTemplate(selectedTemplate);
    if (window.standaloneTemplatesLogger) {
      window.standaloneTemplatesLogger({
        actor: username,
        target: `/${type}/standalone/${template?.slug || templateTitle}`,
        action: `${getDeviceType()}:${target}`
      });
    }

    // A/B Test: templateSuggestionModalOnMyAppsIII
    if (liteMode) {
      logAbTestAction?.({ action: 'preview', target: template?.slug });
    }
  };

  const { templateItem: TemplateItemRenderer, getCloneTemplatePayload } = templateConstants[type];
  const templateCloneWrapper = event => {
    if (window.standaloneTemplatesLogger) {
      window.standaloneTemplatesLogger({
        actor: username,
        target: template._id || template.id,
        action: `${getDeviceType()}:useTemplate`
      });
    }
    const { payload } = getCloneTemplatePayload(undefined, { ...template, theme: templateTheme }, source);
    handleTemplateClone(payload, event.target);

    // A/B Test: templateSuggestionModalOnMyAppsIII
    if (liteMode) {
      logAbTestAction?.({ action: 'clone', target: template.slug });
    }
  };

  return (
    <TemplateItemRenderer
      context={context}
      theme={templateTheme}
      template={template}
      useSlider={useSlider}
      imageLoaded={imageLoaded}
      setImageLoaded={setImageLoaded}
      templateCloneWrapper={templateCloneWrapper}
      handlePreviewTemplate={handlePreviewTemplate}
    />
  );
};

export default TemplateItemContainer;
TemplateItemContainer.defaultProps = {
  source: '',
  context: '',
  username: '',
  templateTheme: '',
  handleTemplateClone: () => { },
  setSelectedTemplate: () => { },
  template: {
    screenshot: 'skeleton',
    _title: 'skeleton',
    title: 'skeleton'
  },
  logAbTestAction: f => f,
  liteMode: false,
  useSlider: false
};
TemplateItemContainer.propTypes = {
  source: string,
  context: string,
  template: object,
  username: string,
  templateTheme: string,
  type: string.isRequired,
  setSelectedTemplate: func,
  handleTemplateClone: func,
  logAbTestAction: func,
  liteMode: bool,
  useSlider: bool
};
