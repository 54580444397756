import React from 'react';
import {
  func, array
} from 'prop-types';
import Moment from 'moment';
import WhatsNewModalDialogue, { SOURCE_TYPE } from './WhatsNewModalDialogue';
import { FEATURE_NAMES } from '../../../../../constants/features';

export const WHATS_NEW_MODAL_FEATURES = {
  [FEATURE_NAMES.List]: {
    content: [
      {
        featureName: 'List Element - Connecting with Table Data',
        disableNewFeatureBadge: true,
        src: {
          type: SOURCE_TYPE.VIDEO,
          url: 'https://cdn.jotfor.ms/assets/img/apps/list-item/whats-new-1.mp4',
          alt: 'List Element Feature'

        },
        specs: [
          'Add the List element to the App Builder for dynamic content integration.',
          'In the Properties panel, map the Title, Description, and Image fields to your table columns.',
          'Use the View Table option to edit data directly.',
          'Any changes made in the table are instantly reflected in the app.',
          'Manage content dynamically through the table to keep your app up-to-date.'
        ]
      },
      {
        featureName: 'List Element - Edit Dynamic Detail Page',
        disableNewFeatureBadge: true,
        src: {
          type: SOURCE_TYPE.VIDEO,
          url: 'https://cdn.jotfor.ms/assets/img/apps/list-item/whats-new-2.mp4',
          alt: 'List Element Feature'
        },
        specs: [
          'The List element displays items as rows from your data table.',
          'It shows summary information like text and images.',
          'Create a Detail Page to display more information when a user clicks on an item.',
          'Click Edit Detail Page to open the builder.',
          'Drag and drop elements to the Detail Page, similar to other pages.',
          'Link elements to specific data columns for dynamic visualization.',
          'Dynamically visualize and manipulate the details of a single record to enhance the user experience.'
        ]
      },
      {
        featureName: 'List Element - Preview List',
        disableNewFeatureBadge: true,
        src: {
          type: SOURCE_TYPE.VIDEO,
          url: 'https://cdn.jotfor.ms/assets/img/apps/list-item/whats-new-3.mp4',
          alt: 'List Element Feature'

        },
        specs: [
          'Experience the power of dynamic content with list and detail views.',
          'Effortlessly browse the list and tap on any item to see its updated details.',
          'Enjoy a consistent and user-friendly template throughout the app.',
          'This seamless interaction enhances user engagement.',
          'Keeps your app fresh and up-to-date.'
        ]
      }
    ]
  },
  [FEATURE_NAMES.PushNotification]: {
    condition: ({ currentUser }) => {
      const pushNotificationReleaseDate = '2024-08-07';
      const isEarlierDate = Moment(currentUser.created_at, 'YYYY-MM-DD').isBefore(Moment(pushNotificationReleaseDate, 'YYYY-MM-DD'));
      return isEarlierDate;
    },
    content: {
      featureName: 'Push Notifications',
      src: {
        url: 'https://cdn.jotfor.ms/assets/img/portal/whatsNewPushNotificationV3.svg',
        alt: 'Push Notification Feature'
      },
      date: 'August 08, 2024',
      specs: [
        'Stay connected with your users by sending push notifications',
        'Keep them updated with the latest news, updates, and offers',
        'Send push notifications even if the app is closed',
        'Monitor delivery and click rates for insights',
        'Increase conversions and keep users engaged in your app'
      ]
    }
  }
};

const WhatsNewModal = ({ onClose, whatsNewKeys }) => {
  const contents = whatsNewKeys.map(key => WHATS_NEW_MODAL_FEATURES[key].content).flat();
  return (
    <WhatsNewModalDialogue onClose={onClose} contents={contents} />
  );
};

WhatsNewModal.propTypes = {
  onClose: func.isRequired,
  whatsNewKeys: array.isRequired
};

export default WhatsNewModal;
