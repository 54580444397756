import React from 'react';
import { createPortal } from 'react-dom';
import {
  node, bool, string, func,
  shape, objectOf, arrayOf, elementType
} from 'prop-types';
import { classNames } from '@jotforminc/utils';

import CloseButton from '../Button/CloseButton';

import { stateClass } from '../../core/utils';

import '../../styles/jfWizard.scss';
import WizardWrapper from './WizardWrapper';

class WizardNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.setView = this.setView.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.setVisibilityState = this.setVisibilityState.bind(this);
    this.getClassNames = this.getClassNames.bind(this);
    this.getModalState = this.getModalState.bind(this);
    this.setCloseButtonVisibilityState = this.setCloseButtonVisibilityState.bind(this);

    this.state = {
      isVisible: null,
      CurrentView: props.DefaultView,
      setViewProps: this.getViewProps(props),
      isCloseButtonVisible: props.closeable
    };

    stateClass('add', 'jfWizard--isReady');
  }

  componentDidMount() {
    const {
      DefaultView,
      targetElement,
      initialView,
      initialVisibilityState,
      wizardRef
    } = this.props;

    this.setView(DefaultView);

    if (targetElement) {
      this.initializeTargetElementEvent();
    }

    if (initialView) {
      this.setView(initialView);
    }

    if (initialVisibilityState) {
      this.setVisibilityState(initialVisibilityState);
    }

    if (wizardRef) {
      wizardRef.current = {
        show: () => this.setVisibilityState(true),
        hide: () => this.setVisibilityState(false),
        setView: this.setView
      };
    }
  }

  componentWillUnmount() {
    const { targetElement } = this.props;
    if (targetElement) {
      this.unbindTargetElementEvent(targetElement);
    }
  }

  handleTargetClick() {
    this.setVisibilityState(true);
  }

  /**
   * Set close button to visible or invisible.
   */
  setCloseButtonVisibilityState(isCloseButtonVisible) {
    this.setState({ isCloseButtonVisible });
  }

  getViewProps = props => {
    return {
      skip: props.skip,
      formId: props.formId,
      forms: props.forms,
      onSave: props.onSave,
      fetchFormQuestions: props.fetchFormQuestions,
      isReportEdit: props?.isReportEdit,
      reportType: props?.reportType,
      reportName: props?.reportName,
      reportPassword: props?.reportPassword,
      fields: props?.fields,
      isProtected: props?.isProtected,
      reportSettings: props?.reportSettings,
      reportID: props?.reportID,
      teamID: props?.teamID
    };
  };

  setView(newView, newProps = {}, resetState = false) {
    const { Navigation, hideOnEscapeKeyPress, onViewChange } = this.props;
    const { setViewProps: oldProps, CurrentView } = this.state;
    onViewChange(newView === 'Main');
    const viewProps = resetState ? this.getViewProps(this.props) : oldProps;

    const setViewProps = { ...viewProps, ...newProps };

    if (hideOnEscapeKeyPress && newProps && newProps.closeWizard) {
      this.toggleVisibility();
      return;
    }

    if (Navigation[newView]) {
      if (CurrentView !== newView) {
        window.scrollTo(0, 0);
      }

      this.setState({ CurrentView: newView, setViewProps });
    }
  }

  setVisibilityState(isVisible) {
    const { onShow, onHide } = this.props;
    this.setState({ isVisible });

    if (isVisible) {
      onShow();
    } else {
      this.setView('Main');
      onHide();
    }

    stateClass(isVisible ? 'add' : 'remove', 'jfWizard--isVisible');
    stateClass(isVisible ? 'add' : 'remove', 'jfWizard--isModalView');

    if (!isVisible) {
      this.setState({ setViewProps: { isModal: false } });
    }
  }

  toggleVisibility() { // eslint-disable-line
    const { isVisible } = this.state;
    this.setVisibilityState(!isVisible);
  }

  initializeTargetElementEvent() {
    const { targetElement } = this.props;
    this.bindTargetElementEvent(targetElement);
  }

  bindTargetElementEvent(targetElement) {
    targetElement.addEventListener('click', this.handleTargetClick.bind(this));
  }

  unbindTargetElementEvent(targetElement) {
    targetElement.removeEventListener('click', this.handleTargetClick.bind(this));
  }

  getClassNames() {
    const { wrapperProps: { className } } = this.props;
    const { setViewProps: { className: viewClassName } } = this.state;

    return classNames(className, viewClassName);
  }

  getModalState() {
    const { isModal } = this.props;
    const { setViewProps: { isModal: viewIsModal } } = this.state;

    stateClass(viewIsModal ? 'add' : 'remove', 'jfWizard--isModalView');

    return viewIsModal || isModal;
  }

  renderClsoeButton() {
    const { isCloseButtonVisible } = this.state;

    return isCloseButtonVisible && (
      <div className="jfWizard-close">
        <CloseButton onClick={this.toggleVisibility} />
      </div>
    );
  }

  renderTarget() {
    const { targetContainer } = this.props;

    if (typeof targetContainer === 'string') {
      return document.querySelector(targetContainer);
    }

    return targetContainer;
  }

  renderContent() {
    const {
      Navigation, isReportEdit, reportType, forms, digest, user, digestProps, teamID
    } = this.props;
    const { CurrentView, setViewProps } = this.state;
    if (isReportEdit && reportType && CurrentView === 'Main') {
      if (reportType === 'digest') {
        this.setView('DigestEmailBuilder', {
          forms,
          user,
          digest,
          digestProps,
          setView: this.setView,
          teamID
        });
        return;
      }
      this.setView('ReportConfiguration');
    }
    const CurrentViewComponent = Navigation[CurrentView];
    const isMobileApp = global.navigator.userAgent.indexOf('JotForm Mobile') > -1 || global.navigator.userAgent.indexOf('JFCEMobile') > -1;
    const {
      wrapperProps: { className, ...wrapperRestProps },
      isModal,
      usePortal,
      isEmbeddedToMixedCreationFlow,
      ...props
    } = this.props;

    const { isVisible } = this.state;
    const isEmbeddedMainStep = isEmbeddedToMixedCreationFlow && CurrentView === 'Main';

    if (!isVisible) return null;

    return (
      <WizardWrapper
        className={classNames('jfWizard', this.getModalState() && 'isModal', isEmbeddedMainStep && 'isEmbeddedMainStep', this.getClassNames())}
        wrapperRestProps={wrapperRestProps}
        withContainer={setViewProps.withContainer}
      >
        <CurrentViewComponent
          {...props}
          viewProps={setViewProps}
          setView={this.setView}
          setVisibilityState={this.setVisibilityState}
          setCloseButtonVisibilityState={this.setCloseButtonVisibilityState}
          isEmbeddedToMixedCreationFlow={isEmbeddedToMixedCreationFlow}
        />
        {!isMobileApp && this.renderClsoeButton()}
      </WizardWrapper>
    );
  }

  render() {
    const { usePortal } = this.props;

    return usePortal ? createPortal(this.renderContent(), this.renderTarget()) : this.renderContent();
  }
}

WizardNavigation.propTypes = {
  targetElement: node,
  targetContainer: node,
  wrapperProps: shape({}),
  setViewWrapperProps: shape({}),
  isModal: bool,
  closeable: bool,
  initialVisibilityState: bool,
  initialView: string,
  hideOnEscapeKeyPress: bool,
  onShow: func,
  onHide: func,
  forms: arrayOf(shape()),
  usePortal: bool,
  wizardRef: shape(),
  DefaultView: string.isRequired,
  Navigation: objectOf(elementType).isRequired,
  isReportEdit: bool,
  reportType: string,
  digest: shape({}),
  digestProps: shape({}),
  user: shape({}),
  teamID: string,
  isEmbeddedToMixedCreationFlow: bool,
  onViewChange: func
};

const getInitialView = () => {
  try {
    const { location: { search = '', pathname = '' } } = global;

    const urlParams = new URLSearchParams(search);
    let wizardInitialView = urlParams.get('wizardInitialView');

    if (wizardInitialView === 'UseTemplate' && pathname.includes('/mytables')) {
      wizardInitialView = 'SheetTemplates';
    }
    return wizardInitialView;
  } catch {
    return null;
  }
};

WizardNavigation.defaultProps = {
  targetElement: null,
  targetContainer: null,
  wrapperProps: {},
  setViewWrapperProps: {},
  isModal: false,
  closeable: true,
  initialVisibilityState: false,
  initialView: getInitialView(),
  hideOnEscapeKeyPress: true,
  onShow: f => f,
  onHide: f => f,
  forms: [],
  usePortal: false,
  wizardRef: null,
  isReportEdit: false,
  reportType: null,
  digest: {},
  user: {},
  digestProps: {},
  teamID: '',
  isEmbeddedToMixedCreationFlow: false,
  onViewChange: f => f
};

export default WizardNavigation;
