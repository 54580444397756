export class ActionManager {
  constructor({
    user,
    projectName,
    trackOnce,
    enableDebugMode
  }) {
    this.user = user || {};
    this.projectName = projectName;
    this.trackOnce = trackOnce || false;
    this.JotFormActions = null;
    this.registerJotformAction = this.registerJotformAction.bind(this);
    this.sentEvents = {};
    this.enableDebugMode = enableDebugMode || false;
  }

  setupJotFormActions() {
    const { JotFormActions } = global;
    if (typeof JotFormActions !== 'function') return;
    this.JotFormActions = JotFormActions(this.projectName);
  }

  tick({ actor = '', action = '', target = '' } = {}) {
    this.JotFormActions?.tick({ actor, action, target });

    if (this.trackOnce) {
      this.sentEvents[`${action}${target}`] = true;
    }
  }

  logDebugAction({ actor, action, target }) {
    console.log({ actor, action, target });
    const hasMissingParameter = [actor, action, target].some(param => !param);
    if (hasMissingParameter) {
      console.warn('Missing parameter in actor, action or target');
    }
    this.tick({ actor, action, target });
  }

  registerJotformAction({ action, target }) {
    if (!this.JotFormActions) {
      this.setupJotFormActions();
    }

    if (this.trackOnce && this.sentEvents[`${action}${target}`]) {
      return;
    }

    const { username: actor = null, account_type: accountType } = this.user;
    const isEnterprise = global.JOTFORM_ENV === 'ENTERPRISE';
    const isEnvProd = global.JOTFORM_ENV === 'PRODUCTION' && !isEnterprise;
    const isJotFormer = ['ADMIN', 'SUPPORT', 'INTERN'].indexOf(accountType?.name) > -1 && !isEnterprise;

    if (this.enableDebugMode) {
      this.logDebugAction({ actor, action, target });
      return;
    }

    if (!this.JotFormActions || !actor || !isEnvProd || isJotFormer) return;

    this.tick({ actor, action, target });
  }
}
