import React, {
  useEffect, useRef, useCallback
} from 'react';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { IPaymentContainerProps, ISellProductsItem } from '../types/common';
import Selectors from '../store/selectors';
import { SellProductsPanel, PaymentPropertiesPanel } from '../components/panels';
import * as actionCreators from '../store/actionCreators';
import EditorBinding from '../store/EditorBinding';
import GatewaySettingsContainer from './GatewaySettingsContainer';
import '../styles/editor.scss';
import { fixAllProductsData } from '../utils';
import { updateAllProductListSettingsOnProduct } from '../api';
import { PAYMENTLISTSETTINGS_QUESTION_PROPS } from '../constants/variables';
import PaymentSettingsPanel from '../components/panels/PaymentSettingsPanel';

const PaymentContainer = ({
  formId,
  actions,
  appName,
  appID,
  onProductsChange = f => f,
  onPaymentSettingsChange = f => f,
  onRightPanelModeChange = f => f,
  onGatewaySettingsChange = f => f,
  user,
  activeGatewaySettings,
  products = [],
  questionProperties = {},
  checkoutFormId,
  initialPanel,
  initialEditor,
  activeProductProps,
  onUseFastCheckoutChange = f => f,
  useFastCheckout,
  isPaymentOverQuota,
  paymentType = 'product',
  isGatewaySettingsDisplayed: _isGatewaySettingsDisplayed = false,
  isDonationItemAvailable = true,
  sendActionTrackEvent,
  questions = {},
  onCheckoutFormQuestionUpdate = f => f,
  onAddPaymentIntegrationBtnClick,
  onEditPaymentIntegrationBtnClick,
  onDeletePaymentIntegrationBtnClick,
  isNewPaymentModalEnabled,
  togglePaymentConnectionModal,
  hasConnectionToTheGatewayType,
  checkoutFormPaymentQuestion,
  onSaveGateway,
  userIsResourceOwner
  // forwardedRef
} : IPaymentContainerProps) : JSX.Element => {
  const dispatch = useDispatch();
  const isMounted = useRef<boolean>(false);
  const activePanel = useSelector(Selectors.getActivePanel);
  const currentActiveProduct = useSelector(Selectors.getActiveProduct);
  const isGatewaySettingsDisplayed = useSelector(Selectors.getIsGatewaySettingsDisplayed);
  const storeProducts : ISellProductsItem[] = fixAllProductsData(useSelector(Selectors.getProducts));
  const view = useSelector(Selectors.getGatewaySettingsView);

  const closeGatewaySettings = useCallback(() => {
    dispatch(actionCreators.changeGatewaySettingsVisibility(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(actionCreators.initUi({
      checkoutFormId, formId, activePanel: initialPanel, activeEditor: initialEditor, selectedOption: null, isGatewaySettingsDisplayed: _isGatewaySettingsDisplayed
    }));
    dispatch(actionCreators.updateActiveProduct({}));
  }, [checkoutFormId, formId, initialPanel, initialEditor]);

  useEffect(() => {
    if (user) {
      dispatch(actionCreators.initUser(user));
    }
  }, [user]);

  useEffect(() => {
    if (!products) return;
    // listen product changes from the parent component
    const fixedIncomingProducts = fixAllProductsData(products);
    if ((isMounted.current !== true || !isEqual(fixedIncomingProducts, storeProducts)) && Array.isArray(products)) {
      dispatch(actionCreators.initProducts(fixedIncomingProducts));
      isMounted.current = true;
    }
  }, [actions, products]);

  useEffect(() => {
    EditorBinding.onProductsChange = onProductsChange;
    EditorBinding.onPaymentSettingsChange = onPaymentSettingsChange;
    EditorBinding.onRightPanelModeChange = onRightPanelModeChange;
    EditorBinding.onGatewaySettingsChange = onGatewaySettingsChange;
  }, [onProductsChange, onPaymentSettingsChange, onRightPanelModeChange, onGatewaySettingsChange]);

  useEffect(() => {
    if (initialPanel && activePanel !== initialPanel) {
      dispatch(actionCreators.changeActivePanel(initialPanel));
    }
  }, [initialPanel, activePanel]);

  useEffect(() => {
    if (!!activeProductProps?.pid && activePanel !== 'sellProducts') {
      dispatch(actionCreators.changeActivePanel('sellProducts'));
    }
  }, [activeProductProps, activePanel]);

  useEffect(() => {
    if (activeProductProps?.pid && activeProductProps.pid !== currentActiveProduct?.pid) {
      dispatch(actionCreators.setActiveProduct(activeProductProps));
      dispatch(actionCreators.changeActiveEditor('productEditor'));
      dispatch(actionCreators.changeSelectedOption(null));
    }
  }, [activeProductProps, currentActiveProduct?.pid]);

  useEffect(() => {
    dispatch(actionCreators.initCheckoutFormQuestions(questions));
  }, [questions]);

  useEffect(() => {
    if (activeGatewaySettings) {
      dispatch(actionCreators.initActiveGatewaySettings(activeGatewaySettings));
    }
  }, [activeGatewaySettings]);

  // This is for migrating some currencies that are converted to EUR
  useEffect(() => {
    const migrateToEURList = ['HRK', 'EEK', 'LTL', 'LVL', 'SKK'];
    const currency = questionProperties?.currency;
    if (currency && migrateToEURList.includes(currency)) {
      updateAllProductListSettingsOnProduct(appID, { platform: appName, questionProperties: { currency: 'EUR' } })
        .then(res => {
          if (res) {
            dispatch(actionCreators.updateProductListSettings({ currency: 'EUR' }));
          }
        }).catch((err: Error) => {
          console.error(err);
        });
    }
  }, [appID, appName, questionProperties.currency]);

  useEffect(() => {
    const paymentListQuestionSettings = Object.fromEntries(Object.entries(questionProperties).filter(([k]) => PAYMENTLISTSETTINGS_QUESTION_PROPS.includes(k)));
    dispatch(actionCreators.updateProductListSettings(paymentListQuestionSettings));
  }, []);

  const onSaveGatewayCb = (changedProps: object) => {
    onSaveGateway({ changedProps });

    if ('currency' in changedProps) {
      updateAllProductListSettingsOnProduct(appID, { platform: 'appBuilder', questionProperties: { currency: changedProps.currency } })
        .then(res => {
          if (res) {
            dispatch(actionCreators.updateProductListSettings({ currency: changedProps.currency }));
          }
        }).catch((err: Error) => {
          console.error(err);
        });
    }
  };

  return (
    <div className={classNames('paymentSettingsEditor', appName)}>
      {['sellProducts'].includes(activePanel) && (
        <SellProductsPanel actions={actions} initialEditor={initialEditor} activeProductProps={activeProductProps} />
      )}

      {
        ['paymentSettings'].includes(activePanel) && (
          window?.isPaymentReusableConnection ? (
            <PaymentPropertiesPanel
              userIsResourceOwner={userIsResourceOwner}
              checkoutFormID={checkoutFormId}
              questionProperties={checkoutFormPaymentQuestion}
              resource="APP"
              togglePaymentConnectionModal={togglePaymentConnectionModal}
              hasConnectionToTheGatewayType={hasConnectionToTheGatewayType}
              user={user}
              onSaveGateway={({ changedProps }) => onSaveGatewayCb(changedProps)}
              onAutosaveGateway={({ key, val }) => {
                const changedProps = { [key]: val };
                onSaveGatewayCb(changedProps);
              }}
              onDetachConnection={() => {
                onDeletePaymentIntegrationBtnClick();
              }}
            />
          ) : (
            <PaymentSettingsPanel
              isPaymentOverQuota={isPaymentOverQuota}
              appName={appName}
              appID={appID}
              isDonationItemAvailable={isDonationItemAvailable}
              sendActionTrackEvent={sendActionTrackEvent}
              onUseFastCheckoutChange={onUseFastCheckoutChange}
              useFastCheckout={useFastCheckout}
              onCheckoutFormQuestionUpdate={onCheckoutFormQuestionUpdate}
              onAddPaymentIntegrationBtnClick={onAddPaymentIntegrationBtnClick}
              onEditPaymentIntegrationBtnClick={onEditPaymentIntegrationBtnClick}
              onDeletePaymentIntegrationBtnClick={onDeletePaymentIntegrationBtnClick}
            />
          )
        )
      }

      {!isNewPaymentModalEnabled && isGatewaySettingsDisplayed === true && (
        <GatewaySettingsContainer
          actions={actions}
          onContainerClose={closeGatewaySettings}
          source="inside"
          activeGatewaySettings={activeGatewaySettings}
          checkoutFormId={checkoutFormId}
          appName={appName}
          appID={appID}
          paymentType={paymentType}
          view={view}
        />
      )}
    </div>
  );
};
declare global {
  interface Window {
    STORYBOOK_ENV?: string
    isAdminUser: boolean
    isHIPAA: string
    PAYPAL: { apps: { Signup: { MiniBrowser: { isOpen: () => boolean } } } },
    teamID: never
    showPaymentReusableMigrationWarning: boolean
    isTeamMember: boolean
    isPaymentReusableConnection: boolean
  }
}

export default PaymentContainer;
