import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import WarningBox from '@jotforminc/warning-box';
import { t } from '@jotforminc/translation';
import DocumentFileUpload from './DocumentFileUpload';
import * as ACTION_CREATORS from '../../../../../store/actionCreators';
import SELECTORS from '../../../../../store/selectors';

const RightPanelDocumentFileUpload = props => {
  const { fileType } = props;
  const dispatch = useDispatch();
  const selectedItemID = useSelector(SELECTORS.getSelectedPortalItem);

  const handleChange = useCallback(data => {
    dispatch(ACTION_CREATORS.updateItemPropAction({
      itemID: selectedItemID,
      prop: data
    }));
  }, [selectedItemID]);

  const isFileTypeVideo = useMemo(() => fileType.startsWith('video/'), [fileType]);

  return (
    <>
      <DocumentFileUpload {...props} onChange={handleChange} />
      {isFileTypeVideo && (
      <WarningBox
        isLongText
        size='medium'
      >
        {t('The video cannot be played in any browser on an iOS device or in a Safari browser on any device.')}
      </WarningBox>
      )}
    </>
  );
};

RightPanelDocumentFileUpload.propTypes = {
  fileType: string
};

RightPanelDocumentFileUpload.defaultProps = {
  fileType: ''
};

export default RightPanelDocumentFileUpload;
