import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Sofort gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlSofort: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_sofort: {}
};

export default controlSofort;
