import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Venmo gateway connection properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlVenmo: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_venmo: {
    authOnly: {
      title: 'Charge Customer Later',
      description: 'You can manually charge the card in 3 days after the form is submitted.',
      type: 'toggle',
      selectedCondition: settings => settings.authOnly === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType !== 'subscription'
    },
    billToAddress: {
      title: 'Billing Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      addressSubfields: 'state|zip|st1|city|st2',
      default: 'none',
      resources: ['FORM'],
      autoSave: true
    },
    usePaypalSubscriptions: {
      title: 'Fetch Subscriptions from PayPal Account',
      description: 'Fetch subscriptions from the given PayPal account. This will override your Jotform subscriptions.',
      type: 'toggle',
      selectedCondition: settings => settings.usePaypalSubscriptions === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'subscription'
    },
    infoline: {
      type: 'infoline',
      title: 'PayPal Smart Buttons Style',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.showSPB === 'Yes'
    },
    styleColor: {
      title: 'Button Color',
      type: 'dropdown',
      options: [
        { id: 'gold', key: 'gold', text: 'Gold' },
        { id: 'blue', key: 'blue', text: 'Blue' },
        { id: 'silver', key: 'silver', text: 'Silver' },
        { id: 'white', key: 'white', text: 'White' },
        { id: 'black', key: 'black', text: 'Black' }
      ],
      default: 'gold',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.showSPB === 'Yes',
      autoSave: true
    },
    styleShape: {
      title: 'Button Shape',
      type: 'dropdown',
      options: [
        { id: 'pill', key: 'pill', text: 'Pill' },
        { id: 'rect', key: 'rect', text: 'Rect' }
      ],
      default: 'rect',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.showSPB === 'Yes',
      autoSave: true
    },
    styleSize: {
      title: 'Button Size',
      type: 'dropdown',
      options: [
        { id: 'small', key: 'small', text: 'Small' },
        { id: 'medium', key: 'medium', text: 'Medium' },
        { id: 'large', key: 'large', text: 'Large' }
      ],
      default: 'medium',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.showSPB === 'Yes',
      autoSave: true
    },
    styleLabel: {
      title: 'Button Label',
      type: 'dropdown',
      options: [
        { id: 'checkout', key: 'checkout', text: 'PayPal Checkout' },
        { id: 'pay', key: 'pay', text: 'Pay with PayPal' },
        { id: 'buynow', key: 'buynow', text: 'Buy Now' },
        { id: 'paypal', key: 'paypal', text: 'PayPal' }
      ],
      default: 'checkout',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.showSPB === 'Yes',
      autoSave: true
    },
    styleLayout: {
      title: 'Button Layout',
      type: 'dropdown',
      options: [
        { id: 'vertical', key: 'vertical', text: 'Vertical' },
        { id: 'horizontal', key: 'horizontal', text: 'Horizontal' }
      ],
      default: 'vertical',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.showSPB === 'Yes',
      autoSave: true
    },
    payLaterEnabled: {
      title: 'Show Pay Later Messaging',
      description: 'Displays information about the installments for Pay Later which is available in the US.',
      type: 'toggle',
      selectedCondition: settings => settings.payLaterEnabled === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'product' && settings.showSPB === 'Yes',
      autoSave: true
    },
    infoline_2: {
      type: 'infoline',
      title: 'Pay Later Messaging Style',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.showSPB === 'Yes' && settings.payLaterEnabled === 'Yes'
    },
    payLaterLayout: {
      title: 'Layout Style',
      type: 'dropdown',
      options: [
        { id: 'text', key: 'text', text: 'Text' },
        { id: 'flex', key: 'flex', text: 'Flex' }
      ],
      default: 'text',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.showSPB === 'Yes' && settings.payLaterEnabled === 'Yes',
      autoSave: true
    },
    payLaterLogoType: {
      title: 'Logo Type',
      type: 'dropdown',
      options: [
        { id: 'primary', key: 'primary', text: 'Primary' },
        { id: 'alternative', key: 'alternative', text: 'Alternative' },
        { id: 'inline', key: 'inline', text: 'Inline' },
        { id: 'none', key: 'none', text: 'None' }
      ],
      default: 'inline',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.showSPB === 'Yes' && settings.payLaterEnabled === 'Yes' && settings.payLaterLayout === 'text',
      autoSave: true
    },
    payLaterLogoPosition: {
      title: 'Logo Position',
      type: 'dropdown',
      options: [
        { id: 'left', key: 'left', text: 'Left' },
        { id: 'right', key: 'right', text: 'Right' },
        { id: 'top', key: 'top', text: 'Top' }
      ],
      default: 'left',
      resources: ['FORM', 'APP'],
      /* eslint-disable-next-line max-len */
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'product' && settings.showSPB === 'Yes' && settings.payLaterEnabled === 'Yes' && settings.payLaterLayout === 'text' && ['primary', 'alternative'].includes(settings.payLaterLogoType),
      autoSave: true
    },
    payLaterTextColor: {
      title: 'Text Color',
      type: 'dropdown',
      options: [
        { id: 'black', key: 'black', text: 'Black' },
        { id: 'white', key: 'white', text: 'White' },
        { id: 'monochrome', key: 'monochrome', text: 'Monochrome' },
        { id: 'grayscale', key: 'grayscale', text: 'Grayscale' }
      ],
      default: 'black',
      resources: ['FORM', 'APP'],
      /* eslint-disable-next-line max-len */
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'product' && settings.showSPB === 'Yes' && settings.payLaterEnabled === 'Yes' && settings.payLaterLayout === 'text',
      autoSave: true
    },
    payLaterTextSize: {
      title: 'Text Size',
      type: 'dropdown',
      options: [
        { id: '10', key: '10', text: '10' },
        { id: '11', key: '11', text: '11' },
        { id: '12', key: '12', text: '12' },
        { id: '13', key: '13', text: '13' },
        { id: '14', key: '14', text: '14' },
        { id: '15', key: '15', text: '15' },
        { id: '16', key: '16', text: '16' }
      ],
      default: '12',
      resources: ['FORM', 'APP'],
      /* eslint-disable-next-line max-len */
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'product' && settings.showSPB === 'Yes' && settings.payLaterEnabled === 'Yes' && settings.payLaterLayout === 'text',
      autoSave: true
    },
    payLaterBackgroundColor: {
      title: 'Message Background',
      type: 'dropdown',
      options: [
        { id: 'blue', key: 'blue', text: 'Blue' },
        { id: 'black', key: 'black', text: 'Black' },
        { id: 'white', key: 'white', text: 'White' },
        { id: 'white-no-border', key: 'white-no-border', text: 'White - No Border' },
        { id: 'gray', key: 'gray', text: 'Gray' },
        { id: 'monochrome', key: 'monochrome', text: 'Monochrome' },
        { id: 'grayscale', key: 'grayscale', text: 'Grayscale' }
      ],
      default: 'blue',
      resources: ['FORM', 'APP'],
      /* eslint-disable-next-line max-len */
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'product' && settings.showSPB === 'Yes' && settings.payLaterEnabled === 'Yes' && settings.payLaterLayout === 'flex',
      autoSave: true
    },
    payLaterRatio: {
      title: 'Banner Ratio',
      type: 'dropdown',
      options: [
        { id: '8x1', key: '8x1', text: '8x1' },
        { id: '20x1', key: '20x1', text: '20x1' }
      ],
      default: '20x1',
      resources: ['FORM', 'APP'],
      /* eslint-disable-next-line max-len */
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'product' && settings.showSPB === 'Yes' && settings.payLaterEnabled === 'Yes' && settings.payLaterLayout === 'flex',
      autoSave: true
    }
  }
};

export default controlVenmo;
