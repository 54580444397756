import compose from 'lodash/fp/compose';
import isString from 'lodash/isString';
import { NON_INPUT_QUESTIONS, STATIC_FIELDS_WITH_PLACEHOLDER, NON_INPUT_WIDGETS } from '@jotforminc/constants';
import { tagDuplicateProperty, shortenString } from './misc';
import { fixUTF, capitalize } from './strings';

export const getPlaceholder = ({
  type, text, name, fields, sublabels, disabled, protected: _protected, selectedField
}, anotherFieldDropdown) => {
  const isProtected = _protected === 'Yes';

  if ((!fields || !fields.length) && text && name) {
    return {
      text: shortenString(text, 40), placeholder: `{${name}}`, disabled, isProtected
    };
  }

  switch (type) {
    case 'control_widget': {
      if (selectedField === '533946093c1ad0c45d000070' && text && fields && !Array.isArray(fields)) { // Configurable List
        return {
          text: shortenString(text, 40), placeholder: `{${name}}`, disabled, isProtected
        };
      }
      return false;
    }
    case 'control_mixed': {
      if (!Array.isArray(fields)) {
        return false;
      }
      return fields.map(({ type: fieldType, fieldID }) => {
        const menuItemText = sublabels && sublabels[`field_${fieldID}`] !== '' ? sublabels[`field_${fieldID}`] : `${fieldType}_${fieldID}`;
        return {
          text: `${text} / ${menuItemText}`, placeholder: `{${name}[${fieldID}]}`, disabled, isProtected
        };
      });
    }
    case 'control_inline': {
      if (!Array.isArray(fields)) {
        return false;
      }
      if (anotherFieldDropdown) {
        return {
          text: `${text || 'Fill in the Blank'}`, placeholder: `{${name}}`, disabled, isProtected
        };
      }

      return fields.map(({ label, id }) => {
        return {
          text: `${text || 'Fill in the Blank'} / ${label}`, placeholder: `{${name}:${id}}`, disabled, isProtected
        };
      });
    }
    default:
      return false;
  }
};

export const getPlaceholderList = (questions, anotherFieldDropdown = false) => questions.reduce((prev, question) => {
  const placeholder = getPlaceholder(question, anotherFieldDropdown);
  if (!placeholder) {
    return prev;
  }

  if (Array.isArray(placeholder)) {
    return [...prev, ...placeholder];
  }

  return [...prev, placeholder];
}, []);

export const getDynamic = questions => questions.filter(({ type }) => NON_INPUT_QUESTIONS.indexOf(type) === -1);
export const getDynamicWidgets = questions => questions.filter(({ type, selectedField }) => type !== 'control_widget' || NON_INPUT_WIDGETS.indexOf(selectedField) === -1);
export const getLabeled = questions => questions.filter(({ text }) => text !== undefined);

export const getPlaceholderListForDynamicQuestions = compose(getPlaceholderList, getLabeled, getDynamic, getDynamicWidgets);

export const getPlaceholderListForDynamicQuestionsForWorkflow = (questions, anotherFieldDropdown = false) => {
  const dynamicQuestions = getDynamic(questions);
  const labeledQuestions = getLabeled(dynamicQuestions);
  const placeholderList = getPlaceholderList(labeledQuestions, anotherFieldDropdown);
  return placeholderList;
};

export const preparePlaceholderDynamicList = (questions, anotherFieldDropdown, usedFromWorkflow) => {
  return usedFromWorkflow
    ? getPlaceholderListForDynamicQuestionsForWorkflow(questions, anotherFieldDropdown)
    : getPlaceholderListForDynamicQuestions(questions);
};

export const getPlaceholderListWithStaticFields = ({
  usedFromWorkflow = false,
  anotherFieldDropdown = false,
  questions = [],
  staticFields = STATIC_FIELDS_WITH_PLACEHOLDER,
  staticFieldsAtTop = true,
  includeSeparator = true,
  isEncrypted = false
}) => {
  if (isEncrypted) {
    return staticFields;
  }
  const dynamicFields = preparePlaceholderDynamicList(questions, anotherFieldDropdown, usedFromWorkflow);
  const [firstPart, secondPart] = staticFieldsAtTop ? [staticFields, dynamicFields] : [dynamicFields, staticFields];
  const separatorPart = firstPart.length && secondPart.length ? [{ text: '------------------------------' }] : [];
  return [...firstPart, ...(includeSeparator ? separatorPart : []), ...secondPart];
};

export const getQuestionByPlaceholder = (placeholder, questionList) => questionList && questionList.length > 0 && questionList.find(question => {
  const tmpPlaceholder = getPlaceholder(question);
  switch (true) {
    case !placeholder:
      return false;
    case Array.isArray(tmpPlaceholder) && Boolean(Array.isArray(tmpPlaceholder) && tmpPlaceholder.find(({ placeholder: tmpPlaceholderPart }) => tmpPlaceholderPart === placeholder)):
    case tmpPlaceholder.placeholder === placeholder:
      return true;
    default:
      return false;
  }
});

export const preparePillObjectFromQuestion = question => ({
  id: question.qid,
  value: `{${question.name}}`,
  name: question.text,
  text: question.text,
  isValid: true,
  isQuestion: true
});

export const tagDuplicateQuestions = tagDuplicateProperty('text');

export const findSubField = (question, subfieldId) => {
  const questionFields = question?.fields;
  const subfield = Array.isArray(questionFields) ? questionFields.find(field => field?.id === subfieldId) : question;
  return subfield;
};

export const parseFieldValues = (value, splitter = ':') => {
  const fields = value.split(splitter);
  if (fields.length > 1) {
    return { questionID: fields[0], subfieldID: fields[1] };
  }
  return { questionID: fields[0] };
};

export const makeQuestionName = text => {
  if (typeof (text) === 'string') {
    var name = text.replace('&nbsp;', '');
    var tokens = name.split(/\s+/);
    name = fixUTF((tokens['1']) ? tokens['0'].toLowerCase() + capitalize(tokens['1'].toLowerCase()) : tokens['0'].toLowerCase());
    name = name.replace(/\W/gim, '');
    // Neil: If name is empty (due to foreign charsets), return text 'input' to enable propulation (182589)
    return name.length === 0 ? 'input' : name;
  }
};

export const deleteIncorrectPropTypes = question => {
  const objectProps = {
    control_datetime: ['limitDate']
  };
  const fieldTypes = Object.keys(objectProps);
  for (let i = 0; i < fieldTypes.length; i++) {
    const controlType = fieldTypes[i];
    if (question.type && question.type === controlType) {
      const questionProps = Object.keys(question);
      for (let q = 0; q < questionProps.length; q++) {
        const prop = questionProps[q];
        if (isString(question[prop]) && objectProps[controlType].indexOf(prop) > -1) {
          delete question[prop]; // eslint-disable-line
        }
      }
    }
  }
};
