import React, {
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react';
import { useDebounce } from '@jotforminc/hooks';
import { Button } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import { GATEWAY_SUPPORTED_RESOURCES } from '@jotforminc/payment-constants';
import { isSafari } from '@jotforminc/utils';
import { SCROLLABLE_CONTAINER_CLASSLIST } from '../Constants';

interface IActionFooter {
  backText?: string;
  saveText?: string;
  testIdBack?: string;
  testIdSave?: string;
  disableConditionSave?: boolean;
  saveBtnClass?: string;
  resource?: GATEWAY_SUPPORTED_RESOURCES;
  handleBack?: () => void;
  handleSave?: () => void;
}

const ActionFooter = ({
  backText,
  saveText,
  testIdBack,
  testIdSave,
  disableConditionSave,
  saveBtnClass,
  resource,
  handleBack,
  handleSave
}: IActionFooter): JSX.Element => {
  const [hasShadow, setHasShadow] = useState(false);
  const scrollableEl = useMemo(() => {
    return resource ? document.querySelector(SCROLLABLE_CONTAINER_CLASSLIST[resource]) : null;
  }, [resource]);

  const handleShadow = () => {
    if (scrollableEl) {
      const atBottom = scrollableEl?.scrollTop >= (scrollableEl.scrollHeight - scrollableEl.offsetHeight);
      if (!atBottom !== hasShadow) { setHasShadow(!atBottom); }
    }
  };

  const handleShadowDebounced = useCallback(useDebounce(handleShadow, 200), [scrollableEl, hasShadow]);
  handleShadowDebounced();

  useEffect(() => {
    scrollableEl?.addEventListener('scroll', handleShadow);
    window.addEventListener('resize', handleShadow);
    return () => {
      scrollableEl?.removeEventListener('scroll', handleShadow);
      window.removeEventListener('resize', handleShadow);
    };
  }, [scrollableEl, hasShadow]);

  const BackButton = useMemo(() => (
    <Button
      id="cta_back"
      colorStyle="secondary"
      className="text-capitalize order-last xs:order-first"
      onClick={handleBack}
      data-testid={testIdBack}
    >
      <span className="text-sm line-height-xs font-medium tracking-lg">{t(backText || '')}</span>
    </Button>
  ), [handleBack, testIdBack, backText]);

  const SaveButton = useMemo(() => (
    <Button
      id="cta_save"
      colorStyle="success"
      className={`text-capitalize xs:ml-auto ${saveBtnClass}`}
      onClick={handleSave}
      data-testid={testIdSave}
      disabled={disableConditionSave}
    >
      <span className="text-sm line-height-xs font-medium tracking-lg">{t(saveText || '')}</span>
    </Button>
  ), [saveBtnClass, handleSave, testIdSave, disableConditionSave, saveText]);

  /* WEIRD SAFARI POSITION FIXED ISSUE - SAVE BUTTON DISAPPEARS */
  const actionFooter = document.querySelector('.actionFooterWrapper');
  if (actionFooter && isSafari()) {
    const ms = resource === 'FORM' ? 1 : 400;
    const toggleClass = resource === 'FORM' ? 'fixed' : 'right-0';
    actionFooter.classList.remove(toggleClass);
    setTimeout(() => {
      actionFooter.classList.add(toggleClass);
    }, ms);
  }

  const navigationBar = document.querySelector('.multi-page-navigation-bar') !== null;
  const allowShadow = useMemo(() => !window.showPaymentReusableMigrationWarning, []);

  return (
    <div className={`fixed bottom-0 actionFooterWrapper ${navigationBar ? 'bottom-16' : ''} ${hasShadow && allowShadow ? 'hasShadow' : ''}`}>
      <div className="flex justify-between py-3 px-4 flex-col gap-2 xs:gap-0 xs:flex-row">
        { !!handleBack && BackButton }
        { !!handleSave && SaveButton }
      </div>
    </div>
  );
};

ActionFooter.defaultProps = {
  backText: 'Back',
  saveText: 'Save',
  handleBack: null,
  handleSave: null,
  testIdBack: '',
  testIdSave: '',
  disableConditionSave: false,
  saveBtnClass: '',
  resource: 'FORM'
};

export default ActionFooter;
