import * as Sentry from '@sentry/react';
import { ExclusiveEventHintOrCaptureContext } from '@sentry/core/types/utils/prepareEvent';
import { SentryOptions } from './types';
import { defaultSentryConfigs } from '../constants';

class SentryManager {
  constructor(sentryOptions: SentryOptions) {
    if (sentryOptions.dsn) {
      window.errorTracking = true; // FIXME: this is needed for some breadcrumbs, we should refactor code that uses this window variable
      Sentry.init({ ...defaultSentryConfigs, ...sentryOptions });
    }
  }

  captureException = (exception: unknown, hint?: ExclusiveEventHintOrCaptureContext): string => {
    if (exception instanceof Error) {
      return Sentry.captureException(exception, hint);
    }
    // we do not care about exceptions type at this point, just throwing an error is enough
    const exceptionAsString = exception as string;
    return Sentry.captureException(new Error(exceptionAsString), hint);
  };

  captureMessage = Sentry.captureMessage;

  configureScope = (fn: (scope: Sentry.Scope) => void): void => {
    const scope = Sentry.getCurrentScope();
    fn(scope);
  };

  configureGlobalScope = (fn: (scope: Sentry.Scope) => void): void => {
    const scope = Sentry.getGlobalScope();
    fn(scope);
  };

  captureEvent = Sentry.captureEvent;

  createReduxEnhancer = Sentry.createReduxEnhancer;
}
export default SentryManager;
