import { t } from '@jotforminc/translation';
import isUndefined from 'lodash/isUndefined';
import { FEATURE_NAMES } from '../../../../../constants/features';
import { isFeatureEnabled } from '../../../../../utils/features/helper';
import { fixWidgetDefaults } from '../../../../../properties';
import { ScRightPanelDropdown } from '../../../styles/scRightPanel';
import { getButtonShareDefMessages } from '../constants';

const ChoiceNodes = ['radio', 'checkbox', 'dropdown'];

/**
 * Fixes widget options
 * @param {Object} widget
 * taken from BUILDER repo
 */
const optionGenerator = widget => {
  const { type, options: opts, name } = widget;
  let _options = opts;
  // some checkboxes doesn't have any `options` property
  // check strictly against undefined and set it's name as an option
  if (isUndefined(_options)) {
    _options = name;
  }

  const options = _options.split(',').map((option, i) => {
    const opt = {
      id: i,
      value: option,
      text: t(option)
    };

    // checking for `OptionValue::OptionText` formatted options
    if (option.indexOf('::') >= 0) {
      [opt.value, opt.text] = option.split('::');
    }
    if (type === 'checkbox') {
      opt.value = 'true';
    }
    return opt;
  });

  return { ...widget, options };
};

export const generateWidgetPanelProps = widgetProps => {
  const { fieldParameters = [] } = fixWidgetDefaults(widgetProps);

  const widgetMapper = {
    dropdown: 'dropdown',
    text: 'textinput',
    radio: 'toggle',
    fileupload: 'widgetFileUpload',
    multipleimage: 'imageUpload',
    color: 'colorPicker'
  };

  const ignoredProps = [
    'textLength',
    'titleLength',
    'attach',
    'customCSS'
  ];

  // widget specific ignore props
  const widgetIgnoreProps = {
    '5293030905a7114f70000027': ['theme'],
    '529cd0ea8afa8f742d000004': ['theme'],
    '529cf1de381e0e8840000004': ['bgcolor']
  };

  const isWidgetHeightAdjustable = clientID => [
    '5272130bf9879ec031000017', // Instagram
    '527213673e8f4eec3100000d', // Pinterest
    '526a318cdeab09892100001c' // Animoto
  ].includes(clientID);

  const getExtraProps = type => {
    switch (type) {
      case 'multipleimage':
        return {
          multipleFileUpload: true,
          allowMultipleSelection: true,
          tethered: true
        };
      case 'dropdown':
        return {
          DropdownWrapperRenderer: ScRightPanelDropdown
        };
      default:
        return {};
    }
  };

  const widgetPanelProps = fieldParameters.reduce((prev, props) => {
    const {
      name, type
    } = props;
    const { client_id: clientID } = widgetProps;

    if (ignoredProps.includes(name) || (widgetIgnoreProps[clientID] && widgetIgnoreProps[clientID].includes(name))) {
      return prev;
    }

    const _props = ChoiceNodes.indexOf(type) >= 0 ? optionGenerator(props) : props;

    const {
      readable, tip, options, default: _default, ...otherProps
    } = _props;

    const elementType = type ? (widgetMapper[type] || type) : 'textinput';

    const isInstagramWidgetUrlInput = clientID === '5272130bf9879ec031000017' && name === 'URL';

    const itemPanelProps = {
      ...getExtraProps(type),
      ...otherProps,
      title: t(readable),
      description: t(isInstagramWidgetUrlInput ? 'Share Instagram posts on your app' : tip),
      type: elementType,
      options,
      defaultValue: _default || ''
    };

    return { ...prev, [name]: { ...itemPanelProps } };
  }, {});

  if (isWidgetHeightAdjustable(widgetProps.client_id)) {
    widgetPanelProps.frameHeight = {
      defaultValue: '',
      description: undefined,
      name: 'frameHeight',
      options: undefined,
      title: 'Height',
      type: 'textinput'
    };
  }

  widgetPanelProps.shrink = {
    type: 'toggle',
    title: t('Shrink'),
    description: t('Make element smaller.'),
    defaultvalue: 'No',
    options: [{ id: 0, value: 'Yes', text: 'Yes' }, { id: 1, value: 'No', text: 'No' }]
  };

  if (isFeatureEnabled(FEATURE_NAMES.ItemDuplication)) {
    widgetPanelProps.duplicate = {
      type: 'duplication',
      title: t('Duplicate Element'),
      description: t('Duplicate this element with all saved properties.'),
      allowMultiplePropChange: true,
      additionalProps: ['page']
    };
  }

  return widgetPanelProps;
};

export const getWidgetTitle = itemTitle => {
  switch (itemTitle) {
    case 'YouTube':
      return 'Video';
    case 'Show Map Location':
    case 'Open Street Map':
      return 'Map';
    default:
      return itemTitle;
  }
};

export const replaceTextWithVariables = (text, variables) => {
  let replacedText = text;
  Object.keys(variables).forEach(variable => {
    const variablePlaceholder = `{${variable}}`;
    const variableValue = variables[variable];
    replacedText = replacedText.replaceAll(variablePlaceholder, variableValue);
  });
  return replacedText;
};

export const getUpdatedShareMessage = (parsedValue, donationItems, isDonationGoalActive) => {
  const isTextEdited = !Object.values(getButtonShareDefMessages()).some(item => item === parsedValue.text);
  if (isTextEdited) {
    return false;
  }

  if (parsedValue.type === 'donationShare') {
    if (!donationItems.length) {
      return { type: 'appShare', text: getButtonShareDefMessages().appShare };
    }
    if (isDonationGoalActive) {
      return { type: 'donationShare', text: getButtonShareDefMessages().donationGoalShare };
    }
    if (!isDonationGoalActive) {
      return { type: 'donationShare', text: getButtonShareDefMessages().donationShare };
    }
  }

  return false;
};
