import React, { useMemo } from 'react';
import WarningBox from '@jotforminc/warning-box';
import { t, translationRenderer } from '@jotforminc/translation';
import { IconInfoCircleFilled, IconArrowUpRightFromSquare } from '@jotforminc/svg-icons';
import { Link } from '@jotforminc/magnet';

import { CONNECTION_LIST_DATA } from '../../../../../types/common';
import usePaymentPropsPanel from '../../Context/usePaymentProperties';
import { CONNECTION_STATUSES } from '../../Constants';

interface I_CONNECTION_STATUS_WARNING {
  connectionInfo: CONNECTION_LIST_DATA;
  notConnOwner: boolean;
  isUserConnectionOwner: boolean;
}

const TEXT_UNAUTHORIZED = translationRenderer('This connection won’t be able to collect payments until it’s [1[reconnected]]. Contact the connection owner to reconnect.')({
  renderer1: text => (<span className="color-gray-700 font-medium">{text}</span>)
});

const getReconnectWarningWithLink = (linkText: string, href: string) => {
  return translationRenderer('This connection won’t be able to collect payments until it’s [1[reconnected]] in [2[{location}]]')({
    renderer1: () => (<span className="color-gray-700 font-medium">reconnected</span>),
    renderer2: () => (
      <Link
        href={href}
        target='_blank'
        className="inline-flex items-center"
      >
        <span className='font-normal'>{linkText}</span>
        <IconArrowUpRightFromSquare className='ml-1 w-3.5 h-3.5' />
      </Link>
    )
  });
};

const ConnectionStatusWarning = ({ connectionInfo, notConnOwner, isUserConnectionOwner }: I_CONNECTION_STATUS_WARNING): JSX.Element => {
  const {
    isTeamMemberAndCreator,
    isOrganizationAdmin,
    isTeamAdmin
  } = usePaymentPropsPanel();

  const {
    title,
    titleClass,
    descriptionClass
  } = CONNECTION_STATUSES[connectionInfo.status];

  const isUnauthorized = useMemo(() => isTeamMemberAndCreator || notConnOwner || (isTeamAdmin && !isOrganizationAdmin && connectionInfo.scope === 'org'), [notConnOwner, connectionInfo.scope]);

  const description = useMemo(() => {
    if (isOrganizationAdmin && connectionInfo.scope === 'org') {
      return getReconnectWarningWithLink('Admin Console', `${window.location.origin}/enterprise-admin/`);
    }
    if ((isOrganizationAdmin || isTeamAdmin) && connectionInfo.scope === 'team') {
      return getReconnectWarningWithLink('Team Settings', `${window.location.origin}/myaccount/team/${window.teamID}/connections`);
    }
    if (isUserConnectionOwner) {
      return getReconnectWarningWithLink('My Account', `${window.location.origin}/myaccount/connections`);
    }
    if (isUnauthorized) {
      return TEXT_UNAUTHORIZED;
    }

    return getReconnectWarningWithLink('My Account', `${window.location.origin}/myaccount/connections`);
  }, [isUnauthorized, connectionInfo.scope, isUserConnectionOwner]);

  return (
    <WarningBox
      isLongText
      colorStyle="none"
      size="small"
      wrapperClassName="mt-3"
      className="gap-1 bg-red-200 color-red-500 items-start"
      Icon={IconInfoCircleFilled}
    >
      <span className={`block ${titleClass}`}>{t(title)}</span>
      {description && <span className={descriptionClass}>{description}</span>}
    </WarningBox>
  );
};

export default ConnectionStatusWarning;
