import {
  put, take, takeEvery, select, call
} from 'redux-saga/effects';
import { PaymentActions, Variables, checkIsGatewayConnectedAndTestMode } from '@jotforminc/payment-settings-editor';
import { arrayMove } from '@jotforminc/utils';
import { FEATURE_NAMES } from '../../constants/features';
import { isFeatureEnabled } from '../../utils/features/helper';
import * as ACTION_TYPES from '../actionTypes';
import SELECTORS from '../selectors';
import * as ACTION_CREATORS from '../actionCreators';
import { isYes } from '../../utils';
import { getDefaultQuestionValues } from '../../constants/questions';
import * as API from '../../modules/api';

function* watchFetchQuestions({ payload: checkoutFormID }) {
  const result = yield call(PaymentActions.getQuestionProps, checkoutFormID);

  yield put({ type: ACTION_TYPES.FETCH_CHECKOUT_FORM_QUESTIONS.SUCCESS, payload: result });
}

function* watchFetchQuestionsSuccess() {
  const { connectedGateway, isGatewayConfigured, isGatewayTestMode } = yield select(SELECTORS.getGatewayProperties);
  const gatewaySettings = yield select(SELECTORS.getCheckoutFormActiveGatewaySettings);
  const checkoutFormPaymentQuestion = yield select(SELECTORS.getCheckoutFormPaymentQuestion);
  if (!checkoutFormPaymentQuestion) {
    const appID = yield select(SELECTORS.getAppID);
    try {
      const questions = yield call(API.createPaymentQuestion, appID);
      yield put({ type: ACTION_TYPES.ADD_CHECKOUT_FORM_QUESTION.SUCCESS, payload: questions[Variables.CHECKOUT_FORM_QUESTIONS.GATEWAY] });
    } catch (e) {
      console.error('Error while creating payment question', e);
    }
  }
  const { type = '' } = gatewaySettings;
  const [isConnected, isTestMode] = checkIsGatewayConnectedAndTestMode(gatewaySettings);
  if (connectedGateway !== type || isYes(isGatewayConfigured) !== isConnected || isYes(isGatewayTestMode) !== isTestMode) {
    yield put(ACTION_CREATORS.updatePortalAction({ connectedGateway: type, isGatewayConfigured: isConnected, isGatewayTestMode: isTestMode }));
  }
}

function* watchPaymentGatewayChanges({ payload }) {
  const { gatewaySettings, itemDeleted = 0, ...others } = payload;
  if (!isFeatureEnabled(FEATURE_NAMES.NewPaymentModal)) {
    const [isConnected, isTestMode] = checkIsGatewayConnectedAndTestMode(payload);
    const { type = '' } = payload;
    yield put(ACTION_CREATORS.updatePortalAction({ connectedGateway: type, isGatewayConfigured: isConnected, isGatewayTestMode: isTestMode }));
    yield put({ type: ACTION_TYPES.CHECKOUT_FORM_GATEWAY_SETTINGS_CHANGE.SUCCESS, payload });
  } else {
    const [isConnected, isTestMode] = checkIsGatewayConnectedAndTestMode({ ...others, ...gatewaySettings });
    const { type = '' } = payload;
    const checkoutFormID = yield select(SELECTORS.getCheckoutFormIDSelector);
    const result = yield call(PaymentActions.switchPaymentGateway, checkoutFormID, others, itemDeleted);
    if (type === 'control_iyzico' && isConnected) {
      yield put(ACTION_CREATORS.addCheckoutFormQuestion({
        type: 'control_address', name: 'address', required: 'Yes', subfields: 'state|zip|st1|city|st2|country'
      }));
      const { payload: { qid } } = yield take(ACTION_TYPES.ADD_CHECKOUT_FORM_QUESTION.SUCCESS);
      yield put(ACTION_CREATORS.updateCheckoutFormQuestion(Variables.CHECKOUT_FORM_QUESTIONS.GATEWAY.toString(), { billingAdd: qid }));
    }
    yield put(ACTION_CREATORS.updatePortalAction({ connectedGateway: type, isGatewayConfigured: isConnected, isGatewayTestMode: isTestMode }));
    yield put({ type: ACTION_TYPES.CHECKOUT_FORM_GATEWAY_SETTINGS_CHANGE.SUCCESS, payload: { ...result[0], ...(others.paymentType && { paymentType: others.paymentType }) } });
    if (gatewaySettings) {
      yield put(ACTION_CREATORS.updateCheckoutFormSettings(gatewaySettings));
    }
  }
}
function* watchCheckoutFormSettingsChanges({ payload: settings }) {
  const paymentQuestion = yield select(SELECTORS.getCheckoutFormPaymentQuestion);
  const questionFormattedSettings = Object.entries(settings).reduce((prev, [property, PropertyValue]) => {
    return { ...prev, [`question[${property}]`]: PropertyValue };
  }, {});
  const checkoutFormID = yield select(SELECTORS.getCheckoutFormIDSelector);
  const result = yield call(PaymentActions.updateActiveGatewaySettings, checkoutFormID, Variables.CHECKOUT_FORM_QUESTIONS.GATEWAY, questionFormattedSettings);
  yield put({ type: ACTION_TYPES.CHECKOUT_FORM_SETTINGS_CHANGE.SUCCESS, payload: { ...paymentQuestion, ...result[0] } });
}

function* watchAddQuestion({ payload: question }) {
  const checkoutFormID = yield select(SELECTORS.getCheckoutFormIDSelector);
  const lastVisibleQuestionOrder = yield select(SELECTORS.getLastVisibleQuestionOrder);
  const lastQuestionID = yield select(SELECTORS.getLastQuestionID);

  const { type } = question;

  const defaultQuestionValues = getDefaultQuestionValues()[type];

  // TODO: extract default props getter from form-builder
  const { questions } = yield call(PaymentActions.addCheckoutFormQuestion, checkoutFormID, {
    ...defaultQuestionValues,
    ...question,
    name: `${type.replace('control_', '')}${lastQuestionID + 1}`,
    order: lastVisibleQuestionOrder + 1
  });

  yield put({ type: ACTION_TYPES.ADD_CHECKOUT_FORM_QUESTION.SUCCESS, payload: questions });
}

function* watchRemoveQuestion({ payload: questionID }) {
  const checkoutFormID = yield select(SELECTORS.getCheckoutFormIDSelector);

  yield call(PaymentActions.removeCheckoutFormQuestion, checkoutFormID, questionID);

  yield put({ type: ACTION_TYPES.REMOVE_CHECKOUT_FORM_QUESTION.SUCCESS, payload: questionID });
}

function* watchSortQuestion({ payload: { oldIndex, newIndex } }) {
  const checkoutFormID = yield select(SELECTORS.getCheckoutFormIDSelector);
  const questions = yield select(SELECTORS.getCheckoutFormVisibleQuestionsArray);
  const hiddenQuestions = yield select(SELECTORS.getCheckoutFormHiddenQuestionsArray);

  const sortedQuestions = arrayMove([...questions, ...hiddenQuestions], oldIndex, newIndex).map((question, index) => ({ ...question, order: index + 1 }));

  const questionsObject = Object.fromEntries(sortedQuestions.map(question => [question.qid, question]));

  yield put({ type: ACTION_TYPES.SORT_CHECKOUT_FORM_QUESTION.SUCCESS, payload: questionsObject });

  const nakedQuestions = sortedQuestions.map(({ qid, order }) => ({ qid, order }));

  yield call(PaymentActions.updateCheckoutFormQuestions, checkoutFormID, nakedQuestions);
}

function* watchUpdateQuestion({ payload: { id, prop } }) {
  const checkoutFormID = yield select(SELECTORS.getCheckoutFormIDSelector);

  yield call(PaymentActions.updateCheckoutFormQuestionProp, checkoutFormID, id, prop);

  yield put({ type: ACTION_TYPES.UPDATE_CHECKOUT_FORM_QUESTION.SUCCESS, payload: { id, prop } });
}

export default function* () {
  yield takeEvery(ACTION_TYPES.FETCH_CHECKOUT_FORM_QUESTIONS.REQUEST, watchFetchQuestions);
  yield takeEvery(ACTION_TYPES.FETCH_CHECKOUT_FORM_QUESTIONS.SUCCESS, watchFetchQuestionsSuccess);
  yield takeEvery(ACTION_TYPES.ADD_CHECKOUT_FORM_QUESTION.REQUEST, watchAddQuestion);
  yield takeEvery(ACTION_TYPES.REMOVE_CHECKOUT_FORM_QUESTION.REQUEST, watchRemoveQuestion);
  yield takeEvery(ACTION_TYPES.UPDATE_CHECKOUT_FORM_QUESTION.REQUEST, watchUpdateQuestion);
  yield takeEvery(ACTION_TYPES.SORT_CHECKOUT_FORM_QUESTION.REQUEST, watchSortQuestion);
  yield takeEvery(ACTION_TYPES.CHECKOUT_FORM_GATEWAY_SETTINGS_CHANGE.REQUEST, watchPaymentGatewayChanges);
  yield takeEvery(ACTION_TYPES.CHECKOUT_FORM_SETTINGS_CHANGE.REQUEST, watchCheckoutFormSettingsChanges);
}
