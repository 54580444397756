import React, { useEffect, Fragment } from 'react';
import {
  string, func, bool, arrayOf, object
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { classNames } from '@jotforminc/utils';
import { AppPreviewBody } from '../../../AppPreview';
import Button from '../../../Button';
import texts from '../../../../core/texts';
import SimilarTemplatesItem from './SimilarTemplatesItem';
import './style.scss';

const AppTemplatePreviewContent = ({
  id, template, categoryData, useTemplate, modalContentLoading,
  isCloningTemplate, similarTemplates, setModalContentLoading, modalBodyRef, frameSrc
}) => {
  const templateType = frameSrc.split('/')[1];

  useEffect(() => {
    if (Object.keys(categoryData).length > 0) {
      setModalContentLoading(false);
    }
  }, [categoryData]);

  const { title = '', description = '' } = template;
  return (
    <div className="jfModal-content">
      <AppPreviewBody
        currentThemeId={id}
        template={template}
        customTryText='Try this app!'
        showAsTemplatePreview={true}
        modalContentLoading={modalContentLoading}
      />
      {
        (Object.keys(template).length > 0) && (
          <div className='content-area'>
            {!isEnterprise() && (
              <>
                <div className='jfWizard-modal-detail'>
                  <div className='description-area' dangerouslySetInnerHTML={{ __html: description }} />
                  <div className='button-wrapper'>
                    <div className='action-area'>
                      <p className='title'>{t('See app in action')}</p>
                      <p className='subtitle'>{t('Use your camera to scan the QR code and preview the app on your device.')}</p>
                      <div className='qr-wrapper'>
                        <img src={JSON.parse(template.JSON || '{}')?.templateQRCode} alt={title} />
                      </div>
                    </div>
                    <Button
                      onClick={useTemplate}
                      disabled={isCloningTemplate && 'disabled'}
                      className={classNames('forUseTemplate', isCloningTemplate && 'isCloningTemplate')}
                    >
                      {isCloningTemplate ? t(texts.PLEASE_WAIT) : t(texts.USE_TEMPLATE)}
                    </Button>
                  </div>
                </div>
              </>
            )}
            <SimilarTemplatesItem
              parentTemplateID={id}
              templateType={templateType}
              modalBodyRef={modalBodyRef}
              categoryData={categoryData}
              similarTemplates={similarTemplates}
              setModalContentLoading={setModalContentLoading}
            />
          </div>
        )
      }
    </div>
  );
};

AppTemplatePreviewContent.defaultProps = {
  id: null,
  template: {},
  frameSrc: '/',
  categoryData: null,
  similarTemplates: [],
  modalBodyRef: undefined,
  isCloningTemplate: false,
  modalContentLoading: false
};

AppTemplatePreviewContent.propTypes = {
  id: string,
  frameSrc: string,
  template: object,
  categoryData: object,
  modalBodyRef: object,
  isCloningTemplate: bool,
  modalContentLoading: bool,
  useTemplate: func.isRequired,
  similarTemplates: arrayOf(object),
  setModalContentLoading: func.isRequired
};

export default AppTemplatePreviewContent;
