import {
  gatewayFieldToName,
  gatewaySupportedCurrencies,
  gatewayResourceSupportedPaymentTypes,
  gatewayListSortedByCountry
} from '../gateway';
import type { GATEWAY_CONNECTION_PROPS } from '../types';

/**
 * Control PayPal Business gateway connection properties.
 * @type {GATEWAY_CONNECTION_PROPS}
 */
export const controlPaypalComplete: GATEWAY_CONNECTION_PROPS = {
  gateway_type: 'control_paypalcomplete',
  name: gatewayFieldToName.control_paypalcomplete,
  supportedCurrencies: gatewaySupportedCurrencies.control_paypalcomplete,
  supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_paypalcomplete,
  sortByCountry: gatewayListSortedByCountry.control_paypalcomplete,

  /** APM's */
  isApm: false,
  isApmSupport: true,
  supportedApm: {
    control_venmo: {
      name: gatewayFieldToName.control_venmo,
      supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_venmo,
      supportedCurrencies: gatewaySupportedCurrencies.control_venmo
    }
  },
  isChildTypes: false,
  childTypes: [],
  isReferenceParentGatewayType: false,
  parentType: null,
  /** APM's: End */

  /** Env Settings */
  env: 'production',
  allowNewConnection: true,
  allowResourceTypes: ['APP', 'FORM'],
  isDeprecated: false,

  /** Connection Properties */
  connection: {
    type: 'oauth',
    allowSwitchMode: true,
    mode: {
      name: 'sandbox',
      options: [
        { value: 'enabled', text: 'Test Mode', mappedValue: '1' },
        { value: 'disabled', text: 'Live Mode', mappedValue: '0' }
      ]
    },
    propReferences: {
      merchantId: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Merchant ID' } },
      sandbox: { isSecret: 'No', isRequired: 'Yes' }
    },
    uniquePropNames: ['merchantId']
  }
};
