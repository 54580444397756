/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';

const CardFormIcon = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="13" y="24" width="21" height="16" rx="4" fill="white" />
      <rect x="16" y="30.5" width="16" height="3" rx="1.5" fill="#C8CEED" />
      <rect className="anim blue" x="16" y="30.5" width="3" height="3" rx="1.5" fill="#0099FF" opacity="0" />
      <rect x="30" y="24" width="21" height="16" rx="4" fill="white" />
      <rect x="32" y="30.5" width="16" height="3" rx="1.5" fill="#C8CEED" />
      <rect className="anim yellow" x="45" y="30.5" width="3" height="3" rx="1.5" fill="#FFB629" opacity="0" />
      <rect x="21" y="21" width="22" height="22" rx="4" fill="#E3E5F5" />
      <rect x="22" y="22" width="20" height="20" rx="3" fill="white" />
      <rect x="25" y="28" width="14" height="3" rx="1.5" fill="#C8CEED" />
      <rect className="anim orangeTop" x="25" y="28" width="3" height="3" rx="1.5" fill="#FF6100" opacity="0" />
      <rect x="25" y="33" width="14" height="3" rx="1.5" fill="#C8CEED" />
      <rect className="anim orangeBottom" x="36" y="33" width="3" height="3" rx="1.5" fill="#FF6100" opacity="0" />
    </svg>
  );
};

export default CardFormIcon;
