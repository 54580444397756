import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { SVGIcon } from '@jotforminc/icon-selector';
import { number, string } from 'prop-types';
import Styled, { css } from 'styled-components';
import { isYes } from '../../../../../utils';
import { FEATURE_NAMES } from '../../../../../constants/features';
import { isFeatureEnabled } from '../../../../../utils/features/helper';
import SELECTORS from '../../../../../store/selectors';

const ScPageIcon = Styled.div`
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
  display: inline-block;
  margin-right: 8px;

  .jfIconSVG-wrapper > div {
    ${({ size }) => css`
      width: ${size}px;
      height: ${size}px;
    `}

    svg {
      fill: #6C73A8;
    }
  }
`;

const PageIcon = ({ showPageIcon, pageIcon, size }) => {
  const showAllPagesIcon = useSelector(SELECTORS.getShowAllPagesIcon);

  const hasIcon = isYes(showAllPagesIcon) && isYes(showPageIcon) && pageIcon;

  return hasIcon && (
    <ScPageIcon size={size}>
      <SVGIcon url={isFeatureEnabled(FEATURE_NAMES.FullIconUrl) ? `https://cdn.jotfor.ms${pageIcon}` : pageIcon} />
    </ScPageIcon>
  );
};

PageIcon.propTypes = {
  showPageIcon: string,
  pageIcon: string,
  size: number
};

PageIcon.defaultProps = {
  showPageIcon: 'No',
  pageIcon: '',
  size: 14
};

export default memo(PageIcon);
