import * as ACTION_TYPES from '../actionTypes';
import { ITEM_TYPES } from '../../constants/itemTypes';
import { FORM_PRODUCTS, ITEM_ADDITION_ORDER_STRATEGY } from '../../constants';
import { FEATURE_NAMES } from '../../constants/features';
import { isFeatureEnabled } from '../../utils/features/helper';

export const selectPortalItemAction = selection => {
  return { type: ACTION_TYPES.SELECT_PORTAL_ITEM, payload: selection };
};

export const toggleNetworkStatusAction = status => {
  return { type: ACTION_TYPES.TOGGLE_NETWORK_STATUS, payload: status };
};

export const setModeAction = mode => {
  return { type: ACTION_TYPES.SET_MODE, payload: mode };
};

export const fetchFormsAction = () => {
  return { type: ACTION_TYPES.FETCH_FORMS.REQUEST };
};

export const fetchPortalAction = portalID => {
  return { type: ACTION_TYPES.FETCH_PORTAL.REQUEST, portalID };
};

export const fetchSignAction = () => {
  return { type: ACTION_TYPES.FETCH_SIGNS.REQUEST };
};

export const updatePortalSlugAction = ({
  slug, resolve, reject, forReset = false
}) => {
  return {
    type: ACTION_TYPES.UPDATE_PORTAL_SLUG.REQUEST, slug, resolve, reject, forReset
  };
};

export const updatePortalAction = data => {
  return { type: ACTION_TYPES.UPDATE_PORTAL.UNDOABLE, payload: data };
};

export const updatePortalPropAction = props => {
  return { type: ACTION_TYPES.UPDATE_PORTAL.REQUEST, payload: props };
};

export const addPortalItemAction = (item, order = ITEM_ADDITION_ORDER_STRATEGY.TO_END_OF_THE_PAGE, withClicking = false, isDuplicate = false, focusItemID = null) => {
  const assignTempID = i => {
    const assigned = { ...i, tempID: `${Math.floor(Math.random() * Date.now())}` };

    if (isFeatureEnabled(FEATURE_NAMES.FormResource)) return assigned;

    return (i.type === ITEM_TYPES.FORM ? i : assigned);
  };
  const items = (Array.isArray(item) ? item : [item]).map(assignTempID);
  return {
    type: ACTION_TYPES.ITEM_ADDITION_PORTAL_ORDER_WORKER.REQUEST,
    payload: {
      items,
      order,
      withClicking,
      isDuplicate,
      focusItemID
    }
  };
};
// config {hasSecondaryPage: boolean}
export const createDataSourceItem = (item, config) => ({ type: ACTION_TYPES.CREATE_DATA_SOURCE_ITEM.REQUEST, payload: { item, config } });

export const removePortalItems = itemIDs => {
  return { type: ACTION_TYPES.REMOVE_PORTAL_ITEMS.REQUEST, payload: { itemIDs } };
};

export const updateItemPropAction = data => {
  return { type: ACTION_TYPES.UPDATE_ITEM_PROP.UNDOABLE, payload: data };
};

export const updateItemPropWithoutDebounceAction = payload => {
  return { type: ACTION_TYPES.UPDATE_ITEM_PROP.WITHOUT_DEBOUNCE, payload };
};

export const updateMultipleItemAction = (data, extraOptions = {}) => {
  const { dontStack = false } = extraOptions;
  return { type: ACTION_TYPES.UPDATE_MULTIPLE_ITEM.UNDOABLE, payload: data, dontStack };
};

export const setTodoItemCompleted = data => {
  return { type: ACTION_TYPES.UPDATE_ITEM_PROP.WITHOUT_DEBOUNCE, payload: data };
};

export const setItemPropAction = (itemID, prop) => {
  return { type: ACTION_TYPES.UPDATE_ITEM_PROP.WITHOUT_DEBOUNCE, payload: { itemID, prop } };
};

export const changeOrderAction = data => {
  return { type: ACTION_TYPES.UPDATE_ORDER_WORKER.REQUEST, payload: data };
};

export const setAppDesignModeAction = mode => {
  return { type: ACTION_TYPES.SET_APP_DESIGN_MODE, payload: mode };
};

export const setRightPanelModeAction = mode => {
  return { type: ACTION_TYPES.SET_RIGHT_PANEL_MODE, payload: mode };
};

export const toggleRightPanelAction = shouldOpen => {
  return { type: ACTION_TYPES.TOGGLE_RIGHT_PANEL, payload: shouldOpen };
};

export const togglePreviewPanelAction = shouldOpen => {
  return { type: ACTION_TYPES.TOGGLE_PREVIEW_PANEL, payload: shouldOpen };
};

export const toggleLivePreviewAction = isActive => {
  return { type: ACTION_TYPES.TOGGLE_LIVE_PREVIEW, payload: isActive };
};

export const prepareRightPanelAction = (mode, item) => {
  return { type: ACTION_TYPES.PREPARE_RIGHT_PANEL, payload: { mode, item } };
};

export const setPWAStatusAction = ({ isOpenedInPWA, isPWAInstalled }) => {
  return { type: ACTION_TYPES.SET_PWA_STATUS, isOpenedInPWA, isPWAInstalled };
};

export const useTemplateAction = data => {
  return { type: ACTION_TYPES.CREATE_FORM_FROM_TEMPLATE.REQUEST, payload: data };
};

export const useFormCreateAction = formID => {
  return { type: ACTION_TYPES.CREATE_FORM_FROM_SCRATCH.REQUEST, payload: formID };
};

export const useSignTemplateAction = (cloneData, confirmData) => {
  return { type: ACTION_TYPES.CREATE_SIGN_DOCUMENT_FROM_TEMPLATE.REQUEST, payload: { cloneData, confirmData } };
};

export const bulkShareAction = data => {
  return { type: ACTION_TYPES.BULK_SHARE_PORTAL.REQUEST, payload: data };
};

export const bulkUnshareAction = resourceShareIDList => {
  return { type: ACTION_TYPES.BULK_DELETE_SHARE_PORTAL.REQUEST, payload: { resourceShareIDList } };
};

export const trackEventAction = data => {
  return { type: ACTION_TYPES.TRACK_EVENT, payload: data };
};

export const updateResourceShareURLAction = data => {
  return { type: ACTION_TYPES.UPDATE_RESOURCE_SHARE_URL.REQUEST, payload: data };
};

export const toggleLeftPanelAction = isOpen => {
  return { type: ACTION_TYPES.TOGGLE_LEFT_PANEL, payload: isOpen };
};

export const notFoundFormAction = formError => {
  return { type: ACTION_TYPES.FETCH_FORMS.ERROR, payload: (formError ? 'FORM_NOT_FOUND' : 'UNKNOWN_FORM_ERROR') };
};

export const notFoundResourceAction = (resourceType, isIDInvalid) => {
  const upperCaseResourceType = (resourceType === FORM_PRODUCTS.SENTBOX) ? 'FORM' : resourceType.toUpperCase();
  const errorType = (resourceType === FORM_PRODUCTS.FORM && !isIDInvalid) ? 'UNKNOWN_FORM_ERROR' : `${upperCaseResourceType}_NOT_FOUND`;
  return { type: ACTION_TYPES.FETCH_RESOURCES_ERROR, payload: errorType };
};

export const shouldSqueezeAction = isSqueeze => {
  return { type: ACTION_TYPES.SHOULD_WINDOW_SQUEEZE, payload: isSqueeze };
};

export const appPreviewStatusAction = status => {
  return { type: ACTION_TYPES.APP_PREVIEW_STATUS, payload: status };
};

export const toggleAppPreviewDeviceAction = device => {
  return { type: ACTION_TYPES.APP_PREVIEW_DEVICE, payload: device };
};

export const toggleImageCropState = (prop, isCropMode) => {
  const types = { appCoverBgURL: ACTION_TYPES.SET_APP_COVER_CROP_MODE, appHeaderBgURL: ACTION_TYPES.SET_APP_HEADER_CROP_MODE };
  return { type: types[prop], payload: isCropMode };
};

export const updateItemLoadingStatus = data => {
  return { type: ACTION_TYPES.SET_ITEM_LOADING_STATUS, payload: data };
};

export const sendFeedbackAction = data => {
  // usage example
  // dispatch(sendFeedbackAction({ stars: 5, feedbackText: 'Phoenix Team is amazing!' }));

  // star rating value (integer)
  // rating value decides which question to reply (are we updating texts on modal same as form condition?)
  return { type: ACTION_TYPES.SEND_FEEDBACK.REQUEST, payload: data };
};

export const setUserAction = (user = {}) => {
  const {
    account_type: { name: accountTypeName } = {},
    email = ''
  } = user;

  // FormUsers dont have any account_type, but email..
  const type = accountTypeName ? 'USER' : ((email && 'FORM_USER') || '');
  return { type: ACTION_TYPES.FETCH_USER.SUCCESS, payload: { credentials: user, type } };
};

export const updateUserSettings = data => {
  return { type: ACTION_TYPES.UPDATE_USER.REQUEST, payload: data };
};

export const continueAsUser = () => ({ type: ACTION_TYPES.CONTINUE_AS_USER.REQUEST });

export const fetchUserAppsAction = () => {
  return { type: ACTION_TYPES.FETCH_APPS.REQUEST };
};

export const undoAction = () => ({ type: ACTION_TYPES.UNDO });
export const redoAction = () => ({ type: ACTION_TYPES.REDO });

export const toastAction = props => {
  const { error = false, ...payload } = props;
  return { type: ACTION_TYPES.TOAST[error ? 'ERROR' : 'NOTIFICATION'], payload };
};

export const shoppingToastAction = payload => ({ type: ACTION_TYPES.APP_TOAST.SHOPPING_NOTIFICATION, payload });

export const undoableToastAction = message => ({ type: ACTION_TYPES.TOAST.UNDO_NOTIFICATION, payload: { message } });

export const showGenericModalAction = payload => ({ type: ACTION_TYPES.SHOW_MODAL, payload });

export const showWhatsNewModalAction = whatsNewKeys => ({ type: ACTION_TYPES.SHOW_WHATS_NEW_MODAL, payload: whatsNewKeys });

export const clearLastAddedItemAction = () => ({ type: ACTION_TYPES.SET_LAST_ADDED_ITEM, payload: '' });

export const applyColorSchemeToAppAction = schemeID => ({ type: ACTION_TYPES.APPLY_COLOR_SCHEME_TO_APP, payload: { schemeID } });

export const applyColorSchemeToItemAction = (schemeID, colorList, itemID) => ({ type: ACTION_TYPES.APPLY_COLOR_SCHEME_TO_ITEM, payload: { schemeID, colorList, itemID } });

export const selectMultipleItemAction = ({
  selection, withRange = false, withMouse = false
}) => {
  return {
    type: ACTION_TYPES.SELECT_MULTIPLE_PORTAL_ITEM,
    payload: {
      selection, withRange, withMouse
    }
  };
};

export const selectAllItemsAction = () => {
  return {
    type: ACTION_TYPES.SELECT_ALL_ITEMS
  };
};

export const removeSelectedItemsAction = () => {
  return {
    type: ACTION_TYPES.REMOVE_SELECTED_ITEMS
  };
};

export const toggleMultipleSelectionModeAction = value => {
  return {
    type: ACTION_TYPES.TOGGLE_MOBILE_MULTIPLE_SELECTION_MODE,
    payload: value
  };
};

export const setTodoItemCount = value => {
  return {
    type: ACTION_TYPES.SET_TODO_COUNT,
    payload: value
  };
};

export const setDoneItemCount = value => {
  return {
    type: ACTION_TYPES.SET_DONE_COUNT,
    payload: value
  };
};

export const calculateTodoItemCount = () => {
  return {
    type: ACTION_TYPES.CALCULATE_TODO_COUNT
  };
};

export const calculateDoneItemCount = () => {
  return {
    type: ACTION_TYPES.CALCULATE_DONE_COUNT
  };
};

export const setIsAppDone = value => {
  return {
    type: ACTION_TYPES.SET_IS_APP_DONE,
    payload: value
  };
};

export const restartProgressAction = () => {
  return {
    type: ACTION_TYPES.RESTART_PROGRESS
  };
};

export const setLastAnimatedDoneCountAction = lastAnimatedDoneCount => ({ type: ACTION_TYPES.SET_LAST_ANIMATED_DONE_COUNT, payload: lastAnimatedDoneCount });

export const addNewPageAction = payload => ({ type: ACTION_TYPES.ADD_NEW_PAGE.UNDOABLE, payload });
export const updatePageAction = payload => ({ type: ACTION_TYPES.UPDATE_PAGE.UNDOABLE, payload });
export const deletePageAction = (pageID, deleteItems, type) => ({ type: ACTION_TYPES.DELETE_PAGE.UNDOABLE, payload: { pageID, deleteItems, type } });
export const selectPageAction = pageID => ({ type: ACTION_TYPES.SELECT_PAGES, payload: pageID });
export const updateLastInteractedPageIDAction = pageID => ({ type: ACTION_TYPES.UPDATE_LAST_INTERACTED_PAGE_ID, payload: pageID });
export const setActivePageAction = pageID => ({ type: ACTION_TYPES.ACTIVE_PAGE, payload: pageID });

export const setAppLocation = location => ({ type: ACTION_TYPES.SET_APP_LOCATION, payload: location });

export const navigateToAction = payload => ({ type: ACTION_TYPES.NAVIGATE_TO, payload });

export const brandingButtonClickAction = () => ({ type: ACTION_TYPES.BRANDING_BANNER_CLICK });

export const setUserSlug = slug => ({ type: ACTION_TYPES.SET_USER_SLUG, slug });

export const layoutChangeAction = data => ({ type: ACTION_TYPES.LAYOUT_CHANGE.REQUEST, payload: data });

export const changePageOrderAction = data => ({ type: ACTION_TYPES.CHANGE_PAGE_ORDER.REQUEST, payload: data });

export const setForceTitleRename = data => ({ type: ACTION_TYPES.SET_FORCE_TITLE_RENAME, payload: data });

export const duplicateItemAction = () => ({ type: ACTION_TYPES.DUPLICATE_ITEM });

export const productListChangeAction = data => ({ type: ACTION_TYPES.PRODUCT_LIST_CHANGE, payload: data });

export const addProductListItemAction = payload => ({ type: ACTION_TYPES.ADD_PRODUCT_LIST_ITEM.UNDOABLE, payload });

export const updateProductListItemAction = payload => ({ type: ACTION_TYPES.UPDATE_PRODUCT_LIST_ITEM.UNDOABLE, payload });

export const deleteProductListItemAction = payload => ({ type: ACTION_TYPES.DELETE_PRODUCT_LIST_ITEM.UNDOABLE, payload });

export const userChangeAction = user => ({ type: ACTION_TYPES.USER_CHANGE, payload: user });

export const updatePortalUserPropsAction = payload => ({ type: ACTION_TYPES.UPDATE_PORTAL_USER_PROPS.REQUEST, payload });

export const setCheckoutFormStatus = status => ({ type: ACTION_TYPES.SET_CHECKOUT_FORM_STATUS, payload: status });

export const setCheckoutFormVisibility = visibility => ({ type: ACTION_TYPES.SET_CHECKOUT_FORM_VISIBILITY, payload: visibility });

export const setActiveScreen = activeScreen => ({ type: ACTION_TYPES.SET_ACTIVE_SCREEN, payload: activeScreen });

export const setActiveFormProduct = activeFormProduct => ({ type: ACTION_TYPES.SET_ACTIVE_FORM_PRODUCT, payload: activeFormProduct });

export const setActiveItem = payload => ({ type: ACTION_TYPES.SET_ACTIVE_ITEM, payload });

export const setActiveBuilderPage = payload => ({ type: ACTION_TYPES.SET_ACTIVE_BUILDER_PAGE, payload });

export const setRouterAnimationTypeAction = payload => ({ type: ACTION_TYPES.SET_ROUTER_ANIMATION_TYPE, payload });

export const navigationItemClickAction = payload => ({ type: ACTION_TYPES.NAVIGATION_ITEM_CLICK, payload });

export const setNavigationSettingsPopoverIdAction = payload => ({ type: ACTION_TYPES.NAVIGATION_SETTINGS_POPOVER, payload });

export const onTodoCompleteAction = (formID, submissionCount) => ({ type: ACTION_TYPES.ON_TODO_COMPLETE, payload: { formID, submissionCount } });

export const donateAction = payload => ({ type: ACTION_TYPES.DONATE, payload });

export const onProductAddToCartAction = (productID, itemID, action) => ({ type: ACTION_TYPES.ON_PRODUCT_ADD_TO_CART, payload: { productID, itemID, action } });

export const addOrUpdateCartAction = (formID, productID, data) => ({ type: ACTION_TYPES.ADD_OR_UPDATE_CART, payload: { productID, formID, data } });

export const favoriteProductAction = (itemID, productID, noNotification) => ({ type: ACTION_TYPES.FAVORITE_PRODUCT, payload: { itemID, productID, noNotification } });

export const setCartProductsAction = payload => ({ type: ACTION_TYPES.SET_CART_PRODUCTS, payload });

export const setIsChangedPriceInCartAction = payload => ({ type: ACTION_TYPES.SET_IS_CHANGED_PRICE_IN_CART, payload });

export const setFavoriteProductsAction = payload => ({ type: ACTION_TYPES.SET_FAVORITE_PRODUCTS, payload });

export const setCheckoutKeyAction = payload => ({ type: ACTION_TYPES.SET_CHECKOUT_KEY, payload });

export const setActiveProduct = (itemID, productID) => ({ type: ACTION_TYPES.SET_ACTIVE_PRODUCT, payload: { itemID, productID } });

export const onProductItemDetailsAction = data => ({ type: ACTION_TYPES.PRODUCT_ITEM_DETAIL.REQUEST, payload: data });

export const onFilterProductsAction = data => ({ type: ACTION_TYPES.FILTER_PRODUCTS, payload: data });

export const createNewPortalWithStore = () => ({ type: ACTION_TYPES.CREATE_PORTAL_WITH_STORE });

export const createNewPortalWithTemplate = data => ({ type: ACTION_TYPES.CREATE_PORTAL_WITH_TEMPLATE, payload: data });

export const createNewPortalWithDonation = () => ({ type: ACTION_TYPES.CREATE_PORTAL_WITH_DONATION });

export const showDonationItemAction = () => ({ type: ACTION_TYPES.SHOW_DONATION_ITEM });

export const setAccountBoxStatus = val => ({ type: ACTION_TYPES.TOGGLE_ACCOUNT_BOX, payload: val });

export const onFormPickerModalConfirm = ({
  selection, portalOrder, page, withClicking
}) => ({
  type: ACTION_TYPES.ON_FORM_PICKER_MODAL_CONFIRM,
  payload: {
    selection, portalOrder, page, withClicking
  }
});

export const onResourcePickerModalConfirm = ({
  selectedResourcesArray, resourceType, page, addOrder, type
}) => ({
  type: ACTION_TYPES.ON_RESOURCE_PICKER_MODAL_CONFIRM,
  payload: {
    selectedResourcesArray, resourceType, page, addOrder, type
  }
});

export const onLeftPanelItemClickAction = type => ({ type: ACTION_TYPES.ON_LEFT_PANEL_ITEM_CLICK, payload: type });

export const onItemClickAction = (itemID, event, rightPanelMode) => {
  const { metaKey, ctrlKey, shiftKey } = event || {};
  return {
    type: ACTION_TYPES.ON_ITEM_CLICK,
    payload: {
      itemID, metaKey, ctrlKey, shiftKey, rightPanelMode
    }
  };
};

export const openRightPanelWithModeAction = mode => ({ type: ACTION_TYPES.OPEN_RIGHT_PANEL_WITH_MODE, payload: mode });

export const openProductDetailAction = data => ({ type: ACTION_TYPES.OPEN_PRODUCT_DETAIL, payload: data });
export const updateAppLogo = data => ({ type: ACTION_TYPES.UPDATE_APP_LOGO.REQUEST, payload: data });

export const onStageClick = () => ({ type: ACTION_TYPES.ON_STAGE_CLICK });
export const onDragEnd = payload => ({ type: ACTION_TYPES.ON_DRAG_END, payload });
export const onDeletePage = payload => ({ type: ACTION_TYPES.ON_DELETE_PAGE, payload });
export const onMultipleItemUpdate = props => ({ type: ACTION_TYPES.ON_MULTIPLE_ITEM_UPDATE, payload: props });

export const replaceFormItemAction = ({ itemID, formID }) => ({ type: ACTION_TYPES.REPLACE_FORM_ITEM.REQUEST, payload: { itemID, formID } });

export const setUIProp = (key, value) => ({ type: ACTION_TYPES.SET_UI_PROP, payload: { key, value } });

export const updateGatewaySettings = gatewaySettings => ({ type: ACTION_TYPES.CHECKOUT_FORM_GATEWAY_SETTINGS_CHANGE.REQUEST, payload: gatewaySettings });
export const addCheckoutFormQuestion = question => ({ type: ACTION_TYPES.ADD_CHECKOUT_FORM_QUESTION.REQUEST, payload: question });
export const removeCheckoutFormQuestion = questionID => ({ type: ACTION_TYPES.REMOVE_CHECKOUT_FORM_QUESTION.REQUEST, payload: questionID });
export const updateCheckoutFormQuestion = (id, prop) => ({ type: ACTION_TYPES.UPDATE_CHECKOUT_FORM_QUESTION.REQUEST, payload: { id, prop } });
export const sortCheckoutFormQuestions = questions => ({ type: ACTION_TYPES.SORT_CHECKOUT_FORM_QUESTION.REQUEST, payload: questions });

export const updateCheckoutFormSettings = checkoutFormSettings => ({ type: ACTION_TYPES.CHECKOUT_FORM_SETTINGS_CHANGE.REQUEST, payload: checkoutFormSettings });

// Gateway Actions
export const setPaymentModalFlowType = payload => ({ type: ACTION_TYPES.SET_PAYMENT_MODAL_FLOW_TYPE, payload: { paymentModalFlowType: payload } });
export const setPaymentModalFlowStep = payload => ({ type: ACTION_TYPES.SET_PAYMENT_MODAL_FLOW_STEP, payload: { paymentModalFlowStep: payload } });

export const initAppElementPanelAbTest = () => ({ type: ACTION_TYPES.INIT_ELEMENTS_PANEL_AB_TEST });
export const fetchNotificationHistory = () => { return { type: ACTION_TYPES.FETCH_NOTIFICATON_HISTORY.REQUEST }; };
export const sendPushNotification = data => ({ type: ACTION_TYPES.SEND_PUSH_NOTIFICATION.REQUEST, payload: data });

// Live Preview Actions
export const setLivePreviewSizeMode = mode => ({ type: ACTION_TYPES.SET_LIVE_PREVIEW_SIZE_MODE, payload: mode });
export const setLivePreviewStatus = status => ({ type: ACTION_TYPES.SET_LIVE_PREVIEW_STATUS, payload: status });

export const enabledPushNotification = status => ({ type: ACTION_TYPES.ENABLE_PUSH_NOTIFICATION, payload: { enabledPushNotification: status } });

export const setApiRequestsCompleted = payload => ({ type: ACTION_TYPES.SET_API_REQUESTS_COMPLETED, payload });
export const setAppUpdatedAt = () => ({ type: ACTION_TYPES.APP_UPDATED });

/// Data Source Actions
export const createAndLinkDetailsPage = ({
  itemID, columns, resourceID
}) => ({ type: ACTION_TYPES.DS_CREATE_DETAIL_PAGE, payload: { itemID, columns, resourceID } });
export const dsFetchColumnsRequest = ({ resourceID, viewID }) => ({ type: ACTION_TYPES.DS_FETCH_RESOURCE_COLUMNS.REQUEST, payload: { resourceID, viewID } });
export const dsFetchColumnsRequestWithThrottle = ({ resourceID, viewID }) => ({ type: ACTION_TYPES.DS_FETCH_RESOURCE_COLUMNS.WITH_THROTTLE, payload: { resourceID, viewID } });
export const dsFetchColumnsSuccess = source => ({ type: ACTION_TYPES.DS_FETCH_RESOURCE_COLUMNS.SUCCESS, payload: source });
export const dsSetLoading = payload => ({ type: ACTION_TYPES.DS_SET_LOADING, payload });
export const dsFetchRow = payload => ({ type: ACTION_TYPES.DS_FETCH_RESOURCE_ROW.REQUEST, payload });
export const dsFetchRowSuccess = payload => ({ type: ACTION_TYPES.DS_FETCH_RESOURCE_ROW.SUCCESS, payload });

export const dsFetchSourceItemData = payload => ({ type: ACTION_TYPES.DS_FETCH_SOURCE_ITEM_DATA.REQUEST, payload });
export const dsFetchSourceItemDataSuccess = payload => ({ type: ACTION_TYPES.DS_FETCH_SOURCE_ITEM_DATA.SUCCESS, payload });
export const dsFetchSourceItemDataError = payload => ({ type: ACTION_TYPES.DS_FETCH_SOURCE_ITEM_DATA.ERROR, payload });

export const dsSetActiveListRowIDAndNavigateToDetailsPage = payload => ({ type: ACTION_TYPES.DS_LIST_SET_ACTIVE_ROW_ID.REQUEST, payload });
export const dsSetActiveRowID = ({ itemID, rowID }) => ({ type: ACTION_TYPES.DS_LIST_SET_ACTIVE_ROW_ID.SUCCESS, payload: { itemID, rowID } });
export const dsSetActiveListRowIDSuccess = payload => ({ type: ACTION_TYPES.DS_LIST_SET_ACTIVE_ROW_ID.SUCCESS, payload });
export const dsCreateDetailPageDefaults = payload => ({ type: ACTION_TYPES.DS_CREATE_DETAIL_PAGE_DEFAULTS, payload });
export const dsInitializePropsWithColumns = payload => ({ type: ACTION_TYPES.DS_INITIALIZE_PROPS_WITH_COLUMNS.REQUEST, payload });
export const dsInitializeItems = payload => ({ type: ACTION_TYPES.DS_INITIALIZE_ITEMS, payload });
export const dsDeleteDetailPage = payload => ({ type: ACTION_TYPES.DS_DELETE_DETAIL_PAGE, payload });

export const dsFetchDetailPage = payload => ({ type: ACTION_TYPES.DS_FETCH_DETAIL_PAGE.REQUEST, payload });
export const dsFetchPageDetailSuccess = payload => ({ type: ACTION_TYPES.DS_FETCH_DETAIL_PAGE.SUCCESS, payload });
export const dsViewDefaultTableRequest = payload => ({ type: ACTION_TYPES.DS_VIEW_DEFAULT_TABLE.REQUEST, payload });
export const dsCreateTableRequest = payload => ({ type: ACTION_TYPES.DS_CREATE_TABLE.REQUEST, payload });
export const dsFetchAllResourceLinkedItems = payload => ({ type: ACTION_TYPES.DS_FETCH_ALL_RESOURCE_LINKED_ITEMS, payload });

// Ai assistant actions
export const aiShowAssistant = isOpen => ({ type: ACTION_TYPES.AI_SHOW_ASSISTANT, payload: { aiIsShow: isOpen } });
export const aiAddChatMessage = prompt => ({ type: ACTION_TYPES.AI_ADD_CHAT_MESSAGE, payload: { prompt } });
