import classNames from 'classnames';
import {
  arrayOf, bool, func, objectOf, oneOfType, shape, string
} from 'prop-types';
import React, { useState } from 'react';
import { t } from '@jotforminc/translation';
import { useFuse } from '@jotforminc/hooks';
import SearchInput from '@jotforminc/search-input';

import { IconMagnifyingGlass, IconAngleUp } from '@jotforminc/svg-icons';
import SelectionGroup from './SelectionGroup';
import Option from './Option';

const questionsPropType = arrayOf(
  shape({
    formTitle: string,
    questions: oneOfType([
      arrayOf(shape({ text: string, qid: string })),
      objectOf(shape({ text: string, qid: string }))
    ])
  })
);

function ColoredFieldsRenderer({
  formTitle,
  questions,
  color,
  optionParser,
  suggestions,
  setMenuVisibility,
  isSingle,
  defaultValue,
  hasForm,
  onChange,
  formID,
  staticFields,
  isMain,
  isExpanded,
  setExpanded
}) {
  const options = optionParser(questions, { staticFields: staticFields.map(f => ({ ...f, placeholder: isMain ? f.placeholder : f.placeholder.replace('{', `{f${formID}_`) })) });
  const suggestedOptions = options.filter(({ text }) => suggestions?.find(({ text: suggestionText }) => text === suggestionText));
  const wrapperClasses = classNames('formFields-wrapper', { isCollapsed: !isExpanded });
  const suggestionText = suggestions?.[0]?.text?.toLowerCase();

  return (
    <div className={wrapperClasses} key={formTitle}>
      <div className="formFields-select">
        {formTitle && (
          <div
            className="formFields-header"
            onClick={setExpanded}
            style={{
              borderLeftColor: color
            }}
          >
            <div className="formFields-header-text">
              {formTitle}
            </div>
            <div className="formFields-header-icon">
              <IconAngleUp />
            </div>
          </div>
        )}
        {suggestedOptions.length !== 0 && hasForm && isExpanded && (
        <SelectionGroup
          isMultiSelect={isSingle} // yes!
          options={suggestedOptions}
          defaultValue={isSingle ? defaultValue : ''}
          onSelectionChange={val => {
            if (!isSingle) {
              setMenuVisibility(false);
            }
            onChange(isSingle ? val : [val]);
          }}
          OptionRenderer={Option(isSingle)}
        />
        )}
        {options.length === 0 && hasForm && isExpanded && (
        <div className="formFields-empty">
          {t(`This form doesn't have any ${suggestionText || 'appropriate'} fields to add.`)}
        </div>
        )}
      </div>
    </div>
  );
}

ColoredFieldsRenderer.propTypes = {
  formTitle: string.isRequired,
  color: string.isRequired,
  suggestions: arrayOf(shape({})).isRequired,
  questions: arrayOf(questionsPropType).isRequired,
  hasForm: bool.isRequired,
  isSingle: bool.isRequired,
  setMenuVisibility: func.isRequired,
  defaultValue: arrayOf(string).isRequired,
  onChange: func.isRequired,
  optionParser: func.isRequired,
  formID: string.isRequired,
  staticFields: arrayOf(shape({})).isRequired,
  isMain: bool.isRequired,
  isExpanded: bool,
  setExpanded: func
};

ColoredFieldsRenderer.defaultProps = {
  isExpanded: true,
  setExpanded: x => x
};

export default function MultipleForms({
  hasForm,
  isSingle,
  setMenuVisibility,
  defaultValue,
  onChange,
  optionParser,
  formArray,
  staticFields,
  isDark
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedIndex, setExpandedIndex] = useState(0);
  const allQuestions = formArray.flatMap(q => optionParser(q.questions));
  const suggestions = useFuse(allQuestions, searchTerm, ['name', 'text']);
  const noResultFound = suggestions.length === 0 && optionParser(formArray.flatMap(q => q.questions)).length !== 0;

  const setExpanded = index => setExpandedIndex(expandedIndex === index ? -1 : index);

  return (
    <div className={classNames('formFields-container', 'forMultiple', { isDark })}>
      <div className="formFields-row forSearch">
        <div className="formFields-search-icon">
          <IconMagnifyingGlass />
        </div>
        <SearchInput className="formFields-search" placeholder={t('Search fields')} onChange={setSearchTerm} />
      </div>
      <div className="formFields-row forFields">
        {formArray.map(({
          formTitle, questions, color, id, isMain
        }, index) => {
          const toggleExpand = () => setExpanded(index);
          return (
            <ColoredFieldsRenderer
              key={id || formTitle}
              formTitle={formTitle}
              questions={questions}
              color={color}
              isSingle={isSingle}
              setMenuVisibility={setMenuVisibility}
              defaultValue={defaultValue}
              onChange={onChange}
              optionParser={optionParser}
              hasForm={hasForm}
              suggestions={suggestions}
              formID={id}
              staticFields={staticFields}
              isMain={isMain}
              isExpanded={expandedIndex === index || searchTerm.length > 2}
              setExpanded={toggleExpand}
            />
          );
        })}
      </div>
      {noResultFound ? (
        <div className="formFields-empty">
          {t('No result found. We couldn’t find any matching items.')}
        </div>
      ) : null}
      {!hasForm && (
        <div className="formFields-empty">
          {t("You don't have a form to select a field.")}
        </div>
      )}
    </div>
  );
}

MultipleForms.propTypes = {
  formArray: questionsPropType.isRequired,
  hasForm: bool.isRequired,
  isSingle: bool.isRequired,
  setMenuVisibility: func.isRequired,
  defaultValue: arrayOf(string).isRequired,
  onChange: func.isRequired,
  optionParser: func.isRequired,
  staticFields: arrayOf(shape({})).isRequired,
  isDark: string
};

MultipleForms.defaultProps = {
  isDark: false
};
