import React, { useRef } from 'react';
import { object, func, bool } from 'prop-types';
import { t } from '@jotforminc/translation';

import { getTopValue } from './utils';
import { placeholderPageImage } from '../../constantImages';
import { getDeviceType } from '../../getDeviceType';
import { onImageError } from '../../onImageError';
import PreviewHint from './PreviewHint';

const PdfTemplateItem = ({
  template, imageLoaded, handlePreviewTemplate, setImageLoaded
}) => {
  let animationTimeoutReferance;
  const screenshotRef = useRef();
  const { screenshot } = template;
  const title = template._title || template.title;
  const isSkeleton = title === 'skeleton';

  const updateScreenshotPosition = position => {
    if (!imageLoaded || getDeviceType() !== 'desktop') return;
    const screenshotElement = screenshotRef.current;
    if (screenshotElement === null || !screenshotElement) return;
    screenshotElement.style.top = `${position}px`;
  };

  const handleMouseEnterAnimation = () => {
    updateScreenshotPosition(getTopValue(screenshotRef));
    animationTimeoutReferance = setTimeout(() => updateScreenshotPosition(0), 2000);
  };

  const handleMouseLeave = () => {
    if (animationTimeoutReferance === undefined) return;
    clearTimeout(animationTimeoutReferance);
    updateScreenshotPosition(0);
  };

  const previewTemplateWrapper = action => {
    handlePreviewTemplate(action, { ...template });
  };

  return (
    <div className={`item page-item${imageLoaded && !isSkeleton ? ' image-loaded' : ''}`}>
      <div
        className="image"
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnterAnimation}
        onClick={() => previewTemplateWrapper('viewTemplateFromImg')}
      >
        <PreviewHint />
        <div className="shadows" />
        <svg viewBox='1 0 3 1.94' className='aspect-ratio' />
        <img
          alt={title}
          ref={screenshotRef}
          onError={onImageError}
          onLoad={() => setImageLoaded(true)}
          src={!isSkeleton ? screenshot : placeholderPageImage}
        />
      </div>
      <h3 onClick={() => previewTemplateWrapper('viewTemplateFromTitle')}>
        <span>{title}</span>
      </h3>
      <button
        className="useTemplate"
        type="button"
        onClick={() => previewTemplateWrapper('viewFormPreviewButton')}
      >
        <span>{t('Preview')}</span>
      </button>
    </div>
  );
};

export default PdfTemplateItem;
PdfTemplateItem.defaultProps = {
};
PdfTemplateItem.propTypes = {
  template: object.isRequired,
  imageLoaded: bool.isRequired,
  setImageLoaded: func.isRequired,
  handlePreviewTemplate: func.isRequired
};
