import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Bluepay gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlBluepay: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_bluepay: {
    requireBillingAddr: {
      title: 'Require Billing Address',
      type: 'toggle',
      selectedCondition: settings => settings.requireBillingAddr === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM']
    }
  }
};

export default controlBluepay;
