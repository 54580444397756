import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Paypal Express gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlPaypalExpress: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_paypalexpress: {
    customDataField: {
      title: 'Custom Data Field',
      type: 'dropdown',
      subType: 'formQuestion',
      questionTypesAsOption: ['control_textbox', 'control_hidden'],
      default: 'none',
      resources: ['FORM'],
      renderCondition: formQuestions => Array.isArray(formQuestions) && formQuestions.filter(q => ['control_textbox', 'control_hidden'].includes(q.type)).length > 0
    },
    digitalGoods: {
      title: 'Digital goods',
      type: 'toggle',
      selectedCondition: settings => settings.digitalGoods === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM']
    },
    authOnly: {
      title: 'Authorization Only',
      type: 'toggle',
      selectedCondition: settings => settings.authOnly === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM']
    },
    payeraddress: {
      title: 'Require Shipment Address',
      type: 'toggle',
      selectedCondition: settings => settings.payeraddress === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM']
    },
    country: {
      title: 'Checkout Language',
      type: 'dropdown',
      options: [
        { id: '', key: '', text: 'Default' },
        { id: 'AL', key: 'AL', text: 'Albania' },
        { id: 'DZ', key: 'DZ', text: 'Algeria' },
        { id: 'AD', key: 'AD', text: 'Andorra' },
        { id: 'AO', key: 'AO', text: 'Angola' },
        { id: 'AI', key: 'AI', text: 'Anguilla' },
        { id: 'AG', key: 'AG', text: 'Antigua and Barbuda' },
        { id: 'AR', key: 'AR', text: 'Argentina' },
        { id: 'AM', key: 'AM', text: 'Armenia' },
        { id: 'AW', key: 'AW', text: 'Aruba' },
        { id: 'AU', key: 'AU', text: 'Australia' },
        { id: 'AT', key: 'AT', text: 'Austria' },
        { id: 'AZ', key: 'AZ', text: 'Azerbaijan Republic' },
        { id: 'BS', key: 'BS', text: 'Bahamas' },
        { id: 'BH', key: 'BH', text: 'Bahrain' },
        { id: 'BB', key: 'BB', text: 'Barbados' },
        { id: 'BE', key: 'BE', text: 'Belgium' },
        { id: 'BZ', key: 'BZ', text: 'Belize' },
        { id: 'BJ', key: 'BJ', text: 'Benin' },
        { id: 'BM', key: 'BM', text: 'Bermuda' },
        { id: 'BT', key: 'BT', text: 'Bhutan' },
        { id: 'BO', key: 'BO', text: 'Bolivia' },
        { id: 'BA', key: 'BA', text: 'Bosnia and Herzegovina' },
        { id: 'BW', key: 'BW', text: 'Botswana' },
        { id: 'BR', key: 'BR', text: 'Brazil' },
        { id: 'BN', key: 'BN', text: 'Brunei' },
        { id: 'BG', key: 'BG', text: 'Bulgaria' },
        { id: 'BF', key: 'BF', text: 'Burkina Faso' },
        { id: 'BI', key: 'BI', text: 'Burundi' },
        { id: 'KH', key: 'KH', text: 'Cambodia' },
        { id: 'CA', key: 'CA', text: 'Canada' },
        { id: 'CV', key: 'CV', text: 'Cape Verde' },
        { id: 'KY', key: 'KY', text: 'Cayman Islands' },
        { id: 'CZ', key: 'CZ', text: 'Czech Republic' },
        { id: 'TD', key: 'TD', text: 'Chad' },
        { id: 'CL', key: 'CL', text: 'Chile' },
        { id: 'CN', key: 'CN', text: 'China' },
        { id: 'CO', key: 'CO', text: 'Colombia' },
        { id: 'KM', key: 'KM', text: 'Comoros' },
        { id: 'CK', key: 'CK', text: 'Cook Islands' },
        { id: 'CR', key: 'CR', text: 'Costa Rica' },
        { id: 'HR', key: 'HR', text: 'Croatia' },
        { id: 'CY', key: 'CY', text: 'Cyprus' },
        { id: 'DK', key: 'DK', text: 'Denmark' },
        { id: 'DJ', key: 'DJ', text: 'Djibouti' },
        { id: 'DM', key: 'DM', text: 'Dominica' },
        { id: 'DO', key: 'DO', text: 'Dominican Republic' },
        { id: 'EC', key: 'EC', text: 'Ecuador' },
        { id: 'SV', key: 'SV', text: 'El Salvador' },
        { id: 'ER', key: 'ER', text: 'Eritrea' },
        { id: 'EE', key: 'EE', text: 'Estonia' },
        { id: 'ET', key: 'ET', text: 'Ethiopia' },
        { id: 'FK', key: 'FK', text: 'Falkland Islands' },
        { id: 'FO', key: 'FO', text: 'Faroe Islands' },
        { id: 'FJ', key: 'FJ', text: 'Fiji' },
        { id: 'FI', key: 'FI', text: 'Finland' },
        { id: 'FR', key: 'FR', text: 'France' },
        { id: 'GF', key: 'GF', text: 'French Guiana' },
        { id: 'PF', key: 'PF', text: 'French Polynesia' },
        { id: 'GA', key: 'GA', text: 'Gabon Republic' },
        { id: 'GM', key: 'GM', text: 'Gambia' },
        { id: 'DE', key: 'DE', text: 'Germany' },
        { id: 'GI', key: 'GI', text: 'Gibraltar' },
        { id: 'GR', key: 'GR', text: 'Greece' },
        { id: 'GL', key: 'GL', text: 'Greenland' },
        { id: 'GD', key: 'GD', text: 'Grenada' },
        { id: 'GP', key: 'GP', text: 'Guadeloupe' },
        { id: 'GT', key: 'GT', text: 'Guatemala' },
        { id: 'GN', key: 'GN', text: 'Guinea' },
        { id: 'GW', key: 'GW', text: 'Guinea Bissau' },
        { id: 'GY', key: 'GY', text: 'Guyana' },
        { id: 'HN', key: 'HN', text: 'Honduras' },
        { id: 'HK', key: 'HK', text: 'Hong Kong' },
        { id: 'HU', key: 'HU', text: 'Hungary' },
        { id: 'IS', key: 'IS', text: 'Iceland' },
        { id: 'IN', key: 'IN', text: 'India' },
        { id: 'ID', key: 'ID', text: 'Indonesia' },
        { id: 'IE', key: 'IE', text: 'Ireland' },
        { id: 'IL', key: 'IL', text: 'Israel' },
        { id: 'IT', key: 'IT', text: 'Italy' },
        { id: 'JM', key: 'JM', text: 'Jamaica' },
        { id: 'JP', key: 'JP', text: 'Japan' },
        { id: 'JO', key: 'JO', text: 'Jordan' },
        { id: 'KZ', key: 'KZ', text: 'Kazakhstan' },
        { id: 'KE', key: 'KE', text: 'Kenya' },
        { id: 'KI', key: 'KI', text: 'Kiribati' },
        { id: 'KW', key: 'KW', text: 'Kuwait' },
        { id: 'KG', key: 'KG', text: 'Kyrgyzstan' },
        { id: 'LA', key: 'LA', text: 'Laos' },
        { id: 'LV', key: 'LV', text: 'Latvia' },
        { id: 'LS', key: 'LS', text: 'Lesotho' },
        { id: 'LI', key: 'LI', text: 'Liechtenstein' },
        { id: 'LT', key: 'LT', text: 'Lithuania' },
        { id: 'LU', key: 'LU', text: 'Luxembourg' },
        { id: 'MG', key: 'MG', text: 'Madagascar' },
        { id: 'MW', key: 'MW', text: 'Malawi' },
        { id: 'MY', key: 'MY', text: 'Malaysia' },
        { id: 'MV', key: 'MV', text: 'Maldives' },
        { id: 'ML', key: 'ML', text: 'Mali' },
        { id: 'MT', key: 'MT', text: 'Malta' },
        { id: 'MH', key: 'MH', text: 'Marshall Islands' },
        { id: 'MQ', key: 'MQ', text: 'Martinique' },
        { id: 'MR', key: 'MR', text: 'Mauritania' },
        { id: 'MU', key: 'MU', text: 'Mauritius' },
        { id: 'YT', key: 'YT', text: 'Mayotte' },
        { id: 'MX', key: 'MX', text: 'Mexico' },
        { id: 'FM', key: 'FM', text: 'Micronesia' },
        { id: 'MN', key: 'MN', text: 'Mongolia' },
        { id: 'MS', key: 'MS', text: 'Montserrat' },
        { id: 'MA', key: 'MA', text: 'Morocco' },
        { id: 'MZ', key: 'MZ', text: 'Mozambique' },
        { id: 'NA', key: 'NA', text: 'Namibia' },
        { id: 'NR', key: 'NR', text: 'Nauru' },
        { id: 'NP', key: 'NP', text: 'Nepal' },
        { id: 'NL', key: 'NL', text: 'Netherlands' },
        { id: 'AN', key: 'AN', text: 'Netherlands Antilles' },
        { id: 'NC', key: 'NC', text: 'New Caledonia' },
        { id: 'NZ', key: 'NZ', text: 'New Zealand' },
        { id: 'NI', key: 'NI', text: 'Nicaragua' },
        { id: 'NE', key: 'NE', text: 'Niger' },
        { id: 'NU', key: 'NU', text: 'Niue' },
        { id: 'NF', key: 'NF', text: 'Norfolk Island' },
        { id: 'NO', key: 'NO', text: 'Norway' },
        { id: 'OM', key: 'OM', text: 'Oman' },
        { id: 'PW', key: 'PW', text: 'Palau' },
        { id: 'PA', key: 'PA', text: 'Panama' },
        { id: 'PG', key: 'PG', text: 'Papua New Guinea' },
        { id: 'PE', key: 'PE', text: 'Peru' },
        { id: 'PH', key: 'PH', text: 'Philippines' },
        { id: 'PN', key: 'PN', text: 'Pitcairn Islands' },
        { id: 'PL', key: 'PL', text: 'Poland' },
        { id: 'PT', key: 'PT', text: 'Portugal' },
        { id: 'QA', key: 'QA', text: 'Qatar' },
        { id: 'CG', key: 'CG', text: 'Republic of the Congo' },
        { id: 'RE', key: 'RE', text: 'Reunion' },
        { id: 'RO', key: 'RO', text: 'Romania' },
        { id: 'RU', key: 'RU', text: 'Russia' },
        { id: 'RW', key: 'RW', text: 'Rwanda' },
        { id: 'KN', key: 'KN', text: 'Saint Kitts and Nevis Anguilla' },
        { id: 'PM', key: 'PM', text: 'Saint Pierre and Miquelon' },
        { id: 'VC', key: 'VC', text: 'Saint Vincent and Grenadines' },
        { id: 'WS', key: 'WS', text: 'Samoa' },
        { id: 'SM', key: 'SM', text: 'San Marino' },
        { id: 'ST', key: 'ST', text: 'São Tomé and Príncipe' },
        { id: 'SA', key: 'SA', text: 'Saudi Arabia' },
        { id: 'SN', key: 'SN', text: 'Senegal' },
        { id: 'SC', key: 'SC', text: 'Seychelles' },
        { id: 'SL', key: 'SL', text: 'Sierra Leone' },
        { id: 'SG', key: 'SG', text: 'Singapore' },
        { id: 'SK', key: 'SK', text: 'Slovakia' },
        { id: 'SI', key: 'SI', text: 'Slovenia' },
        { id: 'SB', key: 'SB', text: 'Solomon Islands' },
        { id: 'ZA', key: 'ZA', text: 'South Africa' },
        { id: 'KR', key: 'KR', text: 'South Korea' },
        { id: 'ES', key: 'ES', text: 'Spain' },
        { id: 'LK', key: 'LK', text: 'Sri Lanka' },
        { id: 'SH', key: 'SH', text: 'St. Helena' },
        { id: 'LC', key: 'LC', text: 'St. Lucia' },
        { id: 'SR', key: 'SR', text: 'Suriname' },
        { id: 'SJ', key: 'SJ', text: 'Svalbard and Jan Mayen Islands' },
        { id: 'SZ', key: 'SZ', text: 'Swaziland' },
        { id: 'SE', key: 'SE', text: 'Sweden' },
        { id: 'CH', key: 'CH', text: 'Switzerland' },
        { id: 'TW', key: 'TW', text: 'Taiwan' },
        { id: 'TJ', key: 'TJ', text: 'Tajikistan' },
        { id: 'TZ', key: 'TZ', text: 'Tanzania' },
        { id: 'TH', key: 'TH', text: 'Thailand' },
        { id: 'TG', key: 'TG', text: 'Togo' },
        { id: 'TO', key: 'TO', text: 'Tonga' },
        { id: 'TT', key: 'TT', text: 'Trinidad and Tobago' },
        { id: 'TN', key: 'TN', text: 'Tunisia' },
        { id: 'TR', key: 'TR', text: 'Turkey' },
        { id: 'TM', key: 'TM', text: 'Turkmenistan' },
        { id: 'TC', key: 'TC', text: 'Turks and Caicos Islands' },
        { id: 'TV', key: 'TV', text: 'Tuvalu' },
        { id: 'UG', key: 'UG', text: 'Uganda' },
        { id: 'UA', key: 'UA', text: 'Ukraine' },
        { id: 'AE', key: 'AE', text: 'United Arab Emirates' },
        { id: 'GB', key: 'GB', text: 'United Kingdom' },
        { id: 'US', key: 'US', text: 'United States' },
        { id: 'UY', key: 'UY', text: 'Uruguay' },
        { id: 'VU', key: 'VU', text: 'Vanuatu' },
        { id: 'VA', key: 'VA', text: 'Vatican City State' },
        { id: 'VE', key: 'VE', text: 'Venezuela' },
        { id: 'VN', key: 'VN', text: 'Vietnam' },
        { id: 'VG', key: 'VG', text: 'Virgin Islands (British)' },
        { id: 'WF', key: 'WF', text: 'Wallis and Futuna Islands' },
        { id: 'YE', key: 'YE', text: 'Yemen' },
        { id: 'ZM', key: 'ZM', text: 'Zambia' }
      ],
      default: 'none',
      resources: ['FORM']
    },
    emailpending: {
      title: 'Pending Payment Email',
      type: 'toggle',
      subType: 'withRadioGroup',
      radioOptions: [
        { id: 'Yes', key: 'Yes', text: 'Notifiers Only' },
        { id: 'All', key: 'All', text: 'Notifiers & Autoresponders' }
      ],
      selectedCondition: settings => ['Yes', 'All'].includes(settings.emailpending),
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP']
    },
    checkoutLogo: {
      title: 'Checkout Logo',
      type: 'imageUpload',
      default: '',
      resources: ['FORM']
    },
    cartColor: {
      title: 'Checkout Cart Color',
      type: 'input',
      subType: 'color',
      default: '#CCCCCC',
      resources: ['FORM']
    }
  }
};

export default controlPaypalExpress;
