import { createGlobalStyle, css } from 'styled-components';
import animations from './animations';
import {
  mainFontColor
} from './colors';

export const mainFont = 'Circular,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

export const ScrollBar = css`
  &::-webkit-scrollbar {
    width: var(--jfv-panel-scrollbar-width);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--jfv-panel-scrollbar-thumb);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--jfv-panel-scrollbar-thumb-hover);
  }
`;

export const ScrollBarLeft = css`
  &::-webkit-scrollbar-track {
    background-color: var(--jfv-left-panel-background);
    border-left: var(--jfv-left-panel-scrollbar-border);
  }
`;

export const ScrollBarRight = css`
  &::-webkit-scrollbar-track {
    background-color: var(--jfv-right-panel-background);
    border-left: var(--jfv-right-panel-scrollbar-border);
  }
`;

/* eslint-disable max-len */
export const GlobalStyle = createGlobalStyle`

  html{
    height: 100%;

    &:not(.public-html){
      overflow: hidden;
    }
  }
  body {
    height: 100%;
    color: ${mainFontColor};

    &:not(.public-body){
      overflow: hidden;
    }
  }

  .isMobilePreview {
    * {
      cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKxSURBVHgBxVc7i1pBFJ7rvmTjGkksFoTEwkfsFguN2CwIItiksbDLT0hhq4W1Qn6CjTY2NoIIgo0YBWXL9VGYQtjCBOOaxF1315zvOldujK9dvdkPDs4d5843c+6Zc74R2CMwmUzU9GMk05GpefeI7AomCMJo07mEdQM42RnZO066Ch2yC1rABduGmEjf0885mbpard6WSqWbQqFw22q17pvN5h3G6PV6ldPpPLDb7QfBYPDYZrPtU3efrLhqAcISQrjyA5kRhNFo9Dqbzd6wDeD3+48ikciJw+E4ZFMPZGgB/bXEnPTjYDB4GY/HB0T6kz0B4XBYEwqFTrRa7Q96TMyTC4tIu93uSSAQ+F4ul8dsC7hcroN0Ov3KYDBcz5PPE2Onb30+X29bUjl5LpfT086/EnFC6lfJSBG5Rrh3V6QA5orFYtixkQerCEFG/IkC6ZgitMcUQKVS0VPAPVDzM867ipNitzpEL1MIfG4pJ8xcfdZoNMabHpmnAHPjaLJpImIqKQ2mUqlfTGEgAbHpt1Zjx6forNVqd0xhIOvxpnFGTB9/Z5G8DO12W9qcDsRilen1eg9MYVAc3fOmWsWeCSAWa6jVat1jCgOVjDfFcyzmT5PJtM8UBsonb16BuIOWx+M5ZAoDNXtGzOVKx+12HzGFAaEArlnKJFyicKOIM4WAubk6EVWJRIyHEZQDUwh87r4kh0Ri7u4idk0DXrAdA2qES6Gi1PePEBgOh2+8Xu+3/yYEODIajWYAuYIX2JaQpA/XXRn5f38Rc02UgEbK5/Ovt3E73IudLtJbIteil55F3s4tAGrhnEwHoZBMJn/X6/UxKplUVCwWy77ZbN5DAkIu4IRisBLhl2Vzr73CyBYgisE1Qztkl2x6jVl5j9qIWLYAlNBTbvJLG1zZecyl7Q/SBULOnr052gAAAABJRU5ErkJggg==') 15 15, auto !important;
      &:active {
        cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKjSURBVHgBxZdPaxpBGMZnTWMsJWJUSkAEWxCqIOToQSV40pP9CEXw3qM38eaxd0H8CHrSiyLqwWNA0ICHCiLkoEYMpVbT2vdZZ2Vj/JesQx54cVx35zczzr7vMxJ7gRaLhYE+HBQmCgO/PKW4Q0iSND20L2nfDRx2RfGFQ3epS3FDA7hhWsAE9dLHNYWhWCzqS6XSWb1e1/d6vZN+v3+Ce4xG4z+32/3o8Xjm0Wj0t9frndPlMUVl1wCkLUAs5VcKB4DJZPK80Wjo2QEi8CyRSDyEQqEZW65AjgYw3gvm0G+j0egiHo+fp9PpD+wVisViv1Kp1IPZbL6nr9l1uLQJ2ul0LJFI5KLdbp8yDXK5XPN8Pn/vdDqH6/B1MGb6yefzWbRC1XDaF0Oa+U8CZ5XrOhUUO9eB5T0WFEJf6BN9880qS1KBv9NG+hgOhy1MgAqFwpA23ISaP/C+6zgUszVh9zJB4n0rOWG11Ff0upwe+sq8RugbryZbJiKmU9JgJpN5zwSrXC4DjP/agBlf4mKz2TzahtqmWq12xpuOFbjVar1jgoVUy5smgOUqM5lMdEywlPwOpnDYNgEs11CbzfaXCRYqGW/K77GcP+12u3Awyidv3gHcRcvv9/9hgoWavQJzu9INBoMzJlgwCmCtUibpFoUbRZwJEvrm7kR2JQoYX6ZwDkyQeN9jxQ7JYL7cFcwazoEdWeiTW6GKcu2ZERgMBp8DgQCMwFEy2V4jwJWzWq0jsisjeuCRaZRifbjvyql/ewLmnigLj1StVodalh3PYqab/JbM2vTQm9jbtQHALVxTmGAUULNRPlHJlKKCVIushwSEXMCB8mYlYGNb33uPMKoByGZwz61dilu2PMbsPEcdBFYNACX0kof60Ial7L7k0PYfCrpJd4w7io4AAAAASUVORK5CYII=') 15 15, auto !important;
      }
    }
    .page {
      display: block;
      position: relative;
      min-height: 100%;
      margin-bottom: 0;
      padding-bottom: 48px; 
    }

    .router{
      height: 100%;
    }

  }
  #root, .portalApp, .portalApp > div { height: 100%; }
  #root {
    -webkit-overflow-scrolling: touch;
  }

  .portalApp div[tabindex="-1"]:not([class]) {
    flex: 1 1 auto;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    position: relative;
  }

  .portalApp [class*="portalApp-"] { height: 100%; overflow-y: auto; }

  .jNewHeader-col.jNewHeader-titleWrapper h1.jNewHeader-title { font-weight: 400; }
  .jNewHeader.isPortal.isPreview {
    height: 0;
    opacity: 0;
    pointer-events: none;
    transition: .3s all ease;
  }

  div.jNewHeader-col.jNewHeader-rightWrapper.alignRight {
    button.quickQR {
      display: none;
    }

    button.cloneApp {

      &::before {
        content: "Admin Only";
        position: absolute;
        top: -10px;
        right: 0;
        padding: 1.5px 10px;
        background: red;
        border-radius: 10px;
        font-size: 10px;
        color: #FFF;
      }

      @media screen and (max-width: 480px) {
        &::before {
          display: none;
        }
      }
    }


  }

  @media screen and (max-width: 480px) {
    div.jNewHeader-col.jNewHeader-rightWrapper.alignRight {
      display: flex;
      align-items: center;

      button.quickQR {
        display: inline-flex;
      }
    }
  }

  h1, h2, h3, h4, h5, h6, p { margin: 0; }
  ul { margin: 0; padding: 0; list-style: none; }

  ::placeholder { color: #9e9fb7; }
  .revisionIcon { width: 0; }
  button.jfFeedbackButton {
    z-index: 3 !important;
  }

  .jfFeedbackButton.u-appBuilder {
    border-radius: 11px 11px 0 0;
    width: 170px;
    max-width: 300px;

    &.u-bottomRight {
      bottom: 0;

      +.jfFeedbackButton-modal {
        bottom: 60px;
      }
    }

    &.jFisActive,
    &:hover {
      border-radius: 11px 11px 0 0;

      .jfFeedbackButton-icon {
        opacity: 1;
      }

      .jfFeedbackButton-text {
        padding: 0;
      }
    }

    .jfFeedbackButton-icon.u-appBuilder {
      display: inline-block;
      background-image:  url("https://cdn.jotfor.ms/assets/img/cardforms/feedbackButton.svg");
      background-position: 50% 56%;
      background-size: 18px;
      background-repeat: no-repeat;
    }

    .jfFeedbackButton-text {
      font-size: 16px;
      color: #282e42;
    }
  }

  .router {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  // TODO @Buri, remove top and bottom when new builder opened.
  .page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow-y: auto;
    align-items: center;
    justify-content: center;
  }

  .location-enter .formPage {
    transform: translateX(100%);
    z-index: 1;
  }

  .location-enter.location-enter-active .formPage {
    transform: translateX(0%);
    transition: all 500ms;
  }

  .location-exit.location-exit-active .formPage {
    transform: translateX(100%);
    transition: all 500ms;
  }

  .location-enter .page {
    transform: translateX(-100%);
    z-index: 1;
  }

  .location-enter.location-enter-active .page {
    transform: translateX(0%);
    transition: all 500ms;
  }

  .location-exit.location-exit-active .page {
    transform: translateX(-100%);
    transition: all 500ms;
  }

  .slide-up-enter.portalApp-select {
    transform: translateY(100%);
    opacity: 0;
  }

  .slide-up-enter.slide-up-enter-active.portalApp-select {
    transform: translateY(0%);
    opacity: 1;
    transition: all 500ms;
  }

  .slide-up-exit.portalApp-select {
    transform: translateY(-100%);
    opacity: 1;
  }

  .slide-up-exit.slide-up-exit-active.portalApp-select {
    transform: translateY(0%);
    opacity: 0;
    transition: all 500ms;
  }

  .slide-up-enter.portalApp-previewSplash {
    transform: translateY(-100%);
    opacity: 0;
  }

  .slide-up-enter.slide-up-enter-active.portalApp-previewSplash {
    transform: translateY(0%);
    opacity: 1;
    transition: all 500ms;
  }

  .slide-up-exit.portalApp-previewSplash {
    position: absolute;
    transform: translateY(0%);
    opacity: 1;
  }

  .slide-up-exit.slide-up-exit-active.portalApp-previewSplash {
    transform: translateY(-100%);
    opacity: 0;
    transition: all 500ms;
  }

  .mobileCtxMenu-enter {
    opacity: 0;
    transition: all 400ms;
  }

  .mobileCtxMenu-enter-active {
    opacity: 1;
  }

  .mobileCtxMenu-exit {
    opacity: 1;
    transition: all 400ms;
  }

  .mobileCtxMenu-exit-active {
    opacity: 0;
  }

  .toast-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(10, 21, 81, 0.4);
    pointer-events: all;
    user-select: none;
    overflow: hidden;
    z-index: 1101;

    &-appear {
      opacity: 0;
      &-done {
        opacity: 1;
        transition: .3s opacity;
      }
    }
  }

  .Toastify__toast-container--top-right:has(div.clonePreventedFormsToast) {
    width: 400px;
    left: auto;

    .clonePreventedFormsToast {
      background-color: transparent !important;
    }

    @media screen and (max-width: 480px) {
      width: 344px;

      .clonePreventedFormsToast {
        margin: 0 !important;
        background-color: transparent !important;
      }
    }
  }

  #builderPortalToaster {
    .Toastify  {
      &__toast {
        &--default {
          background-color: #252D5B;
          padding: 0;

          @media screen and (max-width: 480px) {
            margin: 15px 17px;
            border-radius: 4px;
          }
        }

        &-body {
          padding: 0;
        }

        &-container {
          padding: 0;
        }

        &-container--top-right {
          top: 130px;
          right: 15px;
        }
      }
    }
  }

  #publicPortalToaster {
    .Toastify  {
      &__toast {
        &--default {
          background-color: #fff;
          padding: 0;

          @media screen and (max-width: 480px) {
            margin: 15px 17px;
            border-radius: 4px;
          }
        }

        &-body {
          padding: 0;
        }

        &-container {
          padding: 0;
        }

        &-container--top-right {
          top: 80px;
          right: 15px;
        }
      }
    }
  }

  #preview-content {
    .jPreview-close {
      top: 12px;
    }

    .jPreview-header {
      background-color: #252D5B;
      height: 64px;
      border-radius: 0;

      .jPreviewButton {
        &-fileSize {
          display: none;
        }
      }

      .jPreview-fileDesc {
        font-size: 14px;
        line-height: 18px;
      }
      .jPreview-fileThumbnailBox {
        svg { width: 24px; height: 24px; }
      }
    }

    .jPreview-zoomContainer {
      button {
        svg {
          color: #fff;
          width: 24px;
          height: 24px;
        }
      }
    }

    .react-pdf__Document {
      max-width: 100%;
    }

    .jPreview-fileType {
      background-color: transparent;
      svg {
        width: 26px;
        height: 32px;
      }
    }

    .previewOther {
      > svg {
        color: #fff;
        width: 122px;
        height: 150px;
      }
    }
      
    @media screen and (max-width: 840px) {
      .jPreview-header .jPreviewButton.forDownload > span { display: block; }
    }

    @media screen and (max-width: 480px) {
      .jPreview-header {
        .jPreviewButton-fileSize, .jPreviewButton.forDownload > span {
          display: none;
        }
      }
    }
  }

  .jfSelect-list {
    background-color: #fff
  }

  // for testimonial widget scheme item dragging
  .schemaItemWrapper {
    z-index: 5000;
  }

  .appNavigation {
    &-popover {
      background-color: #fff;
      max-width: 316px;
      width: 80vw;
      z-index: 11;

      .jfOverflowableList {
        &-item {
          padding: 12px 10px;
          background-color: #fff;
          cursor: pointer;
          justify-content: flex-start;
          fill: #0A1551;

          .isActive, &:hover {
            color: #0099FF;
            fill: #0099FF;
          }

          .appPageLink {
            width: 100%;

            &-container {
              white-space: nowrap;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              transition: .3s;
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
        }

        &-overflow {
          &-items {
            width: 100%;
          }
        }
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000004d;
    z-index: 1;
    pointer-events: none;
    display: block;
  }

  // for account box wrapper on portal to make sure it doesn't overlap with branding footer
  .jNewHeader-accountBoxWrapper {
    max-height: calc(100vh - 140px);
    max-height: calc(100svh - 140px);
  }

  div.mce-container.mce-panel.mce-floatpanel.mce-window.mce-in .mce-reset .mce-window-body iframe {
    width: 100% !important;
  }

  aside .rightPanelPrimaryAction {
    background-color: #646E7F;
    color: #fff;

    &:hover {
      background-color: #7d8799;
    }

    &:active {
      background-color: #4e5562;
    }
  }

  aside button.customMagnetBtn {
    background-color: var(--jfv-panel-right-item-background);
    &:hover {
      background-color: var(--jfv-panel-right-item-background);
    }
    &:active {
      background-color: var(--jfv-panel-right-item-background);
    }
    border: var(--jfv-panel-right-item-border);
    color: #fff;
  }

  .warningBox .customMagnetBtn {
    width: auto;
    border: 0;
  }

  ${animations}
`;
