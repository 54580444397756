import React, {
  ChangeEvent, ComponentPropsWithRef, forwardRef, useEffect, useRef, useState
} from 'react';
import { cx } from 'class-variance-authority';
import { IconLockFilled } from '@jotforminc/svg-icons';

import { useFormControl } from '../../contexts';
import { commonFormControlCVA } from '../../constants/common.cva';

import { textareaBarCVA, textareaCVA, textareaContainerCVA } from './textarea.cva';
import { TextareaProps, textareaDefaultProps } from './textarea.types';

import '@jotforminc/jotform.css';

export const Textarea = forwardRef((
  props: TextareaProps,
  ref: ComponentPropsWithRef<'textarea'>['ref']
):JSX.Element => {
  const {
    colorStyle,
    readOnly,
    disabled,
    size,
    theme,
    resize,
    maxLength,
    onChange,
    className,
    value,
    ghost,
    ...rest
  } = useFormControl(props);

  const [count, setCount] = useState(0);
  const readOnlyVariant = readOnly && !disabled;
  const textareaContainerRef = useRef(null);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCount(e.target.value.length);
    if (onChange) onChange(e);
  };

  const textareaContainerClassName = cx(
    className,
    commonFormControlCVA.container({
      colorStyle,
      readOnly: !!readOnlyVariant,
      disabled: !!disabled,
      theme,
      ghost: !!ghost
    }),
    textareaContainerCVA({ resize })
  );

  const textareaClassName = cx(
    commonFormControlCVA.input({
      disabled: !!disabled,
      theme,
      size
    }),
    textareaCVA({ size })
  );

  useEffect(() => {
    if (textareaContainerRef.current) {
      const textarea = (textareaContainerRef.current as HTMLDivElement).firstChild as HTMLTextAreaElement;
      setCount(textarea.value.length);
    }
  }, [value]);

  return (
    <div className={textareaContainerClassName} ref={textareaContainerRef}>
      <textarea
        className={textareaClassName}
        disabled={disabled}
        readOnly={readOnly}
        maxLength={maxLength}
        onChange={handleChange}
        ref={ref}
        value={value}
        {...rest}
      />
      {(readOnly || !!maxLength)
      && (
        <div className={textareaBarCVA({ size, theme })}>
          {readOnly && <IconLockFilled className="w-4 h-4" />}
          {!!maxLength && (
            <span className="magnet-textarea-count">
              {count}
              /
              {maxLength}
            </span>
          )}
        </div>
      )}
    </div>
  );
});

Textarea.defaultProps = textareaDefaultProps;
