/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import upperCase from 'lodash/upperCase';
import { t } from '@jotforminc/translation';
import { classNames } from '@jotforminc/utils';
import {
  IconAngleDown, IconAngleUp, IconArrowRight, IconLayerFilled, IconXmark
} from '@jotforminc/svg-icons';
import { templateConstants } from '../../templateConstants';
import {
  filterWrapper,
  prettyTemplatePageType,
  isGuest,
  isHomepage,
  formatBigNumber
} from '../../utils';

import { getDeviceType } from '../../getDeviceType';

import Accordion from './Accordion';
import Sorting from './Sorting';
import Search from './Search';
import LanguagesFilter from './LanguagesFilter';
import FormLayout from './FormLayout';

const NewSidebar = ({ sidebarProps }) => {
  const {
    categories,
    selectedCategory,
    setAsideShow,
    asideShow,
    templateType,
    handleSelectCategory,
    setActiveParentCategory,
    username,
    isMyTemplates,
    filters,
    setFilters,
    searchResult,
    isAllCategoriesSelected,
    isMobile,
    searchComponentProps,
    source,
    language,
    setLanguage,
    predefinedCategory,
    selectedIndustryCategory
  } = sidebarProps;

  const { sidebarTabs = [], showAllCategories = false } = templateConstants[templateType];
  const [subMenuActive, setSubMenuActive] = useState(null);
  const [accordionOpen, setAccordionOpen] = useState(null);
  const templatePageTypePretty = prettyTemplatePageType(templateType, false);
  const selectedIndustryCat = selectedIndustryCategory;

  const getFeaturedCategories = tab => {
    const filterArray = [];
    if (!showAllCategories) {
      filterArray.push({ property: 'featured', value: '1' });
    }
    if (sidebarTabs.length > 1) {
      filterArray.push({ property: 'type', value: tab.value });
    }
    const featuredCategories = filterWrapper(templateType, categories, filterArray).filter(
      category => category?.parent === '' || category?.parent === null
    );
    if (predefinedCategory && predefinedCategory?.subCategories && !selectedIndustryCat) {
      const predefinedCategoryID = predefinedCategory?.id || predefinedCategory?._id;
      return featuredCategories.filter(category => {
        return predefinedCategoryID && (category?.id || category?._id) === predefinedCategoryID;
      });
    }
    return featuredCategories;
  };

  const isSelectedCategory = categoryID => {
    if (!selectedCategory) return false;
    return categoryID === (selectedCategory._id || selectedCategory.id);
  };

  const isSubCategorySelected = subCategories => {
    return Object.keys(subCategories).find(subID => isSelectedCategory(subID));
  };

  const subMenuActiveCheck = categoryID => {
    return categoryID === subMenuActive;
  };

  const handleSelectMyTemplates = () => {
    handleSelectCategory({ clickedMyTemplates: true });
    window.standaloneTemplatesLogger({
      actor: username,
      target: 'My Templates',
      action: `${getDeviceType()}:click`
    });
  };

  const addTooltip = elm => {
    if (isMobile) return;
    const isCheck = e => {
      return (e.offsetWidth < e.scrollWidth);
    };

    if (isCheck(elm) && !elm.parentElement.querySelector('.tooltip')) {
      elm.parentElement.insertAdjacentHTML('beforeend', `<div class="tooltip" aria-hidden="true">${t(elm.innerText)}</div>`);
    }
  };

  const myTemplatesVisible = username !== '' && !isGuest();
  const industryCategoryType = categories.find(category => category.slug === selectedIndustryCat);

  useEffect(() => {
    const categoryList = document.querySelectorAll('.jfSidebar--categoryList a span:not(.countTotal)');
    categoryList.forEach(elm => addTooltip(elm));
  }, [categories, subMenuActive, asideShow, accordionOpen]);

  return (
    <div className="jfSidebar--wrapper">
      <div className="asideMobile">
        <button
          type="button"
          onClick={() => setAsideShow(true)}
          className="asideMobileButton"
        >
          {t(`${templatePageTypePretty} Categories`)}
        </button>
      </div>
      <aside className={`jfSidebar${asideShow ? ' isActive' : ''}`}>
        <button className="jfSidebar--close" type="button" onClick={() => setAsideShow(false)}>
          <IconXmark width="32" height="32" />
        </button>
        <div className="jfSidebar--inner">
          {(!isMobile && !isHomepage(isMyTemplates, selectedCategory, searchResult.searchView, isAllCategoriesSelected)) && <Search props={searchComponentProps} sidebarSearch={true} />}
          <Sorting
            username={username}
            filters={filters}
            setFilters={setFilters}
            templateType={templateType}
          />
          {
            templateType === 'form-templates' && (
              <FormLayout
                filters={filters}
                username={username}
                setFilters={setFilters}
              />
            )
          }
          {sidebarTabs.filter(sidebarTab => (!predefinedCategory || selectedIndustryCat || (sidebarTab?.value === predefinedCategory?.type || sidebarTab?.value === 'categories'))).map((tab, idx) => (
            <Accordion
              username={username}
              text={t(upperCase(tab.text))}
              open={industryCategoryType ? industryCategoryType?.type === tab.value : idx === 0 || predefinedCategory !== undefined}
              onToggle={() => setAccordionOpen(!accordionOpen)}
            >
              <ul className="jfSidebar--categoryList">
                {getFeaturedCategories(tab).map(category => {
                  const { name, total, subCategories = {} } = category;
                  const categoryID = category._id || category.id;
                  const isParentActive = isSelectedCategory(categoryID)
                || isSubCategorySelected(subCategories);
                  const hasSubCategories = Object.keys(subCategories).length > 0;
                  const handleSelectParentCategory = () => {
                    handleSelectCategory({ category });
                    setActiveParentCategory();
                  };
                  const handleShowCategory = e => {
                    e.stopPropagation();
                    if (subMenuActiveCheck(categoryID)) {
                      setSubMenuActive(null);
                      return;
                    }
                    setSubMenuActive(categoryID);
                  };
                  return (
                    <li
                      key={categoryID}
                      onClick={handleSelectParentCategory}
                      className={classNames((isParentActive && 'isActive'), (subMenuActiveCheck(categoryID)) && hasSubCategories && 'isActive')}
                    >
                      <a>
                        <span>{t(name)}</span>
                        {hasSubCategories && !isParentActive && (
                        <i
                          className="jfSidebar--categoryList--icon"
                          onClick={e => handleShowCategory(e)}
                        >
                          {subMenuActiveCheck(categoryID) ? (
                            <IconAngleUp width="20" height="20" />
                          ) : (
                            <IconAngleDown width="20" height="20" />
                          )}
                        </i>
                        )}
                        <span className="countTotal">{formatBigNumber(total)}</span>
                      </a>
                      {(isParentActive || subMenuActiveCheck(categoryID))
                    && hasSubCategories && (
                      <ul>
                        {Object.keys(subCategories).map(subCategoryId => {
                          const subCategory = subCategories[subCategoryId];
                          const subCategoryID = subCategory._id || subCategory.id;
                          const isSubCategoryActive = isSelectedCategory(subCategoryID);
                          const handleSelectSubCategory = e => {
                            e.stopPropagation();
                            handleSelectCategory({ category: subCategory });
                            setActiveParentCategory(
                              isParentActive ? category : undefined
                            );
                          };
                          return (
                            <li
                              key={subCategoryID}
                              onClick={handleSelectSubCategory}
                              className={isSubCategoryActive ? 'isActive' : ''}
                            >
                              <a>
                                <span>{t(subCategory.name)}</span>
                                <span className="countTotal">
                                  {formatBigNumber(subCategory.total)}
                                </span>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                      )}
                    </li>
                  );
                })}
                <li
                  key="all-templates"
                  className="allTemplates"
                  onClick={() => {
                    handleSelectCategory({ clickedAllCategories: true });
                    setSubMenuActive(null);
                  }}
                >
                  <a>
                    <span>{t('All Categories')}</span>
                    <IconArrowRight width="20" height="20" />
                  </a>
                </li>
              </ul>
            </Accordion>
          ))}
          {(!predefinedCategory && searchResult?.searchView !== true) && (
            <LanguagesFilter
              source={source}
              username={username}
              language={language}
              setLanguage={setLanguage}
              templateType={templateType}
            />
          )}
          {
            (myTemplatesVisible && !predefinedCategory) && (
              <div className={`jfSidebar--myTemplates${isMyTemplates ? ' isActive' : ''}`} onClick={handleSelectMyTemplates}>
                <IconLayerFilled width="20" height="20" />
                <span>{t('My Templates')}</span>
              </div>
            )
          }
        </div>
      </aside>
    </div>
  );
};

export default NewSidebar;
