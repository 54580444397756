import React, { useState, useMemo } from 'react';
import { IconTrashFilled } from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';

import DetachConnectionDialog from '../DetachConnectionDialog';
import usePaymentPropsPanel from '../../Context/usePaymentProperties';

interface I_REMOVECONNECTIONBTN {
  resetConnectionInfo: () => void;
  disabledBtnClass: string;
}

const RemoveConnectionButton = ({
  resetConnectionInfo,
  disabledBtnClass
}: I_REMOVECONNECTIONBTN): JSX.Element => {
  const {
    questionProperties: { paymentType },
    hasConnection,
    detachConnection,
    isCollaborator,
    isJotformerAccessingUserResource,
    resource
  } = usePaymentPropsPanel();
  const [showDetachDialog, setShowDetachDialog] = useState<boolean>(false);
  const unauthorized = isCollaborator || isJotformerAccessingUserResource;
  const notProductSellingInForm = paymentType !== 'product' && resource === 'FORM';
  const notProductSellingTooltip = !isCollaborator && notProductSellingInForm;
  const isRemoveBtnDisabled = notProductSellingInForm || unauthorized;

  const tooltipText = useMemo(() => {
    let text = '';

    if (unauthorized) {
      text = t('Only the form owner can add/remove connections.');
    } else if (notProductSellingTooltip) {
      text = t('Your selected payment type can’t be used without a payment gateway.');
    }
    return text;
  }, [unauthorized, notProductSellingTooltip]);

  const onDetach = async () => {
    const res = await detachConnection();
    if (!res) { return; }
    resetConnectionInfo();
    setShowDetachDialog(false);
  };

  return (
    <div className='inline-block group relative'>
      <Button
        startIcon={() => <IconTrashFilled className="min-w-4 min-h-4" />}
        onClick={() => {
          if (isRemoveBtnDisabled) { return; }
          if (hasConnection) {
            setShowDetachDialog(true);
          } else {
            onDetach();
          }
        }}
        className={`max-w-8 max-h-8 bg-gray-500 hover:bg-gray-400 ${isRemoveBtnDisabled ? disabledBtnClass : ''}`}
        data-test-id='payment-connection-detach-btn'
      />
      {
        isRemoveBtnDisabled && (
          <span className='group-hover:block hiddenjf absolute bg-gray-800 shadow-dark-md radius-lg top-9 xs:min-w-92 min-w-80 -right-4 p-3 color-white text-sm line-height-xs z-9'>
            {t(tooltipText)}
          </span>
        )
      }
      {
        showDetachDialog && hasConnection && (
          <DetachConnectionDialog
            showDialog={showDetachDialog}
            onClose={() => setShowDetachDialog(false)}
            onDetach={onDetach}
          />
        )
      }
    </div>
  );
};

export default RemoveConnectionButton;
