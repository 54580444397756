import React, { useState } from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import {
  gatewayLightIcons, supportedGatewayConnectionPropsList, supportedPaymentTypesWithLabel
} from '@jotforminc/payment-constants';
import type { GATEWAY_PAYMENT_TYPES, PAYMENT_FIELDS, SUPPORTED_CURRENCIES } from '@jotforminc/payment-constants';
import { IconExclamationCircle, IconExclamationCircleFilled, IconTurnArrowShortRightFilled } from '@jotforminc/svg-icons';

/**
 * Renders a component that displays information about a disabled payment gateway connection.
 *
 * @param {Object} props - The component props.
 * @param {PAYMENT_FIELDS} props.gatewayType - The type of the payment gateway.
 * @param {Object|null} props.disabledReason - The reason why the payment gateway connection is disabled.
 * @param {GATEWAY_PAYMENT_TYPES} props.disabledReason.paymentType - The unsupported payment type.
 * @param {SUPPORTED_CURRENCIES} props.disabledReason.currency - The unsupported currency.
 * @returns {JSX.Element} The rendered component.
 */
export const DisabledConnectionInforms = ({
  gatewayType,
  disabledReason = null
}: {
  gatewayType: PAYMENT_FIELDS;
  disabledReason: { paymentType?: GATEWAY_PAYMENT_TYPES; currency?: SUPPORTED_CURRENCIES; } | null;
}): any => {
  const [isHovered, setIsHovered] = useState(false);

  const Icon = gatewayLightIcons[gatewayType] as React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  const getGatewayName = supportedGatewayConnectionPropsList[gatewayType].name;
  const getPaymentTypeName = supportedPaymentTypesWithLabel[disabledReason?.paymentType as GATEWAY_PAYMENT_TYPES] || null;

  const messageInform = (): any => {
    switch (true) {
      case disabledReason?.currency !== undefined && disabledReason?.paymentType === undefined:
        return translationRenderer('[1[{gatewayName}]] does not support [2[{currency}]].')({
          renderer1: () => <span className='font-medium'>{getGatewayName}</span>,
          renderer2: () => <span className='font-medium'>{disabledReason?.currency}</span>
        });
      case disabledReason?.currency === undefined && disabledReason?.paymentType !== undefined:
        return translationRenderer('[1[{gatewayName}]] does not support [2[{paymentType}]].')({
          renderer1: () => <span className='font-medium'>{getGatewayName}</span>,
          renderer2: () => <span className='font-medium'>{getPaymentTypeName}</span>
        });
      case disabledReason?.currency !== undefined && disabledReason?.paymentType !== undefined:
        return translationRenderer('[1[{gatewayName}]] does not support [2[{currency}]] and [3[{paymentType}]]')({
          renderer1: () => <span className='font-medium'>{getGatewayName}</span>,
          renderer2: () => <span className='font-medium'>{disabledReason?.currency}</span>,
          renderer3: () => <span className='font-medium'>{getPaymentTypeName}</span>
        });
      default:
        break;
    }
  };

  return (
    <div
      className='reusableModal-tooltip-trigger relative flex items-center'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <IconExclamationCircleFilled className='w-6 h-6 color-gray-800 ease-in-out duration-300' />
      ) : (
        <IconExclamationCircle className='h-6 w-6 color-gray-100 ease-in-out duration-300' />
      )}
      <div
        className="reusableModal-custom-tooltip flex items-center justify-start radius-lg gap-1" style={{
          width: 'max-content'
        }}
      >
        <Icon className='h-6 w-6 shrink-0' />
        <span className='flex items-center gap-1' data-test-id="disabled_message_informs">
          {messageInform()}
          {' '}
          <a
            href="https://www.jotform.com/online-payments/compare/"
            target="_blank"
            className='color-blue-500 flex items-center gap-1 font-medium'
          >
            {t('Learn more')}
            <IconTurnArrowShortRightFilled className='h-4 w-4' />
          </a>
        </span>
      </div>

    </div>
  );
};
