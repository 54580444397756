import { isEnterprise } from '@jotforminc/enterprise-utils';
import type { PAYMENT_APM_NAMES } from '@jotforminc/payment-constants';
import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

const hasSquareAPM = (nameAPM: PAYMENT_APM_NAMES) => ['CashApp', 'appleAndGooglePay', 'Afterpay', 'Clearpay'].includes(nameAPM);

/**
 * Control Square gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlSquare: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_square: {
    location: {
      title: 'Business Location',
      type: 'dropdown',
      subType: 'dynamicDropdown',
      default: '',
      resources: ['FORM', 'APP']
    },
    sendReceipt: {
      title: 'Send Payment Receipt',
      type: 'toggle',
      description: 'Send an email with a link to the Square receipt to the customer.',
      selectedCondition: settings => String(settings.sendReceipt) === 'true',
      valueTrue: 'true',
      valueFalse: 'false',
      default: 'false',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType !== 'subscription'
    },
    authOnly: {
      title: 'Authorization Only',
      type: 'toggle',
      description: 'Authorize the card now to charge it manually later. Expires after 6 days.',
      selectedCondition: settings => settings.authOnly === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType !== 'subscription'
    },
    paymentMethodsTitle: {
      title: 'Payment Methods Label Text',
      type: 'input',
      default: 'Payment Methods',
      resources: ['FORM', 'APP'],
      /* eslint-disable-next-line max-len */
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && (settings.showApplePay === 'Yes' || settings.showGooglePay === 'Yes' || settings.showCashApp === 'Yes' || settings.showACH === 'Yes' || settings.showAfterpay === 'Yes'),
      requiredCondition: () => true
    },
    emailField: {
      title: 'Customer Email',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      requiredCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'subscription',
      autoSave: true
    },
    orderFulfillmentType: {
      title: 'Order Fulfillment Type',
      type: 'dropdown',
      options: [
        { id: 'PICKUP', key: 'PICKUP', text: 'Pickup' },
        { id: 'SHIPMENT', key: 'SHIPMENT', text: 'Shipment' }
      ],
      default: 'Pickup',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType !== 'subscription'
    },
    title: {
      type: 'title',
      title: 'Payment Methods',
      resources: ['FORM', 'APP'],
      requiredCondition: () => true,
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && !hasSquareAPM(settings.nameAPM)
    },
    showCard: {
      text: 'Credit Card',
      type: 'checkbox',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'Yes',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && !hasSquareAPM(settings.nameAPM),
      disableCondition: () => true,
      lockedCondition: () => true
    },
    showGooglePay: {
      text: 'Google Pay',
      type: 'checkbox',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && !hasSquareAPM(settings.nameAPM),
      /* eslint-disable-next-line max-len */
      disableCondition: (settings, hasConnection) => !Array.isArray(settings) && typeof settings === 'object' && (!hasConnection || !['USD', 'GBP', 'CAD'].includes(settings.currency) || settings.paymentType === 'subscription'),
      lockedCondition: () => false
    },
    showApplePay: {
      text: 'Apple Pay',
      type: 'checkbox',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && !hasSquareAPM(settings.nameAPM),
      disableCondition: (settings, hasConnection) => !Array.isArray(settings) && typeof settings === 'object' && (!hasConnection || settings.paymentType === 'subscription'),
      lockedCondition: () => false
    },
    showCashApp: {
      text: 'Cash App Pay',
      type: 'checkbox',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && !hasSquareAPM(settings.nameAPM),
      /* eslint-disable-next-line max-len */
      disableCondition: (settings, hasConnection) => !Array.isArray(settings) && typeof settings === 'object' && (!hasConnection || !['USD'].includes(settings.currency) || settings.paymentType === 'subscription'),
      lockedCondition: () => false
    },
    showACH: {
      text: 'ACH Bank Transfer',
      type: 'checkbox',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && !hasSquareAPM(settings.nameAPM) && !isEnterprise(),
      /* eslint-disable-next-line max-len */
      disableCondition: (settings, hasConnection) => !Array.isArray(settings) && typeof settings === 'object' && (!hasConnection || !['USD'].includes(settings.currency) || settings.paymentType === 'subscription' || settings.authOnly === 'Yes'),
      lockedCondition: () => false
    },
    showAfterpay: {
      text: 'Afterpay',
      type: 'checkbox',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && !hasSquareAPM(settings.nameAPM),
      /* eslint-disable-next-line max-len */
      disableCondition: (settings, hasConnection) => !Array.isArray(settings) && typeof settings === 'object' && (!hasConnection || !['USD', 'AUD', 'NZD', 'CAD'].includes(settings.currency) || settings.paymentType === 'subscription'),
      lockedCondition: () => false
    },
    showClearpay: {
      text: 'Clearpay',
      type: 'checkbox',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && !hasSquareAPM(settings.nameAPM),
      /* eslint-disable-next-line max-len */
      disableCondition: (settings, hasConnection) => !Array.isArray(settings) && typeof settings === 'object' && (!hasConnection || !['GBP'].includes(settings.currency) || settings.paymentType === 'subscription' || settings.authOnly === 'Yes'),
      lockedCondition: () => false
    },
    ccTitle: {
      title: 'Credit Card Label Text',
      type: 'input',
      default: 'Credit Card',
      resources: ['FORM', 'APP'],
      /* eslint-disable-next-line max-len */
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.showApplePay !== 'Yes' && settings.showGooglePay !== 'Yes' && settings.showCashApp !== 'Yes' && settings.showACH !== 'Yes' && settings.showAfterpay !== 'Yes',
      requiredCondition: () => true
    }
  }
};

export default controlSquare;
