import {
  gatewayFieldToName,
  gatewaySupportedCurrencies,
  gatewayResourceSupportedPaymentTypes,
  gatewayListSortedByCountry
} from '../gateway';
import type { GATEWAY_CONNECTION_PROPS } from '../types';

/**
 * Control Cash App gateway connection properties.
 * @type {GATEWAY_CONNECTION_PROPS}
 */
export const controlCashApp: GATEWAY_CONNECTION_PROPS = {
  gateway_type: 'control_cashApp',
  name: gatewayFieldToName.control_cashApp,
  supportedCurrencies: gatewaySupportedCurrencies.control_cashApp,
  supportedPaymentTypes: gatewayResourceSupportedPaymentTypes.control_cashApp,
  sortByCountry: gatewayListSortedByCountry.control_cashApp,

  /** APM's */
  isApm: true,
  isApmSupport: false,
  supportedApm: {},
  isChildTypes: false,
  childTypes: [],
  isReferenceParentGatewayType: true,
  apmType: 'CashApp',
  parentType: 'control_square',
  /** APM's: End */

  /** Env Settings */
  env: 'production',
  allowNewConnection: true,
  allowResourceTypes: ['APP', 'FORM'],
  isDeprecated: false,

  /** Connection Properties */
  connection: {
    type: 'oauth',
    allowSwitchMode: true,
    mode: {
      name: 'allowTest',
      options: [
        { value: 'true', text: 'Test Mode', mappedValue: '1' },
        { value: 'false', text: 'Live Mode', mappedValue: '0' }
      ]
    },
    propReferences: {
      merchantId: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Merchant ID' } },
      accessToken: { isSecret: 'Yes', isRequired: 'Yes', inputLabels: { label: 'Access Token' } },
      refreshToken: { isSecret: 'Yes', isRequired: 'Yes', inputLabels: { label: 'Refresh Token' } },
      allowTest: { isSecret: 'No', isRequired: 'Yes' },
      tokenExpiry: { isSecret: 'No', isRequired: 'Yes', inputLabels: { label: 'Token Expiry' } }
    },
    uniquePropNames: ['merchantId']
  }
};
