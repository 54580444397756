import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { getActiveModal } from '../../store/selectors/uiSelectors';
import { MODALS } from '../../constants/modals';
import * as ACTION_CREATORS from '../../store/actionCreators';
import DeletePageModal from '../../modules/Builder/components/Modals/DeletePageModal';
import RestartAppModal from '../../modules/PublicApp/RestartAppModal';
import ResourcePickerModal from '../../modules/Builder/components/Modals/ResourcePickerModal';
import FormPickerModal from '../../modules/Builder/components/Modals/FormPickerModal';
import ProductItemDetailsModal from '../../modules/PublicApp/Modals/ProductItemDetailsModal';
import SearchInProductsModal from '../../modules/PublicApp/Modals/SearchInProductsModal';
import CheckoutFormPreviewModal from '../../modules/Builder/components/Modals/CheckoutFormPreviewModal';
import AppIsDoneModal from '../../modules/PublicApp/AppIsDoneModal';
import WhatsNewModal from '../../modules/Builder/components/Modals/WhatsNewModal';
import ShareAppModal from '../../modules/PublicApp/Modals/ShareAppModal';
import PaymentGatewayPickerModal from '../../modules/Builder/components/Modals/PaymentGatewayPickerModal';
import AppNameIconSettingsModal from '../../modules/Builder/components/Modals/AppNameIconSettingsModal';
import { ExitIntentSurveyModal } from '../../modules/Builder/components/Modals/ExitIntentSurveyModal';
import DataSourcePickerModal from './DataSourcePickerModal';
import TableProductModal from './TableProductModal';

export const MODAL_COMPONENTS = {
  [MODALS.DELETE_PAGE_MODAL]: DeletePageModal,
  [MODALS.RESTART_PROGRESS_MODAL]: RestartAppModal,
  [MODALS.RESOURCE_PICKER_MODAL]: ResourcePickerModal,
  [MODALS.FORM_PICKER_MODAL]: FormPickerModal,
  [MODALS.PRODUCT_ITEM_DETAIL_MODAL]: ProductItemDetailsModal,
  [MODALS.SEARCH_IN_PRODUCTS_MODAL]: SearchInProductsModal,
  [MODALS.CHECKOUT_FORM_PREVIEW_MODAL]: CheckoutFormPreviewModal,
  [MODALS.APP_IS_DONE_MODAL]: AppIsDoneModal,
  [MODALS.WHAT_IS_NEW]: WhatsNewModal,
  [MODALS.SHARE_APP]: ShareAppModal,
  [MODALS.PAYMENT_GATEWAY_PICKER_MODAL]: PaymentGatewayPickerModal,
  [MODALS.APP_NAME_ICON_SETTING_MODAL]: AppNameIconSettingsModal,
  [MODALS.EXIT_INTENT_SURVEY_MODAL]: ExitIntentSurveyModal,
  [MODALS.DATA_SOURCE_TABLE_PICKER_MODAL]: DataSourcePickerModal,
  [MODALS.TABLE_PRODUCT_MODAL]: TableProductModal
};

const Modals = () => {
  const dispatch = useDispatch();
  const { name, ...payload } = useSelector(getActiveModal);

  const Modal = MODAL_COMPONENTS[name];

  const handleClose = useCallback(e => {
    // cookine banner does not stop event propagation
    if (typeof e === 'boolean' && e === false) {
      return;
    }

    dispatch(ACTION_CREATORS.showGenericModalAction({ name: '' }));
  }, []);

  if (!Modal) return null;

  return createPortal(
    <Modal onClose={handleClose} {...payload} />,
    document.getElementById('modal-root')
  );
};

export default Modals;
