/* eslint-disable react/prop-types */
import React from 'react';
import { t } from '@jotforminc/translation';
import { sanitize } from 'dompurify';
import { IconMagnifyingGlass, IconXmarkCircleFilled } from '@jotforminc/svg-icons';
import { classNameGenerator } from '@jotforminc/utils';
import { getDeviceType } from '../../getDeviceType';

import { isHomepage } from '../../utils';
import { logSearchAction } from '../../api';

const Search = ({ props, sidebarSearch }) => {
  const {
    getTemplates, searchKeyword, searchResult, setSearchResult, isMyTemplates, username,
    setSelectedCategory, setSearchKeyword, stickySearchWrapper, setTemplates, templateType, setStandaloneTemplates,
    selectedCategory, setIsAllCategoriesSelected, setIsMyTemplates, isAllCategoriesSelected, source
  } = props;

  const handleSearch = () => {
    const isEmpty = searchKeyword === '';
    setTemplates([]);
    setStandaloneTemplates([]);
    if (!isEmpty) logSearchAction(username, searchKeyword, templateType, source);
    setSearchResult({ searchView: !isEmpty, searchedKeyword: searchKeyword });
    getTemplates({ searchKeywordParameter: searchKeyword });
    setSelectedCategory();
    setIsMyTemplates(false);
    setIsAllCategoriesSelected(false);
    window.standaloneTemplatesLogger({
      actor: username,
      target: searchKeyword,
      action: `${getDeviceType()}:search`
    });
  };

  const handleFocusSearch = () => {
    if (global?.standaloneTemplatesLogger) {
      global?.standaloneTemplatesLogger({
        actor: username,
        action: `${getDeviceType()}:searchBox-click`
      });
    }
  };

  const handleResetSearchInput = () => {
    setSearchKeyword('');
  };

  return (
    <div
      className={classNameGenerator({
        'jfSidebar--search': sidebarSearch,
        isSticky: stickySearchWrapper,
        showMobile: (!isHomepage(isMyTemplates, selectedCategory, searchResult.searchView, isAllCategoriesSelected) && !sidebarSearch)
      })}
    >
      <button className="jfSidebar--search-icon" onClick={handleSearch} type="button">
        <IconMagnifyingGlass width={16} height={16} />
      </button>
      <input
        type="search"
        value={searchKeyword}
        onFocus={handleFocusSearch}
        placeholder={t('Search in all templates')}
        onChange={e => setSearchKeyword(sanitize(e.target.value))}
        onKeyDown={e => e.key === 'Enter' && handleSearch()}
      />
      <i
        className="jfSidebar--search-close"
        onClick={handleResetSearchInput}
        onKeyDown={handleResetSearchInput}
      >
        <IconXmarkCircleFilled width={16} height={16} />
      </i>
    </div>
  );
};

export default Search;
