// TODO :: export from jotform-common
import React from 'react';
import Moment from 'moment';
import { Moment as CommonMoment } from '@jotforminc/jotform-common';
import { IconClockFilled, IconCheckCircleFilled, IconCheck } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';

export const stringToMoment = (date, includeSeconds = false) => (Moment(date, `YYYY-MM-DD HH:mm:${includeSeconds ? ':ss' : ''} a`));

export const momentToString = (date, includeSeconds = false) => (date.format(`YYYY-MM-DD HH:mm${includeSeconds ? ':ss' : ''}`));
export const momentToTime = date => (date || new Moment()).format('HH:mm');
export const momentToMilliSeconds = date => Moment(date, 'YYYY-MM-DD HH:mm:ss').valueOf();

// returns in milliseconds
export const getDiffOfDates = (timezone, date, refDate = false, includeSeconds = false) => {
  const dateToCheck = (typeof date === 'string') ? stringToMoment(date, includeSeconds) : date;
  CommonMoment.setTimezone(timezone);
  const disableZoneDisableMoment = CommonMoment.dateToMoment(momentToString(dateToCheck, includeSeconds), `YYYY-MM-DD HH:mm${includeSeconds ? ':ss' : ''}`);
  const refDateStr = refDate ? refDate : momentToString(Moment(), includeSeconds);
  const disableZoneNowMoment = CommonMoment.dateToMoment(refDateStr, 'YYYY-MM-DD HH:mm:ss');
  return disableZoneDisableMoment.diff(disableZoneNowMoment);
};

export const isPastDate = (timezone, date, refDate = false) => {
  return getDiffOfDates(timezone, date, refDate) <= 0;
};

export const getHumanReadableDate = date => {
  return stringToMoment(date).format('LLL');
};

export const getHumanReadableDuration = milliseconds => {
  return Moment.duration(milliseconds).humanize();
};

export const getNotificationStatus = status => {
  switch (status) {
    case 'PENDING':
      return {
        color: 'yellow-600',
        badgeColor: 'warning',
        text: t('Pending'),
        icon: IconClockFilled,
        iconElement: <IconClockFilled fill='#fff' />
      };
    case 'DONE':
      return {
        color: 'green-500',
        badgeColor: 'success',
        text: t('Sent'),
        icon: IconCheck,
        iconElement: <IconCheckCircleFilled fill='#fff' />
      };
    default:
      return {
        color: 'yellow-600',
        badgeColor: 'warning',
        text: t('pending'),
        icon: IconClockFilled,
        iconElement: <IconClockFilled fill='#fff' />
      };
  }
};
