import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Worldpay US gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlWorldpayUS: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_worldpayus: {
    ccTitle: {
      title: 'Credit Card Label Text',
      type: 'input',
      default: 'Credit Card',
      resources: ['FORM']
    }
  }
};

export default controlWorldpayUS;
