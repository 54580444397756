import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Firstdata gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlFirstdata: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_firstdata: {
    ccTitle: {
      title: 'Credit Card Label Text',
      type: 'input',
      default: 'Credit Card',
      resources: ['FORM', 'APP']
    }
  }
};

export default controlFirstdata;
