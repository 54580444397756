import React, { useCallback } from 'react';
import { func, string, object } from 'prop-types';
import Styled from 'styled-components';
import { t } from '@jotforminc/translation';
import { ConfirmationModal } from '@jotforminc/modals';
import { useDispatch, useSelector } from 'react-redux';
import { IconExclamationTriangleFilled } from '@jotforminc/svg-icons';
import * as ACTION_CREATORS from '../../../../../store/actionCreators';
import SELECTORS from '../../../../../store/selectors';

const ScCommonConfirmationModal = Styled.div`
  svg { width: 73px }

  .taste.isRed {
    background: #FF4948;

    &:hover {
      background: #F12E2D;
    }
  }

  .taste.isGhost {
    background-color: #FAFAFC;

    &:hover {
      background-color: #fff;
      color: #9EA7CE;
      border-color: #9EA7CE;
    }
  }

  .dontshow {
    background-color: #EDF8FF;
    margin: 20px 0 0;
  }

  .dontshow-input:checked + .dontshow-checkbox:before {
    background-color: #0099FF;
    border-color: #0099FF;
  }

  .dontshow-checkbox {
    cursor: pointer;
  }

  .dontshow-text {
    cursor: pointer;
  }
`;

const DeletePageModal = ({
  onClose, pageID, type, pageProps
}) => {
  const dispatch = useDispatch();
  const { linkedItemID } = pageProps;
  const isDsPage = !!linkedItemID;

  const selectedPageID = useSelector(SELECTORS.getSelectedPage) || pageID;

  const handleConfirm = useCallback(({ dontShowAgain: deleteItems = false }) => {
    dispatch(ACTION_CREATORS.deletePageAction(selectedPageID, isDsPage ? true : deleteItems, type));
    onClose();
  }, [isDsPage]);

  return (
    <ScCommonConfirmationModal>
      <ConfirmationModal
        defaultVisible={true}
        icon={<IconExclamationTriangleFilled color="#D11F42" />}
        backText={t('CANCEL')}
        confirmText={t('DELETE PAGE')}
        title={t('Are you sure you want to delete this page?')}
        subtitle={t('Are you sure you want to proceed?')}
        description={t('You can undo this action by pressing Command + Z.')}
        dontShowAgainText={t('Delete all elements on this page.')}
        showDontShowAgain={!isDsPage}
        onConfirm={handleConfirm}
        onBack={onClose}
        onClose={onClose}
      />
    </ScCommonConfirmationModal>
  );
};

DeletePageModal.defaultProps = {
  pageID: '',
  type: '',
  pageProps: {}
};

DeletePageModal.propTypes = {
  onClose: func.isRequired,
  type: string,
  pageID: string,
  pageProps: object
};

export default DeletePageModal;
