/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  string, object, func, arrayOf, bool
} from 'prop-types';
// import { classNames } from '@jotforminc/utils';
// import { t } from '@jotforminc/translation';
import { isEnterprise } from '@jotforminc/enterprise-utils';

import './style.scss';
import SimilarTemplatesItem from './SimilarTemplatesItem';
import Loader from '../../../Loader';
import Infobar from './InfoBar';
import ModalControls from './ModalControls';

const FormTemplatePreviewContent = ({
  frameSrc, template, modalBodyRef, onTemplateClone,
  categoryData, setModalContentLoading, similarTemplates,
  previewURL, theme, useTemplate, isCloningTemplate, handlePrevNextTemplateHandler
}) => {
  const templateType = frameSrc.split('/')[1];
  const {
    id,
    _id,
    _description = ''
  } = template;
  return (
    <div className="jfModal-content">
      <div className="jfWizard--content-area w-full h-full min-h-sm pb-24 lg:pb-0 isMobile">
        <Loader />
        <object
          aria-label="Preview"
          type="text/html"
          id="form-preview"
          className="jfWizard-modal-frame"
          data={previewURL}
        />
      </div>
      {(Object.keys(template).length > 0) && (
        <>
          <ModalControls
            className='flex lg:hidden'
            useTemplate={useTemplate}
            isCloningTemplate={isCloningTemplate}
            handlePrevNextTemplateHandler={handlePrevNextTemplateHandler}
          />
          <div className='content-area'>
            {!isEnterprise() && (
              <>
                <Infobar
                  templateType={templateType}
                  template={template}
                  categoryData={categoryData}
                />
                <div className='jfWizard-modal-detail full'>
                  <div className='description-area' dangerouslySetInnerHTML={{ __html: _description }} />
                </div>
              </>
            )}
            {similarTemplates.length > 0 && (
              <SimilarTemplatesItem
                theme={theme}
                parentTemplateID={(id || _id)}
                templateType={templateType}
                modalBodyRef={modalBodyRef}
                categoryData={categoryData}
                onTemplateClone={onTemplateClone}
                similarTemplates={similarTemplates}
                setModalContentLoading={setModalContentLoading}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

FormTemplatePreviewContent.defaultProps = {
  template: {},
  previewURL: string,
  frameSrc: '/',
  categoryData: null,
  similarTemplates: [],
  onTemplateClone: f => f,
  modalBodyRef: undefined,
  isCloningTemplate: false,
  handlePrevNextTemplateHandler: f => f
};

FormTemplatePreviewContent.propTypes = {
  frameSrc: string,
  previewURL: null,
  template: object,
  categoryData: object,
  modalBodyRef: object,
  onTemplateClone: func,
  isCloningTemplate: bool,
  useTemplate: func.isRequired,
  similarTemplates: arrayOf(object),
  handlePrevNextTemplateHandler: func,
  setModalContentLoading: func.isRequired
};

export default FormTemplatePreviewContent;
