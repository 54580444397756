/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { t } from '@jotforminc/translation';
import { capitalizeFirstLetter, classNames } from '@jotforminc/utils';

import { templateConstants } from '../templateConstants';
import {
  filterWrapper, prettyTemplatePageType, isHomepage, isGuest
} from '../utils';
import { getDeviceType } from '../getDeviceType';

import Search from './Search';
import ThemeSelector from './ThemeSelector';
import TemplateFilters from './TemplateFilters';

import { ScBottomArea } from './ScMainWrapper';
import { ScMyTemplates } from './ScMyTemplates';
import { ScAsideWrapper } from './ScAsideWrapper';

const Sidebar = ({ sidebarProps }) => {
  const {
    categories, selectedCategory, isAllCategoriesSelected, setAsideShow,
    asideShow, searchComponentProps, templateType, handleSelectCategory,
    filters, setFilters, isMobile, searchResult, isMyTemplates, language,
    setLanguage, setActiveParentCategory, username, source
  } = sidebarProps;

  const { sidebarTabs = [], showAllCategories = false } = templateConstants[templateType];
  const [activeTab, setActiveTab] = useState(sidebarTabs[0]);
  const templatePageTypePretty = prettyTemplatePageType(templateType, false);

  useEffect(() => {
    const { type = '' } = selectedCategory || {};
    if (type === '') return;

    const relatedTab = sidebarTabs.find(tab => tab?.value === type);
    if (relatedTab) setActiveTab(relatedTab);
  }, [selectedCategory]);

  const getFeaturedCategories = (tab = activeTab) => {
    const filterArray = [];
    if (!showAllCategories) {
      filterArray.push({ property: 'featured', value: '1' });
    }
    if (sidebarTabs.length > 1) {
      filterArray.push({ property: 'type', value: tab.value });
    }
    return filterWrapper(templateType, categories, filterArray).filter(category => category?.parent === '' || category?.parent === null);
  };

  const getFilteredZeroTabs = () => {
    return sidebarTabs.filter(tab => getFeaturedCategories(tab).length !== 0);
  };

  const handleSelectMyTemplates = () => {
    handleSelectCategory({ clickedMyTemplates: true });
    window.standaloneTemplatesLogger({
      actor: username,
      target: 'My Templates',
      action: `${getDeviceType()}:click`
    });
  };

  const handleSelectTab = tab => {
    window.standaloneTemplatesLogger({
      actor: username,
      target: capitalizeFirstLetter(tab.text),
      action: `${getDeviceType()}:category-tab-click`
    });
    setActiveTab(tab);
  };

  const isSelectedCategory = categoryID => {
    if (!selectedCategory) return false;
    return categoryID === (selectedCategory._id || selectedCategory.id);
  };

  const isSubCategorySelected = subCategories => {
    return Object.keys(subCategories).find(subID => isSelectedCategory(subID));
  };

  const myTemplatesVisible = username !== '' && !isGuest();

  return (
    <ScAsideWrapper>
      <div className="asideMobile">
        <button type="button" onClick={() => setAsideShow(true)} className="asideMobileButton">{t(`${templatePageTypePretty} Categories`)}</button>
      </div>
      {(!isMobile && !isHomepage(isMyTemplates, selectedCategory, searchResult.searchView, isAllCategoriesSelected)) && <Search props={searchComponentProps} sidebarSearch={true} />}
      <aside className={`aside${asideShow ? ' isActive' : ''}`}>
        <div className="mobileAsideHeader">
          <span>{t(`${templatePageTypePretty} Categories`)}</span>
          <button type="button" aria-label="Close" onClick={() => setAsideShow(false)} />
        </div>
        <div className="tabItems">
          {getFilteredZeroTabs().map(tab => {
            const { text } = tab;
            return (
              <a
                key={text}
                onClick={() => handleSelectTab(tab)}
                className={activeTab.text === text ? 'active' : ''}
              >
                {t(capitalizeFirstLetter(text))}
              </a>
            );
          })}
        </div>
        <div className="mobileScrollableContent">
          <div className="tabContent">
            <ul className="tabList">
              {getFeaturedCategories().map(category => {
                const { name, total, subCategories = {} } = category;
                const categoryID = category._id || category.id;
                const isParentActive = isSelectedCategory(categoryID) || isSubCategorySelected(subCategories);
                const hasSubCategories = isParentActive && Object.keys(subCategories).length > 0;
                const handleSelectParentCategory = () => {
                  handleSelectCategory({ category });
                  setActiveParentCategory();
                };
                return (
                  <li
                    key={categoryID}
                    onClick={handleSelectParentCategory}
                    className={classNames(isParentActive && 'active')}
                  >
                    <a>{t(name)}</a>
                    <span>{total}</span>
                    {
                      hasSubCategories && (
                        <ol className='subCategories'>
                          {Object.keys(subCategories).map(subCategoryId => {
                            const subCategory = subCategories[subCategoryId];
                            const subCategoryID = subCategory._id || subCategory.id;
                            const isSubCategoryActive = isSelectedCategory(subCategoryID);
                            const handleSelectSubCategory = e => {
                              e.stopPropagation();
                              handleSelectCategory({ category: subCategory });
                              setActiveParentCategory(isParentActive ? category : undefined);
                            };
                            return (
                              <li
                                key={subCategoryID}
                                onClick={handleSelectSubCategory}
                                className={isSubCategoryActive ? 'active' : ''}
                              >
                                <a>{t(subCategory.name)}</a>
                                <span>{subCategory.total}</span>
                              </li>
                            );
                          })}
                        </ol>
                      )
                    }
                  </li>
                );
              })}
              <li
                key='all-templates'
                className={isAllCategoriesSelected ? 'active' : ''}
                onClick={() => handleSelectCategory({ clickedAllCategories: true })}
              >
                <a style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{t('All Categories')}</a>
              </li>
            </ul>
            <ScBottomArea>
              {(searchResult?.searchView !== true) && (
                <TemplateFilters
                  source={source}
                  filters={filters}
                  username={username}
                  isMobile={isMobile}
                  language={language}
                  updateFilter={setFilters}
                  setLanguage={setLanguage}
                  templateType={templateType}
                />
              )}
              {
                myTemplatesVisible && (
                  <ScMyTemplates className={`${isMyTemplates ? ' active' : ''}`} onClick={handleSelectMyTemplates}>
                    <span className="myTemplatesIcon" />
                    <span className="myTemplatesTxt">{t('My Templates')}</span>
                  </ScMyTemplates>
                )
              }
              {templateType === 'form-templates' && <ThemeSelector filters={filters} setFilters={setFilters} />}
            </ScBottomArea>
          </div>
        </div>
      </aside>
    </ScAsideWrapper>
  );
};

export default Sidebar;
