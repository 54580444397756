import React, { useState, useEffect, useRef } from 'react';
import {
  object, func, bool, string, arrayOf
} from 'prop-types';
import Dropdown from '@jotforminc/dropdown';
import { t } from '@jotforminc/translation';
import { capitalizeFirstLetter } from '@jotforminc/utils';
import { Languages } from '@jotforminc/constants';

import { fetchLanguages } from '../apiTemplates';
import { getDeviceType } from '../getDeviceType';
import { ScFilterWrapper, ScFilterMenuBox } from './ScTemplateFilters';
import { templateConstants } from '../templateConstants';

const TemplateFilters = ({
  filters, updateFilter, isMobile, language, setLanguage, templateType, username, source
}) => {
  const deviceType = getDeviceType();
  const containerRef = useRef();
  const [filterShow, setFilterShow] = useState(false);
  const [languages, setLanguages] = useState([]);

  const { sorting } = filters;

  const handleClickOutside = event => {
    const clickedTarget = event.target;
    if (!filterShow) return;
    const isSelfClick = containerRef?.current?.contains(clickedTarget);
    if (isSelfClick) return;
    const filterDropdown = document.querySelector('.jfSelect-wrapper.filterDropdown');
    if (filterDropdown !== null && filterDropdown.contains(clickedTarget)) return;
    setFilterShow(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [filterShow]);

  useEffect(() => {
    fetchLanguages(templateType, source)
      .then(res => {
        try {
          const { data: { content } } = res;
          const formattedLanugages = content.map(lang => {
            const templateLang = lang._language || lang.language;
            if (Languages && Languages.length > 0) {
              const languageObject = Languages.find(l => templateLang === l.key);
              if (languageObject !== undefined) return { value: templateLang, text: languageObject.value };
              return undefined;
            }
            return { value: templateLang, text: templateLang };
          });
          setLanguages(formattedLanugages.filter(lang => lang !== undefined).sort((a, b) => (a.text > b.text ? 1 : -1)));
        } catch (e) {
          setLanguages([]);
        }
      })
      .catch(() => {
        setLanguages([]);
      });
  }, []);

  const handleChangeFilter = (filterKey, newValue) => {
    if (filters[filterKey] === newValue) return;
    updateFilter({
      ...filters,
      [filterKey]: newValue
    });
    if (isMobile) setFilterShow(false);
  };

  const handleRemoveLanguage = () => {
    setLanguage([]);
    window.standaloneTemplatesLogger({
      actor: username,
      target: 'all-languages',
      action: `${getDeviceType()}:languageSelect`
    });
    setFilterShow(false);
  };

  const handleSelectLanguage = selected => {
    setLanguage([selected]);
    window.standaloneTemplatesLogger({
      actor: username,
      target: selected,
      action: `${getDeviceType()}:languageSelect`
    });
    setFilterShow(false);
  };

  const handleChangeSorting = option => {
    window.standaloneTemplatesLogger({
      actor: username,
      target: option,
      action: `${getDeviceType()}:sorting`
    });
    handleChangeFilter('sorting', option);
  };

  return (
    <ScFilterWrapper ref={containerRef}>
      <div className="cta" onClick={() => setFilterShow(true)}><span>{t('Filter & Sort')}</span></div>
      <div className={`sub${filterShow ? ' active' : ''}`}>
        <button type="button" className="close" onClick={() => setFilterShow(false)}>
          {t('Close')}
        </button>
        <ScFilterMenuBox>
          <li>
            <label htmlFor="language" className="info">
              {t('Language')}
              :
            </label>
            <div className="dropdown-wrapper">
              {deviceType === 'desktop' ? (
                <Dropdown
                  id="language"
                  data={languages}
                  focusTrap={true}
                  searchable={true}
                  wrapperClass="filterDropdown"
                  selectedOptionValues={language}
                  defaultOptionText={t('Select Language')}
                  onRemoveTag={handleRemoveLanguage}
                  inputClass="searchFilter-input searchFilter-select filterDropdown"
                  onOptionClick={selected => handleSelectLanguage(selected)}
                />
              ) : (
                <select
                  value={language}
                  multiple={false}
                  onChange={selected => handleSelectLanguage(selected.target.value)}
                >
                  {[{ text: t('Select Language'), value: '' }, ...languages].map(lang => (
                    <option key={lang.value} value={lang.value}>
                      {lang.text}
                    </option>
                  ))}
                </select>
              )}
              { language.length > 0 && (
                <span className='clear' onClick={handleRemoveLanguage} />
              )}
            </div>
          </li>
          <li>
            <label htmlFor="sorting" className="info">
              {t('Sort By')}
              :
            </label>
            <div className="button-group" id="sorting" role="group">
              {(templateConstants[templateType].sortingOptions || []).map(option => (
                <button
                  key={option}
                  type="button"
                  onClick={() => handleChangeSorting(option)}
                  className={`filter-button${sorting === option ? ' active' : ''}`}
                >
                  {t(capitalizeFirstLetter(option))}
                </button>
              ))}
            </div>
          </li>
        </ScFilterMenuBox>
      </div>
    </ScFilterWrapper>
  );
};

TemplateFilters.defaultProps = {
  source: '',
  username: '',
  isMobile: false,
  templateType: 'form-templates'
};

TemplateFilters.propTypes = {
  isMobile: bool,
  source: string,
  username: string,
  templateType: string,
  filters: object.isRequired,
  setLanguage: func.isRequired,
  updateFilter: func.isRequired,
  language: arrayOf(string).isRequired
};

export default TemplateFilters;
