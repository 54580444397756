import { isEnterprise } from '@jotforminc/enterprise-utils';
import { fetchNavigationResources } from './api';
import { appList } from '../constants';

const visibilityConditions = {
  pdfEditor: {
    key: 'pdf_designer_group',
    group: ['1', '2', '3', '5', '10'],
    adminCheck: true,
    keyExistCheck: true,
    ifKeyNotExist: true,
    replaceWith: 'oldPdfEditor'
  },
  sign: {
    key: '',
    group: [],
    adminCheck: false,
    visibilityChecker: (user, sourceApp) => {
      return user.allowSign && ['sign', 'inbox', 'portal', 'workflow'].includes(sourceApp);
    }
  },
  signInbox: {
    key: '',
    group: [],
    adminCheck: false,
    visibilityChecker: (user, sourceApp) => {
      return user.allowSign && ['portal'].includes(sourceApp);
    }
  },
  portal: {
    key: '',
    group: [],
    adminCheck: false, // this is handled at backend in user.allowMyApps prop
    visibilityChecker: user => user.allowMyApps
  },
  boards: {
    key: '',
    group: [],
    adminCheck: false,
    visibilityChecker: user => user.allowBoards
  },
  aiAgentBuilder: {
    key: '',
    group: [],
    adminCheck: false,
    visibilityChecker: (user, form) => user?.allowAIAgentFormFiller && form?.isAIAgentCreated
  },
  conversations: {
    key: '',
    group: [],
    adminCheck: false,
    visibilityChecker: () => false
  }
};

const visibilityCheck = (user, app, sourceAppName, form) => {
  const appDetail = visibilityConditions[app];
  if (appDetail) {
    if (appDetail.adminCheck && user.account_type && user.account_type.name === 'ADMIN' && !isEnterprise()) {
      return true;
    }
    if (appDetail.keyExistCheck && !user[appDetail.key]) {
      return appDetail.ifKeyNotExist;
    }
    if (appDetail.group.indexOf(user[appDetail.key]) > -1) {
      return true;
    }

    if (appDetail.visibilityChecker && (typeof appDetail.visibilityChecker) === 'function') {
      if (sourceAppName === 'formBuilder' && app === 'aiAgentBuilder') {
        return appDetail.visibilityChecker(user, form);
      }
      return appDetail.visibilityChecker(user, sourceAppName);
    }
    return false;
  }
  return true;
};

const getAppList = (user, list, sourceAppName, form) => list.reduce((newApps, app) => {
  if (user.allowedProductsInTeam && !user.allowedProductsInTeam.includes(app)) {
    return newApps;
  }

  if (!visibilityCheck(user, app, sourceAppName, form)) {
    if (visibilityConditions[app] && visibilityConditions[app].replaceWith) {
      newApps.push(visibilityConditions[app].replaceWith);
    }
  } else if (sourceAppName === 'portal' && app === 'tables') {
    newApps.push('appTables');
  } else {
    newApps.push(app);
  }

  if (sourceAppName === 'boards') { // TODO: review visibleList config for boards again
    return ['boards'];
  }

  if (sourceAppName === 'conversations') {
    return ['conversations'];
  }

  return newApps;
}, []);

export const getUserAppList = (user, sourceAppName = '', form) => {
  if (window.JOTFORM_ENV === 'ENTERPRISE') {
    if (user && user.isDataOnlyUser === '1') {
      return [];
    }
    const enterpriseConf = window.enterpriseConfigs || window.CUSTOMIZED_CONFIGS;
    const visibleApps = enterpriseConf && (enterpriseConf.visibleNavigationApps || enterpriseConf.VISIBLE_NAVIGATION_APPS);
    let newAppList = appList;
    if (visibleApps) {
      newAppList = appList.filter(app => visibleApps.indexOf(app) > -1);
    }
    return getAppList(user, newAppList, sourceAppName, form);
  }

  return getAppList(user, appList, sourceAppName, form);
};
export const getToggleContext = context => `is${context.charAt(0).toUpperCase()}${context.slice(1)}Opened`;
export const getToggleHandler = context => `${context}ToggleHandler`;
export const getToggleDisabled = context => `${context}ToggleDisabled`;
export const getNavigationResources = async (from, id, to) => {
  const { type, result } = await fetchNavigationResources(from, id, to);
  return { type, result: Object.values(result) };
};

export const getDefaultLogoSrc = () => {
  const hostname = global?.location?.hostname || '';
  if (hostname === 'www.jform.co.kr') return 'https://cdn.jotfor.ms/assets/img/logo2021/ko-KR/jform-logo.svg';
  return 'https://cdn.jotfor.ms/assets/img/logo2021/jotform-logo.svg';
};

export const isPlural = c => c > 1 || c === 0;

export const isShowTeamBadge = (user, isTeamMember, teamBadgeID) => {
  const canShowTeamBadge = !isEnterprise() ? isTeamMember : (user && user.userType !== 'formuser' && user.account_type?.name !== 'GUEST');
  return canShowTeamBadge && !!teamBadgeID;
};

export const hasTeamAvatarInfo = team => {
  const {
    name, teamAvatarURL, teamAvatarIcon, teamAvatarEmojiId, teamAvatarIconSvgRef
  } = team || {};

  return name && !!(teamAvatarURL || teamAvatarIcon || teamAvatarEmojiId || teamAvatarIconSvgRef);
};
