import { t } from '@jotforminc/translation';
import { elementType, bool, string } from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@jotforminc/magnet';
import { IconCopyFilled } from '@jotforminc/svg-icons';
import PanelItemDescriptionRenderer from '../PanelItemDescriptionRenderer';
import * as ACTION_CREATORS from '../../../../../../store/actionCreators';

const DuplicateItemButton = ({
  DescriptionRenderer, isDescriptionVisible, action, variant, fullWidth, customClasses, showTextOnly, size
}) => {
  const dispatch = useDispatch();

  const handleItemDuplicate = useCallback(() => {
    dispatch(ACTION_CREATORS.duplicateItemAction());
    dispatch(ACTION_CREATORS.trackEventAction({ action }));
  }, []);

  return (
    <>
      {isDescriptionVisible && <DescriptionRenderer description={t('Clone selected elements with all saved properties.')} />}
      <Button
        onClick={handleItemDuplicate}
        startIcon={IconCopyFilled}
        variant={variant}
        fullWidth={fullWidth}
        className={`${customClasses !== '' ? customClasses : 'flex mt-1 mb-2'}`}
        colorStyle='secondary'
        showTextOnly={showTextOnly}
        size={size}
      >
        {t('Duplicate')}
      </Button>
    </>

  );
};

DuplicateItemButton.propTypes = {
  DescriptionRenderer: elementType,
  isDescriptionVisible: bool,
  action: string,
  variant: string,
  fullWidth: bool,
  showTextOnly: string,
  size: string,
  customClasses: string
};

DuplicateItemButton.defaultProps = {
  DescriptionRenderer: PanelItemDescriptionRenderer,
  isDescriptionVisible: true,
  action: 'rightPanelDuplicationClicked',
  variant: 'filled',
  fullWidth: false,
  showTextOnly: '',
  size: 'medium',
  customClasses: ''
};

export default DuplicateItemButton;
