import { createSelector } from 'reselect';
import { getAppPath } from '@jotforminc/router-bridge';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { useItemDefaults, useWidgetDefaults } from '../../properties';
import {
  checkMobilePhone, getAppSubdomainBaseURL, getTeamID, isItemTypeWidget, isYes, slugifyURL, transformJotformURLToSubdomainURL
} from '../../utils';
import { FEATURE_NAMES, isTesterFeatureEnabled } from '../../constants/features';
import { isFeatureEnabled } from '../../utils/features/helper';
import { ITEM_TYPES } from '../../constants/itemTypes';
import { getLayoutProps } from '../../constants';
import * as USER_SELECTORS from './userSelectors';
import * as CHECKOUT_FORM_SELECTORS from './checkoutFormSelectors';
import * as UI_SELECTORS from './uiSelectors';
import * as PORTAL_SELECTORS from './portalSelectors';
import * as FORMS_SELECTORS from './formsSelectors';
import * as WIDGETS_SELECTORS from './widgetsSelectors';
import * as SHARE_SELECTORS from './shareSelectors';
import * as PROGRESS_SELECTORS from './progressSelectors';
import * as SIGNS_SELECTORS from './signsSelectors';
import * as PRODUCT_SELECTORS from './productSelectors';
import { checkItemAvailable } from '../../utils/itemAvailability';

export const extendItemWithDefaults = item => createSelector(PORTAL_SELECTORS.getAppVersionSelector, WIDGETS_SELECTORS.getWidgetsSelector, (appVersion, allWidgets) => {
  if (isItemTypeWidget(item.type)) {
    return { ...useWidgetDefaults(allWidgets, `${item.type}_${item.clientID}`), ...item };
  }
  return { ...useItemDefaults(item.type, appVersion), ...item };
});

export const getItemWithDefaults = id => createSelector(PORTAL_SELECTORS.getPortalItemByIDSelector(id), PORTAL_SELECTORS.getAppVersionSelector, WIDGETS_SELECTORS.getWidgetsSelector,
  (item, appVersion, allWidgets) => {
    if (!Object.keys(item).length) return {};

    const { type, clientID } = item;
    if (isItemTypeWidget(type)) {
      return { ...useWidgetDefaults(allWidgets, `${type}_${clientID}`), ...item };
    }
    const defaultsByVersion = useItemDefaults(type, appVersion);
    return { ...defaultsByVersion, ...item };
  });

export const getLastSelectedItemOrder = createSelector([PORTAL_SELECTORS.getPortalItems, UI_SELECTORS.getSelectedPortalItems], (portalItems, selectedPortalItems) => {
  const selectedItems = portalItems?.filter(({ id }) => selectedPortalItems.includes(id));
  return selectedItems?.reduce((prev, { portalOrder: order }) => Math.max(prev, parseInt(order, 10) + 0.5), -1);
});

// Temporary selector, until forms are duplicable
export const getAllSelectedItemsAreForms = createSelector(UI_SELECTORS.getSelectedPortalItems, PORTAL_SELECTORS.getPortalItems, (selectedItemIDs, portalItems) => {
  return portalItems.filter(i => selectedItemIDs.includes(i.id)).every(i => i.type === ITEM_TYPES.FORM);
});

export const getIsAppSubdomainEnabled = createSelector(USER_SELECTORS.getUser, user => {
  return ((isTesterFeatureEnabled(user, FEATURE_NAMES.AppSubdomain) || isFeatureEnabled(FEATURE_NAMES.AppSubdomain) || user?.isAppSubdomainAllowed) && !window.isHIPAA && !window.isGDPR);
});

export const getPublicLink = createSelector(
  SHARE_SELECTORS.getResourceShareLink,
  PORTAL_SELECTORS.getAppInfoWithDefaults,
  USER_SELECTORS.getMainSlug,
  PORTAL_SELECTORS.getIsAppPrivate,
  getIsAppSubdomainEnabled,
  (
    resourceShareURL,
    {
      slug: appSlug,
      scope,
      id: portalID,
      appDomain
    },
    mainSlug,
    isAppPrivate,
    isAppSubdomainEnabled
  ) => {
    const shouldUseSlug = mainSlug && appSlug !== portalID;
    const shouldUseAppDomain = isEnterprise() && !!appDomain;
    switch (true) {
      case shouldUseSlug && shouldUseAppDomain:
        return `https://${appDomain}${getAppPath()}/${mainSlug.trim()}/${appSlug.trim()}`;
      case shouldUseAppDomain:
        return `https://${appDomain}${scope}`;
      case Boolean(isAppPrivate && resourceShareURL && isAppSubdomainEnabled):
        const url = transformJotformURLToSubdomainURL(resourceShareURL);
        if (shouldUseSlug) {
          return slugifyURL(url, portalID, mainSlug, appSlug);
        }
        return url;
      case shouldUseSlug && isAppSubdomainEnabled:
        return `${getAppSubdomainBaseURL()}/${mainSlug.trim()}/${appSlug.trim()}`;
      case shouldUseSlug:
        return `${window.location.origin}${getAppPath()}/${mainSlug.trim()}/${appSlug.trim()}`;
      case Boolean(isAppPrivate && resourceShareURL && !window.isHIPAA):
        return resourceShareURL;
      case isAppSubdomainEnabled:
        return `${getAppSubdomainBaseURL()}/${portalID}`;
      default:
        return `${window.location.origin}${scope}`;
    }
  }
);

export const getHasSentboxAccess = createSelector([USER_SELECTORS.selectIsUserLoggedIn, PORTAL_SELECTORS.selectIsAppLoginable], (isUserLoggedIn, isAppLoginable) => isAppLoginable && isUserLoggedIn);

export const getQRLink = createSelector(PORTAL_SELECTORS.getAppInfoWithDefaults, USER_SELECTORS.getUser, SHARE_SELECTORS.getResourceShareLink, (
  {
    organizationAccess,
    ssoProtected,
    assigneeProtected,
    scope,
    id: portalID
  },
  user,
  resourceShareURL
) => {
  const isAppPrivate = isYes(assigneeProtected) || isYes(ssoProtected) || !!organizationAccess;
  const isAppSubdomainEnabled = (isTesterFeatureEnabled(user, FEATURE_NAMES.AppSubdomain) || isFeatureEnabled(FEATURE_NAMES.AppSubdomain) || user?.isAppSubdomainAllowed)
    && !window.isHIPAA && !window.isGDPR;
  switch (true) {
    case Boolean(isAppPrivate && resourceShareURL && isAppSubdomainEnabled):
      return transformJotformURLToSubdomainURL(resourceShareURL);
    case Boolean(isAppPrivate && resourceShareURL):
      return resourceShareURL;
    case isAppSubdomainEnabled:
      return `${getAppSubdomainBaseURL()}/${portalID}`;
    default:
      return `${window.location.origin}${scope}`;
  }
});

export const getPortalForms = createSelector([FORMS_SELECTORS.getForms, PORTAL_SELECTORS.getPortalItems], (forms, portalItems) => forms.filter(form => {
  const { id: formID } = form;
  return portalItems.find(({ id, type, buttonValue }) => {
    const addedAsFormElement = id === formID;
    const addedAsButtonElement = type === ITEM_TYPES.BUTTON && buttonValue === formID;
    return addedAsFormElement || addedAsButtonElement;
  });
}));
export const getPortalFormItems = createSelector([FORMS_SELECTORS.getForms, PORTAL_SELECTORS.getPortalItems], (forms, items) => forms.filter(form => items.find(({ id }) => id === form.id)));
export const getNonPortalForms = createSelector([FORMS_SELECTORS.getForms, PORTAL_SELECTORS.getPortalItems],
  (forms, portalItems) => forms.filter(form => !portalItems.find(portalItem => portalItem.id === form.id)));

export const getShouldShowProgressBar = createSelector(
  [USER_SELECTORS.selectIsUserLoggedIn, PROGRESS_SELECTORS.getTodoCount, PORTAL_SELECTORS.selectIsAppLoginable, PORTAL_SELECTORS.getIsProgressBarVisible],
  (isUserLoggedIn, todoCount, isAppLoginable, isProgressBarVisible) => isProgressBarVisible && isAppLoginable && isUserLoggedIn && todoCount >= 2
);

export const getActivePageID = createSelector([UI_SELECTORS.getUIState, PORTAL_SELECTORS.getFirstPageID], ({ activePageID }, firstPageID) => activePageID || firstPageID);

export const getItemDefaultsGetter = createSelector([PORTAL_SELECTORS.getAppLayout, WIDGETS_SELECTORS.getWidgetsSelector], (appLayout, allAvailableWidgets) => itemType => {
  const layoutProps = getLayoutProps(appLayout, itemType);
  if (!isItemTypeWidget(itemType)) {
    return {
      ...useItemDefaults(itemType),
      ...layoutProps.itemProps
    };
  }
  const baseDefaults = useItemDefaults(ITEM_TYPES.WIDGET);
  const widgetDefaults = useWidgetDefaults(allAvailableWidgets, itemType);
  return {
    ...baseDefaults,
    ...widgetDefaults,
    ...layoutProps.itemProps
  };
});

export const getAccountBoxScreen = createSelector(PORTAL_SELECTORS.getIsPaymentApp, UI_SELECTORS.getUIState, (isPaymentApp, { activeAccountBoxScreen }) => {
  if (activeAccountBoxScreen) {
    return activeAccountBoxScreen;
  }

  return isPaymentApp ? '' : 'loginOptions';
});

export const getHasBrandingFooter = createSelector(
  [PORTAL_SELECTORS.getShowJotFormPowered, UI_SELECTORS.getIsMobileCtxMenuOpened], (showJotFormPowered, isMobileCtxMenuOpened) => !window.isTemplateMode && showJotFormPowered && !isMobileCtxMenuOpened
);

export const getIsOrderNowButtonVisible = createSelector([UI_SELECTORS.getActiveScreen, UI_SELECTORS.getActiveFormProduct, PRODUCT_SELECTORS.getCartProductsCount,
  UI_SELECTORS.getIsMobileCtxMenuOpened, PORTAL_SELECTORS.getIsFastCheckoutActive],
(activeScreen, activeFormProduct, cartProductsCount, isMobileCtxMenuOpened, isFastCheckoutActive) => (
  !activeScreen && !activeFormProduct && checkMobilePhone() && !isFastCheckoutActive && cartProductsCount > 0 && !isMobileCtxMenuOpened));

export const getAvailableSignSelector = createSelector([SIGNS_SELECTORS.getSignsSelector, PORTAL_SELECTORS.getUsedSignItems], (signs, usedSigns) => {
  return signs.filter(sign => !usedSigns.includes(sign.formID));
});

export const getIsOwner = createSelector([USER_SELECTORS.getUsername, PORTAL_SELECTORS.getPortalOwner], (username, portalOwner) => username === portalOwner);

export const getShouldWarnToEditers = createSelector([getIsOwner, USER_SELECTORS.getIsAdmin], (isOwner, isAdmin) => isAdmin && (!isOwner && !getTeamID()));

export const getSelectedPortalItemsWithInfo = createSelector(
  [PORTAL_SELECTORS.getPortalItems, UI_SELECTORS.getSelectedPortalItems], (items, selectedItems) => items.filter(({ id }) => selectedItems.includes(id))
);

export const getSelectedPortalItemWithInfo = createSelector(getSelectedPortalItemsWithInfo, ([item]) => item);

export const getShouldShowError = itemID => createSelector(
  UI_SELECTORS.getIsItemSelected(itemID),
  UI_SELECTORS.getMobileMultipleSelectionMode,
  UI_SELECTORS.getSelectedPortalItemsCount, (
    isItemSelected,
    isMultipleSelected,
    selectedItemCount
  ) => {
    return isItemSelected && !isMultipleSelected && (selectedItemCount === 1);
  }
);

export const getItemAvailable = (itemID, actionTrackerType = '') => createSelector(
  PORTAL_SELECTORS.avaliableItemAppProperties,
  PORTAL_SELECTORS.getPortalItemByIDSelector(itemID),
  UI_SELECTORS.getLastAddedItemIDSelector,
  getItemDefaultsGetter,
  USER_SELECTORS.getIsPaymentOverQuota,
  USER_SELECTORS.getOverQuotaUpgradeLink,
  CHECKOUT_FORM_SELECTORS.getCheckoutFormActiveGatewaySettings,
  getShouldShowError(itemID),
  (
    appProperties,
    itemProperties,
    lastAddedItemID,
    getItemDefaults,
    isPaymentOverQuota,
    overQuotaUpgradeLink,
    gatewaySettings,
    showError
  ) => {
    const { type: itemType = '', clientID } = itemProperties;
    const widgetType = itemType === ITEM_TYPES.WIDGET ? `${itemType}_${clientID}` : itemType;
    const { itemAvailable, error, info } = checkItemAvailable({
      appProperties,
      itemProperties: itemID === 'contactInformationItem' ? { type: ITEM_TYPES.CONTACT_INFORMATION } : itemProperties,
      lastAddedItemID,
      emptyItemProperties: getItemDefaults(widgetType),
      isPaymentOverQuota,
      overQuotaUpgradeLink,
      actionTrackerType,
      gatewaySettings
    });

    const isThereErrorMessage = !!error;
    const shouldShowError = showError && isThereErrorMessage;

    return {
      error,
      itemAvailable,
      shouldShowError,
      info
    };
  }
);
