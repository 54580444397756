/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, memo, useState } from 'react';
import {
  Dialog, DialogBody, DialogIcon, DialogTitle, DialogDescription, DialogContent, DialogActions, DialogCloseButton, Button, FormControl, InputText, Textarea
} from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import { IconPlus, IconCheck } from '@jotforminc/svg-icons';

/** utils */
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { isDevelopment } from '../utils/functions/isDevelopment';

/** types */
import type { USER_LOCATION_PROPS, GATEWAY_CONNECTION_MODAL_SOURCE } from '../types';

export const GatewayRequestModal = memo(({
  isOpen,
  onClose,
  onCompleteRequest,
  searchedKeyword,
  modalSource,
  resourceId,
  user,
  userLocation
}: {
  isOpen: boolean,
  onClose: () => void,
  onCompleteRequest: () => void,
  searchedKeyword: string | null,
  modalSource: GATEWAY_CONNECTION_MODAL_SOURCE,
  resourceId: string | null,
  user: {
    accountType: string;
    email: string | null;
    username: string;
    name: string;
    teamRoles?: string[];
  } | null;
  userLocation: USER_LOCATION_PROPS,
}): any => {
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [requestedGateway, setRequestedGateway] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [errorInputs, setErrorInputs] = useState<string[]>([]);

  const submitRequest = () => {
    setIsSubmitLoading(true);

    if (!requestedGateway || requestedGateway.trim() === '' || requestedGateway.trim().length === 0) {
      setErrorInputs(['requestedGateway']);
      setIsSubmitLoading(false);
      return;
    }

    const formId = isDevelopment() ? '241791395701965' : '241483142643049';
    const submitBaseUrl = 'https://submit.jotformpro.com/submit';
    const formSubmitUrl = `${submitBaseUrl}/${formId}`;

    const formQuestionData: { [key: string]: string; } = {
      formID: formId,
      q3_requestedGateway: requestedGateway || '',
      q4_whyDoYouNeedThisGateway: reason || '',
      q5_resource: modalSource,
      q6_resourceId: resourceId || '',
      q7_environment: isEnterprise() ? 'Enterprise' : 'BSG',
      q8_environmentSlug: window.COMPANY_DOMAIN || window.location.hostname,
      q11_searchKeyword: searchedKeyword || '',
      q15_accountType: user?.accountType || '',
      q17_email: user?.email || '',
      q18_username: user?.username || '',
      q19_fullName: user?.name || '',
      q22_country: userLocation?.country_code || '',
      q23_continentCode: userLocation?.continent_code || '',
      q24_city: userLocation?.city || ''
    };

    const formData = new FormData();
    Object.keys(formQuestionData).forEach((key: string) => formData.append(key, formQuestionData[key]));

    fetch(formSubmitUrl, { method: 'POST', body: formData }).then(() => {
      setErrorInputs([]);
      setRequestedGateway('');
      setReason('');
      setIsSubmitLoading(false);
      setIsSubmitted(true);
    }).catch(() => {
      setIsSubmitLoading(false);
      setIsSubmitted(false);
    });
  };

  const handleClose = () => {
    setIsSubmitLoading(false);
    setIsSubmitted(false);
    setRequestedGateway('');
    setReason('');
    setErrorInputs([]);
    onClose();
  };

  return (
    <Dialog
      ariaLabel={t('Request Gateway')}
      onClose={() => handleClose()}
      open={isOpen}
    >
      <DialogBody>
        <DialogIcon
          colorStyle="informative"
          icon={!isSubmitted ? IconPlus : IconCheck}
        />
        <DialogTitle>
          {!isSubmitted ? t('Request Payment Gateway') : t('Thank You!')}
        </DialogTitle>
        <DialogDescription>
          {!isSubmitted ? t('Tell us more about the gateway you’re looking for!') : t('Your request has been submitted.')}
        </DialogDescription>
        {!isSubmitted && (
          <DialogContent className="px-8">
            <FormControl
              id="requested-gateway"
              className="mb-3"
              colorStyle={errorInputs.includes('requestedGateway') ? 'error' : undefined}
            >
              <label htmlFor="requested-gateway" className='mb-2 flex flex-col max-h-11'>
                <span className="font-medium text-md tracking-md color-navy-700 line-height-xl">
                  {t('Name of Requested Gateway')}
                  {' '}
                  <span className='color-red-400'>*</span>
                </span>
              </label>
              <InputText
                key="gateway-name"
                placeholder={t('Enter gateway name')}
                className='w-full'
                autoComplete="off"
                required={true}
                value={requestedGateway}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setRequestedGateway(e.target.value)}
                onBlur={() => {
                  if (!requestedGateway || requestedGateway.trim() === '' || requestedGateway.trim().length === 0) {
                    setErrorInputs(['requestedGateway']);
                  } else {
                    setErrorInputs([]);
                  }
                }}
                data-test-id="requested_gateway"
              />
            </FormControl>

            <FormControl id="requested-gateway-reason">
              <label htmlFor="requested-gateway-reason" className='mb-2 flex flex-col max-h-11'>
                <span className="font-medium text-md tracking-md color-navy-700 line-height-xl">
                  {t('Why do you need this gateway?')}
                </span>
              </label>
              <Textarea
                key="gateway-reason"
                className="h-40"
                placeholder={t('Enter your reason for requesting this gateway')}
                autoComplete="off"
                required={true}
                value={reason}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setReason(e.target.value)}
                data-test-id="requested_gateway_reason"
              />
            </FormControl>
          </DialogContent>
        )}
      </DialogBody>
      <DialogActions>
        {!isSubmitted ? (
          <>
            <Button
              colorStyle="secondary"
              onClick={() => handleClose()}
              variant="outline"
              disabled={isSubmitLoading}
              data-test-id="cancel_request"
            >
              {t('Cancel')}
            </Button>
            <Button
              disabled={isSubmitLoading || requestedGateway.trim() === '' || requestedGateway.trim().length === 0}
              loader={isSubmitLoading}
              onClick={submitRequest}
              data-test-id="submit_request"
            >
              {t('Send Request')}
            </Button>
          </>
        ) : (
          <Button
            onClick={() => {
              onCompleteRequest();
              handleClose();
            }}
            data-test-id="close_modal"
          >
            {t('Close')}
          </Button>
        )}
      </DialogActions>
      <DialogCloseButton onClick={() => {
        if (isSubmitted) {
          onCompleteRequest();
        }
        handleClose();
      }}
      />
    </Dialog>
  );
});
