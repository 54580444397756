import {
  all,
  call, put, select
} from 'redux-saga/effects';
import { t } from '@jotforminc/translation';
import { arrayMoveImmutable } from 'array-move';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import isEmpty from 'lodash/isEmpty';
import {
  ADD_NEW_PAGE, CHANGE_PAGE_ORDER, DELETE_PAGE, UPDATE_PAGE, UPDATE_MULTIPLE_ITEM
} from '../actionTypes';
import SELECTORS from '../selectors';
import * as API from '../../modules/api';
import {
  selectPageAction, updateLastInteractedPageIDAction, updatePageAction, trackEventAction, updateMultipleItemAction, undoableToastAction, toggleRightPanelAction
} from '../actionCreators';
import { DATA_SOURCE_ITEMS, ITEM_TYPES } from '../../constants/itemTypes';
import { isPageHomepage } from '../../modules/PublicApp/utils';
import { BUTTON_ROLE_TYPES } from '../../modules/Builder/components/HomePage/RightPanel/ButtonActions/buttonRoleTypes';
import { usePageDefaults } from '../../properties';
import { FEATURE_NAMES } from '../../constants/features';
import { isFeatureEnabled } from '../../utils/features/helper';
import { sanitizeSVGIconURLs } from '../utils';
import * as ACTION_CREATORS from '../actionCreators';
import { APP_PREVIEW_STATES } from '../../constants';
import { DELETE_PAGE_TYPES } from '../../modules/Builder/components/HomePage/constants';

export function* pageActions(action) {
  const { type, payload = {}, dontStack = false } = action;
  const portalID = yield select(SELECTORS.getPortalIDSelector);
  switch (type) {
    case ADD_NEW_PAGE.UNDOABLE:
      const pageDefaults = usePageDefaults();
      const pages = yield select(SELECTORS.getPages);
      const {
        items,
        pages: oldPage = {},
        newPageOrder,
        dontSelect = false,
        name = '',
        pageType,
        detailPageProps = {},
        pageProps
      } = payload;

      const { linkedItemID = undefined } = detailPageProps;

      // BUGFIX #3828720 :: App should restore the old page settings when user undos the page deletion.
      const oldPageProps = {
        name: oldPage.name, pageIcon: oldPage.pageIcon, showPageIcon: oldPage.showPageIcon, showPageOnNavigation: oldPage.showPageOnNavigation, linkedItemID: oldPage.linkedItemID
      };
      const cleanOldPageProps = omitBy(oldPageProps, isNil); // This line removes the undefined and null fields just in case.

      const tempID = Date.now().toString();
      const pageOrder = newPageOrder ? newPageOrder : pages.length + 1;

      const page = {
        ...isFeatureEnabled(FEATURE_NAMES.MultipageImps) && pageDefaults,
        linkedItemID,
        ...cleanOldPageProps,
        tempID,
        pageOrder,
        name,
        type: pageType,
        ...pageProps
      };

      yield put({ type: ADD_NEW_PAGE.REQUEST, payload: { pages: [{ ...page, pageOrder: pageOrder - 1 }] } });

      // actual api request
      const result = yield call(API.addPageToPortal, portalID, page);
      // !! (use a shimmer + prevent consecutive request action on UI (areAPIRequestsCompletedSelector) IF NECESSARY)
      yield put({
        type: ADD_NEW_PAGE.SUCCESS,
        payload: result,
        requestAction: action,
        currentData: { page }
      });

      const { pageID } = result;

      if (!linkedItemID) {
        yield put(ACTION_CREATORS.updateLastInteractedPageIDAction(pageID));
      }

      if (items) { // Restoring page with items
        const restoredItems = items.map(item => {
          const id = item.type === ITEM_TYPES.FORM ? item.id : undefined;
          return {
            ...item, page: pageID, id, portalOrder: undefined
          };
        });
        yield put(ACTION_CREATORS.addPortalItemAction(restoredItems));
      }

      yield put(trackEventAction({ action: 'pageAdded', target: { id: pageID, order: pageOrder } }));
      if (!dontSelect) {
        yield put(selectPageAction(pageID));
      }

      break;
    case UPDATE_PAGE.UNDOABLE:
      const { pageID: updatedID, prop } = payload;

      if (prop?.pageIcon) {
        prop.pageIcon = sanitizeSVGIconURLs(prop.pageIcon);
      }

      yield put({
        type: UPDATE_PAGE.REQUEST,
        payload
      });
      const updatedProps = yield call(API.updatePage, portalID, updatedID, prop);
      yield put(trackEventAction({ action: 'pageUpdated', target: { id: updatedID, prop } }));
      yield put({
        type: UPDATE_PAGE.SUCCESS,
        payload: updatedProps
      });
      break;
    case DELETE_PAGE.UNDOABLE:
      const {
        deleteItems = false, pageID: removedPageID, type: removeUseType = ''
      } = payload;
      const currentPortalItems = yield select(SELECTORS.getPortalItems);
      const currentPages = yield select(SELECTORS.getPages);

      if (deleteItems && currentPages) {
        const removingItems = currentPortalItems.filter(({ page: itemPageID }) => String(itemPageID) === String(removedPageID));
        const removingListItemIDs = removingItems.reduce((acc, { type: itemType, id }) => {
          if (itemType !== ITEM_TYPES.LIST) {
            return acc;
          }
          return [...acc, id];
        }, []);

        if (!isEmpty(removingListItemIDs)) {
          const linkedPages = currentPages.filter(p => removingListItemIDs.includes(p?.linkedItemID));
          yield all(
            linkedPages.map(linkedPage => put({ ...ACTION_CREATORS.deletePageAction(linkedPage.id, true, DELETE_PAGE_TYPES.pageItem), dontStack: true }))
          );
        }
      }

      if (removeUseType !== 'RIGHT_PANEL') {
        // Close it's right panel
        yield put(toggleRightPanelAction(false));
      }

      yield put({ type: DELETE_PAGE.REQUEST, payload: { pageID: removedPageID } });
      yield put(ACTION_CREATORS.setLivePreviewStatus(APP_PREVIEW_STATES.LOADING));
      const response = yield call(API.removePageFromPortal, portalID, removedPageID, deleteItems);
      const {
        pages: newPages,
        items: newItems
      } = response;

      // Clear selected and lastInteractedPages
      yield put(updateLastInteractedPageIDAction(''));
      yield put(selectPageAction());

      // Removing page may be a buttonValue, lets update them too..
      const buttonsNavigatingToRemovedPage = currentPortalItems.filter(i => i.type === ITEM_TYPES.BUTTON && i.buttonRole === BUTTON_ROLE_TYPES.NAVIGATION && i.buttonValue === removedPageID);
      const updatingButtons = buttonsNavigatingToRemovedPage.map(b => ({
        id: b.id, buttonValue: ''
      }));
      yield put(updateMultipleItemAction(updatingButtons, { dontStack: true }));

      const currentData = {
        pages: currentPages,
        items: currentPortalItems
      };

      yield put(trackEventAction({ action: 'pageDeleted', target: { id: removedPageID } }));
      yield put({
        type: DELETE_PAGE.SUCCESS,
        payload: {
          items: newItems,
          pages: newPages
        },
        requestAction: action,
        currentData,
        dontStack
      });
      if (!dontStack) {
        yield put(undoableToastAction(t('Page is deleted.')));
      }
      break;
    default:
      break;
  }
}

export function* watchHeadingItemToPageNaming(action) {
  const { payload: { itemID, prop: { title, newPage } } } = action;
  if (!title) return; // we need title to change the page name

  const headingItem = yield select(SELECTORS.getPortalItemByIDSelector(itemID));
  const currentTitle = headingItem.title;
  const itemPageID = yield select(SELECTORS.getPageIDByItemID(itemID));
  const items = yield select(SELECTORS.getPortalItems);

  // pageID may be the page item was moved or the current itemPage
  const pageID = newPage || itemPageID;
  const isHomePage = !pageID || isPageHomepage(pageID); // check the homepage or not

  // get the page items
  const pageItems = isHomePage ? [
    ...items.filter(item => !item.page || isPageHomepage(item.page))
  ] : [...items.filter(item => item.page === pageID)];

  // get the Heading items that actionable heading item is located
  const pageHeadingItems = pageItems.filter(item => item.type === ITEM_TYPES.HEADING);
  const moreThanOneHeading = pageHeadingItems.length > 1;

  // for the other pages
  const page = yield select(SELECTORS.getPageByID(pageID));
  const pageName = page.name;
  const doesCurrentTitleEqualPageName = currentTitle === pageName;
  const shouldChangePageName = !moreThanOneHeading && (!pageName || doesCurrentTitleEqualPageName);
  if (shouldChangePageName) {
    yield put(updatePageAction({ pageID: pageID, prop: { name: title } }));
  }
}

export function* watchPageUpdate(action) {
  const { payload } = action;
  const { oldIndex, newIndex: _newIndex, skipDetailPages = false } = payload;
  const pages = yield select(SELECTORS.getPages);
  const items = yield select(SELECTORS.getPortalItems);
  const dsItems = items.filter(({ type }) => DATA_SOURCE_ITEMS.includes(type));

  const portalID = yield select(SELECTORS.getPortalIDSelector);

  const dsPages = dsItems.reduce((acc, { id: dsItemID, page }) => {
    const detailPageID = pages.find(({ linkedItemID }) => linkedItemID === dsItemID)?.id;
    if (!detailPageID) {
      return acc;
    }

    return { ...acc, [page]: [...acc?.[page] ?? [], detailPageID] };
  }, {});

  const newIndex = skipDetailPages
    // eslint-disable-next-line func-names
    ? (function () {
      let indx = _newIndex;

      if (indx === oldIndex) {
        return indx;
      }

      const shouldIncrement = _newIndex > oldIndex;
      while (indx > -1 && indx < pages.length && Object.values(dsPages).flat()?.includes(pages?.[indx]?.id)) {
        if (shouldIncrement) {
          indx++;
        } else {
          indx--;
        }
      }

      return indx;
    }())
    : _newIndex;

  const movedPages = arrayMoveImmutable(pages, oldIndex, newIndex).map((item, index) => ({ ...item, pageOrder: `${index}` }));
  const movedPagesWithoutDetailPages = movedPages.filter(({ id }) => !Object.values(dsPages).flat().includes(id));

  const pageMap = new Map();
  const movedPagesWithDetailPages = [];

  // Created a map for quick access
  pages.forEach(page => {
    pageMap.set(page.id, page);
  });

  movedPagesWithoutDetailPages.forEach(page => {
    movedPagesWithDetailPages.push(page);

    if (dsPages[page.id]) {
      dsPages[page.id].forEach(childPageID => {
        const childPage = pageMap.get(childPageID);

        if (childPage) {
          movedPagesWithDetailPages.push(childPage);
        }
      });
    }
  });

  const orderedPages = movedPagesWithDetailPages.map((item, index) => ({ ...item, pageOrder: String(index) }));

  yield put({ type: CHANGE_PAGE_ORDER.SUCCESS, payload: orderedPages });
  const { reOrderedPortalItems = {} } = yield call(API.bulkUpdatePages, portalID, orderedPages);
  yield put({ type: UPDATE_MULTIPLE_ITEM.SUCCESS, payload: reOrderedPortalItems });
}
