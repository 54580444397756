import createWidgetTranslations from './customfield/language';
import createCustomWidget from './customfield/customWidget';
import createWidgetUtils from './customfield/utils';

import injectGetStyle from './utils/getStyle';
import injectPostMessage from './utils/postmessage';
import makeJCFServerCommonChecks from './utils/makeJCFServerCommonChecks';
import clientWidgetFrameLoaded from './widgetsServer/client';
import JFExternalWidgetParamsServer from './external/server';
import JFExternalWidgetParamsClient from './external/client';
import {
  widgetFrameLoaded as builderWidgetFrameLoaded,
  getWidgetTranslatables as builderGetWidgetTranslatables
} from './widgetsServer/builder';

const initWidgetsBuilder = () => {
  // good to go
  // Replaces the old behaviour of WidgetsServerBuilder.js
  if (window.JCFServerCommon === undefined) {
    window.JCFServerCommon = {
      frames: {}
    };
  }

  if (window.widgetTranslatables === undefined) {
    window.widgetTranslatables = {};
  }
  injectPostMessage();
  injectGetStyle();
  window.widgetFrameLoaded = builderWidgetFrameLoaded;
  window.getWidgetTranslatables = builderGetWidgetTranslatables;
  // eslint-disable-next-line no-undef
  if (!Element.prototype.addClassName) {
    // eslint-disable-next-line no-undef
    Element.prototype.addClassName = function (className) {
      if (this.className.includes(className)) return;
      this.className += `${this.className.length ? ' ' : ''}${className}`;
    };
  }
};

export {
  createWidgetTranslations,
  createCustomWidget,
  createWidgetUtils,
  clientWidgetFrameLoaded,
  builderWidgetFrameLoaded,
  builderGetWidgetTranslatables,
  injectGetStyle,
  injectPostMessage,
  makeJCFServerCommonChecks,
  JFExternalWidgetParamsServer,
  initWidgetsBuilder,
  JFExternalWidgetParamsClient
};
