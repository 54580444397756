/* eslint-disable max-len */
import Styled from 'styled-components';

const blueColor = '#0099FF';
const navyColor = '#091141';

export const ScFilterWrapper = Styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 2rem 0 1rem;

  .cta {
    list-style: none;
    margin: 0;
    padding: 3px 8px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    white-space: nowrap;
    font-size: 16px;
    color: ${navyColor};
    letter-spacing: 0;
    line-height: 32px;
    width: 100%;
    border-radius: 4px;
    position: relative;
    border: none;
    background: #E3E5F5;
    transition: all .2s ease-in;
    cursor: pointer;
    display: flex;

    &:hover {
      background: #E7E8EC;
    }

    span {
      vertical-align: middle;
      white-space: normal;
      line-height: 1.25;
      padding: 10px 0;
    }

    &:before {
      content: "";
      background-size: 100% 100%;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      height: 18px;
      margin: auto 8px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNiAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDEpIiBmaWxsPSIjMTEyMjRBIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IHk9IjEiIHdpZHRoPSIxNiIgaGVpZ2h0PSIyIiByeD0iMSIvPjxyZWN0IHk9IjciIHdpZHRoPSIxNiIgaGVpZ2h0PSIyIiByeD0iMSIvPjxyZWN0IHk9IjEzIiB3aWR0aD0iMTYiIGhlaWdodD0iMiIgcng9IjEiLz48Y2lyY2xlIHN0cm9rZT0iI0U3RThFQyIgY3g9IjUiIGN5PSIyIiByPSIyLjUiLz48Y2lyY2xlIHN0cm9rZT0iI0U3RThFQyIgY3g9IjExIiBjeT0iOCIgcj0iMi41Ii8+PGNpcmNsZSBzdHJva2U9IiNFN0U4RUMiIGN4PSI1IiBjeT0iMTQiIHI9IjIuNSIvPjwvZz48L3N2Zz4=");
      background-repeat: no-repeat;
    }
  }
  .sub {
    position: absolute;
    z-index: 5000;
    display: none;
    top: 45px;

    &.active {
      display: block;
      max-width: 291px;
      width: 100%;
      margin: 4px 0;
      padding: 0;
    }

    .close {
      cursor: pointer;
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 5010;
      padding: 0;
      width: 16px;
      height: 16px;
      display: block;
      border: none;
      text-indent: -99999px;
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: center;
      background-color: transparent;
      background-image: url("data:image/svg+xml, %3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-305.000000, -148.000000)' fill='%23B0BEC5' fill-rule='nonzero'%3E%3Cg transform='translate(40.000000, 96.000000)'%3E%3Cpath d='M273,58.5857864 L279.292893,52.2928932 C279.683418,51.9023689 280.316582,51.9023689 280.707107,52.2928932 C281.097631,52.6834175 281.097631,53.3165825 280.707107,53.7071068 L274.414214,60 L280.707107,66.2928932 C281.097631,66.6834175 281.097631,67.3165825 280.707107,67.7071068 C280.316582,68.0976311 279.683418,68.0976311 279.292893,67.7071068 L273,61.4142136 L266.707107,67.7071068 C266.316582,68.0976311 265.683418,68.0976311 265.292893,67.7071068 C264.902369,67.3165825 264.902369,66.6834175 265.292893,66.2928932 L271.585786,60 L265.292893,53.7071068 C264.902369,53.3165825 264.902369,52.6834175 265.292893,52.2928932 C265.683418,51.9023689 266.316582,51.9023689 266.707107,52.2928932 L273,58.5857864 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      html[dir="rtl"] & {
        right: auto;
        left: 8px;
      }
    }
  }
`;
export const ScFilterMenuBox = Styled.ul`
  list-style: none;
  margin: 0;
  text-align: left;
  padding: 16px 8px;
  background: #292A3D;
  border-radius: 4px;

  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    height: 0;
    width: 0;
    left: 12px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #292A3D;
    top: -8px;

    html[dir="rtl"] & {
      left: inherit;
      right: 12px;
    }
  }

  li {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    &:last-child {
      margin-botttom: 0;
    }
    .info {
      padding: 0 8px;
      display: block;
      text-decoration: none;
      font-weight: 600;
      transition: color .15s ease;
      position: relative;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 32px;

      html[dir="rtl"] & {
        text-align: right;
      }
    }
    .dropdown-wrapper {
      position: relative;
      .clear {
        position: absolute;
        z-index: 1;
        right: 24px;
        width: 24px;
        height: 100%;
        border: 0;
        outline: none;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3Csvg width='266' height='264' viewBox='0 0 266 264' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M154.822 131.762L260.232 26.3524C266.822 19.7626 266.822 9.88335 260.232 4.94235C253.643 -1.64745 243.763 -1.64745 238.822 4.94235L131.762 110.352L26.3524 4.94235C19.7626 0.00094986 9.88335 0.000957012 4.94235 6.59076C0.00094986 13.1806 0.00094986 21.4148 4.94235 26.3568L110.352 131.767L4.94235 237.177C-1.64745 243.767 -1.64745 253.646 4.94235 258.587C11.5321 265.177 21.4114 265.177 26.3524 258.587L131.762 153.177L237.172 258.587C243.762 265.177 253.641 265.177 258.582 258.587C265.172 251.997 265.172 242.118 258.582 237.177L154.822 131.762Z' fill='black'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: center;
        html[dir="rtl"] & {
          right: auto;
          left: 24px;
        }
      }
      &:after {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%278%27 height=%275%27%3E%3Cpath fill=%27%23141E46%27 fill-rule=%27evenodd%27 stroke=%27%23141E46%27 d=%27M6.7.5L4 3.1 1.3.5h-.2v.3a68600 68600 0 0 0 3 2.7L7 .8V.5h-.2z%27/%3E%3C/svg%3E");
        width: 2em;
        height: calc(100% - 2px);
        position: absolute;
        pointer-events: none;
        cursor: pointer;
        right: 1px;
        top: 1px;
        content: "";
        background-repeat: no-repeat;
        background-position: 50%;
        background-color: #fff;
        border-radius: 50%;
        &:after {
          right: auto;
          left: 0;
        }          
      }      
      select {
        width: 100%;
        font-size: 16px;
        line-height: 1.55;
        color: ${navyColor};
        background-color: #fff;
        border: 1px solid #d5ddf9;
        border-radius: 4px;
        height: auto;
        padding: 4px 5px 4px 9px;
        appearance: none;
      }
    }
    .jfSelect-input {
      &.filterDropdown {
        background-color: #fff;
        &:after {
          display: none;
        }
        html[dir="rtl"] & {
          padding-right: auto;
          padding-left: 2.25em;
          .jfSelect-inputText {
            text-align: right;
          }
        }
        .jfSelect-tag {
          width: calc(100% - 20px);
          margin: 0;
          padding: 0 5px;
          background: transparent;
          color: ${navyColor};
          html[dir="rtl"] & {
            text-align: right;
            padding-right: 20px;
          }            
          .jfSelect-removeButton {
            background: transparent;
            border: 0;
            outline: none;
            svg path {
              fill: ${navyColor};
            }
          }
        }
      }
    }
    .button-group {
      position: relative;
      display: flex;
      flex: 1;
      vertical-align: middle;

      .filter-button {
        font-size: initial;
        text-align: left;
        display: inline-block;
        box-sizing: border-box;
        flex: 1;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 32px;
        padding: 0 6px;
        border: none;
        white-space: nowrap;
        background-color: #fff;
        color: ${navyColor};

        &.active {
          background-color: ${blueColor};
          color: #fff;
        }

        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;

          html[dir="rtl"] & {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;

          html[dir="rtl"] & {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
        }

      }

    }

  }
`;
