import React from 'react';
import { func, object, string } from 'prop-types';
import { t } from '@jotforminc/translation';
import { capitalizeFirstLetter } from '@jotforminc/utils';

import { getDeviceType } from '../getDeviceType';
import ClassicFormIcon from '../assets/ClassicFormIcon';
import CardFormIcon from '../assets/CardFormIcon';
import { ScThemeSelector } from './ScThemeSelector';

const ThemeSelector = ({ filters, setFilters, username }) => {
  const { theme } = filters;
  const handleSelectTheme = newTheme => {
    if (newTheme === theme) return;
    setFilters({
      ...filters,
      theme: newTheme
    });
    window.standaloneTemplatesLogger({
      actor: username,
      action: `${getDeviceType()}:filterTheme`,
      target: newTheme
    });
  };

  const themeButtons = [
    { buttonText: 'classic', icon: ClassicFormIcon, buttonClass: 'themeClassic' },
    { buttonText: 'card', icon: CardFormIcon, buttonClass: 'themeCard' }
  ];

  return (
    <ScThemeSelector>
      <ul>
        {themeButtons.map(button => (
          <li key={button.buttonText}>
            <button
              className={`${button.buttonClass}${theme === button.buttonText ? ' themeActive' : ''}`}
              onClick={() => handleSelectTheme(button.buttonText)}
              type="button"
            >
              <div className="themeIcon">
                {button.icon()}
              </div>
              <span>{t(capitalizeFirstLetter(button.buttonText))}</span>
            </button>
          </li>
        ))}
      </ul>
    </ScThemeSelector>
  );
};

ThemeSelector.defaultProps = {
  username: ''
};

ThemeSelector.propTypes = {
  username: string,
  filters: object.isRequired,
  setFilters: func.isRequired
};

export default ThemeSelector;
