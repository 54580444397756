import Base from '../v0/App.json';

const migrate = () => {
  const orig = { ...Base };

  return {
    ...orig,
    ...{
      $id: 'https://www.jotform.com/portal/schemas/v1/App.json#',
      title: 'App Schema v1',
      definitions: {
        ...orig.definitions,
        ...{
          yesNo: {
            $ref: '../commonDefinitions.json#/definitions/yesNo'
          },
          alignment: {
            $ref: '../commonDefinitions.json#/definitions/alignment'
          },
          URL: {
            $ref: '../commonDefinitions.json#/definitions/URL'
          },
          logoType: {
            $ref: '../commonDefinitions.json#/definitions/logoType'
          }
        }
      },
      properties: {
        ...orig.properties,
        ...{
          appVersion: {
            ...orig.properties.appVersion,
            const: '1',
            default: '1'
          },
          name: {
            ...orig.properties.name,
            default: 'App Title',
            translate: true
          },
          description: {
            ...orig.properties.description,
            default: ''
          },
          appFontColor: {
            ...orig.properties.appFontColor,
            default: '#0A1551'
          },
          appBgColor: {
            ...orig.properties.appBgColor,
            default: '#F3F3FE'
          },
          appBgColorEnd: {
            ...orig.properties.appBgColorEnd,
            default: '#F3F3FE'
          },
          logoURL: {
            ...orig.properties.logoURL,
            default: 'https://cdn.jotfor.ms/assets/img/logo2021/jotform_portal_logo_icon400x400-dark.png'
          },
          logoBackground: {
            ...orig.properties.logoBackground,
            default: '#DFDFFF'
          },
          splashBgColor: {
            ...orig.properties.splashBgColor,
            default: '#0A1551'
          },
          appFontFamily: {
            type: 'string',
            description: 'The font family of for all over the app',
            default: 'Circular'
          },

          // APP COVER:
          showAppCover: {
            $ref: '#/definitions/yesNo',
            description: 'Whether to show app cover or not',
            default: 'Yes'
          },
          appCoverBgURL: {
            $ref: '#/definitions/URL',
            description: 'App cover background URL',
            default: ''
          },
          appCoverBgColor: {
            $ref: '#/definitions/color',
            description: 'App cover background start color',
            default: '#DFDFFF'
          },
          appCoverBgColorEnd: {
            $ref: '#/definitions/color',
            description: 'App cover background end color',
            default: '#DFDFFF'
          },
          appCoverBgCropInfo: {
            type: 'string',
            description: 'App cover background image position and width/height',
            default: ''
          },

          // App Header
          // will be changed with new app header design
          showAppHeader: {
            $ref: '#/definitions/yesNo',
            description: 'Whether to show app header or not',
            default: 'Yes'
          },
          openAppHeader: {
            $ref: '#/definitions/yesNo',
            description: 'Whether to show app header or not completely',
            default: 'Yes'
          },
          appHeaderTextAlignment: {
            $ref: '#/definitions/alignment',
            description: 'App Header inner alignments',
            default: 'center'
          },
          appHeaderBgURL: {
            $ref: '#/definitions/URL',
            description: 'App header background URL',
            default: ''
          },
          appHeaderBgCropInfo: {
            type: 'string',
            description: 'App header background image position and width/height',
            default: ''
          },
          appHeaderBgColor: {
            $ref: '#/definitions/color',
            description: 'App header background color',
            default: '#DFDFFF'
          },

          // App Footer
          appFooterVisible: {
            $ref: '#/definitions/yesNo',
            description: 'Whether to show app footer or not',
            default: 'No'
          },

          // App Icon
          appIconURL: {
            $ref: '#/definitions/color',
            description: 'App icon image URL',
            default: 'https://cdn.jotfor.ms/assets/img/logo2021/jotform_portal_logo_icon400x400-dark.png'
          },
          appIconBackground: {
            $ref: '#/definitions/color',
            description: 'App icon background color',
            default: '#DFDFFF'
          },
          appIconColor: {
            $ref: '#/definitions/color',
            description: 'App icon color',
            default: '#0A1551'
          },
          iconColor: {
            ...orig.properties.iconColor,
            default: '#0A1551'
          },
          appIconType: {
            $ref: '#/definitions/logoType',
            description: 'App icon type',
            default: 'LOGO/IMAGE'
          },
          appIconSvgRef: {
            type: 'string',
            description: 'svgRef of the icon',
            default: ''
          },
          // Item Listings Background
          showListBg: {
            $ref: '#/definitions/yesNo',
            description: 'Whether to show item list background or not',
            default: 'Yes'
          },
          listBgColor: {
            $ref: '#/definitions/color',
            description: 'Item list background color',
            default: '#ffffff'
          },
          disableOnDate: {
            $ref: '#/definitions/yesNo',
            description: 'Disable an app on a specific date.',
            default: 'No'
          },
          redirectToApp: {
            $ref: '#/definitions/yesNo',
            description: 'Redirect app to another app when disabled.',
            default: 'No'
          },
          redirectedApp: {
            type: 'string',
            description: 'Redirect app id when the app is disabled.',
            default: ''
          },
          disabledAppText: {
            type: 'string',
            description: 'Text to show when an app is disabled.',
            default: 'This app is currently unavailable.',
            translate: true
          },
          disableDate: {
            type: 'string',
            description: 'Disable date of the app formatted as YYYY-MM-DD HH:mm',
            default: ''
          },
          disableDateTimezone: {
            type: 'string',
            description: 'Disable timezone of the app disable date',
            default: ''
          },
          overrideSACL: {
            $ref: '#/definitions/yesNo',
            description: 'Whether to override Save And Continue Later feature enabled on forms.',
            default: 'Yes'
          },
          pushNotification: {
            $ref: '#/definitions/yesNo',
            description: 'Whether to override the push notification sending feature enabled in the apps.',
            default: 'No'
          },
          showA2HS: {
            $ref: '#/definitions/yesNo',
            description: 'Disable/enable add to home screen pop-up on public app',
            default: 'Yes'
          },
          preventCloningApp: {
            $ref: '#/definitions/yesNo',
            description: 'Prevent other users from cloning this app.',
            default: 'No'
          },
          overridingItemProps: {
            type: 'string',
            description: 'Overriding item props according to scheme/theme',
            default: '{}'
          },
          showProgressBar: {
            $ref: '#/definitions/yesNo',
            description: 'Show progress bar on the app for required item completions',
            default: 'No'
          },
          progressRestartable: {
            $ref: '#/definitions/yesNo',
            description: 'Whether app users can restart their completion progress or not',
            default: 'No'
          },
          appLayout: {
            type: 'string',
            description: 'Applied app layout',
            default: 'DEFAULT'
          },
          appLogoSize: {
            type: 'string',
            description: 'App logo size in px',
            default: '0'
          },
          showNavigationBar: {
            $ref: '#/definitions/yesNo',
            description: 'Set the visibility state of the navigation.',
            default: 'Yes'
          },
          showAllPagesIcon: {
            $ref: '#/definitions/yesNo',
            description: 'Set the visibility state of the all pages icon.',
            default: 'Yes'
          },
          mobileMenuTitle: {
            type: 'string',
            description: 'Mobile menu name for public app.',
            default: 'MENU',
            translate: true
          },
          showMobileMenuIcon: {
            $ref: '#/definitions/yesNo',
            description: 'Set the visibility state of the mobile menu icon.',
            default: 'Yes'
          },
          mobileMenuIcon: {
            type: 'string',
            description: 'Mobile menu icon for public app.',
            default: ''
          },
          useFastCheckout: {
            $ref: '#/definitions/yesNo',
            description: 'Fast checkout for shopping apps.',
            default: 'No'
          },
          currency: {
            type: 'string',
            description: 'The currency type to be used in payment elements.',
            default: 'USD'
          },
          decimalMark: {
            type: 'string',
            description: 'Decimal mark to separate prices in apps.',
            default: 'point'
          },
          useDecimal: {
            $ref: '#/definitions/yesNo',
            description: 'Indicates whether to use decimal mark in prices or not.',
            default: 'Yes'
          },
          productListLayout: {
            type: 'string',
            description: 'Product list layout',
            default: 'V2C'
          },
          showSearch: {
            $ref: '#/definitions/yesNo',
            description: 'Show search',
            default: 'Yes'
          }
        }
      }
    }
  };
};
const migrated = migrate();

export { migrated as AppV1 };
