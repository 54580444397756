import React from 'react';
import { translationRenderer } from '@jotforminc/translation';
import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Stripe gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlStripe: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_stripe: {
    stripeLink: {
      /* eslint-disable-next-line max-len */
      title: translationRenderer('Enable 1-Click Checkout with [1[{link}]]')({
        renderer1: () => (
          <a href="https://link.co/" target="_blank"><img alt="Link" src={`https://cdn.jotfor.ms/assets/img/payments/stripeLinkIcon.svg?v0.${Math.floor(Math.random() * 100) + 1}`} /></a>
        )
      }) as string,
      description: 'Allow your customers to autofill their payment information.',
      type: 'toggle',
      subType: 'dynamicToggle',
      selectedCondition: settings => settings.stripeLink === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      disableCondition: settings => settings.isValidAccountCountry !== 'Yes'
    },
    sendEmail: {
      title: 'Send Email to Customer',
      description: 'Automatically send an email to a customer when 3D Secure authentication problems occur',
      type: 'toggle',
      selectedCondition: settings => settings.sendEmail === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'Yes',
      resources: ['FORM']
    },
    laterCharge: {
      title: 'Charge Customer Immediately',
      type: 'toggle',
      selectedCondition: settings => settings.laterCharge === 'immediately',
      valueTrue: 'immediately',
      valueFalse: 'later',
      default: 'immediately',
      resources: ['FORM', 'APP']
    },
    createCustomer: {
      title: 'Create Stripe Customer Record',
      type: 'toggle',
      subType: 'withRadioGroup',
      radioType: 'button',
      radioOptions: [
        { id: 'Unique', key: 'Unique', text: 'Each Unique Customer' },
        { id: 'All', key: 'All', text: 'Each Submission' }
      ],
      selectedCondition: settings => ['Unique', 'All'].includes(settings.createCustomer) || settings.paymentType === 'subscription',
      valueTrue: 'Unique',
      valueFalse: 'No',
      default: 'Unique',
      resources: ['FORM', 'APP'],
      disableCondition: settings => settings.paymentType === 'subscription'
    },
    useStripeCoupons: {
      title: 'Fetch Coupons from Stripe Account',
      description: 'Fetch coupons from the given Stripe account. This will override your Jotform coupons.',
      type: 'toggle',
      selectedCondition: settings => settings.useStripeCoupons === '1' || settings.useStripeSubscriptions === '1',
      valueTrue: '1',
      valueFalse: '0',
      default: '0',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'subscription',
      disableCondition: settings => settings.useStripeSubscriptions === '1'
    },
    useStripeSubscriptions: {
      title: 'Fetch Subscriptions from Stripe Account',
      description: 'Fetch subscriptions from the given Stripe account. This will override your Jotform subscriptions.',
      type: 'toggle',
      selectedCondition: settings => settings.useStripeSubscriptions === '1',
      valueTrue: '1',
      valueFalse: '0',
      default: '0',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'subscription'
    },
    showStripeCoupons: {
      title: 'Show Fetched Coupons from Stripe Account',
      description: 'Show fetched coupons from the given Stripe account.',
      type: 'toggle',
      selectedCondition: settings => settings.showStripeCoupons === '1',
      valueTrue: '1',
      valueFalse: '0',
      default: '1',
      resources: ['FORM'],
      /* eslint-disable-next-line max-len */
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'subscription' && (settings.useStripeCoupons === '1' || settings.useStripeSubscriptions === '1')
    },
    isBillingInformation: {
      title: 'Ask Billing Information to Customer',
      type: 'toggle',
      selectedCondition: settings => settings.isBillingInformation === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM']
    },
    emailField: {
      title: 'Customer Email Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.isBillingInformation === 'Yes',
      autoSave: true
    },
    billingAdd: {
      title: 'Use Field as Billing Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.isBillingInformation === 'Yes',
      autoSave: true
    },
    shippingAdd: {
      title: 'Use Field as Shipping Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.isBillingInformation === 'Yes',
      autoSave: true
    },
    phoneField: {
      title: 'Customer Phone Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_phone'],
      questionTypeToAdd: 'control_phone',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.isBillingInformation === 'Yes',
      autoSave: true
    },
    customDataField: {
      title: 'Custom Data Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_textbox', 'control_fullname', 'control_autoincrement'],
      questionTypeToAdd: 'control_textbox',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.isBillingInformation === 'Yes',
      autoSave: true
    }
  }
};

export default controlStripe;
