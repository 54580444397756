import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Paysafe gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlPaysafe: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_paysafe: {
    ccTitle: {
      title: 'Credit Card Field Label',
      type: 'input',
      default: 'Credit Card',
      resources: ['FORM', 'APP']
    },
    addressTitle: {
      title: 'Billing Address Field Label',
      type: 'input',
      default: 'Billing Address',
      resources: ['FORM']
    }
  }
};

export default controlPaysafe;
