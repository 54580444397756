import { getAppPath } from '@jotforminc/router-bridge';
import {
  shareFacebook,
  shareTwitter,
  shareLinkedin,
  shareWhatsapp
} from '@jotforminc/utils';
import { getDomainURL } from '@jotforminc/request-layer';
import { t } from '@jotforminc/translation';
import { StorageHelper } from '@jotforminc/storage-helper';
import { navigate } from '@reach/router';

import { FORM_PRODUCTS } from '../../constants';
import {
  FORCE_MOBILE_DEVICE_PARAM,
  getAppLinkWithUTM,
  getUserAgentPlatformType,
  isAppSubdomain,
  shouldForceMobile
} from '../../utils';
import { ITEM_TYPES } from '../../constants/itemTypes';
import { FEATURE_NAMES } from '../../constants/features';
import { isFeatureEnabled } from '../../utils/features/helper';

export const isPWA = () => {
  try {
    return (
      window.location.href.indexOf('?utm_source=jotform_pwa') > -1 // from app's manifest.json
      || window.matchMedia('(display-mode: standalone)').matches
      || (window.navigator.standalone)
      || document.referrer.includes('android-app://')
    );
  } catch (e) {
    return false;
  }
};

export const checkPWAInstallation = appID => {
  // TODO Consider using navigator.getInstalledRelatedApps() in future
  return StorageHelper.hasKeyValuePair({ key: `${appID}_pwa_installed`, value: 'yes' });
};

export const persistPWAInstallation = (appID, isInstalled = true) => {
  const isInstalledKey = `${appID}_pwa_installed`;
  const installationDateKey = `${appID}_pwa_latest_date`;

  if (isInstalled) {
    StorageHelper.setLocalStorageItem({ key: isInstalledKey, value: 'yes' });
    StorageHelper.setLocalStorageItem({ key: installationDateKey, value: Date.now() });
  } else {
    StorageHelper.removeLocalStorageItem({ key: isInstalledKey });
    StorageHelper.removeLocalStorageItem({ key: installationDateKey });
  }
};

export const getLastInstallationDate = appID => {
  const installationDateKey = `${appID}_pwa_latest_date`;
  return StorageHelper.getLocalStorageItem({ key: installationDateKey });
};

export const registerServiceWorker = scope => {
  console.log('Register service worker');
  if (!scope && scope.length < 10) {
    console.log('invalid scope for serviceWorker.js', scope);
    return;
  }
  const serviceWorkerPath = `${window.location.origin}${scope}/serviceWorker.js`;

  const onRegisterError = error => {
    const msg = `Service worker registration failed: ${error}`;
    if (error.name === 'NotSupportedError') {
      console.warn(msg);
    } else {
      console.error(msg);
    }
  };

  if ('serviceWorker' in window.navigator) {
    window.navigator.serviceWorker.register(serviceWorkerPath, { scope }).then(
      f => f, // Registration success case
      onRegisterError
    );
  } else {
    console.warn('Service workers are not supported.');
  }
};

export const addForceMobileDeviceParam = urlSearchParam => {
  const [key, val] = FORCE_MOBILE_DEVICE_PARAM;
  urlSearchParam.set(key, val);
};

export const prepareAppURL = (scope, isAppReady) => {
  const appURL = new URL(scope, window.location.origin);
  appURL.hash = 'preview';
  appURL.searchParams.append('nojump', '1');
  addForceMobileDeviceParam(appURL.searchParams); // Mutates the URL

  return isAppReady && appURL.toString();
};

export const isSVGAsset = path => /\.svg$/i.test(path);

export const isValidNumber = number => /^\d+$/.test(number);

export const isPageHomepage = pageID => ['', 'homepage'].includes(pageID);

export const generateIframeURL = ({
  appID, id, type = FORM_PRODUCTS.FORM, resourceURL, forceMobile = false, additionalQueries = {}, embedDocumentKey
}) => {
  const isProductForm = [FORM_PRODUCTS.FORM, FORM_PRODUCTS.CONTINUE_DRAFT].includes(type);

  let domainPath = isProductForm && isAppSubdomain() ? 'https://app.jotform.com/form' : getDomainURL();
  const searchParams = new URLSearchParams(window.location.search);

  // in payment forms after checkout redirect to thank you page
  if (isProductForm && searchParams.has('sid')) {
    const url = new URL(window.location.href);
    const iframeParams = new URLSearchParams({ sid: searchParams.get('sid') });
    searchParams.delete('sid');
    url.search = searchParams;
    navigate(url, { replace: true });
    return new URL(`${domainPath}/complete.php?${iframeParams}`).toString();
  }

  switch (type) {
    case FORM_PRODUCTS.TABLES:
      domainPath += `/tables/${id}`;
      break;
    case FORM_PRODUCTS.SENTBOX:
    case FORM_PRODUCTS.PREVIOUS_ORDERS:
    case FORM_PRODUCTS.PREVIOUS_DONATIONS:
      domainPath += `/sentbox/${id}`;
      break;
    case FORM_PRODUCTS.REPORT:
      domainPath = resourceURL;
      break;
    case FORM_PRODUCTS.SIGN:
      domainPath += `/sign/${id}/invite/${embedDocumentKey}`;
      break;
    default:
      domainPath += `/${id}`;
      break;
  }

  let frameURL = new URL(domainPath + window.location.search);
  if (window.location.search.includes('draft=')) {
    // Handle Save and Continue Case
    const urlParams = new URLSearchParams(window.location.search);
    const domainURL = getDomainURL().replace(/^https:\/\//, '');
    const hostname = `https://${domainURL.replace(/^form\./, '')}`;
    frameURL = new URL(`${hostname}/draft/${urlParams.get('draft')}${window.location.search}`);
  }

  Object.entries(additionalQueries).forEach(([key, value]) => frameURL.searchParams.set(key, value));
  frameURL.searchParams.set('jotform_pwa', '1');
  if (isFeatureEnabled(FEATURE_NAMES.PWAEnabled)) frameURL.searchParams.set('form_pwa_enabled', '1');
  frameURL.searchParams.set('pwa_id', appID);
  frameURL.searchParams.set('pwa_device', getUserAgentPlatformType());
  frameURL.searchParams.set('pwa_isPWA', isPWA());
  frameURL.searchParams.set('hideAccountBox', '1');
  frameURL.searchParams.set('no-form-toolbar', 'true');
  frameURL.searchParams.set('pwa_app_path', global.encodeURIComponent(getAppPath()));
  if (isProductForm) {
    frameURL.searchParams.set('form_id', id);
  }
  const jotformersWeekApp = '222010792350848';
  if (FORM_PRODUCTS.SENTBOX === type && appID !== jotformersWeekApp) {
    frameURL.searchParams.set('filters', `submittedVia:eq=${appID}`);
  }
  if (FORM_PRODUCTS.SIGN === type) {
    frameURL.searchParams.set('signEmbed', '1');
  }
  if (shouldForceMobile() || forceMobile) {
    addForceMobileDeviceParam(frameURL.searchParams); // Mutates the URL
  }

  return frameURL.toString()
    .replace(/\+/g, ' '); // Spaces in queryStrings are interpreted as + sign :( https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#gotchas
};

export const generateContinueWithDraftURL = (frameURL, sessionID, token, continueKey) => {
  // const searchParams = handleSearchParams(new URLSearchParams(window.location.search));
  let continueWithDraftURL = frameURL;

  if (continueKey) {
    const url = new URL(frameURL);
    const hostname = `${url.protocol}//${url.hostname.replace(/^app\./, '')}`;
    url.searchParams.set('saclEmbed', '1');
    const draftURL = new URL(`${hostname}/draft/${continueKey}${url.search}`);
    continueWithDraftURL = draftURL;
  } else if (sessionID && token) {
    const searchParams = new URLSearchParams();
    searchParams.set('session', sessionID);
    searchParams.set('stoken', token);
    continueWithDraftURL += `&${searchParams.toString()}`;
  }

  // const queryString = simplifySearchParams(searchParams).toString();

  return continueWithDraftURL;
};

export const getProductItemProps = (items, itemID, itemType) => {
  const itemProps = items.find(i => {
    switch (itemType) {
      case ITEM_TYPES.TABLE_LINK:
      case ITEM_TYPES.REPORT_LINK:
      case ITEM_TYPES.SENTBOX_LINK:
        return i.resourceID === itemID && i.type === itemType;
      case undefined:
        return i.id === itemID || i.resourceID === itemID || i.buttonValue === itemID;
      default:
        return i.id === itemID;
    }
  });

  if (!itemProps) {
    // Accessing a button-valued form with formID (for scl links)
    if (itemType === ITEM_TYPES.FORM) {
      return items.find(i => i.buttonValue === itemID);
    }
    // Case when there is no sentbox element but clicking sentbox on 3-dot
    return items.find(i => i.id === itemID);
  }

  if (itemType === ITEM_TYPES.SENTBOX_LINK) {
    const { submissionCount } = items.find(i => i.id === itemID);
    return { ...itemProps, submissionCount };
  }

  return itemProps;
};

export const getSocialSharingUtmSources = (source, isDonationCheckout = false) => {
  switch (source) {
    case 'whatsapp':
      return isDonationCheckout ? 'donation-share-whatsapp' : 'whatsapp';
    case 'facebook':
      return isDonationCheckout ? 'donation-share-facebook' : 'facebook';
    case 'twitter':
      return isDonationCheckout ? 'donation-share-twitter' : 'twitter';
    case 'linkedin':
      return isDonationCheckout ? 'donation-share-linkedin' : 'linkedin';
    case 'nativeShare':
      return isDonationCheckout ? 'donation-native-share' : 'native-share';
    case 'copyLink':
      return isDonationCheckout ? 'donation-share-copy-link' : 'copy-link';
    default:
      return '';
  }
};

export const allSocialSharingUtmSources = [
  'copy-link',
  'native-share',
  'whatsapp',
  'facebook',
  'twitter',
  'linkedin',
  'donation-share-copy-link',
  'donation-native-share',
  'donation-share-whatsapp',
  'donation-share-facebook',
  'donation-share-twitter',
  'donation-share-linkedin'
];

export const getSocialMediaOptions = (shareMessage, publicLink, appID, icons, updatedAt, isDonationCheckout = false) => {
  return [
    {
      title: 'Whatsapp',
      icon: icons.whatsappIcon,
      action: () => (
        shareWhatsapp(`${t(shareMessage)}\n\n${getAppLinkWithUTM(publicLink, appID, getSocialSharingUtmSources('whatsapp', isDonationCheckout))}&time=${updatedAt}`)
      )
    },
    {
      title: 'Facebook',
      icon: icons.facebookIcon,
      action: () => shareFacebook(`${getAppLinkWithUTM(publicLink, appID, getSocialSharingUtmSources('facebook', isDonationCheckout))}&time=${updatedAt}`)
    },
    {
      title: 'X',
      icon: icons.twitterIcon,
      action: () => shareTwitter(`${t(shareMessage)}\n\n${getAppLinkWithUTM(publicLink, appID, getSocialSharingUtmSources('twitter', isDonationCheckout))}`)
    },
    {
      title: 'LinkedIn',
      icon: icons.linkedinIcon,
      action: () => shareLinkedin(`${getAppLinkWithUTM(publicLink, appID, getSocialSharingUtmSources('linkedin', isDonationCheckout))}&time=${updatedAt}`)
    }
  ].filter(Boolean);
};
