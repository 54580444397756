/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable quote-props */
export default [
  {
    "id": 10001,
    "name": "Education & Administration",
    "title": "Education & Administration",
    "slug": "education-administration",
    "canonical": "",
    "order": 1,
    "type": "type",
    "count": 2,
    "description": "Explore our tailored forms designed specifically for the education and administration sectors. Streamline processes like student registration, feedback collection, event planning, and administrative tasks with our easy-to-use form builder. Empower your institution with efficient and organized data management solutions, ensuring seamless operations and improved communication.",
    "pageDescription": "Explore our tailored forms designed specifically for the education and administration sectors. Streamline processes like student registration, feedback collection, event planning, and administrative tasks with our easy-to-use form builder. Empower your institution with efficient and organized data management solutions, ensuring seamless operations and improved communication.",
    "pageTitle": "Education & Administration",
    "metaTitle": "Education & Administration",
    "metaKeywords": "Education & Administration",
    "disclaimer": "",
    "featured": 0,
    "furtherDescription": "",
    "relatedCategories": "",
    "parent": "",
    "parentCategories": "",
    "schema": "",
    "total": 2,
    "hasSubCategoryTemplate": "",
    "url": "/agent-directory/category/education-administration",
    "color": "#FAD5B4"
  },
  {
    "id": 10002,
    "name": "Hospitality & Travel",
    "title": "Hospitality & Travel",
    "slug": "hospitality-travel",
    "canonical": "",
    "order": 2,
    "type": "type",
    "count": 2,
    "description": "Enhance guest experiences and streamline operations with our form solutions designed for the hospitality and travel industry. Our form builder empowers hotels, travel agencies, tour operators, and event planners to create customized forms for reservations, guest feedback, check-ins, room service requests, and much more. Simplify your booking processes, gather valuable customer insights, and ensure smooth communication with your guests.\n\nFrom personalized travel itineraries to event registration and feedback collection, our forms are adaptable to your unique needs, helping you deliver exceptional service and boost customer satisfaction. Whether you’re managing a boutique hotel, a large resort, or planning a travel event, our platform provides the tools you need to stay organized, save time, and focus on what matters most—creating memorable experiences for your clients. Discover how our flexible and user-friendly forms can transform your hospitality and travel operations, making every guest interaction more efficient and enjoyable.",
    "pageDescription": "Enhance guest experiences and streamline operations with our form solutions designed for the hospitality and travel industry. Our form builder empowers hotels, travel agencies, tour operators, and event planners to create customized forms for reservations, guest feedback, check-ins, room service requests, and much more. Simplify your booking processes, gather valuable customer insights, and ensure smooth communication with your guests.\n\n From personalized travel itineraries to event registration and feedback collection, our forms are adaptable to your unique needs, helping you deliver exceptional service and boost customer satisfaction. Whether you’re managing a boutique hotel, a large resort, or planning a travel event, our platform provides the tools you need to stay organized, save time, and focus on what matters most—creating memorable experiences for your clients. Discover how our flexible and user-friendly forms can transform your hospitality and travel operations, making every guest interaction more efficient and enjoyable.",
    "pageTitle": "Hospitality & Travel",
    "metaTitle": "Hospitality & Travel",
    "metaKeywords": "Hospitality & Travel",
    "disclaimer": "",
    "featured": 0,
    "furtherDescription": "",
    "relatedCategories": "",
    "parent": "",
    "parentCategories": "",
    "schema": "",
    "total": 2,
    "hasSubCategoryTemplate": "",
    "url": "/agent-directory/category/hospitality-travel",
    "color": "#BEEEC0"
  },
  {
    "id": 10003,
    "name": "Healthcare & Wellness",
    "title": "Healthcare & Wellness",
    "slug": "healthcare-wellness",
    "canonical": "",
    "order": 3,
    "type": "type",
    "count": 3,
    "description": "Optimize patient care and streamline administrative processes with our form solutions tailored for the healthcare and wellness sectors.\n\n Our form builder allows clinics, hospitals, wellness centers, and private practices to easily create and manage forms for patient intake, appointment scheduling, consent forms, feedback collection, and more.\n\n Enhance your patient experience by reducing wait times, minimizing paperwork, and ensuring accurate data collection.\n\n Our forms are designed to support HIPAA compliance and secure patient data management, providing peace of mind for both you and your patients.\n\n From telehealth consent forms to wellness assessments and follow-up surveys, our versatile forms empower your practice to deliver efficient and compassionate care.",
    "pageDescription": "Optimize patient care and streamline administrative processes with our form solutions tailored for the healthcare and wellness sectors.\n\n Our form builder allows clinics, hospitals, wellness centers, and private practices to easily create and manage forms for patient intake, appointment scheduling, consent forms, feedback collection, and more.\n\n Enhance your patient experience by reducing wait times, minimizing paperwork, and ensuring accurate data collection.\n\n Our forms are designed to support HIPAA compliance and secure patient data management, providing peace of mind for both you and your patients.\n\n From telehealth consent forms to wellness assessments and follow-up surveys, our versatile forms empower your practice to deliver efficient and compassionate care.",
    "pageTitle": "Healthcare & Wellness",
    "metaTitle": "Healthcare & Wellness",
    "metaKeywords": "Healthcare & Wellness",
    "disclaimer": "",
    "featured": 0,
    "furtherDescription": "",
    "relatedCategories": "",
    "parent": "",
    "parentCategories": "",
    "schema": "",
    "total": 3,
    "hasSubCategoryTemplate": "",
    "url": "/agent-directory/category/healthcare-wellness",
    "color": "#FAD5B4"
  },
  {
    "id": 10004,
    "name": "Food & Beverage",
    "title": "Food & Beverage",
    "slug": "food-beverage",
    "canonical": "",
    "order": 4,
    "type": "type",
    "count": 0,
    "description": "Food & Beverage",
    "pageDescription": "Food & Beverage",
    "pageTitle": "Food & Beverage",
    "metaTitle": "Food & Beverage",
    "metaKeywords": "Food & Beverage",
    "disclaimer": "",
    "featured": 0,
    "furtherDescription": "",
    "relatedCategories": "",
    "parent": "",
    "parentCategories": "",
    "schema": "",
    "total": 0,
    "hasSubCategoryTemplate": "",
    "url": "/agent-directory/category/food-beverage",
    "color": "#C3F0EB"
  },
  {
    "id": 10005,
    "name": "Retail & Commerce",
    "title": "Retail & Commerce",
    "slug": "retail-commerce",
    "canonical": "",
    "order": 5,
    "type": "type",
    "count": 2,
    "description": "Drive sales and enhance customer engagement with our form solutions tailored for the retail and commerce industry.\n\n Our form builder helps businesses create customized order forms, customer feedback surveys, product inquiries, and more, making it easy to gather essential information and streamline transactions.\n\n Whether you’re managing an online store, a brick-and-mortar shop, or a multi-channel retail operation, our forms simplify the way you handle orders, returns, and customer communication.\n\n With our user-friendly platform, you can effortlessly capture leads, run promotions, and collect valuable insights to optimize your sales strategy.\n\n Boost your business efficiency and deliver a seamless shopping experience by leveraging our adaptable and secure forms to meet the dynamic needs of today’s retail and commerce landscape.",
    "pageDescription": "Drive sales and enhance customer engagement with our form solutions tailored for the retail and commerce industry.\n\n Our form builder helps businesses create customized order forms, customer feedback surveys, product inquiries, and more, making it easy to gather essential information and streamline transactions.\n\n Whether you’re managing an online store, a brick-and-mortar shop, or a multi-channel retail operation, our forms simplify the way you handle orders, returns, and customer communication.\n\n With our user-friendly platform, you can effortlessly capture leads, run promotions, and collect valuable insights to optimize your sales strategy.\n\n Boost your business efficiency and deliver a seamless shopping experience by leveraging our adaptable and secure forms to meet the dynamic needs of today’s retail and commerce landscape.",
    "pageTitle": "Retail & Commerce",
    "metaTitle": "Retail & Commerce",
    "metaKeywords": "Retail & Commerce",
    "disclaimer": "",
    "featured": 0,
    "furtherDescription": "",
    "relatedCategories": "",
    "parent": "",
    "parentCategories": "",
    "schema": "",
    "total": 2,
    "hasSubCategoryTemplate": "",
    "url": "/agent-directory/category/retail-commerce",
    "color": "#BFBCFF"
  },
  {
    "id": 10006,
    "name": "Financial & Legal",
    "title": "Financial & Legal",
    "slug": "financial-legal",
    "canonical": "",
    "order": 6,
    "type": "type",
    "count": 0,
    "description": "Financial & Legal",
    "pageDescription": "Financial & Legal",
    "pageTitle": "Financial & Legal",
    "metaTitle": "Financial & Legal",
    "metaKeywords": "Financial & Legal",
    "disclaimer": "",
    "featured": 0,
    "furtherDescription": "",
    "relatedCategories": "",
    "parent": "",
    "parentCategories": "",
    "schema": "",
    "total": 0,
    "hasSubCategoryTemplate": "",
    "url": "/agent-directory/category/financial-legal",
    "color": "#B2E1EF"
  },
  {
    "id": 10007,
    "name": "Real Estate & Property",
    "title": "Real Estate & Property",
    "slug": "real-estate-property",
    "canonical": "",
    "order": 7,
    "type": "type",
    "count": 1,
    "description": "Transform the way you manage properties and connect with clients using our form solutions designed for the real estate and property industry.\n\n Our form builder enables real estate agents, property managers, and developers to create customized forms for property listings, rental applications, lead capture, open house registrations, client feedback, and more.\n\n Streamline your operations by automating tasks such as collecting client details, scheduling viewings, and processing maintenance requests.\n\n Enhance your client experience with easy-to-use forms that provide a professional and efficient way to gather and manage information.\n\n Whether you’re handling residential sales, commercial leases, or property management, our versatile forms help you stay organized, save time, and focus on closing deals and building client relationships.\n\n Elevate your real estate business with our secure, adaptable forms designed to meet the unique needs of the property industry.",
    "pageDescription": "Transform the way you manage properties and connect with clients using our form solutions designed for the real estate and property industry.\n\n Our form builder enables real estate agents, property managers, and developers to create customized forms for property listings, rental applications, lead capture, open house registrations, client feedback, and more.\n\n Streamline your operations by automating tasks such as collecting client details, scheduling viewings, and processing maintenance requests.\n\n Enhance your client experience with easy-to-use forms that provide a professional and efficient way to gather and manage information.\n\n Whether you’re handling residential sales, commercial leases, or property management, our versatile forms help you stay organized, save time, and focus on closing deals and building client relationships.\n\n Elevate your real estate business with our secure, adaptable forms designed to meet the unique needs of the property industry.",
    "pageTitle": "Real Estate & Property",
    "metaTitle": "Real Estate & Property",
    "metaKeywords": "Real Estate & Property",
    "disclaimer": "",
    "featured": 0,
    "furtherDescription": "",
    "relatedCategories": "",
    "parent": "",
    "parentCategories": "",
    "schema": "",
    "total": 1,
    "hasSubCategoryTemplate": "",
    "url": "/agent-directory/category/real-estate-property",
    "color": "#FFDF8C"
  },
  {
    "id": 10008,
    "name": "Logistics & Transportation",
    "title": "Logistics & Transportation",
    "slug": "logistics-transportation",
    "canonical": "",
    "order": 8,
    "type": "type",
    "count": 0,
    "description": "Logistics & Transportation",
    "pageDescription": "Logistics & Transportation",
    "pageTitle": "Logistics & Transportation",
    "metaTitle": "Logistics & Transportation",
    "metaKeywords": "Logistics & Transportation",
    "disclaimer": "",
    "featured": 0,
    "furtherDescription": "",
    "relatedCategories": "",
    "parent": "",
    "parentCategories": "",
    "schema": "",
    "total": 0,
    "hasSubCategoryTemplate": "",
    "url": "/agent-directory/category/logistics-transportation",
    "color": "#FAD5B4"
  },
  {
    "id": 10009,
    "name": "Corporate & Professional Services",
    "title": "Corporate & Professional Services",
    "slug": "corporate-professional-services",
    "canonical": "",
    "order": 9,
    "type": "type",
    "count": 1,
    "description": "Enhance your business operations and client interactions with our form solutions tailored for the corporate and professional services sectors.\n\n Our form builder empowers consulting firms, legal practices, financial services, HR departments, and other professional service providers to create customized forms for client intake, service requests, feedback collection, contracts, surveys, and more.\n\n Simplify complex workflows, improve data accuracy, and save time by automating tasks such as document submission, appointment scheduling, and client communication.\n\n With our secure and compliant forms, you can confidently manage sensitive information, ensuring your business maintains the highest standards of professionalism and data privacy.\n\n Whether you're onboarding new clients, collecting project details, or gathering feedback, our adaptable forms help you deliver efficient, organized, and client-focused services.\n\n Streamline your processes and elevate your service delivery with our versatile form solutions designed to meet the unique needs of corporate and professional services.",
    "pageDescription": "Enhance your business operations and client interactions with our form solutions tailored for the corporate and professional services sectors.\n\n Our form builder empowers consulting firms, legal practices, financial services, HR departments, and other professional service providers to create customized forms for client intake, service requests, feedback collection, contracts, surveys, and more.\n\n Simplify complex workflows, improve data accuracy, and save time by automating tasks such as document submission, appointment scheduling, and client communication.\n\n With our secure and compliant forms, you can confidently manage sensitive information, ensuring your business maintains the highest standards of professionalism and data privacy.\n\n Whether you're onboarding new clients, collecting project details, or gathering feedback, our adaptable forms help you deliver efficient, organized, and client-focused services.\n\n Streamline your processes and elevate your service delivery with our versatile form solutions designed to meet the unique needs of corporate and professional services.",
    "pageTitle": "Corporate & Professional Services",
    "metaTitle": "Corporate & Professional Services",
    "metaKeywords": "Corporate & Professional Services",
    "disclaimer": "",
    "featured": 0,
    "furtherDescription": "",
    "relatedCategories": "",
    "parent": "",
    "parentCategories": "",
    "schema": "",
    "total": 1,
    "hasSubCategoryTemplate": "",
    "url": "/agent-directory/category/corporate-professional-services",
    "color": "#FAD5B4"
  },
  {
    "id": 10010,
    "name": "Customer Service & Support",
    "title": "Customer Service & Support",
    "slug": "customer-service-support",
    "canonical": "",
    "order": 10,
    "type": "type",
    "count": 1,
    "description": "Improve customer satisfaction and streamline your support operations with our form solutions designed for customer service and support teams.\n\n Our form builder allows you to create customized forms for customer inquiries, support tickets, feedback collection, complaint handling, and satisfaction surveys, ensuring that you capture all the necessary details to resolve issues quickly and effectively.\n\n Enhance your customer experience by providing easy-to-use, accessible forms that help you stay organized and responsive, reducing response times and improving communication.\n\n Automate routine tasks such as routing requests, collecting customer information, and tracking service performance, freeing up your team to focus on delivering exceptional service.\n\n Whether you’re managing a call center, a help desk, or an online support team, our secure and adaptable forms support your mission to provide prompt, professional, and personalized assistance to your customers.\n\n Empower your customer service and support operations with our flexible form solutions designed to meet the dynamic needs of your business.",
    "pageDescription": "Improve customer satisfaction and streamline your support operations with our form solutions designed for customer service and support teams.\n\n Our form builder allows you to create customized forms for customer inquiries, support tickets, feedback collection, complaint handling, and satisfaction surveys, ensuring that you capture all the necessary details to resolve issues quickly and effectively.\n\n Enhance your customer experience by providing easy-to-use, accessible forms that help you stay organized and responsive, reducing response times and improving communication.\n\n Automate routine tasks such as routing requests, collecting customer information, and tracking service performance, freeing up your team to focus on delivering exceptional service.\n\n Whether you’re managing a call center, a help desk, or an online support team, our secure and adaptable forms support your mission to provide prompt, professional, and personalized assistance to your customers.\n\n Empower your customer service and support operations with our flexible form solutions designed to meet the dynamic needs of your business.",
    "pageTitle": "Customer Service & Support",
    "metaTitle": "Customer Service & Support",
    "metaKeywords": "Customer Service & Support",
    "disclaimer": "",
    "featured": 0,
    "furtherDescription": "",
    "relatedCategories": "",
    "parent": "",
    "parentCategories": "",
    "schema": "",
    "total": 1,
    "hasSubCategoryTemplate": "",
    "url": "/agent-directory/category/customer-service-support",
    "color": "#FFD8CF"
  },
  {
    "id": 10011,
    "name": "Non-profit & Community Services",
    "title": "Non-profit & Community Services",
    "slug": "non-profit-community-services",
    "canonical": "",
    "order": 11,
    "type": "type",
    "count": 0,
    "description": "Empower your mission and streamline your operations with our form solutions tailored for non-profit and community service organizations.\n\n Our form builder helps you create customized forms for volunteer registrations, donation collection, event sign-ups, membership applications, surveys, and feedback, allowing you to connect more effectively with your supporters and community members.\n\n Simplify your workflows by automating administrative tasks such as managing volunteers, collecting participant information, and tracking donations, freeing up valuable time and resources to focus on your cause.\n\n Enhance your outreach efforts with accessible, user-friendly forms that make it easy for people to get involved, contribute, and stay informed about your initiatives.\n\n Whether you’re running a charity, organizing community events, or managing a non-profit program, our adaptable and secure forms help you manage data efficiently and maintain the highest standards of transparency and engagement.\n\n Support your organization’s goals with our versatile form solutions designed to meet the unique needs of non-profit and community service sectors.",
    "pageDescription": "Empower your mission and streamline your operations with our form solutions tailored for non-profit and community service organizations.\n\n Our form builder helps you create customized forms for volunteer registrations, donation collection, event sign-ups, membership applications, surveys, and feedback, allowing you to connect more effectively with your supporters and community members.\n\n Simplify your workflows by automating administrative tasks such as managing volunteers, collecting participant information, and tracking donations, freeing up valuable time and resources to focus on your cause.\n\n Enhance your outreach efforts with accessible, user-friendly forms that make it easy for people to get involved, contribute, and stay informed about your initiatives.\n\n Whether you’re running a charity, organizing community events, or managing a non-profit program, our adaptable and secure forms help you manage data efficiently and maintain the highest standards of transparency and engagement.\n\n Support your organization’s goals with our versatile form solutions designed to meet the unique needs of non-profit and community service sectors.",
    "pageTitle": "Non-profit & Community Services",
    "metaTitle": "Non-profit & Community Services",
    "metaKeywords": "Non-profit & Community Services",
    "disclaimer": "",
    "featured": 0,
    "furtherDescription": "",
    "relatedCategories": "",
    "parent": "",
    "parentCategories": "",
    "schema": "",
    "total": 0,
    "hasSubCategoryTemplate": "",
    "url": "/agent-directory/category/non-profit-community-services",
    "color": "#C3F0EB"
  },
  {
    "id": 10012,
    "name": "Creative & Media",
    "title": "Creative & Media",
    "slug": "creative-media",
    "canonical": "",
    "order": 12,
    "type": "type",
    "count": 0,
    "description": "Unleash your creativity and streamline your projects with our form solutions designed for the creative and media industries.\n\n Our form builder empowers artists, designers, photographers, content creators, marketing agencies, and media professionals to create customized forms for project briefs, client feedback, collaboration requests, talent applications, and more.\n\n Simplify your workflow by automating tasks such as collecting creative briefs, managing client approvals, tracking project progress, and gathering feedback, allowing you to focus on what you do best—creating compelling content.\n\n Enhance your client and team interactions with professional, easy-to-use forms that help you stay organized, communicate effectively, and deliver projects on time.\n\n Whether you’re producing a video, designing a campaign, or managing a creative project, our adaptable forms provide the flexibility and functionality you need to keep your creative processes running smoothly.\n\n Elevate your creative and media projects with our secure, versatile form solutions designed to meet the unique needs of your industry.",
    "pageDescription": "Unleash your creativity and streamline your projects with our form solutions designed for the creative and media industries.\n\n Our form builder empowers artists, designers, photographers, content creators, marketing agencies, and media professionals to create customized forms for project briefs, client feedback, collaboration requests, talent applications, and more.\n\n Simplify your workflow by automating tasks such as collecting creative briefs, managing client approvals, tracking project progress, and gathering feedback, allowing you to focus on what you do best—creating compelling content.\n\n Enhance your client and team interactions with professional, easy-to-use forms that help you stay organized, communicate effectively, and deliver projects on time.\n\n Whether you’re producing a video, designing a campaign, or managing a creative project, our adaptable forms provide the flexibility and functionality you need to keep your creative processes running smoothly.\n\n Elevate your creative and media projects with our secure, versatile form solutions designed to meet the unique needs of your industry.",
    "pageTitle": "Creative & Media",
    "metaTitle": "Creative & Media",
    "metaKeywords": "Creative & Media",
    "disclaimer": "",
    "featured": 0,
    "furtherDescription": "",
    "relatedCategories": "",
    "parent": "",
    "parentCategories": "",
    "schema": "",
    "total": 0,
    "hasSubCategoryTemplate": "",
    "url": "/agent-directory/category/creative-media",
    "color": "#FAD5B4"
  },
  {
    "id": 10013,
    "name": "Information Technology",
    "title": "Information Technology",
    "slug": "information-technology",
    "canonical": "",
    "order": 13,
    "type": "type",
    "count": 0,
    "description": "Information Technology",
    "pageDescription": "Information Technology",
    "pageTitle": "Information Technology",
    "metaTitle": "Information Technology",
    "metaKeywords": "Information Technology",
    "disclaimer": "",
    "featured": 0,
    "furtherDescription": "",
    "relatedCategories": "",
    "parent": "",
    "parentCategories": "",
    "schema": "",
    "total": 0,
    "hasSubCategoryTemplate": "",
    "url": "/agent-directory/category/information-technology",
    "color": "#FAD5B4"
  }
];
