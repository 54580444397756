import {
  takeEvery, put, select, call, spawn
} from 'redux-saga/effects';
import { t } from '@jotforminc/translation';
import { PaymentActions } from '@jotforminc/payment-settings-editor';
import * as ACTION_TYPES from '../actionTypes';
import SELECTORS from '../selectors';
import { safeWorker } from '../utils';
import productDetailsAction from './productDetails';

const PAYMENT_SETTINGS_EDITOR = {
  PRODUCT: '@PRODUCTS/UPDATE_PRODUCT',
  PAYMENT_SETTINGS: '@PRODUCT_LIST/UPDATE_PRODUCT_LIST_SETTINGS'
};

function* watchProductListActions({ payload }) {
  const {
    products = [],
    itemID: targetItemID
  } = payload;

  const [selectedItemID] = yield select(SELECTORS.getSelectedPortalItems);

  let itemID = targetItemID || selectedItemID;
  if (!itemID) {
    const allProductList = yield select(SELECTORS.getProductListItems);
    const firstProductItemEl = document.querySelector('div[type="PRODUCT_LIST"]');

    if (firstProductItemEl) {
      firstProductItemEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      itemID = allProductList[0]?.id;
    }
  }

  const itemProps = yield select(SELECTORS.getItemWithDefaults(itemID));
  const prop = {
    ...itemProps,
    products
  };

  yield put({ type: ACTION_TYPES.UPDATE_ITEM_PROP.WITHOUT_DEBOUNCE, payload: { itemID, prop } });
}

function* watchAddProductListItem({ payload }) {
  const { itemID, formID, data = {} } = payload;

  const { products: _products = [] } = yield select(SELECTORS.getItemWithDefaults(itemID));

  // Please don't spread. Talk to burak@phx
  const getDefaultProductData = () => ({
    name: t('Product Name'),
    description: t('Product Description'),
    images: JSON.stringify(['https://cdn.jotfor.ms/assets/img/payments/sample_image-4.png']),
    options: JSON.stringify([{
      type: 'quantity', properties: '1\n2\n3\n4\n5\n6\n7\n8\n9\n10', name: 'Quantity', defaultQuantity: '', specialPricing: false, specialPrices: '', expanded: false
    }]),
    price: 10,
    fitImageToCanvas: 'Yes'
  });

  const productData = { ...getDefaultProductData(), ...data };
  const product = yield call(PaymentActions.createProduct, formID, productData);

  const products = [..._products, product];

  yield put({ type: ACTION_TYPES.PRODUCT_LIST_CHANGE, payload: { itemID, products, type: PAYMENT_SETTINGS_EDITOR.PRODUCT } });

  yield put({ type: ACTION_TYPES.ADD_PRODUCT_LIST_ITEM.SUCCESS });
}

function* watchUpdateProductListItem({ payload }) {
  const {
    itemID, formID, productIndex, prop, value, productID: _productID
  } = payload;

  const { products: _products = [] } = yield select(SELECTORS.getItemWithDefaults(itemID));

  const productID = _productID || _products[productIndex].pid;

  const products = _products.map(p => {
    return parseInt(p.pid, 10) === parseInt(productID, 10) ? ({ ...p, [prop]: value }) : p;
  });

  yield put({ type: ACTION_TYPES.PRODUCT_LIST_CHANGE, payload: { itemID, products } });

  yield call(PaymentActions.updateProduct, formID, productID, { [prop]: value });

  yield put({ type: ACTION_TYPES.UPDATE_PRODUCT_LIST_ITEM.SUCCESS });
}

function* watchDeleteProductListItem({ payload }) {
  const {
    itemID, formID, productID
  } = payload;

  // delete active product
  yield put({ type: ACTION_TYPES.SET_ACTIVE_PRODUCT, payload: {} });

  const response = yield call(PaymentActions.removeProduct, formID, productID);

  yield put({ type: ACTION_TYPES.PRODUCT_LIST_CHANGE, payload: { itemID, products: response } });

  yield put({ type: ACTION_TYPES.UPDATE_PRODUCT_LIST_ITEM.SUCCESS });
}

export default function* () {
  yield takeEvery(ACTION_TYPES.PRODUCT_LIST_CHANGE, watchProductListActions);
  yield takeEvery(ACTION_TYPES.ADD_PRODUCT_LIST_ITEM.UNDOABLE, safeWorker(watchAddProductListItem));
  yield takeEvery(ACTION_TYPES.UPDATE_PRODUCT_LIST_ITEM.UNDOABLE, safeWorker(watchUpdateProductListItem));
  yield takeEvery(ACTION_TYPES.DELETE_PRODUCT_LIST_ITEM.UNDOABLE, safeWorker(watchDeleteProductListItem));
  yield spawn(productDetailsAction);
}
