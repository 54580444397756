import {
  takeLatest, put, call, select
} from 'redux-saga/effects';
import { navigate } from '@reach/router';

import * as NAVIGATION from '../../utils/navigation';
import * as NAVIGATION_CONSTANTS from '../../constants/navigation';

import { safeWorker } from '../utils';
import { NAVIGATE_TO, SET_ACTIVE_ITEM } from '../actionTypes';
import * as ACTIONS from '../actionCreators';
import SELECTORS from '../selectors';
import { RightPanelModes } from '../../modules/Builder/components/HomePage/constants';
import { APP_MODES, APP_PREVIEW_URL_HASH, UI_PROPS } from '../../constants';
import { getIsHomepage } from '../selectors/portalSelectors';

const isTemplatePreviewEnv = (window?.location?.href || '').includes('/app-templates');

const SET_ACTIVE_ITEM_ACTIONS = {
  [NAVIGATION_CONSTANTS.DESTINATION_TYPES.PAGE]: ACTIONS.setActivePageAction,
  [NAVIGATION_CONSTANTS.DESTINATION_TYPES.SCREEN]: ACTIONS.setActiveScreen,
  [NAVIGATION_CONSTANTS.DESTINATION_TYPES.PRODUCT]: ACTIONS.setActiveFormProduct,
  [NAVIGATION_CONSTANTS.DESTINATION_TYPES.BUILDER_PAGE]: ACTIONS.setActiveBuilderPage
};

const ITEM_ANIMATION_TYPES = {
  [NAVIGATION_CONSTANTS.DESTINATION_TYPES.PAGE]: NAVIGATION_CONSTANTS.ANIMATION_TYPES.PAGE_CHANGE,
  [NAVIGATION_CONSTANTS.DESTINATION_TYPES.SCREEN]: NAVIGATION_CONSTANTS.ANIMATION_TYPES.LOCATION,
  [NAVIGATION_CONSTANTS.DESTINATION_TYPES.PRODUCT]: NAVIGATION_CONSTANTS.ANIMATION_TYPES.LOCATION,
  [NAVIGATION_CONSTANTS.DESTINATION_TYPES.BUILDER_PAGE]: NAVIGATION_CONSTANTS.ANIMATION_TYPES.NONE
};

function* handleFormProductNavigation(payload) {
  const destination = NAVIGATION.generateAppURL({
    includeQs: true,
    ...payload
  });
  yield call(navigate, destination, { replace: isTemplatePreviewEnv });
}

function* watchNavigateToFormProduct(payload) {
  const { productType } = payload;

  const isPreview = NAVIGATION.getLocationHash() === APP_PREVIEW_URL_HASH;
  const messagePrefix = NAVIGATION_CONSTANTS.TRACK_MESSAGE_PREFIX[productType];
  const trackMessage = `${messagePrefix}Accessed`;
  const trackTarget = NAVIGATION.getTrackTarget(payload);

  yield put(ACTIONS.setRouterAnimationTypeAction(NAVIGATION_CONSTANTS.ANIMATION_TYPES.LOCATION));
  if (!isPreview) {
    yield put(ACTIONS.trackEventAction({ action: trackMessage, trackTarget }));
  }
  yield handleFormProductNavigation(payload);
}

export function* handleScreenNavigation(payload) {
  const destination = NAVIGATION.generateAppURL({
    includeQs: true,
    ...payload
  });
  // Track event is missing for other screens for now
  yield call(navigate, destination, { replace: isTemplatePreviewEnv });
}

function* watchNavigateToScreen(payload) {
  yield put(ACTIONS.setRouterAnimationTypeAction(NAVIGATION_CONSTANTS.ANIMATION_TYPES.LOCATION));
  yield handleScreenNavigation(payload);
}

function* watchNavigateToPage(payload) {
  const { pageID, animationType = NAVIGATION_CONSTANTS.ANIMATION_TYPES.PAGE_CHANGE } = payload;
  const isHomepage = yield select(getIsHomepage(pageID));
  const activePageID = yield select(SELECTORS.getActivePageID);

  const destination = NAVIGATION.generateAppURL({
    pageID: isHomepage ? 'homepage' : pageID
  });

  yield put(ACTIONS.setRouterAnimationTypeAction(activePageID === pageID ? NAVIGATION_CONSTANTS.ANIMATION_TYPES.NONE : animationType));
  yield call(navigate, destination, { replace: isTemplatePreviewEnv });
  yield put(ACTIONS.trackEventAction({ action: 'navigatedToPage', target: { page: pageID } }));
  yield put(ACTIONS.trackEventAction({ action: 'pageVisited', target: { pageID } }));
}

function* handleGoBackInPublicApp() {
  const activeFormProduct = yield select(SELECTORS.getActiveFormProduct);
  const activePageID = yield select(SELECTORS.getActivePageID);

  const isHomepage = yield select(SELECTORS.getIsHomepage(activePageID));

  const returnToPage = activePageID && !isHomepage;

  const shouldEraseQueryParams = activeFormProduct && activeFormProduct.includes('/continue');

  const destination = NAVIGATION.generateAppURL({
    forLogout: shouldEraseQueryParams, // Also erases other params. Maybe we can keep them.
    ...(returnToPage && { pageID: activePageID })
  });

  yield put(ACTIONS.trackEventAction({ action: 'backToApp' }));
  yield call(navigate, destination, { replace: isTemplatePreviewEnv });
  yield put(ACTIONS.setRouterAnimationTypeAction(NAVIGATION_CONSTANTS.ANIMATION_TYPES.NONE));
}

function* handleGoBackInBuilder() {
  const destination = NAVIGATION.getBackURL();
  yield call(navigate, destination, { replace: isTemplatePreviewEnv });
}

const BACK_NAVIGATIONS = {
  [APP_MODES.builder]: handleGoBackInBuilder,
  [APP_MODES.public]: handleGoBackInPublicApp
};

function* watchNavigateBack() {
  const appMode = yield select(SELECTORS.getAppModeSelector);
  yield BACK_NAVIGATIONS[appMode]();
}

function* watchNavigateToSpecialPage(payload) {
  const { pageID } = payload;
  const destination = NAVIGATION.getSpecialPageDestination(pageID);
  const newPayload = NAVIGATION.prepareSpecialPageNavigationProps(destination, payload);
  // eslint-disable-next-line no-use-before-define
  yield navigationMethods[destination](newPayload);
}

function* handleBuilderTabNavigation(payload) {
  const { tab, subTab = '' } = payload;

  const destination = NAVIGATION.generateBuilderAppURL({ tab, subTab, includeQs: true });
  yield put(ACTIONS.trackEventAction({ action: `${tab}TabClicked` }));
  yield call(navigate, destination, { replace: isTemplatePreviewEnv });
}

function* handleBuilderSubTabNavigation(payload) {
  const { subTab } = payload;
  const isRightPanelOpen = yield select(SELECTORS.isRightPanelOpenSelector);
  const currentSubTab = yield select(SELECTORS.getCurrentSubTab);
  const tab = yield select(SELECTORS.getCurrentStep);
  const destination = NAVIGATION.generateBuilderAppURL({ tab, subTab, includeQs: true });
  if (subTab !== currentSubTab && isRightPanelOpen) {
    const mode = (subTab === NAVIGATION_CONSTANTS.SETTINGS_SUBTABS.SPLASH) ? RightPanelModes.SPLASH_SCREEN : RightPanelModes.APP_ICON;
    if (subTab === NAVIGATION_CONSTANTS.SETTINGS_SUBTABS.DETAILS) {
      yield put(ACTIONS.setRightPanelModeAction(''));
      yield put(ACTIONS.toggleRightPanelAction(false));
    }
    yield put(ACTIONS.setRightPanelModeAction(mode));
  }
  yield put(ACTIONS.trackEventAction({ action: `${subTab}TabClicked` })); // Track event is the same with tab navigation?

  yield call(navigate, destination, { replace: isTemplatePreviewEnv });
}

const getBuilderNavigationHandler = (tab, subTab) => {
  switch (true) {
    case tab !== '':
      return handleBuilderTabNavigation;
    case subTab !== '':
      return handleBuilderSubTabNavigation;
    default:
      return () => {};
  }
};

function* watchNavigationsInBuilder(payload) {
  const { tab = '', subTab = '' } = payload;
  const handleBuilderNavigation = getBuilderNavigationHandler(tab, subTab);
  yield handleBuilderNavigation(payload);
}

const navigationMethods = {
  [NAVIGATION_CONSTANTS.DESTINATION_TYPES.BACK]: watchNavigateBack,
  [NAVIGATION_CONSTANTS.DESTINATION_TYPES.SCREEN]: watchNavigateToScreen,
  [NAVIGATION_CONSTANTS.DESTINATION_TYPES.PRODUCT]: watchNavigateToFormProduct,
  [NAVIGATION_CONSTANTS.DESTINATION_TYPES.PAGE]: watchNavigateToPage,
  [NAVIGATION_CONSTANTS.DESTINATION_TYPES.SPECIAL_PAGE]: watchNavigateToSpecialPage,
  [NAVIGATION_CONSTANTS.DESTINATION_TYPES.BUILDER_PAGE]: watchNavigationsInBuilder
};

function* navigationInterface(action) {
  const { to } = action.payload;

  yield navigationMethods[to](action.payload);
}

function* clearPreviousActiveItem(itemType, itemName) {
  const activeScreen = yield select(SELECTORS.getActiveScreen);
  const activeFormProduct = yield select(SELECTORS.getActiveFormProduct);
  const activeProductDetail = yield select(SELECTORS.getActiveProductDetails);

  if (activeScreen.length > 0) {
    yield put(ACTIONS.setActiveScreen(''));
  }

  if (activeFormProduct.length > 0) {
    yield put(ACTIONS.setActiveFormProduct(''));
  }

  if (activeProductDetail?.itemID?.length > 0 && itemName !== NAVIGATION_CONSTANTS.SPECIAL_PAGES.PRODUCT) { // Product list product
    yield put(ACTIONS.setUIProp(UI_PROPS.activeProductDetail, {}));
  }
}

function* watchActiveItem() {
  const { itemType, itemName } = NAVIGATION.getItemFromURL();
  const currentAnimationType = yield select(SELECTORS.getRouterAnimationType);
  const renderer = itemType === NAVIGATION_CONSTANTS.DESTINATION_TYPES.SPECIAL_PAGE
    ? NAVIGATION.getSpecialPageDestination(itemName)
    : itemType;
  const setActiveItem = SET_ACTIVE_ITEM_ACTIONS[renderer];
  const animationType = ITEM_ANIMATION_TYPES[renderer];

  yield clearPreviousActiveItem(itemType, itemName);
  yield put(setActiveItem(itemName));

  if (currentAnimationType === NAVIGATION_CONSTANTS.ANIMATION_TYPES.INITIAL) { // For the first navigation after refresh.
    yield put(ACTIONS.setRouterAnimationTypeAction(animationType));
  }
}

export default function* () {
  yield takeLatest(NAVIGATE_TO, navigationInterface);
  yield takeLatest(SET_ACTIVE_ITEM, safeWorker(watchActiveItem));
}
