import React, { useState, useEffect } from 'react';
import {
  func, arrayOf, object, string
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import Loader from '../../../Loader';
import Infobar from './InfoBar';

import './style.scss';
import { getDeviceType } from '../../getDeviceType';

import SimilarTemplatesItem from './SimilarTemplatesItem';

const ApprovalTemplatePreviewContent = ({
  template, categoryData, onTemplateClone, username,
  similarTemplates, setModalContentLoading, modalBodyRef, frameSrc
}) => {
  const [viewMode, setViewMode] = useState('approval');
  const [resourcesLoading, setResourcesLoading] = useState({ approval: false, form: false });

  const templateType = frameSrc.split('/')[1];
  const { id = '', source_snapshot: sourceSnapshot = '{}' } = template;

  useEffect(() => {
    if (!resourcesLoading.approval || !resourcesLoading.form) {
      setModalContentLoading(true);
    }
  }, [viewMode]);

  useEffect(() => {
    if (resourcesLoading[viewMode]) {
      setModalContentLoading(false);
    }
  }, [resourcesLoading]);

  useEffect(() => {
    setResourcesLoading({ approval: false, form: false });
  }, [template]);

  const { description = '' } = template;

  const handleChangeViewMode = newViewMode => {
    setViewMode(newViewMode);
    if (global?.standaloneTemplatesLogger) {
      global.standaloneTemplatesLogger({
        actor: username,
        action: `${getDeviceType()}:${newViewMode}-preview`
      });
    }
  };

  const pageIsReady = Object.keys(categoryData).length > 0;

  const viewOptionButtons = [{ optionKey: 'approval', prettyText: 'APPROVAL FLOW' }, { optionKey: 'form', prettyText: 'FORM' }];
  return (
    <div className="jfModal-content">
      <div className="modal-template-area">
        <div className="viewOptionButtons">
          {viewOptionButtons.map(optionButton => {
            const { prettyText, optionKey } = optionButton;
            return (
              <button
                type='button'
                key={optionKey}
                onClick={() => handleChangeViewMode(optionKey, username)}
                className={`view-option-button${viewMode === optionKey ? ' active' : ''}`}
              >
                {t(prettyText)}
              </button>
            );
          })}
        </div>
        <div className={`template-area ${viewMode}`}>
          <Loader />
          {
            pageIsReady && (
              <>
                <object
                  type="text/html"
                  id="approval-preview"
                  data={`/${templateType}/standalone/preview/approval/${id}?id=${id}`}
                  onLoad={() => setResourcesLoading({ ...resourcesLoading, approval: true })}
                />
                <object
                  type="text/html"
                  id="form-preview"
                  onLoad={() => setResourcesLoading({ ...resourcesLoading, form: true })}
                  data={`/${templateType}/standalone/preview/form/${JSON.parse(sourceSnapshot).id}?id=${id}`}
                />
              </>
            )
          }
        </div>
      </div>
      {
        (Object.keys(template).length > 0) && (
          <div className='content-area'>
            {!isEnterprise() && (
              <>
                <Infobar
                  templateType={templateType}
                  template={template}
                  categoryData={categoryData}
                />
                <div className='jfWizard-modal-detail'>
                  <div className='description-area' dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              </>
            )}
            <SimilarTemplatesItem
              skeletonCount={2}
              parentTemplateID={id}
              templateType={templateType}
              modalBodyRef={modalBodyRef}
              categoryData={categoryData}
              onTemplateClone={onTemplateClone}
              similarTemplates={similarTemplates}
              setModalContentLoading={setModalContentLoading}
              customOnSelectTemplate={() => setResourcesLoading({ approval: false, form: false })}
            />
          </div>
        )
      }
    </div>
  );
};

ApprovalTemplatePreviewContent.defaultProps = {
  template: {},
  frameSrc: '/',
  username: '',
  categoryData: null,
  similarTemplates: [],
  modalBodyRef: undefined,
  onTemplateClone: () => { }
};

ApprovalTemplatePreviewContent.propTypes = {
  template: object,
  username: string,
  frameSrc: string,
  categoryData: object,
  modalBodyRef: object,
  onTemplateClone: func,
  similarTemplates: arrayOf(object),
  setModalContentLoading: func.isRequired
};

export default ApprovalTemplatePreviewContent;
