import { IconProductTeamsBasicFilled, IconBuildings, IconUserFilled } from '@jotforminc/svg-icons';
import type { CONNECTION_GET_SANDBOX_TYPES } from '@jotforminc/payment-gateways';

export const SCROLLABLE_CONTAINER_CLASSLIST = {
  FORM: '.rightPanelContent',
  APP: 'div[data-tabid="General"]'
};

export const OWNER_INDICATOR_CONFIG = {
  user: {
    Icon: IconUserFilled,
    colorClass: 'color-yellow-400'
  },
  team: {
    Icon: IconProductTeamsBasicFilled,
    colorClass: 'color-blue-400'
  },
  org: {
    Icon: IconBuildings,
    colorClass: 'color-orange-400'
  }
};

export const MODE_INDICATORS: Array<{
  sandbox: CONNECTION_GET_SANDBOX_TYPES,
  text: string,
  bgColor: string
}> = [
  {
    sandbox: '0',
    text: 'Live',
    bgColor: 'bg-blue-500'
  },
  {
    sandbox: '1',
    text: 'Test',
    bgColor: 'bg-yellow-500'
  },
  {
    sandbox: '2',
    text: 'Dev',
    bgColor: 'bg-yellow-500'
  }
];

export const PLUG_NOCONNECTION = {
  iconClass: 'color-red-500',
  btnClass: 'bg-red-200 hover:bg-red-200'
};

export const CONNECTION_STATUSES = {
  0: {
    title: 'Connection valid',
    titleClass: '',
    descriptionClass: ''
  },
  1: {
    title: 'Connection attached!',
    titleClass: '',
    descriptionClass: ''
  },
  2: {
    title: 'Connection deleted',
    titleClass: '',
    descriptionClass: ''
  },
  3: {
    title: 'Connection failed!',
    titleClass: 'color-red-500 font-normal text-xs',
    descriptionClass: 'color-gray-600 mt-1 font-normal text-xs'
  },
  5: {
    title: 'Connection disabled!',
    titleClass: '',
    descriptionClass: ''
  },
  99: {
    title: '',
    titleClass: '',
    descriptionClass: ''
  }
};
