import React, {
  useEffect, useState, useRef, useMemo, forwardRef, useImperativeHandle
} from 'react';
import {
  arrayOf, bool, func, objectOf, oneOfType, shape, string
} from 'prop-types';
import Styled, { ThemeProvider } from 'styled-components';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import isEqual from 'lodash/isEqual';
import orderByFn from 'lodash/orderBy';
import { propTypes } from '@jotforminc/constants';

import { SelectionGroup as UIKitSelectionGroup, Hooks } from '@jotforminc/uikit';
import { EmptyList, NotFoundList } from '@jotforminc/empty-list';
import { Feature, FeatureToggles } from '@jotforminc/feature-toggle';
import { arrayMove } from '@jotforminc/utils';
import { theme as defaultTheme } from './helpers/defaultTheme';

import ControlBar from './ControlBar';
import { fuseSearch } from './utils'; // Don't import this from utils/data it increases dependency size
import { FormLoadingSkeleton } from './FormLoadingSkeleton';
import { useInfiniteFormLoading } from './hooks';

const SelectionGroup = Styled(forwardRef((props, ref) => <UIKitSelectionGroup {...props} ref={ref} />))`
  .itemCheckbox { display: ${p => (p.isMultiSelect ? 'block' : 'none')} }
`;

const DefaultDataProvider = ({ allItems, searchKeys, fuseOptions }) => ({
  onSearch: async searchTerm => fuseSearch(allItems, searchTerm, searchKeys, fuseOptions)
});

const SmartList = forwardRef(({
  features,
  isMultiSelect,
  items: allItems,
  defaultValue,
  onSelectionChange,
  DataProvider,
  showEmptyListPlaceholder,
  emptyListPlaceholder: EmptyListPlaceholder,
  showNotFoundListPlaceholder,
  notFoundListPlaceholder: NotFoundListPlaceholder,
  ListWrapper,
  ItemComponent,
  ItemContainer,
  ContainerRenderer,
  searchKeys,
  searchPlaceholder,
  bulkActions,
  t,
  formID,
  theme,
  isSortable,
  SortableProps,
  isItemEditable,
  otherControlBarItems,
  listTitle,
  listDescription,
  showFavorite,
  showSelectAll,
  showSelectedItemCount,
  showFormId,
  isNewBranding,
  submissionsKeyword,
  fuseOptions,
  customItemsList,
  disabledHoverText,
  disableDefaultDocument,
  ControlBarRenderer,
  preservedItems,
  productType,
  showPreview,
  isFormPickerInfiniteLoadingEnabled,
  fetchInfiniteFormsCallback
}, ref) => {
  const selectionGroupRef = useRef();

  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItems] = useState(allItems);
  const preparedDefultValue = Array.isArray(defaultValue) ? defaultValue : [defaultValue];
  const [selectedItems, setSelectedItems] = useState(preparedDefultValue);
  const hasAnyItem = isFormPickerInfiniteLoadingEnabled ? items.length > 0 : allItems.length > 0;
  const hasItemsToShow = items.length > 0;
  const selectedItemCount = Array.isArray(selectedItems) ? selectedItems.filter(Boolean).length : 0;
  const isAllSelected = hasAnyItem && (selectedItemCount === allItems.length);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [filterItems, setFilterItem] = useState([]);

  const {
    loading, hasMore, setHasMore, setOffset
  } = isFormPickerInfiniteLoadingEnabled ? useInfiniteFormLoading({
    fetchInfiniteFormsCallback, searchTerm, setItems
  }) : {
    loading: false, hasMore: false, setHasMore: f => f, setOffset: f => f
  };

  Hooks.useEffectIgnoreFirst(() => {
    if (!isFormPickerInfiniteLoadingEnabled) setItems(allItems);
  }, [allItems]);

  const { onSearch } = DataProvider({ allItems, searchKeys, fuseOptions });
  const { onSearch: onSearchWithFilterCriteria } = DataProvider({ allItems: filterItems, searchKeys, fuseOptions });

  Hooks.useEffectIgnoreFirst(() => {
    if (filterCriteria) {
      onSearchWithFilterCriteria(searchTerm).then(setItems);
    } else {
      onSearch(searchTerm).then(setItems);
    }
  }, [searchTerm, filterCriteria]);

  const options = useMemo(() => (items?.length > 0 ? items.map(({ id, ...props }) => ({ id, value: id, ...props })) : []), [items]);
  const preservedItemOptions = useMemo(() => options?.map(opt => opt.value)?.filter(id => preservedItems.includes(id)) || [], [options, preservedItems]);
  // useMemo(() => props => <ItemComponent {...props} isMultiSelect={isMultiSelect} />, [ItemComponent, isMultiSelect]);
  const ItemComponentWrapped = useMemo(() => props => (
    <ItemComponent
      {...props}
      formID={formID}
      disabledHoverText={disabledHoverText}
      isMultiSelect={isMultiSelect}
      isDraggable={isSortable}
      isEditable={isItemEditable}
      showFavorite={showFavorite}
      showFormId={showFormId}
      submissionsKeyword={submissionsKeyword}
      isNewBranding={isNewBranding}
      disableDefaultDocument={disableDefaultDocument}
      productType={productType}
      showPreview={showPreview}
    />
  ), [ItemComponent, isMultiSelect, isItemEditable, isSortable, showFavorite, showFormId, submissionsKeyword, isNewBranding, productType]);
  const clearSelection = () => {
    setSelectedItems(preservedItems);
    const { setSelectedOptionList } = selectionGroupRef.current || {};
    // eslint-disable-next-line no-unused-expressions
    setSelectedOptionList?.(preservedItemOptions);
  };

  const handleAllSelectionChanged = () => {
    if (!isAllSelected) {
      const optionValueList = options.map(opt => opt.value);
      setSelectedItems(optionValueList);
      // eslint-disable-next-line no-irregular-whitespace
      const { setSelectedOptionList } = isSortable ? (selectionGroupRef.current.refs?.wrappedInstance || {}) : selectionGroupRef.current;
      // eslint-disable-next-line no-unused-expressions
      setSelectedOptionList?.(optionValueList);
    } else {
      clearSelection();
    }
  };

  const onItemSelection = itemList => {
    setSelectedItems(itemList);
  };

  Hooks.useEffectIgnoreFirst(() => {
    if (!isEqual(selectedItems, preparedDefultValue) && selectedItems) {
      onSelectionChange(selectedItems);
    }
  }, [selectedItems]);

  useEffect(() => {
    setSelectedItems(defaultValue);
  }, [defaultValue]);

  useImperativeHandle(ref, () => ({
    selectedItems: selectedItems
  }));

  const onSearchTermChange = val => {
    setSearchTerm(val);
    setItems([]);
    setHasMore(true);
    setOffset(prev => {
      if (!val) return -1;
      if (prev === -1) return 0;
      return -1;
    });
  };

  const WrappedSelectionGroup = isSortable ? SortableContainer(SelectionGroup, { withRef: true }) : SelectionGroup;

  const getSortableOptions = () => {
    if (!isSortable || !SortableProps) return {};
    const DefaultSortableElement = SortableElement(props => <li {...props} />);
    const {
      SortableElement: SortableElementAsProp = DefaultSortableElement,
      SortableElementProps = {},
      onSortEnd: onSortEndCallback,
      lockAxis = 'y',
      transitionDuration = 150,
      pressDelay,
      distance,
      helperContainer = () => selectionGroupRef?.current?.container || document.body
    } = SortableProps;

    return {
      OptionContainerRenderer: SortableElementAsProp,
      OptionContainerRendererProps: option => {
        const index = option.index ? option.index : options.findIndex(opt => opt.id === option.id);
        return { index, ...SortableElementProps };
      },
      onSortEnd: ({ oldIndex, newIndex }) => {
        const orderedItems = arrayMove(items, oldIndex, newIndex).map((item, index) => ({ ...item, index }));
        if (onSortEndCallback) onSortEndCallback({ oldIndex, newIndex }, orderedItems);
      },
      lockAxis,
      transitionDuration,
      pressDelay,
      distance,
      helperContainer
    };
  };

  const sortOnClient = sortCriteria => {
    switch (sortCriteria) {
      case 'title_az':
        setItems(orderByFn(items, ['title'], 'asc'));
        break;
      case 'title_za':
        setItems(orderByFn(items, ['title'], 'desc'));
        break;
      case 'last_submission':
        setItems(orderByFn(items, [i => new Date(i[sortCriteria])], 'desc'));
        break;
      case 'count':
      case 'new':
        setItems(orderByFn(items, i => parseInt(i[sortCriteria], 10), 'desc'));
        break;
      default: {
        setItems(orderByFn(items, [sortCriteria], 'desc'));
        break;
      }
    }
  };

  const filterOnClient = filterValue => {
    switch (filterValue) {
      case 'payment':
        const paymentItems = allItems.filter(item => item?.paymentProps);
        setItems(paymentItems);
        setFilterCriteria(filterValue);
        setFilterItem(paymentItems);
        break;
      default:
        setItems(allItems);
        setFilterCriteria(null);
        setFilterItem([]);
        break;
    }
  };

  const handleNotFound = () => {
    if (!hasItemsToShow && showNotFoundListPlaceholder && searchTerm) {
      if (typeof NotFoundListPlaceholder === 'function') {
        return <NotFoundListPlaceholder t={t} />;
      }
      return NotFoundListPlaceholder;
    }
    if (!filterItems.length > 0 && !hasItemsToShow && showNotFoundListPlaceholder && hasAnyItem) {
      if (typeof NotFoundListPlaceholder === 'function') {
        return <NotFoundListPlaceholder t={t} />;
      }
      return NotFoundListPlaceholder;
    }
  };

  return (
    <FeatureToggles
      features={{
        multipleManage: true,
        search: true,
        ...features
      }}
    >
      <ThemeProvider theme={theme}>
        <Feature
          name="showHeader"
          activeComponent={(
            <div className="jNewHeaderFormList-header">
              <div className="jNewHeaderFormList-container">
                <h2 className="jNewHeaderFormList-title">{t(listTitle)}</h2>
                <p className="jNewHeaderFormList-desc">{t(listDescription)}</p>
              </div>
            </div>
          )}
        />
        <ControlBarRenderer
          isVisible={isFormPickerInfiniteLoadingEnabled ? true : hasAnyItem}
          isMultiSelect={isMultiSelect}
          isAllSelected={isAllSelected}
          selectedItemCount={selectedItemCount - preservedItems.length}
          onSelectAll={handleAllSelectionChanged}
          searchPlaceholder={searchPlaceholder}
          searchTerm={searchTerm}
          onSearchTermChange={isFormPickerInfiniteLoadingEnabled ? onSearchTermChange : setSearchTerm}
          clearSelection={clearSelection}
          bulkActions={bulkActions}
          otherItems={otherControlBarItems}
          showSelectAll={showSelectAll}
          showSelectedItemCount={showSelectedItemCount}
          isNewBranding={isNewBranding}
          sortOnClient={sortOnClient}
          filterOnClient={filterOnClient}
          autoFocus
        />
        {!hasAnyItem && !searchTerm && showEmptyListPlaceholder && !loading && (typeof EmptyListPlaceholder === 'function' ? <EmptyListPlaceholder /> : EmptyListPlaceholder)}
        {hasItemsToShow && (
          <ListWrapper>
            {customItemsList.map(({ CustomItemComponent, props }) => {
              return <CustomItemComponent {...props} />;
            })}
            <WrappedSelectionGroup
              ref={selectionGroupRef}
              options={options}
              isMultiSelect={isMultiSelect}
              defaultValue={selectedItems}
              onSelectionChange={onItemSelection}
              OptionRenderer={ItemComponentWrapped}
              OptionContainerRenderer={ItemContainer}
              ContainerRenderer={ContainerRenderer}
              {...getSortableOptions()}
            />
          </ListWrapper>
        )}
        <div className='infinite-loading-intersection'>
          {isFormPickerInfiniteLoadingEnabled && hasMore ? (
            <>{[...Array(40)].map(() => <FormLoadingSkeleton />)}</>
          ) : null}
        </div>
        {!loading && handleNotFound()}
      </ThemeProvider>
    </FeatureToggles>
  );
});

const itemType = shape({ id: string.isRequired });
SmartList.propTypes = {
  features: objectOf(bool),
  isMultiSelect: bool,
  items: arrayOf(itemType),
  defaultValue: oneOfType([arrayOf(string), string]),
  onSelectionChange: func,
  DataProvider: func,
  showEmptyListPlaceholder: bool,
  emptyListPlaceholder: propTypes.renderable,
  showNotFoundListPlaceholder: bool,
  notFoundListPlaceholder: propTypes.renderable,
  ListWrapper: propTypes.renderable,
  ItemComponent: propTypes.renderable,
  ItemContainer: propTypes.renderable,
  ContainerRenderer: propTypes.renderable,
  searchKeys: arrayOf(string),
  searchPlaceholder: string,
  bulkActions: propTypes.renderable,
  t: func,
  formID: string,
  theme: shape({}),
  isSortable: bool,
  SortableProps: shape({}),
  isItemEditable: bool,
  otherControlBarItems: propTypes.renderable,
  listTitle: string,
  listDescription: string,
  showFavorite: bool,
  showSelectAll: bool,
  showSelectedItemCount: bool,
  showFormId: bool,
  submissionsKeyword: string,
  isNewBranding: bool,
  fuseOptions: shape({}),
  customItemsList: arrayOf(shape({
    CustomItemComponent: propTypes.renderable,
    props: shape({})
  })),
  ignoreDefaultValueCheck: bool,
  disabledHoverText: string,
  disableDefaultDocument: bool,
  ControlBarRenderer: propTypes.renderable,
  /** Retains these items on unselect all, and does not count towards option count. */
  preservedItems: arrayOf(string),
  productType: string,
  showPreview: bool,
  isFormPickerInfiniteLoadingEnabled: bool,
  fetchInfiniteFormsCallback: func
};

SmartList.defaultProps = {
  features: {},
  isMultiSelect: true,
  items: [],
  defaultValue: [],
  onSelectionChange: f => f,
  DataProvider: DefaultDataProvider,
  showEmptyListPlaceholder: true,
  emptyListPlaceholder: () => <EmptyList />, // eslint-disable-line react/prop-types
  showNotFoundListPlaceholder: true,
  notFoundListPlaceholder: () => <NotFoundList resource="item" />,
  ListWrapper: ({ children }) => <ul role="listbox" aria-label="list">{children}</ul>, // eslint-disable-line react/prop-types
  ItemComponent: null,
  ItemContainer: props => <li {...props} />,
  ContainerRenderer: forwardRef(({ children }, ref) => ( // eslint-disable-line react/prop-types
    <ul
      data-testid="optionList" role="listbox" aria-label="list"
      ref={ref}
    >
      {children}
    </ul>
  )),
  searchKeys: [],
  searchPlaceholder: '',
  bulkActions: null,
  t: f => f,
  formID: null,
  theme: defaultTheme,
  isSortable: false,
  SortableProps: {},
  isItemEditable: false,
  otherControlBarItems: [],
  listTitle: 'Go to another form',
  listDescription: 'You\'ve done great on this form. Time to focus on another one? Let\'s go then!',
  showFavorite: false,
  showSelectAll: true,
  showSelectedItemCount: true,
  showFormId: false,
  submissionsKeyword: 'Submissions',
  isNewBranding: false,
  fuseOptions: null,
  customItemsList: [],
  ignoreDefaultValueCheck: false,
  disabledHoverText: null,
  disableDefaultDocument: false,
  ControlBarRenderer: ControlBar,
  preservedItems: [],
  productType: '',
  showPreview: false,
  isFormPickerInfiniteLoadingEnabled: false,
  fetchInfiniteFormsCallback: f => f
};

export default SmartList;
