import React from 'react';
import { translationRenderer } from '@jotforminc/translation';
import type { GATEWAY_INFO_TEXTS } from '../types';

export const gatewayInfoTexts: GATEWAY_INFO_TEXTS = {
  control_paypalInvoicing: translationRenderer('Visit our [1[{link}]] to learn how to integrate PayPal Invoicing with Jotform.')({
    renderer1: () => (
      <a href="https://www.jotform.com/help/565-How-to-integrate-PayPal-Invoicing-with-JotForm" target="_blank">user guide</a>
    )
  }),
  control_venmo: translationRenderer('Our [1[{venmo}]] integration is powered by PayPal Business and is available only to U.S.-based merchants and customers.')({
    renderer1: () => (
      <a href="https://venmo.com/" target="_blank">Venmo</a>
    )
  })
};
