import {
  CommonDefinitions, AppV0, FormItemV0,
  AppV1, FormItemV1, HeadingItemV1, ParagraphItemV1, LinkItemV1, DocumentItemV1, ImageItemV1,
  ButtonItemV1, WidgetItemV1, SentboxLinkItemV1, TableLinkItemV1, ReportLinkItemV1, ProductListItemV1
} from '@jotforminc/portal-schemas';

export default {
  CommonDefinitions,
  AppV0,
  FormItemV0,
  AppV1,
  FormItemV1,
  HeadingItemV1,
  ParagraphItemV1,
  LinkItemV1,
  SentboxLinkItemV1,
  TableLinkItemV1,
  ReportLinkItemV1,
  DocumentItemV1,
  ImageItemV1,
  ButtonItemV1,
  WidgetItemV1,
  ProductListItemV1
};
