import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Cybersource gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlCybersource: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_cybersource: {
    email: {
      title: 'Bill to',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      requiredCondition: () => true,
      autoSave: true
    },
    billToAddress: {
      title: 'Billing Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      addressSubfields: 'state|zip|st1|city|st2|country',
      default: 'none',
      resources: ['FORM'],
      requiredCondition: () => true,
      autoSave: true
    },
    authOnly: {
      title: 'Authorization Only',
      type: 'toggle',
      selectedCondition: settings => settings.authOnly === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType !== 'subscription'
    },
    orderPrefix: {
      title: 'Order Prefix',
      type: 'input',
      infoText: 'This prefix will be appended to the beginning of the Order ID that\'s sent to CyberSource.',
      default: '',
      resources: ['FORM', 'APP']
    }
  }
};

export default controlCybersource;
