import React, { memo } from 'react';
import classNames from 'classnames';
import { IconMoneyBillFilled } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { Tooltip } from '@jotforminc/tooltip';
import * as Modal from '../Items';
import useGatewayFlows from '../Context/useGatewayFlows';
import { C_PAYMENT_TYPES } from '../Constants';

const PaymentTypes = (): JSX.Element | null => {
  const {
    currentlyPaymentType,
    selectPaymentType,
    currentlyGateway,
    currentlyGatewayProperties
  } = useGatewayFlows();

  if (!currentlyGateway || !currentlyGatewayProperties) {
    return null;
  }

  const isPurchaseOrder = currentlyGatewayProperties.elementType && currentlyGatewayProperties.elementType === 'purchase_order' ? true : false;

  return (
    <>
      <Modal.Header
        title={!isPurchaseOrder ? t('Add a payment type') : t('Select order type')}
        description={!isPurchaseOrder ? t('Select a payment type to your form to collect payments') : t('Add an order type to your form to collect orders online')}
        leftIcon={<IconMoneyBillFilled className='h-6 w-7' />}
        iconBackgroundColor='bg-green-500'
        iconColor='color-white'
      />
      <Modal.Body>
        <div className='grid cols-2 gap-3 md:cols-4' data-content='paymentModalFlow-payment-types'>
          {
            C_PAYMENT_TYPES.map(data => {
              const isAllowType = currentlyGatewayProperties.paymentTypes?.includes(data.type);
              return (
                <button
                  onClick={() => selectPaymentType(data.type)}
                  type='button'
                  key={data.type}
                  data-paymenttype={data.type}
                  className={classNames(
                    `w-full h-auto radius border hover:border-blue-400 hover:bg-navy-25 ${currentlyPaymentType === data.type ? 'border-blue-400 bg-navy-25' : 'border-navy-75'}`,
                    {
                      'cursor-not-allowed order-last jfTooltipNew-hoverTarget relative': !isAllowType
                    }
                  )}
                  disabled={!isAllowType}
                >
                  <div className={classNames(
                    'w-full h-full flex-col flex py-5 px-4 text-center',
                    {
                      'opacity-50': !isAllowType
                    }
                  )}
                  >
                    <div className='h-10 w-full mb-2'>{data.icon && <data.icon className={`h-9 w-9 ${data.color}`} />}</div>
                    <div className='h-12 flex items-center justify-center w-full'>
                      <h5
                        className='font-medium text-md color-navy-700 line-height-lg'
                      >
                        {t(data.name)}
                      </h5>
                    </div>
                    <div className='h-9 w-full mt-2 mb-3 shrink-0'>
                      <span
                        className='color-navy-300 text-xs line-height-sm tracking-xxs' style={{ fontSize: '13px' }}
                      >
                        {t(data.description)}
                      </span>
                    </div>
                  </div>
                  {
                    !isAllowType && (
                      <Tooltip
                        v2
                        align="Center"
                        attach="Bottom"
                        style={{
                          zIndex: 9999,
                          width: '169px',
                          fontSize: '12px',
                          lineHeight: '14px',
                          padding: '6px 8px',
                          textAlign: 'left'
                        }}
                      >
                        {!isPurchaseOrder ? t('This payment type is not available for this gateway') : t('You must integrate with a payment gateway to select this option')}
                      </Tooltip>
                    )
                  }
                </button>
              );
            })
          }
        </div>
      </Modal.Body>
      <Modal.Footer />
    </>
  );
};

export default memo(PaymentTypes);
