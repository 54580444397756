import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Payfast gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlPayfast: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_payfast: {
    askCustomerInformation: {
      title: 'Ask Customer Information',
      description: 'Automate collecting customer information with form fields.',
      type: 'toggle',
      selectedCondition: settings => settings.askCustomerInformation === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM']
    },
    billingName: {
      title: 'Customer Name Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_fullname'],
      questionTypeToAdd: 'control_fullname',
      default: 'none',
      resources: ['FORM'],
      autoSave: true,
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askCustomerInformation === 'Yes'
    },
    billingEmail: {
      title: 'Customer Email Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      autoSave: true,
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askCustomerInformation === 'Yes'
    },
    billingPhone: {
      title: 'Customer Phone',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_phone'],
      questionTypeToAdd: 'control_phone',
      default: 'none',
      resources: ['FORM'],
      autoSave: true,
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askCustomerInformation === 'Yes'
    },
    billingAdd: {
      title: 'Use Field as Billing Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      default: 'none',
      resources: ['FORM'],
      autoSave: true,
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askCustomerInformation === 'Yes'
    },
    customDataField: {
      title: 'Custom Data Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_textbox', 'control_autoincrement'],
      questionTypeToAdd: 'control_textbox',
      default: 'none',
      resources: ['FORM'],
      autoSave: true,
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askCustomerInformation === 'Yes'
    }
  }
};

export default controlPayfast;
