import React from 'react';
import WarningBox from '@jotforminc/warning-box';
import { IconInfoCircleFilled } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import usePaymentPropsPanel from '../../Context/usePaymentProperties';

const ProductListConnectionWarning = (): JSX.Element => {
  const {
    isCollaborator,
    isJotformerAccessingUserResource
  } = usePaymentPropsPanel();

  /* eslint-disable-next-line max-len */
  const PLConnectionWarningText = (isCollaborator || isJotformerAccessingUserResource) ? t('Only the form owner can add/remove connections.') : t('Add a payment connection to start collecting payments.');
  return (
    <WarningBox
      isLongText
      colorStyle="none"
      size="medium"
      className="bg-yellow-100 color-yellow-500"
      Icon={IconInfoCircleFilled}
    >
      <span className='font-normal color-gray-700 tracking-lg'>{PLConnectionWarningText}</span>
    </WarningBox>
  );
};

export default ProductListConnectionWarning;
