import React from 'react';
import { translationRenderer } from '@jotforminc/translation';
import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Bluesnap gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlBluesnap: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_bluesnap: {
    softDescriptor: {
      title: 'Soft Descriptor',
      type: 'input',
      description: 'The descriptor that appears after a transaction has been authorized.',
      default: '',
      placeholder: 'Enter company name etc.',
      resources: ['FORM', 'APP'],
      requiredCondition: () => true
    },
    sca: {
      title: 'Enable 3D Secure 2.0',
      type: 'toggle',
      description:
        translationRenderer('Collect payments in European Economic Area. [1[{link}]].')({
          renderer1: () => (
            <a
              className="pointer-events-auto"
              href="https://support.bluesnap.com/docs/3d-secure#test-3-d-secure-2"
              target="_blank"
              rel="nofollow"
            >
              <u>
                More
              </u>
            </a>
          )
        }),
      selectedCondition: settings => settings.sca === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP']
    },
    emailField: {
      title: 'Customer Email Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      requiredCondition: settings => settings.paymentType === 'subscription',
      autoSave: true
    },
    phoneField: {
      title: 'Customer Phone Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_phone'],
      questionTypeToAdd: 'control_phone',
      default: 'none',
      resources: ['FORM'],
      autoSave: true
    },
    billingAddress: {
      title: 'Billing Address Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      default: 'none',
      resources: ['FORM'],
      autoSave: true
    },
    ccTitle: {
      title: 'Credit Card Field Label Text',
      type: 'input',
      default: 'Credit Card',
      resources: ['FORM']
    }
  }
};

export default controlBluesnap;
