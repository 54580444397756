/* eslint-disable max-statements */
import sortBy from 'lodash/sortBy';
import max from 'lodash/max';
import { all, put, select } from 'redux-saga/effects';
import { ADD_PORTAL_ITEMS } from '../actionTypes';
import SELECTORS from '../selectors';
import { ITEM_ADDITION_ORDER_STRATEGY } from '../../constants';
import { AVAILABLE_DETAIL_PAGE_ITEMS } from '../../modules/Builder/components/HomePage/RightPanel/dataSourceHelpers';
import * as ACTION_CREATORS from '../actionCreators';

// method for calculating item orders for single item adding
const calculateItemOrders = (items, addedItemOrder) => {
  let nextOrder = addedItemOrder;
  const newItems = [];
  items.forEach(({ portalOrder, ...props }) => {
    const shouldUpdate = parseInt(portalOrder, 10) >= addedItemOrder;
    if (shouldUpdate) nextOrder++;
    const newOrder = shouldUpdate ? nextOrder.toString() : portalOrder.toString();
    newItems.push({ ...props, ...{ portalOrder: newOrder } });
  });
  return newItems;
};

const generateNewItems = ({ order, currentItems, items }) => {
  let newItems;
  if (order === ITEM_ADDITION_ORDER_STRATEGY.TO_END_OF_THE_PAGE) {
    newItems = [...currentItems, ...items];
  } else {
    const rounded = Math.round(order);
    let newItemOrder = rounded;
    newItems = currentItems;
    const orderedNewItems = sortBy(items, p => parseInt(p.portalOrder, 10));
    orderedNewItems.forEach(item => {
      const newOrder = item.portalOrder ? item.portalOrder : newItemOrder.toString();
      newItems = calculateItemOrders(newItems, newOrder);
      newItems.push({ ...item, ...{ portalOrder: newOrder } });
      newItemOrder++;
    });
    newItems = sortBy(newItems, p => parseInt(p.portalOrder, 10));
  }
  return newItems;
};

export function* watchPortalOrderInItemAddition(action) {
  const { dontStack = false, isUndo = false } = action;
  const {
    items,
    order: _order,
    withClicking,
    isDuplicate,
    focusItemID
  } = action.payload;

  let order = _order;
  const pages = yield select(SELECTORS.getPages);
  const firstPageID = yield select(SELECTORS.getFirstPageID);
  const currentItems = yield select(SELECTORS.getPortalItems);
  const isHomepage = items[0]?.page === firstPageID;
  const currentPageID = items[0]?.page; // todo @ for resat
  const currentPage = pages.find(page => (parseInt(page.id, 10) === parseInt(currentPageID, 10) || parseInt(page.tempID, 10) === parseInt(currentPageID, 10)));
  if (currentPageID && !withClicking && !isHomepage) {
    const { pageOrder: currentPageOrder } = currentPage;
    const previousPages = pages.filter(page => parseInt(page.pageOrder, 10) < parseInt(currentPageOrder, 10));
    const previousPageIDs = previousPages.map(page => page.id);
    const itemsInPreviousPages = currentItems.filter(item => previousPageIDs.includes(item.page));
    if (itemsInPreviousPages.length > 0) {
      const prevPagesItemOrders = itemsInPreviousPages.map(item => parseInt(item.portalOrder, 10));
      const maxOrderBefore = max(prevPagesItemOrders);
      order = maxOrderBefore + _order;
    }
  }

  const [filteredItems, unsupportedItems] = items.reduce((prev, item) => {
    if (currentPage.linkedItemID && !AVAILABLE_DETAIL_PAGE_ITEMS.includes(item.type)) {
      return [[...prev[0]], [...prev[1], item]];
    }
    return [[...prev[0], item], [...prev[1]]];
  }, [[], []]);

  if (unsupportedItems.length > 0) {
    yield all(unsupportedItems.map(unsupportedItem => {
      console.error('detail page unsupported element addition try: ', unsupportedItem.type);
      return put(ACTION_CREATORS.toastAction({
        message: 'Element is not supported for detail page'
      }));
    }));

    yield all(unsupportedItems.map(
      unsupportedItem => put(ACTION_CREATORS.trackEventAction({ action: 'detailPageUnsupportedItemAddition', target: { itemType: unsupportedItem.type, pageID: currentPage.id } }))));
  }

  if (order !== ITEM_ADDITION_ORDER_STRATEGY.MULTIPLE_ITEM_DUPLICATION) {
    const newItems = generateNewItems({
      order,
      currentItems,
      items: filteredItems
    });
    yield put({
      type: ADD_PORTAL_ITEMS.REQUEST,
      payload: {
        items: filteredItems,
        order,
        newItems,
        isDuplicate,
        focusItemID
      },
      dontStack,
      isUndo
    });
  } else {
    let newDuplicatedItems = currentItems;
    filteredItems.forEach(item => {
      const { portalOrder, ...itemProps } = item;
      newDuplicatedItems = generateNewItems({
        order: portalOrder,
        items: [itemProps],
        currentItems: newDuplicatedItems
      });
    });
    yield put({ type: ADD_PORTAL_ITEMS.REQUEST, payload: { items, newItems: newDuplicatedItems, isDuplicate }, dontStack });
  }
}
