import React, {
  forwardRef, memo,
  useCallback,
  useState
} from 'react';
import { t } from '@jotforminc/translation';
import { bool, shape, string } from 'prop-types';
import { useDebounce } from '@jotforminc/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconEyeFilled,
  IconEyeSlashFilled,
  IconGearFilled,
  IconGridDotsVertical,
  IconTrashFilled,
  IconLockFilled
} from '@jotforminc/svg-icons';
import SELECTORS from '../../../../../../store/selectors';
import Button from '../../../../../../components/Button';
import { updatePageAction, selectPageAction, onDeletePage } from '../../../../../../store/actionCreators';
import { isYes } from '../../../../../../utils';
import TextInput from '../../../TextInput';
import ScPageSettingsItem from './ScPageSettingsItem';
import PageIconButton from './PageIconButton';
import { DELETE_PAGE_TYPES } from '../../constants';

const PageSettingsItem = forwardRef(({
  draggableProps,
  dragHandleProps,
  showAllPagesIcon,
  isLocked,
  page
}, ref) => {
  const {
    id: pageID,
    name,
    showPageIcon,
    pageIcon,
    showPageOnNavigation: _showPageOnNavigation,
    defaultName // defaultName prop is required for race condition,
  } = page;
  const dispatch = useDispatch();
  const isMultiPage = useSelector(SELECTORS.getIsMultiPage);
  const selectedPageID = useSelector(SELECTORS.getSelectedPage);

  const [hovered, setHovered] = useState(false);
  const [pageName, setPageName] = useState(name);

  const isPageSelected = selectedPageID === pageID;
  const showPageOnNavigation = isYes(_showPageOnNavigation);

  const handlePageUpdate = useCallback(prop => {
    dispatch(updatePageAction({
      pageID,
      prop
    }));
  }, []);

  const debouncedHandlePageUpdate = useDebounce(handlePageUpdate);

  const handlePageNameTextBlur = e => {
    const { target: { value } } = e;
    if (!value) {
      setPageName(defaultName);
    }
  };

  const handlePageNameChange = (props, value) => {
    setHovered(false);
    setPageName(value);
    debouncedHandlePageUpdate({ [props]: value });
  };

  const toggleVisibility = () => handlePageUpdate({ showPageOnNavigation: showPageOnNavigation ? 'No' : 'Yes' });

  const handleSelectPage = () => {
    if (isMultiPage && !isPageSelected) {
      dispatch(selectPageAction(pageID));
    }
  };

  const handleDeletePage = () => {
    dispatch(onDeletePage({
      pageID,
      type: DELETE_PAGE_TYPES.rightPanel
    }));
  };

  return (
    <ScPageSettingsItem
      ref={ref}
      {...draggableProps}
      {...dragHandleProps}
      showPageOnNavigation={showPageOnNavigation}
    >
      <div className='pageSettings-dragHandle '>
        {
          isLocked ? <IconLockFilled /> : <IconGridDotsVertical />
        }
      </div>
      {isYes(showAllPagesIcon) && (
        <PageIconButton
          showAllPagesIcon={showAllPagesIcon}
          showPageIcon={showPageIcon}
          pageIcon={pageIcon}
          handlePageUpdate={handlePageUpdate}
        />
      )}
      <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className='pageSettings-input'>
        <TextInput
          id="search"
          placeholder={t('Page Name')}
          value={pageName}
          onChange={val => handlePageNameChange('name', val)}
          onBlur={handlePageNameTextBlur}
        />
        <div className={`page-item-actions ${hovered ? 'active' : ''}`}>
          <Button type='button' onClick={handleSelectPage} className='pageSettingsActionButton'>
            <IconGearFilled width={16} color='#fff' />
          </Button>
          {
            !isLocked && (
            <Button
              type='button'
              onClick={toggleVisibility}
              className='pageSettingsActionButton'
            >
              {showPageOnNavigation ? <IconEyeFilled width={16} color='#fff' /> : <IconEyeSlashFilled width={16} color='#fff' />}
            </Button>
            )

          }
          <Button type='button' onClick={handleDeletePage} className='pageSettingsActionButton'>
            <IconTrashFilled width={16} color='#DC2626' />
          </Button>
        </div>
      </div>
    </ScPageSettingsItem>
  );
});

PageSettingsItem.propTypes = {
  page: shape({}),
  draggableProps: shape({}),
  dragHandleProps: shape({}),
  showAllPagesIcon: string,
  isLocked: bool
};

PageSettingsItem.defaultProps = {
  page: {},
  draggableProps: {},
  dragHandleProps: {},
  showAllPagesIcon: '',
  isLocked: false
};

export default memo(PageSettingsItem);
