/* eslint-disable max-len */
import React from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import {
  Button, Dialog, DialogBody, DialogIcon, DialogTitle, DialogDescription, DialogActions, DialogCloseButton
} from '@jotforminc/magnet';
import { IconExclamationCircle, IconArrowRight } from '@jotforminc/svg-icons';

/** Data */
import { gatewayDefaultIcons, supportedGatewayConnectionPropsList } from '@jotforminc/payment-constants';

/** Context */
import { useGatewayConnection } from '../../../context';

/** Type */
import type { GATEWAY_CONNECTION_DISCARD_TYPES } from '../../../types';

type OBJECT_DETAIL = {
  title: string;
  description: React.ReactNode | string | null;
};

const UnPlugIcon = () => (
  <svg
    width="40" height="40" viewBox="0 0 24 24"
    className='color-red-400'
    fill="currentColor" xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2.5C6 1.67158 6.67157 1 7.5 1C8.32843 1 9 1.67158 9 2.5V5.5C9 5.77614 8.77614 6 8.5 6H6.5C6.22386 6 6 5.77614 6 5.5V2.5ZM15 2.5C15 1.67158 15.6716 1 16.5 1C17.3284 1 18 1.67158 18 2.5V3.9649C17.877 4.03199 17.7614 4.11757 17.6574 4.22165L15.8789 6H15.5C15.2239 6 15 5.77614 15 5.5V2.5ZM18.7073 8H20C20.5523 8 21 8.44772 21 9V10C21 10.5523 20.5523 11 20 11H19V13C19 13.9193 18.8189 14.8295 18.4672 15.6788C18.1154 16.5281 17.5998 17.2997 16.9497 17.9497C16.2997 18.5998 15.5281 19.1154 14.6788 19.4672C14.1393 19.6906 13.5753 19.8452 13 19.9282V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V19.9282C10.4247 19.8452 9.86065 19.6906 9.32122 19.4672C8.84654 19.2705 8.39611 19.0227 7.97762 18.7289L6.28253 20.4239C5.89199 20.8144 5.2588 20.8144 4.86827 20.4239C4.47773 20.0334 4.47773 19.4002 4.86827 19.0097L19.0109 4.86811C19.4015 4.4776 20.0347 4.4776 20.4252 4.86811C20.8157 5.25862 20.8157 5.89176 20.4252 6.28227L18.7073 8ZM5.53284 15.6788C5.59711 15.8339 5.66685 15.9865 5.74188 16.1362L13.8787 8H4C3.44772 8 3 8.44772 3 9V10C3 10.5523 3.44772 11 4 11H5V13C5 13.9193 5.18106 14.8295 5.53284 15.6788Z"
      fill="currentColor"
    />
  </svg>
);

/**
 * DiscardProcessInformation component provides a confirmation dialog for various
 * actions related to payment gateway connections.
 *
 * It displays different messages and
 * options based on the type of action being performed (changing mode,
 * disconnecting, exiting without saving, or switching payment connection).
 *
 * The component uses the useGatewayConnection hook to access and modify
 * the current state of the gateway connection.
 *
 * When a user confirms an action, it:
 * - Calls the appropriate function to handle the confirmed action.
 * - Closes the dialog.
 * - Updates the connection state as necessary.
 *
 * @component
 * @example
 * <DiscardProcessInformation />
 */
export const DiscardProcessInformation = (): any => {
  const {
    connectionDiscardDialogIsOpen,
    connectionDiscardType,
    connectionDiscardContinueButtonIsLoading,
    currentlyGatewayProperties,
    currentlyConnection,
    selectedConnectionId,
    connectionList,
    showOrHideDiscardNewConnectionDialog,
    discardNewConnectionDialogContinueButtonProcess,
    currentlyFlow
  } = useGatewayConnection();

  if (!connectionDiscardDialogIsOpen || !connectionDiscardType) {
    return null;
  }

  const changeModeDescription = () => {
    return translationRenderer('Changing your payment mode will [1[disconnect]] you from this gateway.')({
      renderer1: (text: string) => <span className='color-navy-700 font-bold'>{text}</span>
    });
  };

  const backOrCloseDescription = () => {
    return translationRenderer('You have not completed your [1[{gatewayName}]] configuration. Any unsaved changes will be lost.')({
      renderer1: () => <span className='color-navy-700 font-bold'>{currentlyGatewayProperties && currentlyGatewayProperties.name}</span>
    });
  };

  const switchGatewayDescription = () => {
    if (!currentlyConnection || !connectionList) {
      return null;
    }

    const getSelectedConnection = connectionList.find(connection => connection.id === selectedConnectionId);
    if (!getSelectedConnection) { return null; }

    const FromGatewayName = supportedGatewayConnectionPropsList[currentlyConnection.gateway].name;
    const ToGatewayName = supportedGatewayConnectionPropsList[getSelectedConnection.gateway].name;
    const FromIcon = gatewayDefaultIcons[currentlyConnection.gateway] as React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    const ToIcon = gatewayDefaultIcons[getSelectedConnection.gateway] as React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

    return (
      <div className="mt-8 -mx-7">
        <div className="flex justify-between items-center relative px-8">
          <div className="absolute border-t border-navy-50 border-dashed h-px w-1/5 transform translate-x-40" />
          <div className="relative flex justify-center items-center flex-col p-1 color-navy-500 radius border border-navy-50 bg-white z-1 w-40">
            <div className="flex justify-center items-center p-1 bg-navy-25 radius w-10 h-10 mb-2">
              <FromIcon className="w-8 h-8" />
            </div>
            <span className="text-sm text-center line-height-xs tracking-lg font-medium mt-2 w-36">{FromGatewayName}</span>
            <span className="text-xs text-center line-height-xs tracking-lg font-normal mt-1 w-36 truncate">{currentlyConnection.title}</span>
            <span className={`absolute right-2 top-2 radius magnet-badge inline-flex items-center font-medium gap-1 h-5 text-xs px-2 color-white ${currentlyConnection.sandbox === '0' ? 'bg-blue-500' : 'bg-yellow-600'}`}>
              <span className="whitespace-nowrap">{currentlyConnection.sandbox === '0' ? t('Live') : t('Test') }</span>
            </span>
          </div>
          <div className="flex justify-center items-center p-2 bg-navy-25 radius z-1">
            <IconArrowRight className="w-4 h-4 color-navy-500" />
          </div>
          <div className="relative flex justify-center items-center flex-col p-1 color-navy-500 radius border border-navy-50 bg-white z-1 w-40">
            <div className="flex justify-center items-center p-1 bg-navy-25 radius w-10 h-10 mb-2">
              <ToIcon className="w-8 h-8" />
            </div>
            <span className="text-sm text-center line-height-xs tracking-lg font-medium mt-2 w-36">{ToGatewayName}</span>
            <span className="text-xs text-center line-height-xs tracking-lg font-normal mt-1 w-36 truncate">{getSelectedConnection.title}</span>
            <span className={`absolute right-2 top-2 radius magnet-badge inline-flex items-center font-medium gap-1 h-5 text-xs px-2 color-white ${getSelectedConnection.sandbox === '0' ? 'bg-blue-500' : 'bg-yellow-600'}`}>
              <span className="whitespace-nowrap">{getSelectedConnection.sandbox === '0' ? t('Live') : t('Test') }</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const getDetail: Partial<{
    [key in GATEWAY_CONNECTION_DISCARD_TYPES]: {
      title: string;
      description: React.ReactNode | string | null;
    }
  }> = {
    changeMode: {
      title: t('Do you want to change your payment mode?'),
      description: changeModeDescription()
    },
    disconnect: {
      title: t('Do you want to disconnect from this gateway?'),
      description: null
    },
    backOrClose: {
      title: t('Do you want to continue without saving?'),
      description: backOrCloseDescription()
    },
    switchGateway: {
      title: t('Do you want to switch your payment connection?'),
      description: switchGatewayDescription()
    }
  };

  const discardText = (): string => {
    if (currentlyFlow === 'EDIT_CONNECTION' || currentlyFlow === 'RE_CONNECT') {
      return t('Yes, Continue');
    }

    return t('Yes, Switch');
  };

  const { title, description } = getDetail[connectionDiscardType] as OBJECT_DETAIL;

  return (
    <Dialog
      id="connection-switch-dialog"
      open={connectionDiscardDialogIsOpen}
      onClose={() => showOrHideDiscardNewConnectionDialog(null)}
      ariaLabel={title}
    >
      <DialogBody>
        <DialogIcon
          icon={connectionDiscardType === 'disconnect' ? UnPlugIcon : IconExclamationCircle}
          colorStyle={(connectionDiscardType === 'changeMode' || connectionDiscardType === 'switchGateway') ? 'warning' : 'error'}
        />
        <DialogTitle data-test-id="connection_switch_title">{title}</DialogTitle>
        {description && (
          <DialogDescription data-test-id="connection_switch_description">{description}</DialogDescription>
        )}
      </DialogBody>
      <DialogActions>
        <Button
          colorStyle="secondary"
          variant="outline"
          onClick={() => showOrHideDiscardNewConnectionDialog(null)}
          disabled={connectionDiscardContinueButtonIsLoading}
          data-test-id="connection_switch_cancel"
        >
          {t('No, Cancel')}
        </Button>
        <Button
          colorStyle={(connectionDiscardType === 'changeMode' || connectionDiscardType === 'switchGateway') ? 'primary' : 'error'}
          variant="filled"
          onClick={discardNewConnectionDialogContinueButtonProcess}
          loader={connectionDiscardContinueButtonIsLoading}
          disabled={connectionDiscardContinueButtonIsLoading}
          data-test-id="connection_switch_continue"
        >
          {discardText()}
        </Button>
      </DialogActions>
      <DialogCloseButton onClick={() => showOrHideDiscardNewConnectionDialog(null)} />
    </Dialog>
  );
};
