/* eslint-disable max-len */
import React, { useRef } from 'react';
import { object, func, bool } from 'prop-types';
import { t } from '@jotforminc/translation';
import { getCurrentTime } from '@jotforminc/utils';

import { placeholderImage } from '../../constantImages';
import { getDeviceType } from '../../getDeviceType';
import { getTopValue } from './utils';
import { onImageError } from '../../onImageError';
import PreviewHint from './PreviewHint';

const defaultColor = 'rgba(255, 113, 26, 1)';
const defaultWhite = 'rgba(255,255,255,1)';
const defaultDark = 'rgba(0,0,0,1)';

const AppTemplateItem = ({
  template, imageLoaded, handlePreviewTemplate, setImageLoaded, templateCloneWrapper
}) => {
  let animationTimeoutReferance;
  const screenshotRef = useRef();
  const slug = template._slug || template.slug;
  const title = template._title || template.title;
  const isSkeleton = title === 'skeleton';

  const screenshotURL = slug ? `https://cdn.jotfor.ms/templates/screenshot/app-templates/${slug}?f=png&w=1044` : placeholderImage;

  const getSplittedRGB = rgbString => rgbString.replace('rgba(', '').replace(')', '').split(',');

  const getLuminance = hexColor => {
    const [r, g, b] = getSplittedRGB(hexColor);

    const uicolors = [r / 255, g / 255, b / 255];
    const c = uicolors.map(col => (col <= 0.03928 ? col / 12.92 : ((col + 0.055) / 1.055) ** 2.4));
    return (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
  };

  const getBorderColor = (bgColor = defaultColor, lightColor = defaultWhite, darkColor = defaultDark) => {
    const L = getLuminance(bgColor);
    const L1 = getLuminance(lightColor);
    const L2 = getLuminance(darkColor);

    return (L > Math.sqrt((L1 + 0.05) * (L2 + 0.05)) - 0.05) ? darkColor : lightColor;
  };

  let notchColor;
  let splashBgColor = 'rgba(243, 243, 254, 1)';
  if (template.JSON) {
    const screenshotBGColor = JSON.parse(template.JSON)?.screenshotBGColor || defaultColor;
    splashBgColor = JSON.parse(template.JSON)?.splashBgColor || defaultColor;
    notchColor = getBorderColor(screenshotBGColor);
  }

  const updateScreenshotPosition = position => {
    if (!imageLoaded || getDeviceType() !== 'desktop') return;
    const screenshotElement = screenshotRef.current;
    if (screenshotElement === null || !screenshotElement) return;
    screenshotElement.style.top = `${position}px`;
  };

  const handleMouseEnterAnimation = () => {
    updateScreenshotPosition(getTopValue(screenshotRef));
    animationTimeoutReferance = setTimeout(() => updateScreenshotPosition(0), 2000);
  };

  const handleMouseLeave = () => {
    if (animationTimeoutReferance === undefined) return;
    clearTimeout(animationTimeoutReferance);
    updateScreenshotPosition(0);
  };

  const isWhite = notchColor === defaultWhite;
  const phoneNotchClass = isWhite ? 'white' : 'black';

  const previewTemplateWrapper = action => {
    handlePreviewTemplate(action, {
      ...template,
      splashBgColor,
      phoneNotchClass
    });
  };

  return (
    <div className={`item app-item${imageLoaded && !isSkeleton ? ' image-loaded' : ''}`}>
      <div
        className="image"
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnterAnimation}
        style={{ borderBottomColor: splashBgColor }}
        onClick={() => previewTemplateWrapper('viewTemplateFromImg')}
      >
        <PreviewHint />
        <img
          alt={title}
          ref={screenshotRef}
          onError={onImageError}
          onLoad={() => setImageLoaded(true)}
          src={!isSkeleton ? screenshotURL : placeholderImage}
        />
        <div className={`phone-notch ${phoneNotchClass}`}>
          <div className="l">
            <span>{getCurrentTime()}</span>
          </div>
          <div className="r">
            <span className="icons" />
          </div>
        </div>
        <svg
          fill="none"
          viewBox="0 0 304 328"
          className="phone-wrapper"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#ffffff"
            className="white-path"
            d="M39.6-0.9H-1.2v40.7C-1.2,17.3,17.1-0.9,39.6-0.9z"
          />
          <path
            fill="#ffffff"
            className="white-path"
            d="M305.7,39.7V-1h-40.7C287.4-1,305.7,17.2,305.7,39.7z"
          />
          <path
            fill="#F3F3FE"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 0A32 32 0 0 0 0 32v296h304V32a32 32 0 0 0-32-32H32Zm0 8A24 24 0 0 0 8 32v295h288V32a24 24 0 0 0-24-24h-40a4 4 0 0 0-4 4 16 16 0 0 1-16 16H92a16 16 0 0 1-16-16 4 4 0 0 0-4-4H32Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill={splashBgColor}
            className='splash-path'
            d="M272 4H32A28 28 0 0 0 4 32v295h296V32a28 28 0 0 0-28-28ZM32 0A32 32 0 0 0 0 32v296h304V32a32 32 0 0 0-32-32H32Z"
          />
          <path
            fill="#C8CEED"
            d="M138 12a2 2 0 1 0 0 4h28a2 2 0 1 0 0-4h-28ZM178 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
          />
          <path
            fill="#C8CEED"
            className='inner-border'
            d="M9 327V32A23 23 0 0 1 32 9h40a3 3 0 0 1 3 3 17 17 0 0 0 17 17h120a17 17 0 0 0 17-17 3 3 0 0 1 3-3h40a23 23 0 0 1 23 23v295h1V32a24 24 0 0 0-24-24h-40a4 4 0 0 0-4 4 16 16 0 0 1-16 16H92a16 16 0 0 1-16-16 4 4 0 0 0-4-4H32A24 24 0 0 0 8 32v295h1Z"
          />
        </svg>
        <div className="animBg" />
      </div>
      <h3 onClick={() => previewTemplateWrapper('viewTemplateFromTitle')}>
        <span>{title}</span>
      </h3>
      <button
        type="button"
        className="useTemplate"
        onClick={templateCloneWrapper}
      >
        <span>
          { t('Use Template') }
        </span>
      </button>
      <button
        className="previewCta"
        type="button"
        onClick={() => handlePreviewTemplate('viewTemplateFromPreviewCta')}
      >
        <span>{t('Preview')}</span>
      </button>
    </div>
  );
};

export default AppTemplateItem;
AppTemplateItem.defaultProps = {
  templateCloneWrapper: f => f
};
AppTemplateItem.propTypes = {
  templateCloneWrapper: func,
  template: object.isRequired,
  imageLoaded: bool.isRequired,
  setImageLoaded: func.isRequired,
  handlePreviewTemplate: func.isRequired
};
