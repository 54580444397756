/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback
} from 'react';
import {
  getGatewayResourceProperties,
  getChildGatewayResourcePropertiesByParent,
  logPaymentEvents,
  hasGatewayConnectionWithRequest,
  getGatewayType,
  getParentGatewayType
} from '@jotforminc/payment-gateways';
import type { GATEWAY_QUESTION_PROPERTIES } from '@jotforminc/payment-gateways';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import type {
  GATEWAY_PAYMENT_TYPES,
  PAYMENT_FIELDS
} from '@jotforminc/payment-constants';
import { supportedGatewayConnectionPropsList } from '@jotforminc/payment-constants';
import {
  I_PAYMENT_PROPS_PANEL_PROVIDER,
  I_PAYMENT_PROPS_PANEL_CONTEXT,
  T_CHANGED_PROPS,
  T_COMPONENT_VISIBLE_PROPS,
  T_INVALID_PROP_NAMES
} from '../../../../types/common';
import {
  changePaymentType as _changePaymentType,
  getConnectionInformation,
  detachPaymentConnection
} from '../../../../api';

const PaymentPropsPanelContext = createContext<I_PAYMENT_PROPS_PANEL_CONTEXT>({} as I_PAYMENT_PROPS_PANEL_CONTEXT);

export const PaymentPropsPanelProvider: FunctionComponent<I_PAYMENT_PROPS_PANEL_PROVIDER> = (({
  children,
  questionProperties,
  formQuestions,
  resource,
  checkoutFormID,
  products,
  formType,
  user,
  userIsResourceOwner,
  onPaymentTypeChange,
  onSaveGateway,
  onAutosaveGateway,
  onDetachConnection,
  getQidOnAddQuestion,
  togglePaymentConnectionModal,
  hasConnectionToTheGatewayType
}) => {
  const { teamID = null, isTeamMember = null } = window;
  const [scrollBottomForError, setScrollBottomForError] = useState(false);
  const [gatewayProperties, setGatewayProperties] = useState<GATEWAY_QUESTION_PROPERTIES>(questionProperties); // gatewayProperties handle states throughout the panel
  const [invalidPropNames, setInvalidPropNames] = useState<T_INVALID_PROP_NAMES>([]);
  const parentType = getParentGatewayType(gatewayProperties.nameAPM);
  const childType = parentType && gatewayProperties.type;
  const isParentAPM = parentType && childType;
  const isMobileApp = window.navigator.userAgent.indexOf('JotForm Mobile') > -1 || window.navigator.userAgent.indexOf('JFCEMobile') > -1;
  const isCollaboratorInNewShareFlow = user.isCSICollaborator;
  const isCollaborator = (!!(window.location.href.match(/iak=([\d | \w | -]*)/) ?? [])[1] && !isMobileApp) || isCollaboratorInNewShareFlow;
  const userAccountType = user.accountType || user.account_type.name;
  const isJotformerAccessingUserResource = !isEnterprise() && !userIsResourceOwner && ['ADMIN', 'SUPPORT', 'HELPDESK'].includes(userAccountType);

  /* Enterprise roles */
  const isInTeamAsset = (isTeamMember && teamID) || false;
  const hasTeamRole = isInTeamAsset && user && user.teamRoles;
  const isTeamMemberAndCreator = hasTeamRole && user.teamRoles.length === 1 && user.teamRoles.includes('form_collaborator');
  const isTeamAdmin = hasTeamRole && user.teamRoles.includes('team_admin');
  const isOrganizationAdmin = isEnterprise() && userAccountType === 'ADMIN';

  const { paymentConnectionID } = questionProperties;
  const hasConnection = !!paymentConnectionID;
  const [changedProps, setChangedProps] = useState<T_CHANGED_PROPS>({});
  const gatewayType: PAYMENT_FIELDS = useMemo(() => getGatewayType(gatewayProperties.type, gatewayProperties.nameAPM), [gatewayProperties.type, gatewayProperties.nameAPM]);

  const gatewayResourceProps = useMemo(() => {
    return isParentAPM ? getChildGatewayResourcePropertiesByParent(parentType, childType) : getGatewayResourceProperties(gatewayType);
  }, [isParentAPM, parentType, childType, gatewayType]);

  const resetChangedProps = () => {
    setChangedProps({});
  };

  const resetInvalidPropNames = () => {
    setInvalidPropNames([]);
  };

  const checkConnection = useCallback(async () => {
    if (gatewayType && resource && resource === 'FORM' && checkoutFormID) {
      const has = await hasGatewayConnectionWithRequest({
        gatewayType: gatewayType,
        resourceType: resource,
        resourceId: checkoutFormID
      });

      if (hasConnectionToTheGatewayType && typeof hasConnectionToTheGatewayType === 'function') {
        hasConnectionToTheGatewayType(has);
      }
    }
  }, [hasConnection, gatewayType, resource, checkoutFormID]);

  useEffect(() => {
    checkConnection();
  }, []);

  const resetAllProps = useCallback(() => {
    setGatewayProperties(questionProperties);
    resetChangedProps();
    resetInvalidPropNames();
  }, [questionProperties]);

  useEffect(() => {
    const isDifferentAPM = gatewayProperties.nameAPM !== questionProperties.nameAPM;
    const isDifferentPaymentType = (gatewayProperties.type !== questionProperties.type) || isDifferentAPM;

    if (isDifferentPaymentType) {
      resetAllProps();
    }
  }, [questionProperties.type, questionProperties.nameAPM]);

  useEffect(() => {
    resetAllProps();
  }, [paymentConnectionID]);

  const logPaymentEvent = useCallback((action: string, target: string): void => {
    const actor = user?.username || '';
    logPaymentEvents(actor, action, target, userAccountType);
  }, [user?.username, userAccountType]);

  const handleAutosave = (key: string, val: string) => {
    onAutosaveGateway({
      qid: questionProperties.qid,
      key,
      val,
      gatewayType
    });
  };

  const setGatewayProperty = (key: string, val: string, isAutosave?: boolean) => {
    const updatedKeyVal = { [key]: val };
    setGatewayProperties({ ...gatewayProperties, ...updatedKeyVal });

    setInvalidPropNames(invalidPropNames.filter(item => item !== key));
    if (isAutosave) {
      handleAutosave(key, val);
    } else {
      setChangedProps({ ...changedProps, ...updatedKeyVal });
    }
  };

  const setMultipleGatewayProperties = (updateObj: object, isAutosave?: boolean) => {
    setGatewayProperties({ ...gatewayProperties, ...updateObj });

    if (isAutosave) {
      onSaveGateway({
        qid: questionProperties.qid,
        changedProps: updateObj
      });
    } else {
      setChangedProps({ ...changedProps, ...updateObj });
    }
  };

  const changePaymentType = async (oldVal: GATEWAY_PAYMENT_TYPES, newVal: GATEWAY_PAYMENT_TYPES) => {
    const res = await _changePaymentType(checkoutFormID, oldVal, newVal);

    if (res && [400, 500].includes(res.code)) {
      return;
    }

    const paymentType = newVal === 'custom' ? 'donation' : newVal;

    const questionProps = {
      ...res.question,
      paymentType
    };

    onPaymentTypeChange({
      qid: questionProperties.qid,
      questionProps,
      formProps: res.form,
      paymentType
    });

    setGatewayProperties({ ...gatewayProperties, ...questionProps });

    logPaymentEvent('change-payment-type', JSON.stringify({ old: oldVal, new: newVal }));
  };

  const getConnectionInfo = async () => {
    const res = await getConnectionInformation(questionProperties.paymentConnectionID, checkoutFormID);
    return res;
  };

  const isComponentVisible = (props: T_COMPONENT_VISIBLE_PROPS) => {
    const {
      resources,
      renderCondition,
      type,
      subType
    } = props;

    if (!resources.includes(resource)) {
      return false;
    }

    let visible = true;

    if (typeof renderCondition === 'function') {
      const param = type === 'dropdown' && subType === 'formQuestion' ? formQuestions : gatewayProperties;
      visible = renderCondition(param, formType);
    }

    return visible;
  };

  const getInvalidPropNames = () => {
    const emptyValues = ['none', '', null, undefined];
    const dontValidate = ['title'];
    const _invalidPropNames = Object.entries(gatewayResourceProps)
      .filter(item => !dontValidate.includes(item[1].type)) // field type validation blacklist
      .filter(item => isComponentVisible(item[1])) // get visible fields
      .filter(item => item[1].requiredCondition && item[1].requiredCondition(gatewayProperties)) // get required fields
      .reduce((acc, item) => {
        if (emptyValues.includes(gatewayProperties[item[0]])) { // check if empty
          return [...acc, item[0]];
        }
        return acc;
      }, []);

    return _invalidPropNames;
  };

  const handleSave = () => {
    const _invalidPropNames = getInvalidPropNames();
    if (_invalidPropNames.length > 0) {
      setInvalidPropNames(_invalidPropNames);
      setScrollBottomForError(true);
      return;
    }

    if (Object.keys(changedProps).length === 0) { return; }

    onSaveGateway({
      qid: questionProperties.qid,
      changedProps
    });

    logPaymentEvent('save-gateway-settings', gatewayProperties.type);

    resetChangedProps();
    resetInvalidPropNames();
  };

  useEffect(() => { // run validations after connection
    if (hasConnection) {
      const _invalidPropNames = getInvalidPropNames();
      if (_invalidPropNames.length > 0) {
        setInvalidPropNames(_invalidPropNames);
      }
    }
  }, [hasConnection]);

  /* eslint-disable complexity */
  const detachConnection = async () => {
    const { isPaymentStoreInBasicFields, paymentType } = gatewayProperties;
    const fromFieldsPanel = isPaymentStoreInBasicFields;
    const gatewayWithProductType = !fromFieldsPanel && paymentType === 'product';

    if (!(fromFieldsPanel || gatewayWithProductType)) { return false; }

    let isSuccessful = true;
    if (hasConnection) {
      const res = await detachPaymentConnection(resource, checkoutFormID);
      if (!(typeof res === 'boolean' && res)) { isSuccessful = false; }
    }

    // log gateway and connection remove
    if (isSuccessful) {
      const { name } = supportedGatewayConnectionPropsList[childType || gatewayProperties.type];
      const apm = supportedGatewayConnectionPropsList[parentType]?.name || gatewayProperties.nameAPM;
      const target = { name, ...(apm && { apm }) };
      const logAction = hasConnection ? 'gateway-connection-remove' : 'gateway-remove';

      logPaymentEvent(logAction, JSON.stringify(target));
    }

    if (isSuccessful && typeof onDetachConnection === 'function') {
      const {
        qid,
        type,
        builderLabel,
        multiple,
        showTotal,
        enableLightBox,
        useDecimal,
        decimalMark,
        currency
      } = questionProperties;
      const props = {
        type: 'control_payment',
        isPaymentStoreInBasicFields: true,
        multiple: multiple || 'Yes',
        showTotal: showTotal || 'Yes',
        enableLightBox: enableLightBox || '0',
        useDecimal: useDecimal || 'Yes',
        decimalMark: decimalMark || 'point',
        currency: currency || 'USD',
        prevBuilderLabel: builderLabel || 'Product List',
        prevType: type,
        prevQid: qid,
        isConnected: 0
      };

      onDetachConnection({
        qid,
        props,
        checkoutFormID
      });
    }

    return isSuccessful;
  };
  /* eslint-enable complexity */

  const returnValue = useMemo(() => {
    return {
      gatewayProperties,
      setGatewayProperties,
      setGatewayProperty,
      changePaymentType,
      getConnectionInfo,
      handleSave,
      isComponentVisible,
      detachConnection,
      getQidOnAddQuestion,
      togglePaymentConnectionModal,
      setMultipleGatewayProperties,
      setScrollBottomForError,
      isJotformerAccessingUserResource,
      scrollBottomForError,
      userIsResourceOwner,
      gatewayResourceProps,
      formQuestions,
      checkoutFormID,
      questionProperties,
      hasConnection,
      changedProps,
      invalidPropNames,
      gatewayType,
      resource,
      products,
      isParentAPM,
      childType,
      user,
      isCollaborator,
      paymentConnectionID,
      isInTeamAsset,
      isTeamMemberAndCreator,
      isTeamAdmin,
      isOrganizationAdmin,
      checkConnection
    };
  }, [
    gatewayProperties,
    setGatewayProperties,
    setGatewayProperty,
    changePaymentType,
    getConnectionInfo,
    handleSave,
    isComponentVisible,
    detachConnection,
    getQidOnAddQuestion,
    togglePaymentConnectionModal,
    setMultipleGatewayProperties,
    setScrollBottomForError,
    isJotformerAccessingUserResource,
    scrollBottomForError,
    userIsResourceOwner,
    gatewayResourceProps,
    formQuestions,
    checkoutFormID,
    questionProperties,
    hasConnection,
    changedProps,
    invalidPropNames,
    gatewayType,
    resource,
    products,
    isParentAPM,
    childType,
    user,
    isCollaborator,
    paymentConnectionID,
    isInTeamAsset,
    isTeamMemberAndCreator,
    isTeamAdmin,
    isOrganizationAdmin,
    checkConnection
  ]);

  return <PaymentPropsPanelContext.Provider value={returnValue}>{children}</PaymentPropsPanelContext.Provider>;
});

export default function usePaymentPropsPanel(): I_PAYMENT_PROPS_PANEL_CONTEXT {
  return useContext(PaymentPropsPanelContext);
}
