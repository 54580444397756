import React, { useEffect } from 'react';
import { t } from '@jotforminc/translation';
import { Radio, Button } from '@jotforminc/magnet';
import type { PAYMENT_QUESTION_PROP_NAMES } from '@jotforminc/payment-constants';
import type {
  TOGGLE_SUBTYPES,
  T_VALUE_TRUE,
  T_VALUE_FALSE,
  T_OPTIONS,
  T_SETTINGS_CONDITION,
  T_RADIOTYPE_BUTTON,
  T_ON_TEXT,
  T_OFF_TEXT
} from '@jotforminc/payment-gateways';
import usePaymentPropsPanel from '../Context/usePaymentProperties';
import PwToggle from '../../../fields/toggle';
import { checkStripeAccountCountry } from '../../../../api';

interface IToggle {
  name: PAYMENT_QUESTION_PROP_NAMES;
  offText: T_ON_TEXT;
  onText: T_OFF_TEXT;
  onClick: (val: string) => void;
  subType?: TOGGLE_SUBTYPES;
  radioOptions?: T_OPTIONS;
  valueTrue: T_VALUE_TRUE;
  valueFalse: T_VALUE_FALSE;
  radioType?: T_RADIOTYPE_BUTTON;
  selectedCondition: T_SETTINGS_CONDITION;
  disableCondition: T_SETTINGS_CONDITION;
  BuilderToggle: any;
  title: string;
  description: string;
}

const Toggle: React.FunctionComponent<IToggle> = ({
  name,
  onText,
  offText,
  onClick,
  subType,
  radioOptions,
  valueTrue,
  valueFalse,
  selectedCondition,
  radioType,
  disableCondition,
  BuilderToggle,
  title,
  description
}) => {
  const {
    gatewayProperties,
    resource,
    checkoutFormID,
    setGatewayProperties
  } = usePaymentPropsPanel();
  const value = gatewayProperties[name];
  const newValue = selectedCondition(gatewayProperties) ? valueFalse : valueTrue;
  const isSelected = selectedCondition(gatewayProperties);
  const isDynamicToggle = subType === 'dynamicToggle';
  const isRadioGroup = subType === 'withRadioGroup' && radioOptions && isSelected;
  const isRadiotypeButton = isRadioGroup && radioType === 'button';

  useEffect(() => {
    if (isDynamicToggle) {
      switch (gatewayProperties.type) {
        case 'control_stripe':
          if (name === 'stripeLink') {
            const enableStripeLink = async () => {
              const isValidAccountCountry = await checkStripeAccountCountry(checkoutFormID, gatewayProperties.qid);
              if (isValidAccountCountry) {
                // save it as state
                setGatewayProperties({ ...gatewayProperties, isValidAccountCountry });
              }
            };
            setTimeout(() => { enableStripeLink(); }, 1000); // needs to wait somehow
          }
          break;
        default:
          break;
      }
    }
  }, []);

  return (
    <>
      <div className='flex justify-between items-center'>
        <div className='flex flex-col mr-2'>
          <span className='text-sm line-height-md tracking-lg font-bold'>{t(title)}</span>
          {description && (<span className='color-gray-200 text-xs'>{t(description)}</span>)}
        </div>
        {
          resource !== 'FORM' ? (
            <PwToggle
              id={name}
              onChange={() => onClick(newValue)}
              isSelected={isSelected}
              valueTrue={valueTrue}
              valueFalse={valueFalse}
              offText={t(offText || 'OFF')}
              onText={t(onText || 'ON')}
              readOnly={disableCondition ? disableCondition(gatewayProperties) : false}
            />
          ) : (
            <BuilderToggle
              type="narrow"
              labelId={name}
              labelNameTrue={t(onText || 'ON')}
              labelNameFalse={t(offText || 'OFF')}
              valueChecked={valueTrue}
              valueUnchecked={valueFalse}
              isDisabled={disableCondition ? disableCondition(gatewayProperties) : false}
              isSelected={isSelected}
              onChange={() => onClick(newValue)}
              testId={name}
            />
          )
        }

      </div>
      {isRadioGroup && !radioType && (
        radioOptions.map(({ id, key, text }) => {
          return (
            <label htmlFor={`${name}_${key}`} className='apm-list mt-3 bg-gray-500 flex flex-row items-center px-3 py-2.5 radius text-sm line-height-xs font-normal'>
              <Radio
                name={key}
                value={key}
                id={`${name}_${id}`}
                theme="dark"
                size="medium"
                onChange={() => onClick(key)}
                checked={value === key}
              />
              <span className="ml-3">{t(text)}</span>
            </label>
          );
        })
      )}
      {isRadiotypeButton && (
        <div className='bg-gray-700 radius flex flex-row w-full items-center max-h-10 mt-4'>
          {
            radioOptions.map(({ id, key, text }) => {
              return (
                <label htmlFor={`${name}_${key}`} className='basis-6/12 p-1 radius m-auto'>
                  <Radio
                    name={key}
                    value={key}
                    id={`${name}_${id}`}
                    theme="dark"
                    size="medium"
                    onChange={() => onClick(key)}
                    checked={value === key}
                    className="hidden"
                  />
                  <Button
                    size="small"
                    fullWidth
                    onClick={() => onClick(key)}
                    className={`focus:outline-0 ${value !== key ? 'bg-gray-700 hover:bg-gray-700' : ''}`}
                  >
                    <span className='text-sm line-height-xs font-normal color-white'>{t(text)}</span>
                  </Button>
                </label>
              );
            })
          }
        </div>
      )}
    </>
  );
};

Toggle.defaultProps = {
  radioType: undefined, // renders default type
  radioOptions: undefined,
  subType: undefined
};

export default Toggle;
