import React, { useState } from 'react';
import {
  string, func, bool, shape
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconCopyFilled } from '@jotforminc/svg-icons';
import {
  Modal, ModalHeader, ModalBody, ModalActions, Button
} from '@jotforminc/magnet';

import CloneSettings from './CloneSettings';
import './styles/CloneModal.scss';

const CloneModal = ({
  title,
  appName,
  onClone,
  onClose,
  isThereAnyForm,
  defaultSetting,
  header: {
    text, description
  },
  buttonText,
  settingsProps,
  ...props
}) => {
  const defaultClonedTitle = t(`Clone of ${title}`);
  const [clonedTitle, setClonedTitle] = useState(defaultClonedTitle);
  const [cloning, setCloning] = useState(false);

  const [cloneSetting, setCloneSetting] = useState(defaultSetting);

  const handleClone = () => {
    setCloning(true);
    onClone({
      title: clonedTitle,
      cloneSetting
    }).finally(() => setCloning(false));
  };

  return (
    <Modal
      {...props}
      open
      ariaLabel={t(text)}
      onClose={onClose}
    >
      <ModalHeader
        icon={IconCopyFilled}
        iconColorStyle={appName}
        title={t(text)}
        description={t(description)}
        onClose={onClose}
      />
      <ModalBody className="modal-body">
        <CloneSettings
          title={clonedTitle}
          onTitleChange={setClonedTitle}
          cloneSetting={cloneSetting}
          onCloneSettingChange={setCloneSetting}
          isThereAnyForm={isThereAnyForm}
          {...settingsProps}
        />
      </ModalBody>
      <ModalActions>
        <Button
          colorStyle="success"
          className="ml-auto"
          loader={cloning}
          onClick={handleClone}
        >
          {t(buttonText)}
        </Button>
      </ModalActions>
    </Modal>
  );
};

CloneModal.propTypes = {
  title: string,
  onClone: func,
  onClose: func,
  defaultSetting: string,
  isThereAnyForm: bool,
  header: shape({ text: string, description: string }),
  buttonText: string,
  appName: string,
  settingsProps: shape({})
};

CloneModal.defaultProps = {
  title: '',
  onClone: f => f,
  onClose: f => f,
  defaultSetting: '',
  isThereAnyForm: false,
  header: { text: '', description: '' },
  buttonText: string,
  appName: '',
  settingsProps: {}
};

export default CloneModal;
