import type { GATEWAY_POWEREDBY_LABELS } from '../types';

const POWERED_BY = 'Powered by';

export const gatewayPoweredByLabels: GATEWAY_POWEREDBY_LABELS = {
  control_bluepay: `${POWERED_BY} Clover`,
  control_echeck: `${POWERED_BY} Authorize.net`,
  control_cashApp: `${POWERED_BY} Square`,
  control_afterpay: `${POWERED_BY} Square`,
  control_clearpay: `${POWERED_BY} Square`
};
