import type { PAYMENT_FIELDS, PAYMENT_QUESTION_PROP_NAMES } from '@jotforminc/payment-constants';
import { COMPONENT_PROPERTIES } from '../../types/index';

import control2co from './control2co';
import controlAfterpay from './controlAfterpay';
import controlApplePayGooglePay from './controlApplePayGooglePay';
import controlAuthnet from './controlAuthnet';
import controlBluepay from './controlBluepay';
import controlBluesnap from './controlBluesnap';
import controlBraintree from './controlBraintree';
import controlCardconnect from './controlCardconnect';
import controlCashApp from './controlCashApp';
import controlChargify from './controlChargify';
import controlClearpay from './controlClearpay';
import controlCybersource from './controlCybersource';
import controlEcheck from './controlEcheck';
import controlEway from './controlEway';
import controlFirstdata from './controlFirstdata';
import controlGocardless from './controlGocardless';
import controlIyzico from './controlIyzico';
import controlMollie from './controlMollie';
import controlMoneris from './controlMoneris';
import controlPagseguro from './controlPagseguro';
import controlPayfast from './controlPayfast';
import controlPayjunction from './controlPayjunction';
import controlPaymentwall from './controlPaymentwall';
import controlPaypal from './controlPaypal';
import controlPaypalComplete from './controlPaypalComplete';
import controlPaypalExpress from './controlPaypalExpress';
import controlPaypalInvoicing from './controlPaypalInvoicing';
import controlPaypalPro from './controlPaypalPro';
import controlPaypalSPB from './controlPaypalSPB';
import controlPaysafe from './controlPaysafe';
import controlPayu from './controlPayu';
import controlPayuMoney from './controlPayuMoney';
import controlSensepass from './controlSensepass';
import controlSkrill from './controlSkrill';
import controlSofort from './controlSofort';
import controlSquare from './controlSquare';
import controlStripe from './controlStripe';
import controlStripeACH from './controlstripeACH';
import controlStripeACHManual from './controlStripeACHManual';
import controlStripeCheckout from './controlStripeCheckout';
import controlVenmo from './controlVenmo';
import controlWorldpay from './controlWorldpay';
import controlWorldpayUS from './controlWorldpayus';

const GATEWAY_RESOURCES = {
  ...control2co,
  ...controlAfterpay,
  ...controlAuthnet,
  ...controlBluepay,
  ...controlBluesnap,
  ...controlBraintree,
  ...controlCardconnect,
  ...controlCashApp,
  ...controlChargify,
  ...controlClearpay,
  ...controlCybersource,
  ...controlEcheck,
  ...controlEway,
  ...controlFirstdata,
  ...controlGocardless,
  ...controlIyzico,
  ...controlMollie,
  ...controlMoneris,
  ...controlPagseguro,
  ...controlPayfast,
  ...controlPayjunction,
  ...controlPaymentwall,
  ...controlPaypal,
  ...controlPaypalComplete,
  ...controlPaypalExpress,
  ...controlPaypalInvoicing,
  ...controlPaypalPro,
  ...controlPaypalSPB,
  ...controlPaysafe,
  ...controlPayu,
  ...controlPayuMoney,
  ...controlSensepass,
  ...controlSkrill,
  ...controlSofort,
  ...controlSquare,
  ...controlStripe,
  ...controlStripeACH,
  ...controlStripeACHManual,
  ...controlStripeCheckout,
  ...controlVenmo,
  ...controlWorldpay,
  ...controlWorldpayUS
};

// consider parent gateways separate as they contain >1 gateways
const PARENT_GATEWAY_RESOURCES = {
  ...controlApplePayGooglePay
};

export const getGatewayResourceProperties = (type: PAYMENT_FIELDS): Partial<Record<PAYMENT_QUESTION_PROP_NAMES, COMPONENT_PROPERTIES>> => {
  const props = GATEWAY_RESOURCES[type];
  if (typeof props === 'undefined') {
    return {};
  }
  return props;
};

export const getChildGatewayResourcePropertiesByParent = (parentType: PAYMENT_FIELDS, childType: PAYMENT_FIELDS): Partial<Record<PAYMENT_QUESTION_PROP_NAMES, COMPONENT_PROPERTIES>> => {
  const props = PARENT_GATEWAY_RESOURCES[parentType]?.[childType];
  if (typeof props === 'undefined') {
    return {};
  }

  return props;
};
