import React from 'react';
// import { InputText, InputNumber } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import type { PAYMENT_QUESTION_PROP_NAMES } from '@jotforminc/payment-constants';
import type { INPUT_SUBTYPES } from '@jotforminc/payment-gateways';
import { InputColor, InputNumber, InputText } from '@jotforminc/magnet';
import usePaymentPropsPanel from '../Context/usePaymentProperties';

interface ITextInput {
  name: PAYMENT_QUESTION_PROP_NAMES;
  subType?: INPUT_SUBTYPES;
  placeholder?: string;
  maxLength: number;
  onChange: (e: any) => void;
  default: string;
}

const TextInput: React.FunctionComponent<ITextInput> = ({
  name,
  subType,
  placeholder,
  onChange,
  maxLength,
  default: defaultValue
}) => {
  const { invalidPropNames, gatewayProperties } = usePaymentPropsPanel();
  const val = gatewayProperties[name] || defaultValue;

  const onInputChange = (e: any) => {
    onChange(e.target.value);
  };

  if (subType === 'color') {
    return (
      <InputColor
        id={name}
        theme='dark'
        defaultValue={val || defaultValue}
        onChange={onInputChange}
      />
    );
  }

  if (subType === 'number') {
    return (
      <InputNumber
        id={name}
        className='payment-input payment-input-number'
        theme='dark'
        colorStyle={invalidPropNames.includes(name) ? 'error' : 'default'}
        placeholder={placeholder && t(placeholder)}
        value={val}
        onChange={onInputChange}
        min='0'
      />
    );
  }

  return (
    <InputText
      id={name}
      className='payment-input payment-input-text'
      theme="dark"
      colorStyle={invalidPropNames.includes(name) ? 'error' : 'default'}
      placeholder={placeholder && t(placeholder)}
      value={val}
      onChange={onInputChange}
      maxLength={maxLength}
    />
  );
};

TextInput.defaultProps = {
  placeholder: undefined,
  subType: undefined
};

export default TextInput;
