import {
  call, put, select, takeEvery
} from 'redux-saga/effects';
import { getCorrectedQuantity } from '../../utils/paymentUtils';
import * as ACTION_TYPES from '../actionTypes';
import SELECTORS from '../selectors';
import * as ACTION_CREATORS from '../actionCreators';
import { SHOPPING_NOTIFICATION_TYPES } from '../../constants';
import { DESTINATION_TYPES } from '../../constants/navigation';
import * as API from '../../modules/api';

function* watchAddOrUpdateCart({ payload: { formID, productID, data } }) {
  const { options: payloadOptions, quantity: payloadQuantity } = data;
  const defaultProduct = yield select(SELECTORS.getProduct(formID, productID));
  const isFastCheckoutActive = yield select(SELECTORS.getIsFastCheckoutActive);
  const currentCartProducts = yield select(SELECTORS.getCart);
  const appID = yield select(SELECTORS.getPortalIDSelector);
  let productListCartProducts = currentCartProducts[formID] || [];
  const correctedQuantity = getCorrectedQuantity(payloadQuantity, defaultProduct);
  const productInCart = productListCartProducts.find(_product => _product.pid === productID);
  const itemID = yield select(SELECTORS.getItemIDByFormID(formID));

  const newProduct = productInCart || defaultProduct;
  const { options = [] } = newProduct;
  const quantityOptionIndex = options.findIndex(option => option.type === 'quantity');
  const hasQuantityOption = quantityOptionIndex !== -1;

  if (payloadOptions) { // It includes quantity option as well when we have one
    newProduct.options = newProduct.options.map((option, index) => (
      { ...option, selected: payloadOptions[index] }
    ));
  } else if (hasQuantityOption) {
    newProduct.options[quantityOptionIndex].selected = correctedQuantity;
  }
  newProduct.quantity = correctedQuantity;

  if (productInCart) {
    if (correctedQuantity === 0) { // Item deletion
      productListCartProducts = productListCartProducts.filter(product => product.pid !== productID);
    } else {
      productListCartProducts = productListCartProducts.map(product => (
        product.pid === productID ? newProduct : product
      ));
    }
  } else { // First addition
    if (!isFastCheckoutActive) {
      yield put(ACTION_CREATORS.shoppingToastAction({
        type: SHOPPING_NOTIFICATION_TYPES.ADD_PRODUCT,
        product: newProduct,
        itemID
      }));
    }
    productListCartProducts.push(newProduct);
  }
  const allCartProducts = !isFastCheckoutActive
    ? { ...currentCartProducts, [formID]: productListCartProducts }
  // Add selected item only, it will be cleared when another product is selected.
    : { [formID]: [newProduct] };

  if (correctedQuantity === 0) {
    yield put(ACTION_CREATORS.shoppingToastAction({
      type: SHOPPING_NOTIFICATION_TYPES.DELETE_PRODUCT
    }));
  }

  const result = yield call(API.updateCart, appID, JSON.stringify(allCartProducts));
  const { checkoutKey } = result;

  yield put(ACTION_CREATORS.setCartProductsAction(allCartProducts));

  if (checkoutKey) {
    yield put(ACTION_CREATORS.setCheckoutKeyAction(checkoutKey));
  }

  if (isFastCheckoutActive) {
    yield put(ACTION_CREATORS.navigateToAction({ to: DESTINATION_TYPES.SPECIAL_PAGE, pageID: 'checkout' }));
  }
}

function* watchFavoriteProduct({ payload: { itemID, productID, noNotification = false } }) {
  const allFavoriteProducts = yield select(SELECTORS.getAllFavoriteProducts);
  const portalID = yield select(SELECTORS.getPortalIDSelector);
  const product = yield select(SELECTORS.getFormProductListProduct(itemID, productID));

  const currentItemFavoriteProducts = allFavoriteProducts[itemID] || [];

  const isProductInFavorites = currentItemFavoriteProducts.find(id => id === productID);

  const notificationType = isProductInFavorites ? SHOPPING_NOTIFICATION_TYPES.UNFAVORITE_PRODUCT : SHOPPING_NOTIFICATION_TYPES.FAVORITE_PRODUCT;

  // Toggle favorite products
  const itemFavoriteProducts = isProductInFavorites ? currentItemFavoriteProducts.filter(id => id !== productID) : [...currentItemFavoriteProducts, productID];

  const favoriteProducts = { ...allFavoriteProducts, [itemID]: itemFavoriteProducts };

  yield call(API.updatePortalUserProperty, portalID, { favoriteProducts: JSON.stringify(favoriteProducts) });
  yield put(ACTION_CREATORS.setFavoriteProductsAction(favoriteProducts));

  if (!noNotification) {
    yield put(ACTION_CREATORS.shoppingToastAction({
      type: notificationType,
      ...notificationType === SHOPPING_NOTIFICATION_TYPES.FAVORITE_PRODUCT && { product }
    }));
  }
}

function* watchAddToCart({ payload: { productID, itemID, action } }) {
  const itemProps = yield select(SELECTORS.getItemWithDefaults(itemID));
  const { products, formID } = itemProps;

  const { options = [] } = products.find(product => product.pid === productID);

  const hasCustomOptions = options.find(option => option?.type !== 'quantity');

  if (hasCustomOptions) {
    yield put(ACTION_CREATORS.openProductDetailAction({ productID, itemID }));
    return;
  }

  yield put(ACTION_CREATORS.addOrUpdateCartAction(formID, productID, action));
}

function* watchProductListItemSelections() {
  const activeProduct = yield select(SELECTORS.getActiveProduct);
  if (activeProduct?.productID) {
    yield put(ACTION_CREATORS.setActiveProduct('', ''));
  }
}

export default function* () {
  yield takeEvery(ACTION_TYPES.FAVORITE_PRODUCT, watchFavoriteProduct);
  yield takeEvery(ACTION_TYPES.ADD_OR_UPDATE_CART, watchAddOrUpdateCart);
  yield takeEvery(ACTION_TYPES.ON_PRODUCT_ADD_TO_CART, watchAddToCart);
  yield takeEvery(ACTION_TYPES.SELECT_PORTAL_ITEM, watchProductListItemSelections);
}
