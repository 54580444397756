import { Tab, TabList } from '@jotforminc/tabs';
import { elementType, string } from 'prop-types';
import React, { useMemo } from 'react';
import PanelItemDescriptionRenderer from '../PanelItemDescriptionRenderer';
import { RightPanelTabLists } from '../../../../constants/rightPanel';
import { RPTabButtonContainer, RPTabButtonWrapper } from '../../../RightPanelContainer';
import NavigationBarProperties from '../NavigationBarProperties';
import AllPagesSettingsPanel from '../AllPagesSettingsPanel';

const MenuSettingsPanel = ({
  showNavigationBar,
  showAllPagesIcon,
  showMobileMenuIcon,
  mobileMenuIcon,
  DescriptionRenderer
}) => {
  const tabButton = useMemo(() => RPTabButtonWrapper({ onClick: f => f, itemTabs: RightPanelTabLists().AppName }), []);

  return (
    <TabList
      list={RightPanelTabLists().MenuSettings}
      TabButtonContainer={RPTabButtonContainer}
      TabButton={tabButton}
    >
      <Tab id="General" key="General">
        <NavigationBarProperties
          showNavigationBar={showNavigationBar}
          DescriptionRenderer={DescriptionRenderer}
          mobileMenuIcon={mobileMenuIcon}
          showMobileMenuIcon={showMobileMenuIcon}
        />
      </Tab>
      <Tab id="AllPages" key="AllPages">
        <AllPagesSettingsPanel
          showAllPagesIcon={showAllPagesIcon}
        />
      </Tab>
    </TabList>
  );
};

MenuSettingsPanel.propTypes = {
  showNavigationBar: string,
  showAllPagesIcon: string,
  showMobileMenuIcon: string,
  mobileMenuIcon: string,
  DescriptionRenderer: elementType
};

MenuSettingsPanel.defaultProps = {
  showNavigationBar: '',
  showAllPagesIcon: '',
  showMobileMenuIcon: '',
  mobileMenuIcon: '',
  DescriptionRenderer: PanelItemDescriptionRenderer
};

export default MenuSettingsPanel;
