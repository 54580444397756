import React from 'react';
import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';

import { isEnterprise } from '@jotforminc/enterprise-utils';
import { FEATURE_NAMES } from '../constants/features';
import { isFeatureEnabled } from './features/helper';
import { ITEM_TYPES } from '../constants/itemTypes';
import Button from '../components/Button';
import { isYes } from '../utils';
import { BUTTON_ROLE_TYPES } from '../modules/Builder/components/HomePage/RightPanel/ButtonActions/buttonRoleTypes';
import DeletedFormWarning from '../components/DeletedFormWarning';
import DonationGatewayWarning from '../components/DonationGatewayWarning';

const AVAILABLE_ITEM = { itemAvailable: true };

const returnEmptyNode = text => (
  <>
    {text}
  </>
);

const getLinkedText = (text, link) => (
  <Button
    onClick={() => (
      handleCustomNavigation(link, '_blank', true)
    )}
  >
    {text}
  </Button>
);

const handleCheckProductList = ({ isPaymentOverQuota, overQuotaUpgradeLink, itemProperties }) => {
  const products = itemProperties?.products;
  if (!products || products.length === 0) {
    return {
      itemAvailable: false,
      error: returnEmptyNode(t('This element won\'t be visible until you add a product.'))
    };
  }

  if (isPaymentOverQuota && isFeatureEnabled(FEATURE_NAMES.OverQuota)) {
    return {
      itemAvailable: false,
      error: (
        <>
          {'You\'re out of '}
          <b>payment submission </b>
          limit.
          {' '}
          {getLinkedText(' Upgrade to Collect Payment', overQuotaUpgradeLink)}
        </>
      )
    };
  }

  return AVAILABLE_ITEM;
};

const handleCheckParagraph = ({ itemProperties: { content } }) => {
  if (!content) {
    return {
      itemAvailable: false,
      error: returnEmptyNode(t('This element will be hidden until you add some content.'))
    };
  }
  return AVAILABLE_ITEM;
};

const handleCheckDocument = ({ itemProperties: { fileURL, fileType = '' } }) => {
  if (!fileURL) {
    return {
      itemAvailable: false,
      error: returnEmptyNode(t('This element won\'t be visible until you upload a file.'))
    };
  }

  if (fileType.startsWith('video/')) {
    return {
      itemAvailable: true,
      error: returnEmptyNode(t('The video cannot be played in any browser on an iOS device or in a Safari browser.'))
    };
  }

  return AVAILABLE_ITEM;
};

const handleCheckImage = ({ itemProperties: { imageURL } }) => {
  if (!imageURL) {
    return {
      itemAvailable: false,
      error: returnEmptyNode(t('This element will be hidden until you upload an image.'))
    };
  }

  return AVAILABLE_ITEM;
};

const handleCheckLink = ({ itemProperties: { title }, emptyItemProperties: { title: emptyItemTitle } }) => {
  if (!title || (title === emptyItemTitle)) {
    return {
      itemAvailable: false,
      error: returnEmptyNode(t('This element won\'t be visible until you add a URL.'))
    };
  }

  return AVAILABLE_ITEM;
};

const getResourceMessage = resourceType => {
  const resourceNameMap = {
    [ITEM_TYPES.TABLE_LINK]: 'table',
    [ITEM_TYPES.REPORT_LINK]: 'report',
    [ITEM_TYPES.SENTBOX_LINK]: 'form',
    [ITEM_TYPES.SIGN_LINK]: 'sign document'
  };

  return `This ${resourceNameMap[resourceType]} has been deleted and won't be visible in the app.`;
};

const handleCheckResource = ({ itemProperties: { type: itemType, status } }) => {
  if (['PURGED', 'DELETED', 'TRASHED'].includes(status)) {
    return { itemAvailable: false, error: getResourceMessage(itemType) };
  }

  return AVAILABLE_ITEM;
};

const handleCheckSentboxLink = ({ itemProperties, appProperties }) => {
  const { itemAvailable, error } = handleCheckResource({ itemProperties });
  if (!itemAvailable) {
    return { itemAvailable, error };
  }

  const appSSOProtected = isYes(appProperties.ssoProtected);
  const appAssigneeProtected = isYes(appProperties.assigneeProtected);
  const appHasOrganizationAccess = !!appProperties.organizationAccess;

  const isPublicAccessibleApp = !appAssigneeProtected && !appSSOProtected && !appHasOrganizationAccess;
  if (isPublicAccessibleApp && isEnterprise()) {
    return {
      itemAvailable: false,
      error: returnEmptyNode(t('This sentbox is private and it won\'t be available in the app unless you make the app also private.'))
    };
  }

  return AVAILABLE_ITEM;
};

const handleCheckSignLink = ({ itemProperties }) => {
  const { itemAvailable, error } = handleCheckResource({ itemProperties });
  if (!itemAvailable) {
    return { itemAvailable, error };
  }

  const { signerCount, embedDocumentKey } = itemProperties;
  if ((signerCount !== 1) || !embedDocumentKey) {
    return {
      itemAvailable: false,
      error: returnEmptyNode(t('This sign document\'s link has been disabled and won\'t be visible in the app.'))
    };
  }

  return AVAILABLE_ITEM;
};

const handleCheckForm = ({ itemProperties, appProperties }) => {
  const {
    status: formStatus,
    ssoProtected,
    organizationAccess,
    assigneeProtected,
    limitSubmission,
    expireDate,
    id
  } = itemProperties;

  // TODO :: commonize @reso
  const appSSOProtected = isYes(appProperties.ssoProtected);
  const appAssigneeProtected = isYes(appProperties.assigneeProtected);
  const appHasOrganizationAccess = !!appProperties.organizationAccess;
  const isPublicAccessibleApp = !appAssigneeProtected && !appSSOProtected && !appHasOrganizationAccess;

  const isFormDeleted = formStatus === 'DELETED';
  const isFormDisabled = formStatus === 'DISABLED';
  const isFormSuspended = formStatus === 'SUSPENDED';

  const formSSOProtected = isYes(ssoProtected);
  const formAssigneeProtected = isYes(assigneeProtected);
  const formHasOrganizationAccess = !!organizationAccess;
  const formHasPrivacyConfliction = !appSSOProtected && formSSOProtected;
  const formHasDisabledSetting = limitSubmission && expireDate && (limitSubmission !== 'No Limit' || expireDate !== 'No Limit');
  const formSettingsLink = `/build/${id}/settings`;

  switch (true) {
    case isFormDeleted:
      return {
        itemAvailable: false,
        error: <DeletedFormWarning formID={id} />
      };
    case isFormSuspended:
      return {
        itemAvailable: false,
        error: returnEmptyNode(t(`This form has been ${formStatus.toLowerCase()} and won't be visible in the app.`))
      };
    case formHasPrivacyConfliction:
      return {
        itemAvailable: false,
        error: returnEmptyNode(t('This form is SSO protected and it won\'t be available in the app unless you make the app also SSO protected.'))
      };
    case isFormDisabled:
      return {
        itemAvailable: true,
        error: (
          <>
            {t('This form has been disabled.')}
            {getLinkedText(t('Go to the form\'s settings.'), formSettingsLink)}
          </>
        )
      };
    case formHasDisabledSetting:
      return {
        itemAvailable: true,
        error: (
          <>
            {t('This form has a disable setting.')}
            {getLinkedText(t('Go to the form\'s settings.'), formSettingsLink)}
          </>
        )
      };
    case (isEnterprise() && isPublicAccessibleApp && (formAssigneeProtected || formHasOrganizationAccess)):
      return {
        itemAvailable: false,
        error: returnEmptyNode(t('This form is private and it won\'t be available in the app unless you make the app also private.'))
      };
    default:
      return AVAILABLE_ITEM;
  }
};

const handleCheckButton = ({ itemProperties, appProperties }) => {
  const { title, buttonRole, buttonValue } = itemProperties;
  switch (true) {
    case !title:
      return {
        itemAvailable: false,
        error: returnEmptyNode(t('This element won’t be visible until a title is added.'))
      };
    case !buttonRole:
      return {
        itemAvailable: false,
        error: returnEmptyNode(t('This element won’t be visible until you select a button action.'))
      };
    case !buttonValue && buttonRole !== 'share':
      const roleNameMap = {
        [BUTTON_ROLE_TYPES.LINK]: 'a link',
        [BUTTON_ROLE_TYPES.MAIL]: 'an email',
        [BUTTON_ROLE_TYPES.PHONE]: 'a phone number',
        [BUTTON_ROLE_TYPES.FORM]: 'a form',
        [BUTTON_ROLE_TYPES.NAVIGATION]: 'a page'
      };
      return {
        itemAvailable: false,
        error: returnEmptyNode(t(`This element won't be visible until ${roleNameMap[buttonRole]} is added.`))
      };
    default:
      if (buttonRole === BUTTON_ROLE_TYPES.FORM) {
        return handleCheckForm({ itemProperties, appProperties });
      }

      return AVAILABLE_ITEM;
  }
};

const handleCheckWidget = ({
  itemProperties: {
    clientID, images, lat, lng, apiKey
  }
}) => {
  switch (clientID) {
    case '529cd0ea8afa8f742d000004': { // IMAGE SLIDER
      if (!images) {
        return {
          itemAvailable: false,
          error: returnEmptyNode(t('This element will be hidden until you upload some images.'))
        };
      }
      return AVAILABLE_ITEM;
    }
    case '5374688fa2c8bddc16000019': { // Show Map Location
      switch (true) {
        case !lat:
          return {
            itemAvailable: false,
            error: returnEmptyNode(t('This element will be hidden until you enter latitude.'))
          };
        case !lng:
          return {
            itemAvailable: false,
            error: returnEmptyNode(t('This element will be hidden until you enter longitude.'))
          };
        case !apiKey:
          return {
            itemAvailable: false,
            error: returnEmptyNode(t('This element will be hidden until you Google Maps API Key.'))
          };
        default:
          return AVAILABLE_ITEM;
      }
    }
    case '0fff4c94790070b09933edd8': { // Open Street Map
      switch (true) {
        case !lat:
          return {
            itemAvailable: false,
            error: returnEmptyNode(t('This element will be hidden until you enter latitude.'))
          };
        case !lng:
          return {
            itemAvailable: false,
            error: returnEmptyNode(t('This element will be hidden until you enter longitude.'))
          };
        default:
          return AVAILABLE_ITEM;
      }
    }
    default:
      return AVAILABLE_ITEM;
  }
};

const handleDonation = ({
  isPaymentOverQuota, overQuotaUpgradeLink, appProperties: { isGatewayConfigured, isGatewayConnected = false }, actionTrackerType
}) => {
  if (isPaymentOverQuota && isFeatureEnabled(FEATURE_NAMES.OverQuota)) {
    return {
      itemAvailable: false,
      error: (
        <>
          {'You\'re out of '}
          <b>payment submission </b>
          limit.
          {' '}
          {getLinkedText(' Upgrade to Collect Payment', overQuotaUpgradeLink)}
        </>
      )
    };
  }

  if (isYes(isGatewayConfigured) || isGatewayConnected) return AVAILABLE_ITEM;

  return {
    itemAvailable: false,
    error: <DonationGatewayWarning actionTrackerType={actionTrackerType} />
  };
};

const handleCheckContactInformation = () => {
  return {
    itemAvailable: true,
    error: returnEmptyNode(t('Contact Information Page will be shown during the checkout process to your users.'))
  };
};

const handleListInfoBox = () => {
  return {
    itemAvailable: true
  };
};

const ITEM_CHECKERS = {
  [ITEM_TYPES.PRODUCT_LIST]: handleCheckProductList,
  [ITEM_TYPES.PARAGRAPH]: handleCheckParagraph,
  [ITEM_TYPES.DOCUMENT]: handleCheckDocument,
  [ITEM_TYPES.IMAGE]: handleCheckImage,
  [ITEM_TYPES.LINK]: handleCheckLink,
  [ITEM_TYPES.TABLE_LINK]: handleCheckResource,
  [ITEM_TYPES.REPORT_LINK]: handleCheckResource,
  [ITEM_TYPES.SENTBOX_LINK]: handleCheckSentboxLink,
  [ITEM_TYPES.SIGN_LINK]: handleCheckSignLink,
  [ITEM_TYPES.FORM]: handleCheckForm,
  [ITEM_TYPES.BUTTON]: handleCheckButton,
  [ITEM_TYPES.WIDGET]: handleCheckWidget,
  [ITEM_TYPES.DONATION]: handleDonation,
  [ITEM_TYPES.CONTACT_INFORMATION]: handleCheckContactInformation,
  [ITEM_TYPES.LIST]: handleListInfoBox
};

export const checkItemAvailable = ({
  itemProperties, ...rest
}) => {
  const { type: itemType } = itemProperties;
  const checker = ITEM_CHECKERS[itemType];
  return checker ? checker({ itemProperties, ...rest }) : AVAILABLE_ITEM;
};
