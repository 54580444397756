import React from 'react';
import { translationRenderer } from '@jotforminc/translation';
import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Braintree gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlBraintree: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_braintree: {
    requireBillingAddr: {
      title: 'Require Billing Address and Check AVS',
      type: 'toggle',
      description:
        translationRenderer('Send billing address to Braintree and check [1[{link}]].')({
          renderer1: () => (
            <a href="https://articles.braintreepayments.com/guides/fraud-tools/basic/avs-cvv-rules" target="_blank">
              Address Verification System(AVS)
            </a>
          )
        }),
      selectedCondition: settings => settings.requireBillingAddr === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM']
    },
    askBillingInformation: {
      title: 'Ask Billing Information to Customer',
      type: 'toggle',
      selectedCondition: settings => settings.askBillingInformation === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM']
    },
    companyName: {
      title: 'Company Name',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_textbox'],
      questionTypeToAdd: 'control_textbox',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    emailField: {
      title: 'Customer Email',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    phoneField: {
      title: 'Customer Phone',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_phone'],
      questionTypeToAdd: 'control_phone',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    authOnly: {
      title: 'Authorization Only',
      type: 'toggle',
      selectedCondition: settings => settings.authOnly === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType !== 'subscription'
    },
    braintreeDescriptionField: {
      title: 'Product Description Field',
      type: 'input',
      description:
        translationRenderer('API name of the [1[{link}]] to which the selected product names will be passed.')({
          renderer1: () => (
            <a href="https://articles.braintreepayments.com/control-panel/custom-fields#creating-a-custom-field" target="_blank">Braintree custom field</a>
          )
        }),
      default: '',
      resources: ['FORM', 'APP']
    },
    ccTitle: {
      title: 'Credit Card Label Text',
      type: 'input',
      default: '',
      resources: ['FORM', 'APP']
    }
  }
};

export default controlBraintree;
