/* eslint-disable max-statements */
import React, {
  useEffect, useState, useRef, useCallback, forwardRef, useImperativeHandle
} from 'react';
import {
  array, arrayOf, bool, elementType, func, shape, string
} from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FileUpload } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';
import { TabListWithStyles, Tab } from '@jotforminc/tabs';
import { useDebounce } from '@jotforminc/hooks';
import {
  Button, InputText, Textarea, FormLabel, FormControl, FormHelperText
} from '@jotforminc/magnet';
import { IconMagnifyingGlass } from '@jotforminc/svg-icons';
import IconSelector from './IconSelector';

import UploadedImagesPanel from './UploadedImagesPanel';
import ImagePreview from './ImagePreview';
import LoadingScreen from './LoadingScreen';

import '../styles/ImageUpload.scss';

import {
  deleteSelectedImage, getUploadedUserImage, searchUnsplash, getRandomImagesFromUnsplash
} from '../actions';
import {
  DEFAULT_TAB_LIST, THEME_MAP, DefaultDeleteButton, DefaultSelectButton, modesRequireUploadedImages
} from '../constants';
import { filterImages } from '../utils';

const ImageUpload = forwardRef(({
  onImageUpload,
  onImageSelect,
  onImageChange,
  onImageRemove,
  isAltTextActive,
  onAltTextChange,
  renderTabs,
  allowMultipleSelection,
  forceSelect,
  optimize,
  addNotification,
  limitType,
  multipleFileUpload,
  targetURL,
  onlyColoredIcons,
  linkTextInputDefaultValue,
  onLinkTextInputSubmit,
  linkTextInputPlaceholder,
  imgDescInputPlaceholder,
  imageList: originalImageList,
  galleryImageList,
  useAPI,
  theme,
  showLabel,
  showActionButtons,
  SelectButtonRenderer,
  DeleteButtonRenderer,
  ActionButtonsWrapper,
  onLinkInputChange,
  ImageUploadPreviewRenderer,
  ImageLoadingScreenRenderer,
  allowedTypes,
  allowImageUpload,
  onFileUploading,
  onFileRemove,
  isFileUploading,
  value: uploadedFile,
  removeButtonText,
  PreviewThumbnail,
  FileInputRenderer,
  showOnlyImages,
  mode,
  TextWrapperRenderer,
  onFileUploadError,
  noUploadedImageText,
  galleryMode,
  inputButtonText,
  initialSelectedImage,
  groupedGalleryClass,
  groupedGalleryImageList,
  isDeleteSelectedDisabled
}, ref) => {
  const [imageList, setImageList] = useState(showOnlyImages ? filterImages(originalImageList) : originalImageList);
  const [altText, setAltText] = useState('');
  const [fileUploadDisplayMode, setFileUploadDisplayMode] = useState(uploadedFile.url ? 'display' : 'upload');
  const defaultSelectedImg = initialSelectedImage || '';
  const [selectedImage, setSelectedImage] = useState(allowMultipleSelection ? [] : defaultSelectedImg);
  const [isActionButtonsVisible, setActionButtonsVisible] = useState(false);
  const [unsplashImageList, setUnsplashImageList] = useState([]);
  const [randomUnsplashImageList, setRandomUnsplashImageList] = useState([]);
  const [unsplashPageNumber, setUnsplashPageNumber] = useState(1);
  const [stopLoadMore, setStopLoadMore] = useState(false);
  const allowGettingUploadedImages = modesRequireUploadedImages.includes(mode);
  const textInputRef = useRef();
  const unsplashSearchInputRef = useRef();
  const isBranding21 = true;

  useImperativeHandle(ref, () => ({
    setSelectedImage
  }));

  const prepareTabTitleList = () => {
    return renderTabs.reduce((prev, tabKey) => {
      return {
        ...prev,
        [tabKey]: {
          ...DEFAULT_TAB_LIST[tabKey],
          label: t(DEFAULT_TAB_LIST[tabKey]?.label)
        }
      };
    }, {});
  };
  const [tabTitleList, setTabTitleList] = useState(prepareTabTitleList());

  const handleGetUploadedImages = () => {
    getUploadedUserImage(showOnlyImages)
      .then((images = []) => setImageList(images));
  };

  const handleAltTextChange = ({ target: { value } }) => {
    setAltText(value);
  };

  const handleImageSelect = url => () => {
    onImageChange(url, selectedImage);

    if (allowMultipleSelection) {
      setSelectedImage([
        ...selectedImage,
        url
      ]);
    } else {
      if (forceSelect || !showActionButtons) {
        onImageSelect(url);
      }
      setSelectedImage(url);
    }
  };

  const handleImageDeselect = url => () => {
    if (allowMultipleSelection) {
      setSelectedImage(selectedImage.filter(image => image !== url));
    }
  };

  const handleUseSelectedImage = url => {
    onImageSelect(url);
  };

  const handleImageUpload = fileData => {
    onImageUpload(fileData);
  };

  const handleLinkTextClick = () => {
    const { current: { value } } = textInputRef;
    onLinkTextInputSubmit(value);
  };

  const handleFileUploadDisplayModeChange = displayMode => {
    setFileUploadDisplayMode(displayMode);
  };

  const renderAltTextInput = () => (
    <div className="jfTabs-body-row image-description-wrapper">
      <FormControl id="imageAltText">
        <FormLabel>{t('Image Description')}</FormLabel>
        <InputText
          className="w-full"
          value={altText}
          onChange={handleAltTextChange}
          placeholder={imgDescInputPlaceholder}
        />
        <FormHelperText className="flex items-center text-xxs gap-1 color-navy-300 text-justify">
          {t('Add alt text for accessibility and SEO purposes. This text will be shown if the image can\'t be loaded.')}
        </FormHelperText>
      </FormControl>
    </div>
  );

  const handleDeleteSelectedImage = () => {
    setSelectedImage('');
    setImageList(imageList.filter(image => !selectedImage.includes(image)));
    if (useAPI) {
      deleteSelectedImage(selectedImage)
        .then(() => handleGetUploadedImages());
    }
    onImageRemove(selectedImage);
  };

  const renderActionButtons = () => (
    <ActionButtonsWrapper>
      <DeleteButtonRenderer onClick={() => handleDeleteSelectedImage()} disabled={isDeleteSelectedDisabled} />
      {
        !forceSelect
        && <SelectButtonRenderer onClick={() => handleUseSelectedImage(selectedImage)} />
      }
    </ActionButtonsWrapper>
  );

  const renderLinkTabInput = () => {
    if (allowMultipleSelection) {
      return (
        <Textarea
          ref={textInputRef}
          onChange={({ target: { value } }) => onLinkInputChange(value)}
          defaultValue={linkTextInputDefaultValue}
          placeholder={linkTextInputPlaceholder}
          rows={5}
          resize="both"
        />
      );
    }
    return (
      <InputText
        className="w-full"
        ref={textInputRef}
        onChange={({ target: { value } }) => onLinkInputChange(value)}
        defaultValue={linkTextInputDefaultValue}
        placeholder={linkTextInputPlaceholder}
      />
    );
  };

  const renderEnterUrl = () => (
    <div className="jfTabs-body-row">
      <FormControl id="imgUrl">
        {showLabel && (
          <FormLabel>{t('Enter URL')}</FormLabel>
        )}
        {renderLinkTabInput()}
      </FormControl>
      {/* eslint-disable */}
      {showActionButtons && <div className="line">
        <Button className="text-uppercase" onClick={handleLinkTextClick}>
          {t('Add Link')}
        </Button>
      </div>}
      {/* eslint-enable */}
    </div>
  );

  const renderUploadImage = () => {
    return (
      <>
        <div className="tethers-wrapper">
          <FileUpload
            showFile={uploadedFile}
            isFileUploading={isFileUploading}
            multi={false}
            onFileUploading={onFileUploading}
            imageUpload={allowImageUpload}
            onFileUpload={fileData => handleImageUpload(fileData)}
            allowedTypes={allowedTypes}
            targetURL={targetURL}
            multipleFileUpload={multipleFileUpload}
            optimize={optimize}
            addNotification={addNotification}
            limitType={limitType}
            displayUpload={true}
            translate={t}
            FilePreviewRenderer={ImageUploadPreviewRenderer}
            LoadingScreenRenderer={ImageLoadingScreenRenderer}
            onFileRemove={onFileRemove}
            removeButtonText={removeButtonText}
            Thumbnail={PreviewThumbnail}
            FileInputRenderer={FileInputRenderer}
            TextWrapperRenderer={TextWrapperRenderer}
            onError={onFileUploadError}
            onDisplayModeChange={handleFileUploadDisplayModeChange}
            buttonText={inputButtonText}
          />
        </div>
        {fileUploadDisplayMode === 'display' && isAltTextActive && renderAltTextInput()}
        {fileUploadDisplayMode === 'upload' && isAltTextActive && renderEnterUrl()}
      </>
    );
  };

  const renderChooseImage = () => (
    <>
      <div className="prevUploads-list">
        <UploadedImagesPanel
          noUploadedImageText={noUploadedImageText}
          uploadedImageList={imageList}
          onImageSelect={handleImageSelect}
          onImageDeselect={handleImageDeselect}
          selectedImage={selectedImage}
          allowMultipleSelection={allowMultipleSelection}
          getUploadedImagesFromServer={useAPI}
        />
      </div>
      {isAltTextActive && renderAltTextInput()}
      {isActionButtonsVisible && showActionButtons && renderActionButtons()}
    </>
  );

  const renderGallery = () => (
    <>
      <div className={`prevUploads-list ${galleryMode}`}>
        <UploadedImagesPanel
          uploadedImageList={galleryImageList}
          onImageSelect={handleImageSelect}
          onImageDeselect={handleImageDeselect}
          selectedImage={selectedImage}
          allowMultipleSelection={allowMultipleSelection}
        />
      </div>
      {isAltTextActive && renderAltTextInput()}
      {isActionButtonsVisible && showActionButtons && renderActionButtons()}
    </>
  );

  const renderGroupedGallery = () => {
    return (
      <div className={groupedGalleryClass}>
        {groupedGalleryImageList.map(list => (
          <div data-testid="grouped-gallery">
            {t(list.text)}
            <div className={`prevUploads-list ${galleryMode}`}>
              <UploadedImagesPanel
                uploadedImageList={list.images}
                onImageSelect={url => () => {
                  setSelectedImage(url);
                  onImageSelect(url);
                }}
                onImageDeselect={url => () => {
                  setSelectedImage('');
                  onImageRemove(url);
                }}
                selectedImage={selectedImage}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const createUnsplashImageList = (list = []) => list.map(image => {
    const {
      id = '',
      urls: { full = '', thumb = '' } = {},
      user: { name = '' } = {}
    } = image;

    return {
      urlFull: full,
      urlThumb: thumb,
      userInfo: name,
      id
    };
  });

  const getRandomUnsplashImages = () => {
    getRandomImagesFromUnsplash().then(response => {
      const list = createUnsplashImageList(response);
      const newList = [...randomUnsplashImageList, ...list];
      setRandomUnsplashImageList(newList);
      setUnsplashImageList(newList);
    }).catch(() => {});
  };

  const getUnsplashImages = loadMore => {
    const keyword = unsplashSearchInputRef && unsplashSearchInputRef.current && unsplashSearchInputRef.current.value;

    if (keyword) {
      if (loadMore && stopLoadMore) return;
      const count = 16;
      const page = loadMore ? unsplashPageNumber + 1 : 1;

      searchUnsplash(keyword, count, page).then(response => {
        const newImageList = createUnsplashImageList(response);
        setUnsplashImageList(currList => (loadMore ? [...currList, ...newImageList] : newImageList));
        setUnsplashPageNumber(page);
        setStopLoadMore(response.length < count);
      }).catch(() => {
        setUnsplashImageList([]);
        setStopLoadMore(true);
      });
    } else if (loadMore) {
      getRandomUnsplashImages();
    } else {
      setUnsplashImageList(randomUnsplashImageList);
    }
  };

  const renderUnsplash = () => {
    return (
      <div>
        <InputText
          className="w-full"
          ref={unsplashSearchInputRef}
          placeholder={t('Search for an image')}
          onChange={useDebounce(() => {
            setUnsplashPageNumber(1);
            setStopLoadMore(false);
            getUnsplashImages();
          }, 600)}
          prefix={{ icon: IconMagnifyingGlass }}
        />
        <InfiniteScroll
          hasMore={!(unsplashSearchInputRef && unsplashSearchInputRef.current && unsplashSearchInputRef.current.value && stopLoadMore)}
          dataLength={unsplashImageList.length}
          next={() => getUnsplashImages(true)}
          className="prevUploads-list"
          height="190px"
        >
          <UploadedImagesPanel
            uploadedImageList={unsplashImageList}
            onImageSelect={urlFull => () => {
              setSelectedImage(urlFull);
              onImageSelect(urlFull);
            }}
            onImageDeselect={urlFull => () => {
              setSelectedImage('');
              onImageRemove(urlFull);
            }}
            selectedImage={selectedImage}
          />
        </InfiniteScroll>
      </div>
    );
  };

  const renderLinkTab = () => (
    <>
      <FormControl id="imageUrl">
        {showLabel && (
          <FormLabel>{t('Enter URL')}</FormLabel>
        )}
        {renderLinkTabInput()}
      </FormControl>
      {isAltTextActive && (
        <div className="jfTabs-body-row">
          <FormControl id="altText">
            <FormLabel>{t('Add Alt Text')}</FormLabel>
            <InputText className="w-full" value={altText} onChange={handleAltTextChange} />
          </FormControl>
        </div>
      )}
      {/* eslint-disable */}
      {showActionButtons && <div className="line">
        <Button className="text-uppercase" onClick={handleLinkTextClick}>
          {t('Add Link')}
        </Button>
      </div>}
      {/* eslint-enable */}
    </>
  );

  const renderIconSelector = () => (
    <IconSelector
      onSuccess={res => handleUseSelectedImage(res.url)}
      onRemove={() => handleUseSelectedImage('')}
      onlyColored={onlyColoredIcons}
    />
  );

  const tabPanelRendererMap = {
    upload: renderUploadImage,
    choose: renderChooseImage,
    link: renderLinkTab,
    icon: renderIconSelector,
    gallery: renderGallery,
    groupedGallery: renderGroupedGallery,
    unsplash: renderUnsplash
  };

  const getRootClassNames = () => {
    const classNames = ['imageUpload'];
    if (isBranding21) classNames.push('branding21');
    if (THEME_MAP[theme]) classNames.push(THEME_MAP[theme]);

    return classNames.join(' ');
  };

  useEffect(() => {
    setActionButtonsVisible(selectedImage.length > 0);
  }, [selectedImage]);

  useEffect(() => {
    setTabTitleList(prepareTabTitleList());
  }, [renderTabs]);

  useEffect(() => {
    if (!imageList.length && useAPI && allowGettingUploadedImages) {
      handleGetUploadedImages();
    }

    if (renderTabs.indexOf('unsplash') > -1) {
      getRandomUnsplashImages();
    }
  }, []);

  useEffect(() => {
    setImageList(showOnlyImages ? filterImages(originalImageList) : originalImageList);
  }, [originalImageList]);

  useEffect(() => {
    onAltTextChange(altText);
  }, [altText]);

  return (
    <TabListWithStyles
      activeTabClassName='is-active'
      tabButtonClassName='tabMenu-inner-link'
      list={tabTitleList}
      // eslint-disable-next-line
      TabButton={useCallback(props => <div {...props} />, [])}
      TabButtonContainer={useCallback(props => <div className='tabMenu' {...props} />, [])}
      Wrapper={useCallback(props => <div className={getRootClassNames()} {...props} />, [])}
      TabListContainer={useCallback(props => <div className='tabContent' {...props} />, [])}
    >
      {
          renderTabs
            .filter(tabKey => Object.keys(tabPanelRendererMap)
              .includes(tabKey))
            .map(tabKey => (
              <Tab id={tabKey} key={tabKey}>
                {tabPanelRendererMap[tabKey]()}
              </Tab>
            ))
        }
    </TabListWithStyles>
  );
});

ImageUpload.propTypes = {
  onImageUpload: func,
  onImageSelect: func,
  onImageChange: func,
  onImageRemove: func,
  addNotification: func,
  onAltTextChange: func,
  onLinkInputChange: func,
  onLinkTextInputSubmit: func,
  isAltTextActive: bool,
  allowMultipleSelection: bool,
  forceSelect: bool,
  optimize: bool,
  multipleFileUpload: bool,
  onlyColoredIcons: bool,
  useAPI: bool,
  showLabel: bool,
  showActionButtons: bool,
  limitType: string,
  targetURL: string,
  linkTextInputDefaultValue: string,
  linkTextInputPlaceholder: string,
  imgDescInputPlaceholder: string,
  imageList: arrayOf(string),
  galleryImageList: array,
  theme: string,
  renderTabs: array,
  ActionButtonsWrapper: elementType,
  SelectButtonRenderer: elementType,
  DeleteButtonRenderer: elementType,
  ImageUploadPreviewRenderer: elementType,
  ImageLoadingScreenRenderer: elementType,
  allowedTypes: string,
  allowImageUpload: bool,
  onFileUploading: func,
  onFileRemove: func,
  isFileUploading: bool,
  value: shape({
    name: string,
    type: string,
    url: string,
    size: string
  }),
  removeButtonText: string,
  PreviewThumbnail: elementType,
  TextWrapperRenderer: elementType,
  FileInputRenderer: elementType,
  mode: string,
  showOnlyImages: bool,
  onFileUploadError: func,
  noUploadedImageText: string,
  galleryMode: string,
  inputButtonText: string,
  initialSelectedImage: string,
  groupedGalleryClass: string,
  groupedGalleryImageList: arrayOf(shape({})),
  isDeleteSelectedDisabled: bool
};

ImageUpload.defaultProps = {
  onImageUpload: f => f,
  onImageSelect: f => f,
  onImageChange: f => f,
  onImageRemove: f => f,
  onAltTextChange: f => f,
  addNotification: f => f,
  onLinkTextInputSubmit: f => f,
  onLinkInputChange: f => f,
  isAltTextActive: false,
  allowMultipleSelection: false,
  forceSelect: false,
  optimize: false,
  multipleFileUpload: false,
  onlyColoredIcons: false,
  useAPI: false,
  showLabel: false,
  showActionButtons: true,
  limitType: '',
  linkTextInputDefaultValue: '',
  theme: '',
  linkTextInputPlaceholder: 'https://',
  imgDescInputPlaceholder: t('Alternative Text'),
  renderTabs: ['upload', 'choose', 'link'],
  imageList: [],
  galleryImageList: [],
  targetURL: undefined,
  ActionButtonsWrapper: props => <div className="prevUploads-button" {...props} />,
  DeleteButtonRenderer: ({ onClick, disabled }) => <DefaultDeleteButton onClick={onClick} disabled={disabled} />, // eslint-disable-line react/prop-types
  SelectButtonRenderer: ({ onClick }) => <DefaultSelectButton onClick={onClick} />, // eslint-disable-line react/prop-types
  ImageUploadPreviewRenderer: ImagePreview,
  ImageLoadingScreenRenderer: LoadingScreen,
  allowedTypes: 'image',
  allowImageUpload: true,
  onFileUploading: f => f,
  onFileRemove: f => f,
  isFileUploading: false,
  value: {},
  removeButtonText: 'Remove Image',
  PreviewThumbnail: undefined,
  TextWrapperRenderer: undefined,
  FileInputRenderer: undefined,
  mode: 'imageUpload',
  showOnlyImages: true,
  onFileUploadError: f => f,
  noUploadedImageText: t('There is no image to show!'),
  galleryMode: '',
  inputButtonText: 'Upload File',
  initialSelectedImage: '',
  groupedGalleryClass: '',
  groupedGalleryImageList: [],
  isDeleteSelectedDisabled: false
};

export default ImageUpload;
