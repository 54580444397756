import { Button, Modal } from '@jotforminc/magnet';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func, string } from 'prop-types';
import { IconXmark } from '@jotforminc/svg-icons';
import TablesRenderer from '../../../modules/PublicApp/ProductRenderer/Renderers/TablesRenderer';
import SELECTORS from '../../../store/selectors';
import { StyledTableProductModalBody } from './styled';
import * as ACTION_CREATORS from '../../../store/actionCreators';
import { getLastUpdatedText } from './helper';

const TableProductModal = ({
  resourceID = '',
  viewID = undefined,
  onClose,
  updatedAt = null
}) => {
  const dispatch = useDispatch();
  const appID = useSelector(SELECTORS.getAppID);
  const { title: resourceTitle = '' } = useSelector(SELECTORS.dsGetColumnsByResourceID({ resourceID, viewID }));

  const handleClose = () => {
    dispatch(ACTION_CREATORS.dsFetchAllResourceLinkedItems({ resourceID, viewID }));
    onClose();
  };

  const userTimezone = useSelector(SELECTORS.getUserTimezone);

  return (
    <Modal open size='xxlarge' onClose={handleClose}>
      <StyledTableProductModalBody>
        <div className='jNewHeader'>
          <div class="jNewHeader-col jNewHeader-titleWrapper alignCenter">
            <h1 class="jNewHeader-title iBlock" contenteditable="false" placeholder="">{resourceTitle}</h1>
            {
                updatedAt
            && (
            <span class="jNewHeader-revisionDate" data-testid="jNewHeader-revisionDate">
              <span
                class="jNewHeader-titleLink"
              >
                <span>{getLastUpdatedText(updatedAt, userTimezone)}</span>
              </span>
            </span>
            )
            }
          </div>
          <div className='absolute right-5 align-center' style={{ alignSelf: 'center' }}>
            <Button
              startIcon={IconXmark}
              colorStyle="secondary"
              rounded
              aria-label="Close Button"
              onClick={handleClose}
              className="ml-auto"
            />
          </div>
        </div>
        <TablesRenderer forModal appID={appID} productID={`${resourceID}${viewID ? `/${viewID}` : ''}`} />
      </StyledTableProductModalBody>
    </Modal>
  );
};

TableProductModal.propTypes = {
  resourceID: string,
  viewID: string,
  onClose: func,
  updatedAt: string
};

export default TableProductModal;
