/* eslint-disable react/prop-types */
import React, {
  useState, useRef, useEffect
} from 'react';
import {
  string, object, func, arrayOf
} from 'prop-types';
import { IconEyeFilled } from '@jotforminc/svg-icons';
import { capitalizeFirstLetter, classNames } from '@jotforminc/utils';
import { t } from '@jotforminc/translation';
import { isEnterprise } from '@jotforminc/enterprise-utils';

import './style.scss';
import RoleSelector from '../RoleSelector';
import { templateConstants } from '../../templateConstants';
import SimilarTemplatesItem from './SimilarTemplatesItem';
// import Button from '../../../Button';
// import texts from '../../../../core/texts';

const PdfTemplatePreviewContent = ({
  frameSrc, template, DOM,
  modalBodyRef, onTemplateClone,
  categoryData, setModalContentLoading, similarTemplates
}) => {
  console.log(DOM);
  const objectRef = useRef();
  const [signers, setSigners] = useState([]);
  const [signAnnotations, setSignAnnotations] = useState([]);
  const [currentParticipantID, setCurrentParticipantID] = useState('');
  const [loadingPreview, setLoadingPreview] = useState(true);
  const templateType = frameSrc.split('/')[1];

  const {
    _id,
    screenshot,
    _description = ''
  } = template;

  const { aliasType } = templateConstants[templateType];

  const resetPreviewStates = () => {
    setSigners([]);
    setSignAnnotations([]);
    setLoadingPreview(true);
    setCurrentParticipantID('');
  };

  useEffect(() => {
    resetPreviewStates();
  }, [template]);

  const getIframeInsideObject = objectEl => {
    const iFrameElement = objectEl.querySelector('IFRAME');
    const { contentDocument, contentWindow } = iFrameElement !== null ? iFrameElement : {};
    return contentDocument || contentWindow.document;
  };

  const isFirstPdfPageRendered = iframeDoc => iframeDoc.getElementsByClassName('PDFV-canvas').length > 0;

  const getOwnerName = owner => {
    const { role, type } = owner;
    if (type === 'owner') return 'Owner';
    return capitalizeFirstLetter(role || type);
  };

  const formatAnnotations = (annotations, iframeDoc) => {
    return annotations.map(annotation => {
      const { id } = annotation;
      return {
        id,
        ref: iframeDoc.querySelector(`[data-annotation-id="${id}"]`)
      };
    });
  };

  const handleLoadObject = (event, tryCount = 0) => {
    const { current: { contentDocument, contentWindow = {} } } = objectRef;
    if (tryCount > 100) {
      setLoadingPreview(false);
    }

    const iframeDoc = getIframeInsideObject(contentDocument);
    if (!iframeDoc || iframeDoc.readyState !== 'complete') {
      setTimeout(() => handleLoadObject(null, tryCount + 1), 100);
      return;
    }

    if (!isFirstPdfPageRendered(iframeDoc)) {
      setTimeout(() => handleLoadObject(null, tryCount + 1), 100);
      return;
    }
    setLoadingPreview(false);
    const { documentProps: { participants }, annotations = [] } = contentWindow;

    const allAnnotations = formatAnnotations(annotations, iframeDoc).filter(annotation => annotation.ref !== null);

    if (allAnnotations.length !== annotations.length && tryCount !== 100) {
      setTimeout(() => handleLoadObject(null, tryCount + 1), 100);
      return;
    }

    setSignAnnotations(allAnnotations);
    setSigners(
      participants.map(singer => {
        return { ...singer, prettyName: getOwnerName(singer) };
      })
    );
  };

  const showHideAnnotationElement = (show, element) => {
    if (show) {
      element.removeAttribute('data-participant');
    } else {
      element.setAttribute('data-participant', 'passive');
    }
  };

  const handleRoleChange = event => {
    const selectedValue = event?.value?.target || event?.value;

    setCurrentParticipantID(selectedValue);
    const { fields: selectedParticipantFields } = signers.find(signer => signer.participantID === selectedValue) || { fields: [] };
    signAnnotations.forEach(annotation => {
      const { ref: annotationElement } = annotation;
      if (selectedValue === '') {
        showHideAnnotationElement(true, annotationElement);
      } else {
        const qid = annotationElement.getAttribute('data-qid');
        const isPassive = !selectedParticipantFields.includes(qid);
        showHideAnnotationElement(!isPassive, annotationElement);
      }
    });
  };

  const signerOptions = [{ prettyName: 'All Roles ', participantID: '' }, ...signers];
  const isRoleBarActive = signers.length > 0;

  const { signable } = template;
  const isSignablePDF = signable === '1';

  const embedPDFCss = '<link rel="stylesheet" type="text/css" href="https://cdn.jotfor.ms/s/pdf-editor/latest/static/css/templates.css"><style>.jfBranding{display:none!important;}</style>';
  return (
    <div className="jfModal-content">
      <div className="jfWizard--content-area">
        {loadingPreview && (
          <>
            <div className="jfWizard--cover-loading">
              <span />
              <span />
              <span />
            </div>
            { isSignablePDF && <img src={screenshot} className="jfWizard--cover" />}
          </>
        )}
        {
          isSignablePDF ? (
            <object
              ref={objectRef}
              type="text/html"
              onLoad={handleLoadObject}
              id="pdf-template-preview"
              data={`/${(aliasType || templateType)}/preview/esign/${_id}`}
              style={{
                width: '100%', opacity: loadingPreview ? 0 : 1, height: loadingPreview ? '0px' : '100%', zIndex: 0
              }}
            />
          ) : (
            <iframe
              title='PDF Preview Area'
              onLoad={() => {
                setTimeout(() => { setLoadingPreview(false); }, 1000);
              }}
              src={`data:text/html,${encodeURIComponent(`${embedPDFCss}${DOM}`)}`}
              style={{
                width: '100%', border: '0', opacity: loadingPreview ? 0 : 1, height: loadingPreview ? '0px' : '100%', zIndex: 0
              }}
            />
          )
        }
      </div>
      {
        isSignablePDF && (
          <div className={classNames('jfWizard-e-sign-bar', isRoleBarActive && 'active')}>
            <div className="jfWizard-e-sign-bar--preview">
              <div className="jfWizard-e-sign-bar--preview-text">
                <IconEyeFilled width="16" height="16" />
                <span>{t('Previewing as')}</span>
                :
              </div>
              <RoleSelector
                options={signerOptions.map(option => {
                  return {
                    text: option.prettyName,
                    value: option.participantID,
                    color: option.color
                  };
                })}
                onChange={handleRoleChange}
                selectedOptionValue={currentParticipantID}
              />
            </div>
            <div className="jfWizard-e-sign-bar--text">
              {t('Customize this template and share it to collect e-signatures')}
            </div>
          </div>
        )
      }
      {(Object.keys(template).length > 0) && (
        <div className='content-area'>
          {!isEnterprise() && (
            <>
              <div className='jfWizard-modal-detail full'>
                <div className='description-area' dangerouslySetInnerHTML={{ __html: _description }} />
              </div>
            </>
          )}
          {similarTemplates.length > 0 && (
            <SimilarTemplatesItem
              parentTemplateID={_id}
              templateType={templateType}
              modalBodyRef={modalBodyRef}
              categoryData={categoryData}
              onTemplateClone={onTemplateClone}
              similarTemplates={similarTemplates}
              setModalContentLoading={setModalContentLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

PdfTemplatePreviewContent.defaultProps = {
  template: {},
  frameSrc: '/',
  categoryData: null,
  similarTemplates: [],
  onTemplateClone: f => f,
  modalBodyRef: undefined
  // isCloningTemplate: false
};

PdfTemplatePreviewContent.propTypes = {
  frameSrc: string,
  template: object,
  categoryData: object,
  modalBodyRef: object,
  onTemplateClone: func,
  // isCloningTemplate: bool,
  // useTemplate: func.isRequired,
  similarTemplates: arrayOf(object),
  setModalContentLoading: func.isRequired
};

export default PdfTemplatePreviewContent;
