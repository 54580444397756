import React from 'react';
import { translationRenderer } from '@jotforminc/translation';
import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control 2checkout gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const control2Co: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_2co: {
    copyUrl: {
      type: 'copyUrl',
      steps: [
        translationRenderer('Log into your [1[{link}]] account')({
          renderer1: () => (<a className="color-blue-500" href="https://www.2checkout.com/va/notifications/" target="_blank">2Checkout</a>)
        }),
        translationRenderer('Set [1[{text}]] to the URL below')({
          renderer1: () => <b className="color-white">Payment Response URL</b>
        })
      ],
      url: 'submit.jotform.com/ipns/2co.php',
      resources: ['FORM', 'APP']
    },
    emailpending: {
      title: 'Pending Payment Email',
      type: 'toggle',
      subType: 'withRadioGroup',
      radioOptions: [
        { id: 'Yes', key: 'Yes', text: 'Notifiers Only' },
        { id: 'All', key: 'All', text: 'Notifiers & Autoresponders' }
      ],
      selectedCondition: settings => ['Yes', 'All'].includes(settings.emailpending),
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM', 'APP']
    }
  }
};

export default control2Co;
