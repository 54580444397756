import compose from 'lodash/fp/compose';

import {
  getPlaceholder,
  getPlaceholderList,
  getQuestionByPlaceholder
} from '@jotforminc/utils';

export const tagifyQuestion = ({
  question, asString = true, mode, key = '', returnAll = false
}) => {
  const placeholderRaw = getPlaceholder(question);
  const placeholders = Array.isArray(placeholderRaw) ? placeholderRaw : [placeholderRaw];
  const allTagifiedQuestions = placeholders.map(({ placeholder, text }) => {
    const tagifyObject = { name: placeholder, value: text, editable: mode !== 'mix' };
    if (!asString) {
      return tagifyObject;
    }

    const jsonString = JSON.stringify(tagifyObject);
    return mode === 'mix' ? `#start#${jsonString}#end#` : jsonString;
  });
  if (returnAll) {
    return allTagifiedQuestions;
  }
  const tagified = allTagifiedQuestions.find(tagifiedQuestion => (asString ? tagifiedQuestion.includes(`${key}`) : JSON.stringify(tagifiedQuestion).includes(`${key}`)));
  return tagified ? tagified : allTagifiedQuestions[0];
};

export const getStaticFieldsAsSimpleQuestions = fields => fields.map(({ text, placeholder }) => ({ text, name: placeholder.match(/{(.*)}/)[1] }));

const getQuestionsFromTagifyValue = (tagifyValue, questions, mode) => questions.filter(question => {
  const taggedQuestions = tagifyQuestion({ question, mode, returnAll: true });
  return taggedQuestions.some(tag => tagifyValue.indexOf(tag) > -1);
});
const getQuestionPlaceholderList = questions => getPlaceholderList(questions).map(({ placeholder }) => placeholder);
export const getSelectedPlaceholderList = compose(getQuestionPlaceholderList, getQuestionsFromTagifyValue);

export const tagifyPlaceholder = (placeholder, questionList, mode) => {
  const question = getQuestionByPlaceholder(placeholder, questionList);
  return question ? tagifyQuestion({
    question, asString: false, key: placeholder, mode
  }) : false;
};
export const tagifyPlaceholderList = (placeholderList, questionList, mode) => placeholderList.map(placeholder => tagifyPlaceholder(placeholder, questionList, mode));

export const getTagifyValue = (sanitizedValue, questions, mode) => {
  if (!sanitizedValue || (Array.isArray(sanitizedValue) && sanitizedValue.length === 0)) {
    return '';
  }
  const usedQuestionNames = {};
  return questions.reduce((prev, question) => {
    switch (question.type) {
      case 'control_mixed':
      case 'control_inline': {
        const placeholderRaw = getPlaceholder(question);
        const placeholders = Array.isArray(placeholderRaw) ? placeholderRaw : [placeholderRaw];
        return (placeholders || []).reduce((sanitized, { placeholder }) => {
          const key = placeholder;
          const tagified = tagifyQuestion({ question, mode, key });
          return sanitized.split(key).join(tagified);
        }, prev);
      }
      default:
        const tagified = tagifyQuestion({ question, mode });
        if (!prev || prev.length < 1) {
          return '';
        }
        // If tagified value is inserted do not recurse
        if (usedQuestionNames[question.name]) {
          return prev;
        }
        usedQuestionNames[question.name] = true;
        return prev.split(`{${question.name}}`).join(tagified);
    }
  },
  sanitizedValue);
};

export const getSanitizedValue = (isModeMix, tagifyValue, selectedPlaceholderList, questionsWithStaticFieldsList) => {
  if (!isModeMix) {
    return (tagifyValue && JSON.parse(tagifyValue));
  }
  const sanitizedValue = selectedPlaceholderList.reduce((prev, placeholder) => {
    const question = getQuestionByPlaceholder(placeholder, questionsWithStaticFieldsList);
    let key;
    let from;
    switch (question.type) {
      case 'control_inline':
      case 'control_mixed':
        {
          const placeholderRaw = getPlaceholder(question);
          const placeholders = Array.isArray(placeholderRaw) ? placeholderRaw : [placeholderRaw];
          const tag = placeholders.find(({ placeholder: tmp }) => prev.includes(tmp) && tmp === placeholder);
          key = tag?.placeholder;
          from = key;
        }
        break;
      default:
        key = '';
        from = `{${question.name}}`;
    }
    const tagifiedQuestion = tagifyQuestion({ question, mode: 'mix', key });
    const re = new RegExp(tagifiedQuestion.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
    return prev.replace(re, from);
  }, tagifyValue).replace(/[\u200B-\u200D\uFEFF]/g, '');

  return (sanitizedValue);
};

export const sanitizeTextBeforeSet = data => {
  return data.replace(/(<([^>]+)>)/ig, '');
};
