import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Sensepass gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlSensepass: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_sensepass: {
    isDirectFlow: {
      title: 'Enable Direct Payments',
      description: 'Accept on-form payments without being redirected to SensePass Checkout.',
      type: 'toggle',
      selectedCondition: settings => settings.isDirectFlow === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM']
    },
    askBillingInformation: {
      title: 'Ask Billing Information to Customer',
      type: 'toggle',
      selectedCondition: settings => settings.askBillingInformation === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM']
    },
    customerName: {
      title: 'Customer Name',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_fullname'],
      questionTypeToAdd: 'control_fullname',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    billingAdd: {
      title: 'Billing Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    emailField: {
      title: 'Customer Email',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    receiptEmailNotification: {
      title: 'Send Payment Request by Email',
      description: 'An invoice email will be generated by SensePass and sent to the customer. It will be valid for 5 days.',
      type: 'toggle',
      selectedCondition: settings => settings.receiptEmailNotification === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM'],
      /* eslint-disable-next-line max-len */
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes' && settings.isDirectFlow !== 'Yes' && settings.emailField !== 'none'
    },
    customerPhone: {
      title: 'Customer Phone',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_phone'],
      questionTypeToAdd: 'control_phone',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    receiptSMSNotification: {
      title: 'Send Payment Request by Text Message',
      description: 'An invoice SMS will be generated by SensePass and sent to the customer. It will be valid for 5 days.',
      type: 'toggle',
      selectedCondition: settings => settings.receiptSMSNotification === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM'],
      /* eslint-disable-next-line max-len */
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes' && settings.isDirectFlow !== 'Yes' && settings.customerPhone !== 'none'
    }
  }
};

export default controlSensepass;
