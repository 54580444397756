import FormTemplatePreviewContent from './components/PreviewModalContents/FormTemplatePreviewContent';
import AppTemplatePreviewContent from './components/PreviewModalContents/AppTemplatePreviewContent';
import ApprovalTemplatePreviewContent from './components/PreviewModalContents/ApprovalTemplatePreviewContent';
import TableTemplatePreviewContent from './components/PreviewModalContents/TableTemplatePreviewContent';
import PdfTemplatePreviewContent from './components/PreviewModalContents/PdfTemplatePreviewContent';
import PageTemplatePreviewContent from './components/PreviewModalContents/PageTemplatePreviewContent';

export const customModalConstants = {
  'form-templates': {
    CustomPreviewModalBody: FormTemplatePreviewContent
  },
  'sign-templates': {
    CustomPreviewModalBody: PdfTemplatePreviewContent
  },
  'pdf-templates': {
    CustomPreviewModalBody: PdfTemplatePreviewContent
  },
  'app-templates': {
    CustomPreviewModalBody: AppTemplatePreviewContent
  },
  'approval-templates': {
    CustomPreviewModalBody: ApprovalTemplatePreviewContent
  },
  'table-templates': {
    CustomPreviewModalBody: TableTemplatePreviewContent
  },
  'page-templates': {
    CustomPreviewModalBody: PageTemplatePreviewContent
  }
};
