import React, { useCallback } from 'react';
import Styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentGatewayFlows, checkCurrencySupport } from '@jotforminc/payment-settings-editor';
import { GatewayConnectionModal } from '@jotforminc/payment-gateways';
import * as ACTION_CREATORS from '../../../../../store/actionCreators';
import SELECTORS from '../../../../../store/selectors';

const ScCommonConfirmationModal = Styled.div`
  svg { width: 73px }

  .taste.isRed {
    background: #FF4948;

    &:hover {
      background: #F12E2D;
    }
  }

  .taste.isGhost {
    background-color: #FAFAFC;
a
    &:hover {
      background-color: #fff;
      color: #9EA7CE;
      border-color: #9EA7CE;
    }
  }

  .dontshow {
    background-color: #EDF8FF;
    margin: 20px 0 0;
  }

  .dontshow-input:checked + .dontshow-checkbox:before {
    background-color: #0099FF;
    border-color: #0099FF;
  }

  .dontshow-checkbox {
    cursor: pointer;
  }

  .dontshow-text {
    cursor: pointer;
  }
`;

const PaymentGatewayPickerModal = () => {
  const dispatch = useDispatch();

  const checkoutFormID = useSelector(SELECTORS.getCheckoutFormIDSelector);
  const getCheckoutFormPaymentQuestion = useSelector(SELECTORS.getCheckoutFormPaymentQuestion);
  const paymentModalFlowType = useSelector(SELECTORS.getPaymentModalFlowType);
  const paymentModalFlowStep = useSelector(SELECTORS.getPaymentModalFlowStep);
  const portalOwner = useSelector(SELECTORS.getPortalOwner);
  const currencyInfo = useSelector(SELECTORS.getPortalPaymentCurrencyInfo);
  const user = useSelector(SELECTORS.getUser);
  const { isPaymentReusableConnection = false } = window;
  const isReusableConnectionEnabled = useSelector(SELECTORS.getIsReusableConnectionEnabled) || isPaymentReusableConnection;

  const onPaymentModalClosed = () => (dispatch(ACTION_CREATORS.showGenericModalAction({ name: '' })));
  const handleGatewaySettingsChange = useCallback(settings => dispatch(ACTION_CREATORS.updateGatewaySettings(settings)), []);
  const handleCheckoutFormSettingsChange = useCallback(settings => dispatch(ACTION_CREATORS.updateCheckoutFormSettings(settings)), []);

  const checkoutFormPaymentQuestion = { ...getCheckoutFormPaymentQuestion, ...currencyInfo };

  if (isReusableConnectionEnabled) {
    return (
      <GatewayConnectionModal
        defaultIsOpen={true}
        onClose={onPaymentModalClosed}
        defaultFlowType="CONNECTTED_ACCOUNT_TO_CONNECTION"
        resourceType="APP"
        resourceId={checkoutFormID}
        user={user || null}
        onUseConnection={(connectionId, paymentFieldType, nameApm) => {
          const { supportedCurrency } = checkCurrencySupport(paymentFieldType, currencyInfo.currency);
          handleGatewaySettingsChange({
            paymentConnectionID: connectionId,
            type: paymentFieldType,
            currency: supportedCurrency,
            paymentType: checkoutFormPaymentQuestion.paymentType,
            ...(nameApm && { nameAPM: nameApm })
          });
        }}
        key="gatewayConnectionModal"
      />
    );
  }

  return (
    <ScCommonConfirmationModal>
      <PaymentGatewayFlows
        defaultGateway={checkoutFormPaymentQuestion.type !== 'control_payment' ? checkoutFormPaymentQuestion.type : null}
        defaultVisible={true}
        defaultFlowType={paymentModalFlowType}
        defaultStep={paymentModalFlowStep}
        defaultPaymentType={(checkoutFormPaymentQuestion && checkoutFormPaymentQuestion.paymentType) ? checkoutFormPaymentQuestion.paymentType : null}
        formId={checkoutFormID}
        resourceType='APP'
        onCloseModal={onPaymentModalClosed}
        onSaveGateway={(type, gatewayProps, isGatewayEdited, closeModal = true) => {
          const isCurrencySupported = checkCurrencySupport(gatewayProps.type, currencyInfo.currency);

          if (isGatewayEdited && checkoutFormPaymentQuestion && checkoutFormPaymentQuestion.type !== 'control_payment' && type !== checkoutFormPaymentQuestion.type) {
            handleGatewaySettingsChange({
              type,
              currency: isCurrencySupported.supportedCurrency,
              gatewaySettings: gatewayProps
            });
          } else if (isGatewayEdited) {
            handleCheckoutFormSettingsChange({ ...gatewayProps });
          } else {
            handleGatewaySettingsChange({
              type,
              currency: isCurrencySupported.supportedCurrency,
              gatewaySettings: gatewayProps
            });
          }
          if (closeModal) {
            dispatch(ACTION_CREATORS.showGenericModalAction({ name: '' }));
          }
          // updateRightPanelMode('newpaymentsettings');
        }}
        formQuestions={null}
        gatewayQuestionProperties={checkoutFormPaymentQuestion}
        user={user}
        portalOwner={portalOwner}
      />
    </ScCommonConfirmationModal>
  );
};

export default PaymentGatewayPickerModal;
