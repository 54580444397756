import { CaptureContext, Scope, Options } from '@sentry/types';
import { SnippetOptions, UserVars } from '@fullstory/browser';

// Senrty types
export interface SentryOptions extends Options {
  allowUrls?: Array<string | RegExp>;
  denyUrls?: Array<string | RegExp>;
  whitelistUrls?: Array<string | RegExp>;
  blacklistUrls?: Array<string | RegExp>;
}
export declare type ExtraOptions = CaptureContext;
export declare type ScopeOptions = (scope: Scope) => void;

// FullStory types
export declare type FullstoryOptions = SnippetOptions;
export declare type FullstoryUserVars = UserVars;

export enum Severity {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug',
  Critical = 'critical'
}
