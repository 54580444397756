import React from 'react';
import { t } from '@jotforminc/translation';
import { LoginFlow } from '@jotforminc/login-flow';

/** context */
import { useGatewayConnection } from '../../../context';

/** types */
import type { LOGGED_USER } from '../../../types';

/** components */
import { LoginFlowColumn } from './loginFlowColumn';

/**
 * CommonLoginFlow component provides a general flow for user login and registration.
 *
 * This component creates a customized login flow using the LoginFlow component
 * from the @jotforminc/login-flow package. It displays the login flow in a
 * multi-column modal and uses customized button names for various login methods.
 *
 * When a user successfully logs in, it:
 * - Saves connection data before login.
 * - Updates the logged-in user information.
 * - Closes the modal.
 * - Reloads the page if the resource type is 'APP' or 'FORM'.
 *
 * @component
 * @param {Object} props
 * @param {() => void} props.onClose - Function used to close the modal
 *
 * @example
 * <CommonLoginFlow onClose={() => {/* close modal *\/}} />
 */
export function CommonLoginFLow({ onClose }: { onClose: () => void }): any {
  const {
    user, changeLoggedUser, saveConnectionDataBeforeLoginOrSignup, resourceType
  } = useGatewayConnection();

  const isActiveRightColumn = window.location.href.includes('signupFlowRightColumn=1') || false;

  return (
    <LoginFlow
      mode='multi-column-modal'
      user={user}
      apiURL="/API"
      onUserLogin={(loggedUser: LOGGED_USER) => {
        saveConnectionDataBeforeLoginOrSignup();
        changeLoggedUser(loggedUser);
        onClose();
        if (resourceType === 'APP' || resourceType === 'FORM') {
          window.location.reload();
        }
      }}
      onClose={() => onClose()}
      forceUser={true}
      additionalScreens={{ epilogue: null }}
      appName="payment-reusable-connection"
      greetingMessage={t('Sign up to collect online payments')}
      OtherColumnComponents={isActiveRightColumn ? [<LoginFlowColumn />] : []}
      buttonNames={{
        google: 'payment-reusable-connection-google-login',
        facebook: 'payment-reusable-connection-facebook-login',
        apple: 'payment-reusable-connection-apple-login',
        emailLogin: 'payment-reusable-connection-email-login',
        emailSignup: 'payment-reusable-connection-email-signup'
      }}
    />
  );
}
