import React from 'react';
import { Flex } from '@jotforminc/magnet';
import {
  IconInfoCircleFilled
} from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { gatewayFieldToName, gatewayLightIcons } from '@jotforminc/payment-constants';
import { gatewayPoweredByLabels, gatewayInfoTexts } from '@jotforminc/payment-gateways';
import usePaymentPropsPanel from '../../Context/usePaymentProperties';

const ConnectionGatewayInfo = (): JSX.Element => {
  const { gatewayType } = usePaymentPropsPanel();
  const gatewayTitle = gatewayFieldToName[gatewayType];
  const GatewayIcon: any = gatewayLightIcons[gatewayType];
  const gatewaySubtitle = gatewayPoweredByLabels[gatewayType];
  const gatewayInfoText = gatewayInfoTexts[gatewayType];
  return (
    <div className="flex items-center">
      <div className='mr-3 bg-gray-500 radius flex items-center justify-center h-10 w-10'>
        <GatewayIcon className="h-8 w-8" />
      </div>
      <Flex
        direction="column"
        className="group relative"
      >
        {
          gatewayInfoText && (
            <span className='group-hover:block hiddenjf absolute bg-gray-800 shadow-dark-md radius-lg bottom-6 xs:min-w-92 min-w-80 -left-16 p-3 color-white text-sm line-height-xs z-9'>
              {gatewayInfoText}
            </span>
          )
        }
        <Flex>
          <span className="color-white text-lg font-medium h-6 flex items-center tracking-sm whitespace-nowrap">{gatewayTitle}</span>
          {gatewayInfoText && (
            <div className='w-3.5 h-3.5 ml-0.5'>
              <IconInfoCircleFilled className='w-3.5 h-3.5 color-white' />
            </div>
          )}
        </Flex>
        {gatewaySubtitle && <span className="color-white color-gray-200 text-xs">{t(gatewaySubtitle)}</span>}
      </Flex>
    </div>
  );
};

export default ConnectionGatewayInfo;
