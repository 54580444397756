export const MODALS = {
  FORM_PICKER_MODAL: 'FORM_PICKER_MODAL',
  APP_IS_DONE_MODAL: 'APP_IS_DONE_MODAL',
  RESTART_PROGRESS_MODAL: 'RESTART_PROGRESS_MODAL',
  RESOURCE_PICKER_MODAL: 'RESOURCE_PICKER_MODAL',
  GUEST_LOGIN_MODAL: 'GUEST_LOGIN_MODAL',
  CLONE_APP_MODAL: 'CLONE_APP_MODAL',
  CHECKOUT_SUCCESS_MODAL: 'CHECKOUT_SUCCESS_MODAL',
  PRODUCT_ITEM_DETAIL_MODAL: 'PRODUCT_ITEM_DETAIL_MODAL',
  SEARCH_IN_PRODUCTS_MODAL: 'SEARCH_IN_PRODUCTS_MODAL',
  DELETE_PAGE_MODAL: 'DELETE_PAGE_MODAL',
  TEAM_STILL_EDITING: 'TEAM_STILL_EDITIN',
  PORTAL_FEEDBACK_MODAL: 'PORTAL_FEEDBACK_MODAL',
  EXIT_INTENT_SURVEY_MODAL: 'EXIT_INTENT_SURVEY_MODAL',
  CHECKOUT_FORM_PREVIEW_MODAL: 'CHECKOUT_FORM_PREVIEW_MODAL',
  WHAT_IS_NEW: 'WHAT_IS_NEW',
  SHARE_APP: 'SHARE_APP',
  PAYMENT_GATEWAY_PICKER_MODAL: 'PAYMENT_GATEWAY_PICKER_MODAL',
  // A/B Test: ctAppNameIconModal
  APP_NAME_ICON_SETTING_MODAL: 'APP_NAME_ICON_SETTING_MODAL',
  DATA_SOURCE_TABLE_PICKER_MODAL: 'DATA_SOURCE_TABLE_PICKER_MODAL',
  TABLE_PRODUCT_MODAL: 'TABLE_PRODUCT_MODAL'
};

export const MODAL_EVENTS = {
  [MODALS.FORM_PICKER_MODAL]: 'formPickerModal',
  [MODALS.APP_IS_DONE_MODAL]: 'appIsDoneModal',
  [MODALS.RESTART_PROGRESS_MODAL]: 'restartProgressModal',
  [MODALS.RESOURCE_PICKER_MODAL]: 'resourcePickerModal',
  [MODALS.DATA_SOURCE_TABLE_PICKER_MODAL]: 'dataSourceTablePickerModal',
  [MODALS.GUEST_LOGIN_MODAL]: 'guestLoginModal',
  [MODALS.CLONE_APP_MODAL]: 'cloneAppModal',
  [MODALS.PRODUCT_ITEM_DETAIL_MODAL]: 'productItemDetailModal',
  [MODALS.SEARCH_IN_PRODUCTS_MODAL]: 'searchInProductsModal',
  [MODALS.DELETE_PAGE_MODAL]: 'deletePageModal',
  [MODALS.TEAM_STILL_EDITING]: 'teamsStillEditingModal',
  [MODALS.PORTAL_FEEDBACK_MODAL]: 'portalFeedbackModal',
  [MODALS.CHECKOUT_FORM_PREVIEW_MODAL]: 'checkoutFormPreviewModal',
  [MODALS.WHAT_IS_NEW]: 'whatsNewModal',
  [MODALS.SHARE_APP]: 'shareAppModal',
  [MODALS.PAYMENT_GATEWAY_PICKER_MODAL]: 'paymentGatewayPickerModal',
  [MODALS.DATA_SOURCE_TABLE_PICKER_MODAL]: 'dataSourceTablePickerModal'
};
