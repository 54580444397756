import React from 'react';
import { string, func } from 'prop-types';
import { IconMobile, IconTablet, IconDesktop } from '@jotforminc/svg-icons';
import { RESPONSIVE_MODES } from '../core/constants';

const ViewMode = ({ viewMode, onViewModeChange }) => {
  const { MOBILE, TABLET, DESKTOP } = RESPONSIVE_MODES;

  const isActive = (vMode, type) => (vMode === type ? 'active' : '');

  return (
    <div className="app-responsive-controller">
      {/* mobile */}
      <button
        type="button"
        aria-label="Mobile"
        onClick={() => onViewModeChange(MOBILE)}
        className={`mobile ${isActive(viewMode, MOBILE)}`}
      >
        <IconMobile />
      </button>
      {/* tablet */}
      <button
        type="button"
        aria-label="Tablet"
        onClick={() => onViewModeChange(TABLET)}
        className={`tablet ${isActive(viewMode, TABLET)}`}
      >
        <IconTablet />
      </button>
      {/* desktop */}
      <button
        type="button"
        aria-label="Desktop"
        onClick={() => onViewModeChange(DESKTOP)}
        className={`desktop ${isActive(viewMode, DESKTOP)}`}
      >
        <IconDesktop />
      </button>
    </div>
  );
};

ViewMode.propTypes = {
  viewMode: string,
  onViewModeChange: func
};

ViewMode.defaultProps = {
  viewMode: RESPONSIVE_MODES.MOBILE,
  onViewModeChange: f => f
};

export default ViewMode;
