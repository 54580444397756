/* eslint-disable @jotforminc/no-native-button */
/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import React from 'react';
import { t } from '@jotforminc/translation';
import {
  func, bool, string, oneOfType, object
} from 'prop-types';
import cx from 'classnames';
import { IconChevronLeft, IconChevronRight } from '@jotforminc/svg-icons';
import Button from '../../../Button';
import texts from '../../../../core/texts';

export default function ModalControls({
  useTemplate, isCloningTemplate, handlePrevNextTemplateHandler, className, newStyle, leftRef, rightRef
}) {
  const ctaControlsClassnames = cx(className, {
    'arrow-desktop lg:flex justify-between fixed top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2 max-w-xxl w-full': newStyle,
    'cta-controls sticky lg:static left-0 right-0 bottom-0 bg-white border-t border-t-navy-50 py-1 lg:py-0 lg:border-t-0 justify-center z-3': !newStyle
  });

  const modalArrowLeftClassnames = cx({
    'modal-arrow left locale-data-aria-label w-16 h-16 color-navy-100 hover:color-white inline-flex items-center justify-center duration-200 transform rtl:rotate-180': newStyle,
    'modal-arrow left relative locale-data-aria-label lg:absolute lg:top-1/3 lg:-left-20 lg:rtl:left-auto lg:rtl:-right-20 w-12 lg:w-16 h-12 lg:h-16 bg-navy-75 color-navy-400 inline-flex items-center justify-center radius lg:radius-full hover:bg-navy-700 hover:color-white duration-200 transform rtl:rotate-180': !newStyle
  });

  const modalArrowRightClassnames = cx({
    'modal-arrow right locale-data-aria-label w-16 h-16 color-navy-100 hover:color-white inline-flex items-center justify-center duration-200 transform rtl:rotate-180': newStyle,
    'modal-arrow right relative locale-data-aria-label lg:absolute lg:top-1/3 lg:-right-20 lg:rtl:right-auto lg:rtl:-left-20 w-12 lg:w-16 h-12 lg:h-16 bg-navy-75 color-navy-400 inline-flex items-center justify-center radius lg:radius-full hover:bg-navy-700 hover:color-white duration-200 transform rtl:rotate-180': !newStyle
  });

  return (
    <div className={ctaControlsClassnames}>
      <button
        ref={leftRef}
        aria-label="Previous Template"
        className={modalArrowLeftClassnames}
        onClick={() => handlePrevNextTemplateHandler({ key: 'ArrowLeft' })}
      >
        <IconChevronLeft className='w-8 h-auto' />
      </button>
      {!newStyle && (
        <Button
          onClick={useTemplate}
          disabled={isCloningTemplate && 'disabled'}
          className="cta-usetemplate locale lg:hidden w-full min-w-44 max-w-68 bg-green-400 color-white h-12 hover:bg-green-300 duration-200 mx-1 px-4 radius"
        >
          {isCloningTemplate ? t(texts.PLEASE_WAIT) : t(texts.USE_TEMPLATE)}
        </Button>
      )}
      <button
        ref={rightRef}
        aria-label="Next Template"
        className={modalArrowRightClassnames}
        onClick={() => handlePrevNextTemplateHandler({ key: 'ArrowRight' })}
      >
        <IconChevronRight className='w-8 h-auto' />
      </button>
    </div>
  );
}

ModalControls.propTypes = {
  className: string,
  useTemplate: func,
  isCloningTemplate: bool,
  handlePrevNextTemplateHandler: func,
  newStyle: bool,
  leftRef: oneOfType(func, object),
  rightRef: oneOfType(func, object)
};

ModalControls.defaultProps = {
  className: '',
  useTemplate: f => f,
  isCloningTemplate: false,
  handlePrevNextTemplateHandler: f => f,
  newStyle: false,
  leftRef: f => f,
  rightRef: f => f
};
