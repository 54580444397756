/* eslint-disable no-param-reassign */

const injectGetStyle = () => {
  function getStyle(el, styleProp) {
    var value; var
      { defaultView } = el.ownerDocument || document;
      // W3C standard way:
    if (defaultView && defaultView.getComputedStyle) {
      // sanitize property name to css notation
      // (hypen separated words eg. font-Size)
      styleProp = styleProp.replace(/([A-Z])/g, '-$1').toLowerCase();
      return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
    } if (el.currentStyle) { // IE
      // sanitize property name to camelCase
      styleProp = styleProp.replace(/-(\w)/g, (str, letter) => {
        return letter.toUpperCase();
      });
      value = el.currentStyle[styleProp];
      // convert other units to pixels on IE
      if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
        return (function (val) {
          var oldLeft = el.style.left;
          var oldRsLeft = el.runtimeStyle.left;
          el.runtimeStyle.left = el.currentStyle.left;
          el.style.left = val || 0;
          val = `${el.style.pixelLeft}px`;
          el.style.left = oldLeft;
          el.runtimeStyle.left = oldRsLeft;
          return val;
        }(value));
      }
      return value;
    }
  }

  if (!window.getStyle) {
    window.getStyle = getStyle;
  }
};

export default injectGetStyle;
