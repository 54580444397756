import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Echeck gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlEcheck: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_echeck: {
    askBillingInformation: {
      title: 'Ask Billing Information to Customer',
      type: 'toggle',
      selectedCondition: settings => settings.askBillingInformation === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM']
    },
    billToAddress: {
      title: 'Billing Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      addressSubfields: 'state|zip|st1|city|st2|country',
      default: 'none',
      resources: ['FORM'],
      requiredCondition: () => true,
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    ecTitle: {
      title: 'eCheck.Net Title',
      type: 'input',
      default: '',
      resources: ['FORM']
    }
  }
};

export default controlEcheck;
