import React from 'react';
import {
  string, object
} from 'prop-types';
import { IconLayersFilled } from '@jotforminc/svg-icons';

export default function Infobar({ templateType, template, categoryData }) {
  const username = template._username || template.username;
  const parentSlug = categoryData?.parent?.slug || categoryData?.parent?._slug;
  const slug = categoryData?.slug || categoryData?._slug;
  const cloneCount = template.clonecount || template._clonecount;
  const formatBigNumber = number => {
    if (number === 0) {
      return '0';
    }
    if (!number) {
      return '';
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <div className="modal-info-bar">
      <div className="details">
        <div className="first-cell">
          Shared by
          {' '}
          <a href={`/${templateType}/user/${username}`} target="_blank" className="first-cell-link">
            {username}
          </a>
          {' '}
          in
          {' '}
          <a href={`/${templateType}${(parentSlug ? `/${parentSlug}` : '')}/${slug}`} target="_blank" className="original-form-link">{categoryData.name}</a>
        </div>
        <div className="clone-cell">
          <IconLayersFilled width={16} height={16} />
          Cloned
          {' '}
          <strong>{formatBigNumber(cloneCount)}</strong>
        </div>
      </div>
    </div>
  );
}

Infobar.propTypes = {
  templateType: null,
  template: {},
  categoryData: null
};

Infobar.defaultProps = {
  templateType: string,
  template: object,
  categoryData: object
};
