/* eslint-disable camelcase */
import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { getFormattedPrice } from '@jotforminc/money-utils';
import { useEventListener } from '@jotforminc/hooks';
import { Hooks } from '@jotforminc/uikit';
import { getUserAppList } from '@jotforminc/header-components';
import { IconCheck, IconAsterisk } from '@jotforminc/svg-icons';
import { createSelector } from 'reselect';
import SELECTORS from '../store/selectors';
import { isYes, getFormCompletion } from '../utils';
import { getDiffOfDates, getHumanReadableDuration } from '../modules/Builder/components/Settings/utils';
import { generateAppURL } from './navigation';
import Badge from '../components/Badge';

export const useDesignatedItemProps = () => {
  const designatedItemID = useSelector(SELECTORS.getDesignatedItemIDSelector);
  const appFontFamily = useSelector(SELECTORS.getAppFontFamily);

  const designatedItemPropsSelector = useMemo(() => {
    if (designatedItemID) {
      return createSelector(
        SELECTORS.getPortalItemByIDSelector(designatedItemID),
        designatedItemProps => designatedItemProps
      );
    }
    // Return a selector that returns an empty object if designatedItemID is falsy
    return createSelector(() => ({}));
  }, [designatedItemID]);

  const designatedItemProps = useSelector(designatedItemPropsSelector);

  return useMemo(() => {
    const decoratorProps = ['itemBgColor', 'itemFontColor', 'itemBorderColor', 'itemTextAlignment'];
    const fromItem = Object.entries(designatedItemProps)
      .reduce((prev, [key, val]) => (decoratorProps.includes(key) ? { ...prev, [key]: val } : prev), {});
    const fromApp = { appFontFamily };
    return { ...fromItem, ...fromApp };
  }, [designatedItemProps, appFontFamily]);
};

export const calculateDisabled = (appID, onDisableTimeUp = f => f) => {
  const { time_zone: userTimeZone } = useSelector(SELECTORS.getUser);
  const currentAppInfo = useSelector(SELECTORS.getAppInfoWithDefaults);
  const otherAppInfo = useSelector(SELECTORS.getOtherAppInfoWithDefaultsByID(appID));

  const {
    status,
    disableOnDate,
    disableDate,
    disableDateTimezone
  } = currentAppInfo.id === appID ? currentAppInfo : otherAppInfo;

  const targetTimezone = disableDateTimezone || userTimeZone;

  const disabledByStatus = status === 'DISABLED';
  const disabledBySuspension = status === 'SUSPENDED';
  const trashedByStatus = status === 'TRASHED';
  const shouldDisableOnDate = isYes(disableOnDate);

  const diff = getDiffOfDates(targetTimezone, disableDate);

  const isTimePassed = diff < 0;
  const isDateSoon = diff <= 6.048e+8; // milliseconds in a week
  const isDateVerySoon = diff <= 6e4; // milliseconds in 1 min
  const when = getHumanReadableDuration(diff);

  useEffect(() => {
    let timer;
    if (shouldDisableOnDate && isDateVerySoon && diff > 0) {
      timer = setTimeout(() => onDisableTimeUp(), diff);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [disableDate, shouldDisableOnDate, diff]);

  // Returns are after hook calls!
  if (trashedByStatus) return { isTrashedNow: true };
  if (disabledByStatus) return { isDisabledNow: true };
  if (disabledBySuspension) return { isDisabledNow: true };
  if (!shouldDisableOnDate) return { isDisabledNow: false };
  if (isTimePassed) return { isDisabledNow: true };

  return {
    isDisabledNow: false, isDateSoon, isDateVerySoon, when
  };
};

export const useBattery = () => {
  const defaultLevel = 100;
  const defaultCharging = false;

  if (!window.navigator.getBattery) return { batteryLevel: defaultLevel, isCharging: defaultCharging };

  const [batteryLevel, _setBatteryLevel] = useState();
  const setBatteryLevel = l => {
    const number = (l * 100).toFixed();
    _setBatteryLevel(number);
  };

  const [isCharging, setCharging] = useState();

  useEffect(() => {
    window.navigator.getBattery().then(battery => {
      const { level: currentLevel, charging: currentlyCharging } = battery;
      setBatteryLevel(currentLevel);
      setCharging(currentlyCharging || defaultCharging);
      // eslint-disable-next-line no-param-reassign
      battery.onlevelchange = e => {
        const { target: { level: newLevel = 1 } = {} } = e || {};
        setBatteryLevel(newLevel);
      };

      // eslint-disable-next-line no-param-reassign
      battery.onchargingchange = e => {
        const { target: { charging = false } = {} } = e || {};
        setCharging(charging);
      };
    });
  }, []); // Once

  return { batteryLevel, isCharging };
};

export const useAppURL = props => {
  const appID = useSelector(SELECTORS.getPortalIDSelector);
  return generateAppURL({ appID, ...props });
};

export const useFormMessageSender = formID => {
  const iframeWindowRef = useRef();

  const handleMessagesFromFrame = ({ source: window, data = {} }) => {
    if (formID === data?.action?.formID) iframeWindowRef.current = window;
  };

  useEventListener('message', handleMessagesFromFrame);

  return (name, payload) => {
    const { current: frameWindow } = iframeWindowRef;

    if (!frameWindow) {
      console.warn(`No FrameWindow found for action ${name}`);
      return;
    }

    frameWindow.postMessage({
      fromJFApps: true,
      action: { name, payload }
    }, window.location.href);
  };
};

export const useFormHeightHandler = (_formID, notAllowLessThan150 = false) => {
  const [frameHeight, setFrameHeight] = useState();

  const messageHandler = message => {
    const { data } = message;
    const getIsGenericSizeAllowed = newSize => (newSize > 150 || !notAllowLessThan150);
    const isJFApps = data?.forJFApps;
    const isResizing = data?.action?.name === 'onFormHeightChange';

    switch (true) {
      case (isJFApps && isResizing):
        const { formID: relatedFormID, payload: newHeight } = data.action;
        const shouldSetNewHeight = (relatedFormID === _formID) && getIsGenericSizeAllowed(newHeight) && newHeight !== frameHeight;
        if (shouldSetNewHeight) {
          setFrameHeight(newHeight);
        }
        break;
      default:
        if (typeof data === 'string' && data.includes('setHeight')) {
          const [, height, formID] = data.split(':');
          if (formID === _formID && getIsGenericSizeAllowed(height)) setFrameHeight(parseFloat(height));
        }
        break;
    }
  };

  useEventListener('message', messageHandler);

  return frameHeight;
};

export const useFormMessageHandler = (formID, handler) => {
  useEventListener('message', ({ data }) => {
    const { forJFApps, action } = data;

    if (forJFApps && formID === action?.formID) handler(action);
  });
};

export const useFormSubmissionCompleteHandler = (formID, handler) => {
  useEventListener('message', message => {
    const {
      data: { action = {}, formID: _formID } = {}
    } = message;

    if (action === 'submission-completed' && _formID === formID) handler(message);
  });
};

export const useFormSubmissionPendingCaptchaHandler = handler => {
  useEventListener('message', message => {
    const {
      data: { action = {} } = {}
    } = message;

    if (action === 'submission-pending-captcha') handler(message);
  });
};

export const useFormSubmissionErrorHandler = handler => {
  useEventListener('message', message => {
    const {
      data: { action = {} } = {}
    } = message;

    if (action === 'submission-error') handler(message);
  });
};

export const useFormSubmissionPendingPaymentHandler = handler => {
  useEventListener('message', message => {
    const {
      data: { action = {} } = {}
    } = message;

    if (action === 'submission-pending-payment') handler(message);
  });
};

export const useFilteredProducts = (searchQuery, productLists, withFunc = false) => {
  const allProductsArray = productLists.reduce((prev, productList) => {
    const {
      products, id: itemID
    } = productList;

    const reducedProducts = products.map(({ pid: productID, name, description }) => ({
      productID,
      name,
      description,
      itemID
    }));

    return [...prev, ...reducedProducts];
  }, []);

  const productListProps = productLists.map(({ id: itemID, title }) => ({ itemID, title }));

  const _filteredProducts = Hooks.useFuse(
    allProductsArray,
    searchQuery,
    ['name', 'description'],
    { threshold: 0.3, tokenize: true, matchAllTokens: true }
  );

  const filteredProducts = _filteredProducts.map(product => product.item ?? product);

  const filteredProductLists = productListProps.map(({ itemID: productListID, title }) => {
    const itemProducts = filteredProducts.filter(({ itemID }) => itemID.toString() === productListID.toString()) || [];

    return {
      itemID: productListID,
      title,
      products: itemProducts
    };
  });
  const sorterFn = searchQuery ? (one, another) => (another.products.length - one.products.length) : f => f;
  return withFunc ? () => filteredProductLists.sort(sorterFn) : filteredProductLists.sort(sorterFn);
};

export const useFilteredProductsOfItem = (products, searchQuery) => {
  const filteredProducts = Hooks.useFuse(
    products,
    searchQuery,
    ['name', 'description'],
    { threshold: 0.3, tokenize: true, matchAllTokens: true }
  );

  return filteredProducts.map(product => product?.item);
};

export const useTouch = (target, onTouch) => {
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [direction, setDirection] = useState(null);

  useEffect(() => {
    const handleTouchStart = function (e) {
      setStartPoint(e.touches[0]);
      setEndPoint(null);
    };
    const handleTouchMove = function (e) {
      setEndPoint(e.touches[0]);
    };

    if (!target) return;
    target.addEventListener('touchstart', handleTouchStart);
    target.addEventListener('touchend', handleTouchMove);
    target.addEventListener('touchmove', handleTouchMove);
    target.addEventListener('touchcancel', handleTouchMove);

    return () => {
      target.removeEventListener('touchstart', handleTouchStart);
      target.addEventListener('touchend', handleTouchMove);
      target.removeEventListener('touchmove', handleTouchMove);
      target.addEventListener('touchcancel', handleTouchMove);
    };
  }, [target]);

  useEffect(() => {
    if (startPoint && endPoint) {
      const diffX = startPoint.clientX - endPoint.clientX;
      const diffY = startPoint.clientY - endPoint.clientY;

      let dir;
      if (Math.abs(diffX) > Math.abs(diffY)) {
        return;
      } if (diffY > 0) {
        dir = 'top';
      } else {
        dir = 'bottom';
      }

      setDirection(dir);
    } else {
      setDirection(null);
    }
  }, [startPoint, endPoint]);

  useEffect(() => {
    if (direction) {
      onTouch(direction);
    }
  }, [direction, onTouch]);
};

export const useEffectOnce = effect => {
  useEffect(effect, []);
};

export const useUnmount = fn => {
  const fnRef = useRef(fn);

  // update the ref each render so if it changes the newest callback will be invoked
  fnRef.current = fn;

  useEffectOnce(() => () => fnRef.current());
};

export const useRequiredBadge = itemID => {
  const itemProps = useSelector(SELECTORS.getItemWithDefaults(itemID));
  const timeZone = useSelector(SELECTORS.getUserTimezone);
  const progressRestartDate = useSelector(SELECTORS.getProgressRestartDate);
  const isAppLoginable = useSelector(SELECTORS.selectIsAppLoginable);
  const isUserLoggedIn = useSelector(SELECTORS.selectIsUserLoggedIn);

  const {
    lastSubmitted,
    required_showBadge,
    completed_showBadge,
    completed_clearBadgeOn,
    completed_clearBadgePeriod
  } = itemProps;

  const showBadgeProps = {
    showBadge: completed_showBadge, clearBadgeOn: completed_clearBadgeOn, clearBadgePeriod: completed_clearBadgePeriod, required_showBadge
  };

  const isFormCompleted = getFormCompletion({
    ...showBadgeProps, timeZone, lastSubmitted, progressRestartDate
  });

  const isFormRequired = isYes(required_showBadge);

  const isCompletedBadgeEnabled = isYes(completed_showBadge);

  const appLoginable = isYes(isAppLoginable);

  if (appLoginable && isUserLoggedIn && isCompletedBadgeEnabled && isFormCompleted) {
    return <Badge color="#78BB07" icon={<IconCheck color="#FFF" />} className="badge" />;
  }

  if (isFormRequired) {
    return <Badge color="#D73838" icon={<IconAsterisk color="#FFF" />} className="badge" />;
  }
};

export const useProductNavigationAppList = () => {
  const formList = useSelector(SELECTORS.getPortalFormItems);
  const signList = useSelector(SELECTORS.getUsedSignItems);
  const productListLists = useSelector(SELECTORS.getProductListItems);
  const donationItems = useSelector(SELECTORS.getDonationItems);
  const user = useSelector(SELECTORS.getUser);

  const ITEM_LIST = {
    FORM: formList,
    SIGN: signList,
    PRODUCT_LIST: productListLists,
    DONATION: donationItems
  };

  const APP_LIST = {
    FORM: getUserAppList(user, 'portal').filter(app => !['sign', 'signInbox'].includes(app)),
    SIGN: ['sign', 'signInbox'],
    PRODUCT_LIST: ['appTables'],
    DONATION: ['appTables']
  };

  const appList = Object.entries(ITEM_LIST).reduce((acc, [itemType, items]) => {
    if (items.length > 0) {
      return [...acc, ...APP_LIST[itemType]];
    }

    return [...acc];
  }, []);

  return Array.from(new Set([...appList]));
};

export const useProductsToRender = itemID => {
  const itemProps = useSelector(SELECTORS.getItemWithDefaults(itemID));
  const productFilter = useSelector(SELECTORS.getFilteredProducts);

  const [searchQuery, setSearchQuery] = useState('');

  const { products } = itemProps;

  const filteredProducts = useFilteredProductsOfItem(products, searchQuery);
  const productsToRender = searchQuery ? filteredProducts : products;

  useEffect(() => {
    const { searchQuery: stateSearchQuery, itemID: stateItemID } = productFilter;
    if (stateItemID === itemID) {
      setSearchQuery(stateSearchQuery);
    }
  }, [productFilter]);

  return [searchQuery, productsToRender];
};

export const useFormattedPrice = () => {
  const { currency, useDecimal, decimalMark } = useSelector(SELECTORS.getPortalPaymentCurrencyInfo);

  return useCallback(price => getFormattedPrice({
    price, useDecimal, currency, decimalMark
  }), [currency, useDecimal, decimalMark]);
};

export const useDraggableInPortal = () => {
  const self = useRef({}).current;

  useEffect(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.pointerEvents = 'none';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    div.style.listStyle = 'none';
    self.elt = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [self]);

  return render => (provided, ...args) => {
    const element = render(provided, ...args);
    if (provided.draggableProps.style.position === 'fixed') {
      return createPortal(element, self.elt);
    }
    return element;
  };
};

export const useClickOutside = (callback, ref) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

export const useInView = (onInView, { threshold, isLoading, hasMore }) => {
  const ref = useRef();

  useEffect(() => {
    let observer;

    global.requestAnimationFrame(() => {
      if (!ref.current) return;

      if (global.IntersectionObserver) {
        observer = new global.IntersectionObserver(([entry]) => {
          if (entry.intersectionRatio === 0) {
            return;
          }

          onInView(true);

          observer.unobserve(ref.current);
        }, { threshold });

        observer.observe(ref.current);
      } else {
        onInView(true);
      }
    });

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [ref.current, isLoading, hasMore]);

  return [ref];
};

// it's for app elements since pages dont have any height
// the 3rd party infinite scroll libraries i have tried and not worked for this case
// because in the app, scroll is top of the all app components
export const useInfiniteLoad = (callback, settings) => {
  const {
    isLoading = false,
    hasMore = false,
    threshold = 0.8
  } = settings;

  useEffect(() => {
    callback();
  }, []);

  const [elementRef] = useInView(() => {
    if (!isLoading && hasMore) {
      callback();
    }
  }, { threshold, isLoading, hasMore });

  return [elementRef];
};
