import React from 'react';
import {
  call, put, select, spawn, take, takeEvery
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import ShoppingNotification from '../../components/Toaster/ShoppingNotification/ShoppingNotification';
import { APP_TOAST, APP_TOAST_STACK } from '../actionTypes';
import SELECTORS from '../selectors';
import { checkMobilePhone } from '../../utils';

const toastIt = ({
  payload, type, currentStack
}) => new Promise((resolve, reject) => {
  const { backdrop, product = null } = payload;

  let options = {
    autoClose: 3e3,
    delay: 150,
    newestOnTop: true
  };

  if (backdrop) {
    options = {
      ...options,
      autoClose: false,
      closeOnClick: false,
      draggable: false
    };
  }

  // may be different notification type?
  let NotificationComponent;

  switch (type) {
    case APP_TOAST.SHOPPING_NOTIFICATION:
      NotificationComponent = ShoppingNotification;

      if (product) {
        options = {
          ...options,
          autoClose: 6e3,
          pauseOnHover: true
        };
      }
      break;
    default:
      break;
  }

  try {
    const currentActiveStack = currentStack.filter(id => toast.isActive(id));
    const isStackOverFlow = (currentActiveStack.length + 1) >= 2;

    if (isStackOverFlow) {
      const willBeUnmountID = currentActiveStack.pop();
      toast.dismiss(willBeUnmountID);
    }

    if (isStackOverFlow) {
      setTimeout(() => {
        const toastID = toast(<NotificationComponent {...payload} />, options);
        resolve(toastID);
      }, 210);
    } else {
      const toastID = toast(<NotificationComponent {...payload} />, options);
      resolve(toastID);
    }
  } catch (e) {
    reject(e);
  }
});

function* watchToastStackChange({ payload }) {
  const toastID = payload;
  const currentStack = yield select(SELECTORS.getAppToastStack);
  const currentActiveStack = currentStack.filter(id => toast.isActive(id));

  const newStack = [toastID, ...currentActiveStack];
  const isStackOverFlow = newStack.length >= 3;
  if (isStackOverFlow) {
    newStack.pop();
  }

  yield put({ type: APP_TOAST_STACK.SUCCESS, payload: newStack });
}

function* watchAppToastActions() {
  const isMobile = checkMobilePhone();
  if (!isMobile) {
    while (true) {
      const action = yield take(({ type }) => type.startsWith('@APP_TOAST'));
      const currentStack = yield select(SELECTORS.getAppToastStack);
      const toastID = yield call(toastIt, { ...action, currentStack });
      yield put({ type: APP_TOAST_STACK.REQUEST, payload: toastID });
    }
  }
}

export default function* () {
  yield spawn(watchAppToastActions);
  yield takeEvery(APP_TOAST_STACK.REQUEST, watchToastStackChange);
}
