const ARROW_KEYS = {
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight'
};

const isNextKey = e => [ARROW_KEYS.DOWN, ARROW_KEYS.RIGHT].includes(e.key);
const isPrevKey = e => [ARROW_KEYS.UP, ARROW_KEYS.LEFT].includes(e.key);

const isArrowKey = e => e?.key?.startsWith('Arrow');

const isSelectorProvided = selector => selector && typeof selector === 'string';

/**
 * Moves focus to next or previous element and
 * calls optional callback with the event and the newly focused element.
 *
 * @param {KeyboardEvent} event
 * @param {Function} onElementClick
 * @param {Boolean|string} selector
 * @returns {void}
 */
export const a11yListNavigation = (event, onElementClick = false, selector = false) => {
  try {
    const { currentTarget } = event;

    if (!isArrowKey(event)) return;

    let nextElement;
    if (isNextKey(event)) {
      nextElement = currentTarget?.nextElementSibling;
      if (isSelectorProvided(selector)) {
        nextElement = currentTarget
          ?.closest(selector.split('>')[0].trim())
          ?.nextElementSibling
          ?.querySelector(selector.split('>')[1].trim());
      }
    } else if (isPrevKey(event)) {
      nextElement = currentTarget?.previousElementSibling;
      if (isSelectorProvided(selector)) {
        nextElement = currentTarget
          ?.closest(selector.split('>')[0].trim())
          ?.previousElementSibling
          ?.querySelector(selector.split('>')[1].trim());
      }
    }

    if (!nextElement) {
      return;
    }

    if (nextElement?.hasAttribute('chromevoxignoreariahidden')) {
      // Chromevox adds a reduntant div element to focus on in case
      // it lands on an unfocusable element, this element however
      // breaks the navigation flow, so we are removing this item.
      nextElement.remove();
      return a11yListNavigation(event, onElementClick);
    }

    nextElement?.focus();

    if (onElementClick) {
      const nextEvent = new window.KeyboardEvent('keydown');
      Object.defineProperty(nextEvent, 'target', { writable: false, value: nextElement });
      onElementClick(nextEvent, nextElement);
    }
  } catch (error) {
    console.error(error);
  }
};

const ACTION_KEYS = {
  ENTER: 'Enter',
  SPACE: ' '
};

const isActionKeyPressed = e => Object.values(ACTION_KEYS).indexOf(e.key) > -1;

export const a11yClickHandler = (event, handler = false) => {
  try {
    if (!isActionKeyPressed(event)) {
      return;
    }

    if (handler) {
      handler(event);
    }
  } catch (e) {
    console.error(e);
  }
};

export const a11yAnnounce = (text, priority) => {
  try {
    const visuallyHidden = {
      position: 'absolute',
      width: '1px',
      height: '1px',
      padding: '0',
      margin: '-1px',
      overflow: 'hidden',
      clip: 'rect(0, 0, 0, 0)',
      'white-space': 'nowrap',
      border: '0'
    };

    var el = document.createElement('div');
    var id = `speak-${Date.now()}`;
    el.setAttribute('id', id);
    el.setAttribute('aria-live', priority || 'polite');

    Object.keys(visuallyHidden).forEach(rule => {
      el.style[rule] = visuallyHidden[rule];
    });

    document.body.appendChild(el);

    window.setTimeout(() => {
      document.getElementById(id).textContent = text;
    }, 100);

    window.setTimeout(() => {
      document.body.removeChild(document.getElementById(id));
    }, 1000);
  } catch (e) {
    console.error(e);
  }
};
