import type { CHILD_GATEWAYS_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Apple Pay & Google Pay gateway resource properties.
//  * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlApplePayGooglePay: CHILD_GATEWAYS_RESOURCE_PROPS_TYPE = {
  control_applePayGooglePay: {
    control_square: {
      location: {
        title: 'Business Location',
        type: 'dropdown',
        subType: 'dynamicDropdown',
        default: '',
        resources: ['FORM', 'APP']
      },
      emailField: {
        title: 'Customer Email',
        type: 'dropdown',
        subType: 'addQuestion',
        questionTypesAsOption: ['control_email'],
        questionTypeToAdd: 'control_email',
        default: 'none',
        resources: ['FORM'],
        requiredCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'subscription',
        autoSave: true
      },
      authOnly: {
        title: 'Authorization Only',
        type: 'toggle',
        description: 'Authorize the card now to charge it manually later. Expires after 6 days.',
        selectedCondition: settings => settings.authOnly === 'Yes',
        valueTrue: 'Yes',
        valueFalse: 'No',
        default: 'No',
        resources: ['FORM', 'APP'],
        renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType !== 'subscription'
      },
      orderFulfillmentType: {
        title: 'Order Fulfillment Type',
        type: 'dropdown',
        options: [
          { id: 'PICKUP', key: 'PICKUP', text: 'Pickup' },
          { id: 'SHIPMENT', key: 'SHIPMENT', text: 'Shipment' }
        ],
        default: 'Pickup',
        resources: ['FORM', 'APP'],
        renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType !== 'subscription'
      },
      sendReceipt: {
        title: 'Send Payment Receipt',
        type: 'toggle',
        description: 'Send an email with a link to the Square receipt to the customer.',
        selectedCondition: settings => String(settings.sendReceipt) === 'true',
        valueTrue: 'true',
        valueFalse: 'false',
        default: 'false',
        resources: ['FORM', 'APP'],
        renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType !== 'subscription'
      },
      ccTitle: {
        title: 'Credit Card Label Text',
        type: 'input',
        default: '',
        resources: ['FORM', 'APP']
      },
      paymentMethodsTitle: {
        title: 'Payment Methods Label Text',
        type: 'input',
        default: 'Payment Methods',
        resources: ['FORM', 'APP']
      }
    },
    control_stripeCheckout: {
      billingEmail: {
        title: 'Customer Email Field',
        type: 'dropdown',
        subType: 'addQuestion',
        questionTypesAsOption: ['control_email'],
        questionTypeToAdd: 'control_email',
        default: 'none',
        resources: ['FORM'],
        autoSave: true
      },
      customDataField: {
        title: 'Custom Data Field',
        type: 'dropdown',
        subType: 'addQuestion',
        questionTypesAsOption: ['control_textbox', 'control_autoincrement'],
        questionTypeToAdd: 'control_textbox',
        default: 'none',
        resources: ['FORM'],
        autoSave: true
      },
      laterCharge: {
        title: 'Charge Customer Immediately',
        type: 'toggle',
        selectedCondition: settings => settings.laterCharge === 'immediately',
        valueTrue: 'immediately',
        valueFalse: 'later',
        default: 'immediately',
        resources: ['FORM', 'APP'],
        renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType !== 'subscription'
      },
      useStripeCoupons: {
        title: 'Fetch Coupons from Stripe Account',
        description: 'Fetch coupons from the given Stripe account. This will override your Jotform coupons.',
        type: 'toggle',
        selectedCondition: settings => settings.useStripeCoupons === '1' || settings.useStripeSubscriptions === '1',
        valueTrue: '1',
        valueFalse: '0',
        default: '0',
        resources: ['FORM'],
        renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'subscription',
        disableCondition: settings => settings.useStripeSubscriptions === '1'
      },
      useStripeSubscriptions: {
        title: 'Fetch Subscriptions from Stripe Account',
        description: 'Fetch subscriptions from the given Stripe account. This will override your Jotform subscriptions.',
        type: 'toggle',
        selectedCondition: settings => settings.useStripeSubscriptions === '1',
        valueTrue: '1',
        valueFalse: '0',
        default: '0',
        resources: ['FORM'],
        renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'subscription'
      },
      showStripeCoupons: {
        title: 'Show Fetched Coupons from Stripe Account',
        description: 'Show fetched coupons from the given Stripe account.',
        type: 'toggle',
        selectedCondition: settings => settings.showStripeCoupons === '1',
        valueTrue: '1',
        valueFalse: '0',
        default: '1',
        resources: ['FORM'],
        renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.paymentType === 'subscription'
      },
      sendReceiptEmail: {
        title: 'Send Receipt Email',
        type: 'toggle',
        selectedCondition: settings => settings.sendReceiptEmail === 'Yes',
        valueTrue: 'Yes',
        valueFalse: 'No',
        default: 'Yes',
        resources: ['FORM', 'APP']
      }
    }
  }
};

export default controlApplePayGooglePay;
