import React from 'react';
import { object, func, bool } from 'prop-types';
import { t } from '@jotforminc/translation';

import { placeholderApprovalImage } from '../../constantImages';
import { onImageError } from '../../onImageError';
import PreviewHint from './PreviewHint';

const ApprovalTemplateItem = ({
  template, imageLoaded, handlePreviewTemplate,
  setImageLoaded, templateCloneWrapper
}) => {
  const {
    slug,
    title
  } = template;
  const isSkeleton = title === 'skeleton';

  const screenshotURL = `https://cdn.jotfor.ms/templates/screenshot/approval-templates/${slug}?f=png&w=1482`;
  const image = slug ? screenshotURL : placeholderApprovalImage;

  return (
    <div className={`item approval-item${imageLoaded && !isSkeleton ? ' image-loaded' : ''}`}>
      <div className="image" onClick={() => handlePreviewTemplate('viewTemplateFromImg')}>
        <div className='bgColorItem' style={{ backgroundColor: template.thumbnailBackgroundColor }} />
        <PreviewHint />
        <img
          className='approval-main'
          alt={title}
          src={!isSkeleton ? image : placeholderApprovalImage}
          onError={onImageError}
          onLoad={() => setImageLoaded(true)}
        />
        <img
          className='approval-blank'
          alt={title}
          src={placeholderApprovalImage}
        />
        <div className="animBg" />
      </div>
      <h3 onClick={() => handlePreviewTemplate('viewTemplateFromTitle')}><span>{title}</span></h3>
      <button
        className="useTemplate"
        type="button"
        onClick={event => templateCloneWrapper(event)}
      >
        <span>{t('Use Template')}</span>
      </button>
      <button
        className="previewCta"
        type="button"
        onClick={() => handlePreviewTemplate('viewTemplateFromPreviewCta')}
      >
        <span>{t('Preview')}</span>
      </button>
    </div>
  );
};

export default ApprovalTemplateItem;
ApprovalTemplateItem.defaultProps = {
};
ApprovalTemplateItem.propTypes = {
  template: object.isRequired,
  imageLoaded: bool.isRequired,
  setImageLoaded: func.isRequired,
  templateCloneWrapper: func.isRequired,
  handlePreviewTemplate: func.isRequired
};
