import Styled, { css } from 'styled-components';
import { mainFontColor } from '../../../styles/colors';

const ScNavigationPanelButton = Styled.div`
button.navBurger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 50px;
  padding: 0 8px;
  background-color: ${({ isScrolledDown }) => (isScrolledDown ? '#FFF9' : '#FFFFFFCC;')};
  font-family: inherit;
  color: ${mainFontColor};
  fill: ${mainFontColor};
  max-width: 56vw;

  svg {
    min-width: 16px;
    height: 10px;
    width: 16px;
  }

  svg.defaultBar {
    height: 24px;
    width: 24px;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: .2s opacity,width ease;

      ${({ isScrolledDown }) => (isScrolledDown ? css`
      width: 0;
      opacity: 0;
      margin: 0;
      ` : css`
      width: unset;
      opacity: 1;
      margin-left: ${({ hasBothIconAndText }) => (hasBothIconAndText ? '6px' : '0')};
      `)};
  }
}

${({ isRightPanel }) => (isRightPanel ? css`
  position: absolute;
  top: 65px;
  left: 25px;

  button.navBurger {
    pointer-events: none;
    cursor: default;
    outline: none;
    max-width: 200px;
  }
` : css``)}
`;

export default ScNavigationPanelButton;
