import React from 'react';
import { t } from '@jotforminc/translation';
import usePaymentPropsPanel from '../../Context/usePaymentProperties';

const SectionHeader = (): JSX.Element => {
  const { hasConnection } = usePaymentPropsPanel();

  return (
    <div className='mb-2'>
      <span className="color-white text-sm font-bold line-height-md block">
        {t('Payment Connection')}
      </span>
      {
        !hasConnection && (
          <span className="text-xs color-gray-200 line-height-xs block">
            {t('Add a connection to collect online payments')}
          </span>
        )
      }
    </div>
  );
};

export default SectionHeader;
