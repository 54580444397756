/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable complexity */
/* eslint-disable @jotforminc/no-native-button */
import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { t } from '@jotforminc/translation';
import { classNames, capitalizeFirstLetter } from '@jotforminc/utils';

import { Button, InputText } from '@jotforminc/magnet';
import {
  IconCopy, IconArrowDown, IconXmark, IconChevronLeft, IconChevronRight, IconMagnifyingGlass, IconUserFilled, IconBullseyeArrow
} from '@jotforminc/svg-icons';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import texts from '../../core/texts';
import ModalControls from '../StandaloneTemplates/components/PreviewModalContents/ModalControls';
import SimilarTemplatesItem from '../StandaloneTemplates/components/PreviewModalContents/SimilarTemplatesItem';
import useIsMobile from '../../hooks/useIsMobile';
import { useOutsideClick } from '../StandaloneTemplates/hooks';
import { formatBigNumber, getPreviewID } from '../StandaloneTemplates/utils';
import Loader from '../Loader';
import agents from '../StandaloneTemplates/agents';

const NewWizardTemplateModal = ({
  similarTemplates, handleClose, previewURL, modalProps, modalDetailsData,
  template, title, frameSrc, categoryData, setModalContentLoading, theme, displayMyformsModal,
  isCloningTemplate = false, useTemplate, categories, setSelectedCategory, legacyStandaloneFrameRef,
  templateTabs = [], searchKeyword, setSearchKeyword, standaloneTemplates, newStandaloneActive
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [modelContentLoading, setModelContentLoading] = useState(true);
  const wizardModalBodyRef = useOutsideClick(() => {
    handleClose();
  });
  const frameRef = useRef();
  const similarTemplatefRef = useRef();

  const leftArrowRef = useRef();
  const rightArrowRef = useRef();

  const templateType = frameSrc.split('/')[1];
  const isAgentTemplates = templateType === 'agent-templates';

  const isMobile = useIsMobile();
  const modalVisibleClassName = 'jfWizard--isModalVisible';

  const processedTemplate = isAgentTemplates ? {
    ...template,
    ...agents.find(agent => agent.id == (template._id || template.id))
  } : template;

  const {
    _id, id,
    _username, username,
    _clonecount, clonecount,
    _description, description,
    _metaDescription, metaDescription,
    _categories, categories: templateCategories,
    identity, questions
  } = processedTemplate;

  useEffect(() => {
    setModelContentLoading(true);
  }, [previewURL]);

  const handleArrowClick = ({ key }) => {
    if (!['ArrowLeft', 'ArrowRight'].includes(key)) return;
    const isArrowLeft = key === 'ArrowLeft';
    const actionTarget = isArrowLeft ? leftArrowRef.current : rightArrowRef.current;

    if (actionTarget) actionTarget.classList.add('load-active');

    if (isAgentTemplates) {
      const currentAgentIndex = agents.findIndex(agent => agent.id == (_id || id));
      let navigatedAgentIndex = isArrowLeft ? currentAgentIndex - 1 : currentAgentIndex + 1;
      if (navigatedAgentIndex === -1 || agents.length === navigatedAgentIndex) {
        navigatedAgentIndex = isArrowLeft ? agents.length - 1 : 0;
      }
      const navigatedTemplateData = {
        ...agents[navigatedAgentIndex],
        previewURL: `https://www.jotform.com/agent-directory/preview/agent/${agents[navigatedAgentIndex].id}?isTemplatePreview=1`
      };
      displayMyformsModal({ template: navigatedTemplateData, ...navigatedTemplateData });
      return;
    }
    let currentTemplateOrder = standaloneTemplates.findIndex(templateIterator => (_id || id) === getPreviewID(templateIterator));

    if ((currentTemplateOrder === 0 && isArrowLeft) || (currentTemplateOrder === standaloneTemplates.length - 1 && !isArrowLeft)) {
      currentTemplateOrder = isArrowLeft ? standaloneTemplates.length : -1;
    }

    const navigatedTemplate = standaloneTemplates[isArrowLeft ? currentTemplateOrder - 1 : currentTemplateOrder + 1];
    if (navigatedTemplate) {
      global.setSelectedTemplate(navigatedTemplate);
    }
  };

  useEffect(() => {
    document.body.classList.add(modalVisibleClassName);
    return () => {
      document.body.classList.remove(modalVisibleClassName);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleArrowClick);
    return () => {
      window.removeEventListener('keydown', handleArrowClick);
    };
  }, [standaloneTemplates, processedTemplate]);

  const formatedTabs = [
    { id: 'overview', name: 'Overview', data: '' }, ...templateTabs
  ];
  const splitedTemplateCategories = (_categories || templateCategories || '').trim().split(',').filter(category => category);
  // eslint-disable-next-line
  const templateCategoriesData = categories.filter(({ id: categoryID, _id: _categoryID }) => splitedTemplateCategories.find(cat => cat == (_categoryID || categoryID)));
  const isEnterpriseEnv = isEnterprise();

  const agentIdentityRenderer = () => {
    try {
      return (
        <div className='w-full grid md:cols-2 gap-10'>
          {
            Object.entries(JSON.parse(identity)).map(([identityName, identityContent]) => {
              const identityData = {
                personality: {
                  icon: IconUserFilled, iconClass: 'color-orange-500', prettyIdentity: 'Personality', color: 'bg-orange-100'
                },
                special_skills: {
                  icon: IconBullseyeArrow, iconClass: 'color-yellow-500', prettyIdentity: 'Special Skills', color: 'bg-yellow-100'
                }
              };
              const {
                icon: IdentityIcon, iconClass, prettyIdentity, color
              } = identityData[identityName] || {};
              if (!prettyIdentity) return null;
              return (
                <div className={`${color} min-h-44 flex flex-col radius-lg p-6 gap-4`}>
                  <h3 className='m-0 flex w-full gap-2 items-center'>
                    <span className={iconClass}>
                      <IdentityIcon className="block" width="24" height="24" />
                    </span>
                    <span className='text-xl font-medium color-navy-700 locale'>
                      {prettyIdentity}
                    </span>
                  </h3>
                  <p className='m-0 text-md line-height-xl color-navy-700 locale'>
                    {identityContent}
                  </p>
                </div>
              );
            })
          }
        </div>
      );
    } catch {
      return null;
    }
  };

  const handleAskMeAction = question => {
    const frameContent = frameRef.current;
    const objWindow = frameContent.contentWindow;
    const objDoc = frameContent.contentDocument || frameContent.contentWindow.document;
    const agentYesNoBtn = objDoc.querySelector('.yes-no-button');
    let clickedYesNo = false;
    if (agentYesNoBtn) {
      agentYesNoBtn.click();
      clickedYesNo = true;
    }

    setTimeout(() => {
      objWindow.postMessage({
        question,
        source: 'agent-directory'
      }, window.location.origin);
      setTimeout(() => {
        const agentSendBtn = objDoc.querySelector('.sendButton');
        if (agentSendBtn) {
          agentSendBtn.click();
        }
      }, 500);
    }, clickedYesNo ? 500 : 0);
  };

  const askMeSectionRenderer = () => {
    try {
      const parsedQuestions = JSON.parse(questions);
      return (
        <div className='page-detail-ask-me mt-8 hidden lg:block'>
          <strong class="text-xl line-height-2xl color-navy-700 font-medium locale">Ask me things like...</strong>
          <ul className='flex flex-col gap-3 -mb-1 mt-4 bg-agent-purple bg-opacity-10 radius color-navy-500 list-disc text-sm line-height-md py-4 pl-8 pr-4 rtl:pr-8 rtl:pl-4'>
            {
              parsedQuestions.map(question => (
                <li
                  className='cursor-pointer'
                  onClick={() => handleAskMeAction(question)}
                >
                  {question}
                </li>
              ))
            }
          </ul>
        </div>
      );
    } catch {
      return null;
    }
  };

  const tabsRenderer = () => {
    if (activeTab === 0) {
      return (
        <div data-tab="overview" className="details-tab-content flex flex-col lg:flex-row mt-10 gap-10 lg:gap-16 pb-4 lg:pb-16">
          {
            (!isEnterpriseEnv && !isAgentTemplates) && (
              <div className="flex-1">
                <strong className="mb-4 color-navy-700 text-xl line-height-2xl block">{t('About this template')}</strong>
                <div className="color-navy-300 text-md line-height-xl about-this-template-section" dangerouslySetInnerHTML={{ __html: _description || description }} />
              </div>
            )
          }
          {
            !isAgentTemplates && (
              <div className="w-80 shrink-0 flex flex-col gap-10 lg:gap-16">
                <div className="categories-section block lg:hidden">
                  <strong className="mb-4 color-navy-700 text-xl line-height-2xl block">Categories</strong>
                  <ul className="flex flex-wrap gap-3 -mb-1">
                    {templateCategoriesData.map(category => (
                      <li>
                        <a
                          href={category.link} title={category.title}
                          className="color-navy-300 py-0.5 px-2 text-sm line-height-md radius mb-1 flex bg-navy-25 hover:bg-navy-75"
                          rel="nofollow noopener"
                        >
                          {category.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="details-section">
                  <strong className="mb-4 color-navy-700 text-xl line-height-2xl block">Details</strong>
                  <div className="flex items-center gap-3">
                    <div className="flex items-center gap-1 text-xs color-navy-300" tabindex="0">
                      <IconCopy width={16} height={16} />
                      <div>
                        <strong>{formatBigNumber(_clonecount || clonecount)}</strong>
                        <span>
                          {' '}
                          {t('Clone')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  !isEnterpriseEnv && (
                    <div className="created-by-section">
                      <strong className="mb-4 color-navy-700 text-xl line-height-2xl block">Created by</strong>
                      <div className="flex items-center gap-2">
                        <div className="radius-full border border-navy-50 w-8 h-8 flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 28"
                            width="16" height="16"
                          >
                            <path
                              id="jotform-logomark-first"
                              fill="#FF6100"
                              d="M19.294 3.934a3.341 3.341 0 0 1 4.727 0 3.344 3.344 0 0 1 0 4.728l-11.817 11.82a3.341 3.341 0 0 1-4.726 0 3.344 3.344 0 0 1 0-4.727l11.816-11.82Z"
                            />
                            <path id="jotform-logomark-second" fill="#0099FF" d="M9.25.98a3.344 3.344 0 0 1 4.727 4.728l-8.271 8.274a3.342 3.342 0 0 1-4.727 0 3.344 3.344 0 0 1 0-4.729L9.25.98Z" />
                            <path id="jotform-logomark-third" fill="#FFB629" d="M18.704 17.527a3.344 3.344 0 0 1 4.726 4.728l-4.726 4.729a3.342 3.342 0 1 1-4.727-4.728l4.727-4.729Z" />
                            <path id="jotform-logomark-fourth" fill="#0A1551" d="M1.671 28h4.667c.745 0 1.118-.9.591-1.427L1.426 21.07C.9 20.542 0 20.915 0 21.659v4.67C0 27.251.748 28 1.671 28Z" />
                          </svg>
                        </div>
                        <a
                          target="_blank"
                          rel="nofollow noopener"
                          href={`/${templateType}/user/${_username || username}`}
                          className="color-blue-500 text-md line-height-xl hover:underline"
                        >
                          {_username || username}
                        </a>
                      </div>
                    </div>
                  )
                }
              </div>
            )
          }
          {
            isAgentTemplates && agentIdentityRenderer()
          }
        </div>
      );
    }
    const { data: activeTabContent } = formatedTabs[activeTab];
    return (
      <div className="jfR-description-area mt-10 gap-10 lg:gap-16 pb-4 lg:pb-16">
        <div dangerouslySetInnerHTML={{ __html: activeTabContent }} />
      </div>
    );
  };

  const formatLegacyStandaloneCategoryData = () => {
    if (!categoryData) return [];
    const result = [];
    const { parent } = categoryData;
    if (parent) result.push({ ...parent, title: parent.name });
    result.push({ ...categoryData, title: categoryData.name });
    return result;
  };

  const templateTypePretty = capitalizeFirstLetter(templateType.split('-')[0]);
  return ReactDOM.createPortal(
    <div className="modal template-detail fixed top-0 left-0 w-full h-full overflow-auto bg-navy-700 bg-opacity-80" style={{ zIndex: 99999 }}>
      <button
        onClick={handleClose}
        className="close-wp absolute lg:fixed color-gray-100 text-xs opacity-70 hover:opacity-100 sm:duration-200 right-4 rtl:right-auto rtl:left-4 top-3 sm:top-2 z-8"
        aria-label="ESC"
        tabindex="0"
        type="button"
      >
        <div className="close-icon w-7 sm:w-11 h-7 sm:h-11 pointer-events-none flex items-center justify-center">
          <IconXmark width={36} height={36} />
        </div>
      </button>
      <div className="modal-wrapper relative max-w-xxl mx-auto md:mb-12 mt-12 lg:mt-16 lg:px-16">
        <div className="template-detail-wrapper relative w-full" ref={wizardModalBodyRef}>
          <div className="bg-white radius-2xl lg:px-8 flex">
            <div className="page-detail-wrapper page-detail-new w-full mx-auto relative mt-2 z-2">
              <div className="w-full px-6 md:px-3 mt-3">
                <p
                  style={{ overflowWrap: 'anywhere' }}
                  className="flex lg:hidden title lg:px-4 mb-4 max-w-full overflow-hidden m-0 grow-1 font-bold text-xl lg:text-4xl color-navy-700"
                >
                  {title}
                </p>
              </div>
              <div className="flex flex-col lg:flex-row gap-8">
                <div
                  className="outline-area max-w-full min-w-full lg:min-w-1/2 xl:min-w-md h-auto radius-2xl lg:p-2"
                  style={{ background: 'linear-gradient(180deg,rgba(31, 34, 37, 0.03) 0%,rgba(31, 34, 37, 0) 100%)' }}
                >
                  <div className="modal-content w-full h-full">
                    <div className="modal-template-area relative z-1 md:min-h-sm md:max-h-sm duration-200 w-full h-120 lg:border border-navy-75 radius-none lg:radius-lg overflow-hidden">
                      { modelContentLoading && <Loader /> }
                      <object
                        ref={frameRef}
                        aria-label="Preview"
                        type="text/html"
                        id={isAgentTemplates ? 'agentPreview' : 'formPreviewArea'}
                        data={previewURL}
                        onLoad={() => {
                          setModelContentLoading(false);
                          rightArrowRef.current.classList.remove('load-active');
                          leftArrowRef.current.classList.remove('load-active');
                        }}
                        className={isAgentTemplates ? 'w-full h-full relative z-0' : 'w-full h-full lg:min-h-md lg:radius-md lg:absolute'}
                      />
                    </div>
                    <div
                      style={{
                        boxShadow: '0px -2px 4px 0px rgba(84, 95, 111, 0.16),0px 0px 1px 0px rgba(37, 45, 91, 0.04)'
                      }}
                      className="cta-controls sticky flex lg:hidden left-0 right-0 bottom-0 bg-white border-t border-t-navy-50 py-4 flex justify-center z-1 lg:mt-2 px-1 overflow-hidden"
                    >
                      <button
                        type="button"
                        ref={isMobile ? leftArrowRef : f => f}
                        // eslint-disable-next-line max-len
                        className="modal-arrow bg-transparent border-0 outline-0 left relative w-12 h-12 color-navy-500 inline-flex items-center justify-center radius hover:bg-navy-25 duration-200 transform rtl:rotate-180"
                        aria-label={t('Previous Template')}
                        onClick={() => handleArrowClick({ key: 'ArrowLeft' })}
                      >
                        <IconChevronLeft width={24} height={24} className="transform rotate-0" />
                      </button>

                      <Button
                        fullWidth
                        colorStyle={isAgentTemplates ? 'apps' : 'primary'}
                        disabled={isCloningTemplate && 'disabled'}
                        className={
                          classNames(
                            'cta-usetemplate w-full min-w-44 max-w-68',
                            isCloningTemplate && 'isCloningTemplate',
                            isAgentTemplates && 'isAgent'
                          )
                        }
                        onClick={({ target }) => useTemplate(processedTemplate, target)}
                        size="large"
                      >
                        {isCloningTemplate ? t(texts.PLEASE_WAIT) : t(isAgentTemplates ? 'Use Agent' : texts.USE_TEMPLATE)}
                      </Button>
                      <button
                        type="button"
                        ref={isMobile ? rightArrowRef : f => f}
                        className="modal-arrow right relative w-12 h-12 color-navy-500 inline-flex items-center justify-center radius hover:bg-navy-25 duration-200 transform rtl:rotate-180"
                        aria-label={t('Next Template')}
                        onClick={() => handleArrowClick({ key: 'ArrowRight' })}
                      >
                        <IconChevronRight width={24} height={24} className="transform rotate-0" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal-content w-full hidden lg:block">
                  <div className="w-full mt-2">
                    <div className="w-full flex flex-col gap-8 mt-4 lg:mt-0">
                      <div className="hero-search-wrapper form-templates aside-search-hero w-full flex gap-2">
                        <div className="hero-search grow-1 relative">
                          <InputText
                            placeholder={`Search in ${templateTypePretty} ${isAgentTemplates ? 'Directory' : 'Templates'}`}
                            className="w-full"
                            value={searchKeyword}
                            onKeyDown={e => {
                              if (e.keyCode === 13) {
                                if (isAgentTemplates) {
                                  const frameWindow = legacyStandaloneFrameRef?.current.contentWindow;
                                  const currentPath = frameWindow.location.pathname;
                                  frameWindow.open(`${currentPath}/search?q=${searchKeyword}`, '_self');
                                } else {
                                  window.handleSearch();
                                }
                                handleClose();
                              }
                            }}
                            onChange={event => {
                              setSearchKeyword(event.target.value);
                            }}
                            prefix={{ icon: IconMagnifyingGlass }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="modalHeader" className="modal-header flex items-center z-6 mt-10 lg:mt-6">
                    <div className="flex flex-col flex-1">
                      <h1 className="title max-w-full overflow-hidden m-0 grow-1 font-bold text-lg lg:text-4xl color-navy-700">
                        {title}
                      </h1>
                    </div>
                  </div>
                  <div className="jfR-description-area modal-content-desc mt-6 color-navy-300 text-md line-height-xl">
                    {_metaDescription || metaDescription}
                  </div>
                  {
                    !isMobile && (
                    <div className="button-wrapper lg:block mt-8">
                      <Button
                        fullWidth
                        colorStyle={isAgentTemplates ? 'apps' : 'primary'}
                        disabled={isCloningTemplate && 'disabled'}
                        className={
                          classNames(
                            'forUseTemplate',
                            isCloningTemplate && 'isCloningTemplate',
                            isAgentTemplates && 'isAgent'
                          )
                        }
                        onClick={({ target }) => useTemplate(processedTemplate, target)}
                        size="large"
                      >
                        {isCloningTemplate ? t(texts.PLEASE_WAIT) : t(isAgentTemplates ? 'Use Agent' : texts.USE_TEMPLATE)}
                      </Button>
                    </div>
                    )
                  }
                  {
                    !isAgentTemplates ? (
                      <div className="page-detail-categories mt-10 lg:mt-16 lg:block">
                        <strong className="font-bold">{t('Categories')}</strong>
                        <ul className="flex flex-wrap gap-3 -mb-1 mt-4">
                          {
                            (newStandaloneActive ? templateCategoriesData : formatLegacyStandaloneCategoryData(categoryData)).map(category => (
                              <li>
                                <a
                                  title={category.title}
                                  rel="nofollow noopener"
                                  onClick={event => {
                                    event.stopPropagation();
                                    if (newStandaloneActive) {
                                      setSelectedCategory(category);
                                    } else if (legacyStandaloneFrameRef?.current) {
                                      const frameWindow = legacyStandaloneFrameRef?.current.contentWindow;
                                      const currentPath = frameWindow.location.pathname;
                                      frameWindow.open(`${currentPath}/category/${category.slug}`, '_self');
                                    }
                                    handleClose();
                                  }}
                                  href="#"
                                  className="color-navy-300 py-0.5 px-2 text-sm line-height-md radius mb-1 flex bg-navy-25 hover:bg-navy-75"
                                >
                                  {category.title}
                                </a>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    ) : askMeSectionRenderer()
                  }
                </div>
              </div>
              <div className="lg:mt-10 px-6 flex flex-col gap-8">
                <div id="overview" className="w-full">
                  <div className="py-4 flex flex-wrap gap-4 items-center border-b border-b-navy-50 justify-between">
                    {
                      !isEnterpriseEnv && (
                        <div className="details-tab-buttons flex gap-1 md:gap-2">
                          {
                            formatedTabs.map((tab, index) => (
                              <button
                                type="button"
                                className={
                                  // eslint-disable-next-line max-len
                                  `duration-200 font-medium px-2 md:px-4 py-1.5 radius-full text-xs md:text-sm line-height-md hover:bg-navy-50 color-navy-300${activeTab === index ? ' bg-navy-25' : ''}`
                                }
                                onClick={() => setActiveTab(index)}
                                data-tab={tab.id}
                              >
                                {tab.name}
                              </button>
                            ))
                          }
                        </div>
                      )
                    }
                    <button
                      type="button"
                      onClick={() => {
                        similarTemplatefRef.current.scrollIntoView({ behavior: 'smooth' });
                      }}
                      className="showMoreTemplatesBtn flex items-center color-navy-500 outline-0 border-0 radius-full hover:bg-navy-25 pr-3 rtl:pl-3 rtl:pr-0"
                    >
                      <span className="p-2 flex">
                        <IconArrowDown width={16} height={16} />
                      </span>
                      <span className="text-xs font-medium">{`Show more ${isAgentTemplates ? 'agents' : 'templates'}`}</span>
                    </button>
                  </div>
                  { tabsRenderer() }
                </div>
                {
                  similarTemplates && (
                    <div ref={similarTemplatefRef}>
                      <SimilarTemplatesItem
                        {...modalProps}
                        {...modalDetailsData}
                        theme={theme}
                        useSlider={true}
                        parentTemplateID={_id || id}
                        templateType={templateType}
                        modalBodyRef={wizardModalBodyRef}
                        onTemplateClone={(templateParam, target) => useTemplate(templateParam, target)}
                        categoryData={categoryData}
                        isAgentTemplates={isAgentTemplates}
                        displayMyformsModal={displayMyformsModal}
                        // eslint-disable-next-line no-nested-ternary
                        similarTemplates={modelContentLoading
                          ? []
                          : (isAgentTemplates
                            ? agents.slice(0, 10).filter(agent => agent.id !== (id || _id))
                            : similarTemplates)}
                        setModalContentLoading={stillLoading => setModalContentLoading(stillLoading)}
                      />
                    </div>
                  )
                }
              </div>
            </div>
            {
              !isMobile && (
                <ModalControls
                  useTemplate={useTemplate}
                  isCloningTemplate={isCloningTemplate}
                  handlePrevNextTemplateHandler={handleArrowClick}
                  newStyle={true}
                  leftRef={!isMobile ? leftArrowRef : f => f}
                  rightRef={!isMobile ? rightArrowRef : f => f}
                />
              )
            }
          </div>
        </div>
      </div>
    </div>,
    global.document.body
  );
};

export default NewWizardTemplateModal;
