/* eslint-disable max-len */
import Styled from 'styled-components';

export const ScMyTemplates = Styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;
  margin: 0 0 1rem;
  font-weight: 400;
  color: #0a224c;
  font-size: 16px;
  transition: 0.15s;
  cursor: pointer;
  box-sizing: content-box;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  outline: none;
  .myTemplatesIcon {
    background-color: #e5f8f0;
    border-radius: 1px;
    width: 16px;
    height: 16px;
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    margin: 0 5px;
    html[dir="rtl"] & {
      transform: rotate(180deg);
    }
    &:after,
    &:before {
      background-color: #01bd6f;
      width: 5px;
      height: 2px;
      border-radius: 1px;
      position: absolute;
      left: 5.5px;
      content: "";
    }
    &:after {
      top: 5px;
      transform: rotate(37deg);
    }
    &:before {
      top: 8px;
      transform: rotate(-48deg);
    }
  }
  &:hover {
    color: #01bd6f;
  }
`;
