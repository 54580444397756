import React, {
  FC,
  ChangeEvent,
  useState,
  useEffect
} from 'react';
import {
  IconMagnifyingGlass,
  IconInfoCircleFilled,
  IconLockFilled
} from '@jotforminc/svg-icons';
import { Hooks } from '@jotforminc/uikit';
import { t, translationRenderer } from '@jotforminc/translation';
import {
  gatewaySupportedCurrencies,
  supportedCurrenciesWithLabel,
  gatewayFieldToName
} from '@jotforminc/payment-constants';
import type { SUPPORTED_CURRENCIES } from '@jotforminc/payment-constants';
import usePaymentPropsPanel from '../Context/usePaymentProperties';

const CurrencyDropdown: FC = () => {
  const {
    gatewayProperties: {
      currency
    },
    hasConnection,
    setGatewayProperty,
    gatewayType
  } = usePaymentPropsPanel();
  const [showDropdown, setShowDropdown] = Hooks.useClickOutsideStateWithSelector(false, '.form-currency-dropdown-button, .form-currency-dropdown');
  const [currencies, setCurrencies] = useState(gatewaySupportedCurrencies[gatewayType]);
  const isFixedCurrency = ['control_square', 'control_cashApp', 'control_afterpay', 'control_clearpay'].includes(gatewayType);
  const supportsOnlyOne = gatewaySupportedCurrencies[gatewayType].length === 1;
  const noConnLabelForFixedCur = translationRenderer(`Connect to [1[${gatewayFieldToName[gatewayType]}]] first`)({
    renderer1: (text: string) => <span>{text}</span>
  });

  const currencyLabel = isFixedCurrency && !hasConnection ? noConnLabelForFixedCur : `${currency} - ${t(supportedCurrenciesWithLabel[currency])}`;

  const filterCurrencies = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCurrencies(gatewaySupportedCurrencies[gatewayType].filter((currencyCode: SUPPORTED_CURRENCIES) => {
      const codeIncludesValue = currencyCode.toLowerCase().includes(value);
      const labelIncludesValue = supportedCurrenciesWithLabel[currencyCode].toLowerCase().includes(value);
      return codeIncludesValue || labelIncludesValue;
    }));
  };

  const onChange = (val: SUPPORTED_CURRENCIES) => {
    const noGatewayConnection = gatewayType === 'control_payment';
    setGatewayProperty('currency', val, noGatewayConnection);
    setCurrencies(gatewaySupportedCurrencies[gatewayType]);
    setShowDropdown(false);
  };

  // reset currencies on close, and gatewayType change
  const resetCurrencies = () => { setCurrencies(gatewaySupportedCurrencies[gatewayType]); };
  useEffect(() => { resetCurrencies(); }, [gatewayType]);
  useEffect(() => { if (!showDropdown) { resetCurrencies(); } }, [showDropdown]);

  return (
    <div className="mr-4 ml-4 relative z-9 paymentSettingsEditor form-currency-dropdown-container">
      <span className='text-sm line-height-md tracking-lg color-white font-bold inline-block mb-2'>
        {t('Currency')}
      </span>
      <button
        type="button"
        className='w-full border bg-gray-500 border-gray-700 color-white h-9 flex p-0 pl-3 justify-between items-center form-currency-dropdown-button radius-sm'
        onClick={() => {
          if (isFixedCurrency || supportsOnlyOne) { return; }
          setShowDropdown(!showDropdown);
        }}
      >
        <span className={`${isFixedCurrency || supportsOnlyOne ? 'color-gray-100' : ''}`}>{currencyLabel}</span>
        <span className="mDropdownMask " />
        <div className="dropdown-arrow-Wrapper form-currency-dropdown-arrow-wrapper ">
          {
            isFixedCurrency || supportsOnlyOne ? (
              <IconLockFilled className='w-5 h-5 color-gray-700' />
            ) : (
              <span className="dropdown-arrow form-currency-dropdown-arrow" />
            )
          }
        </div>
      </button>
      {showDropdown && (
        <div className="w-full absolute bg-gray-700 form-currency-dropdown">
          <div className='flex items-center px-3 py-3'>
            <input
              type="text"
              className='h-5 w-full bg-gray-600 h-8 border-0 color-white pr-7 pl-3 form-currency-dropdown-input'
              onChange={filterCurrencies}
            />
            <IconMagnifyingGlass className='h-4 w-4 absolute top-4.5 right-5 color-gray-300' />
          </div>
          <div className='px-3 pb-3 overflow-x-hidden overflow-y-auto max-h-100 form-currency-dropdown-list'>
            {currencies.map((currencyCode: SUPPORTED_CURRENCIES) => {
              const label = t(supportedCurrenciesWithLabel[currencyCode]);
              const selectedClass = currency === currencyCode ? 'selected' : '';
              return (
                <button
                  key={currencyCode}
                  type="button"
                  data-currency="currency"
                  className={`w-full h-6 bg-transparent border-0 text-left form-currency-dropdown-item ${selectedClass}`}
                  onClick={() => onChange(currencyCode)}
                >
                  <span className="min-w-10 font-bold inline-block">{currencyCode}</span>
                  <span className="currency-name">{label}</span>
                </button>
              );
            })}
          </div>
        </div>
      )}
      {
        hasConnection && isFixedCurrency && (
          <div className='color-gray-200 flex flex-row mt-2'>
            <IconInfoCircleFilled className='min-w-4 h-4 mr-1' />
            <div className='text-xs'>
              {
                translationRenderer(`Your currency was set as [1[${currency}]] based on your [2[${gatewayFieldToName[gatewayType]} account]] settings.`)({
                  renderer1: (text: string) => <span className='color-white'>{text}</span>,
                  renderer2: (text: string) => <a href='https://squareup.com/dashboard' target='_blank'>{text}</a>
                })
              }
            </div>
          </div>
        )
      }
    </div>
  );
};

export default CurrencyDropdown;
