import React from 'react';
import { t } from '@jotforminc/translation';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions
} from '@jotforminc/magnet';
import {
  IconPlugFilled, IconPlus, IconPencilLineFilled, IconArrowsRotate
} from '@jotforminc/svg-icons';

/** Context */
import { useGatewayConnection } from '../../../context';

/** Components */
import { ModalStepsRenderer } from './modalStepsRenderer';
import { ModalFooter } from './modalFooter';
import { DiscardProcessInformation } from './discardProcessInformation';

export const ModalContainer = (): any => {
  const {
    isOpen,
    closeModal,
    currentlyStep,
    currentlyFlow,
    modalSource,
    connectionState,
    showOrHideDiscardNewConnectionDialog
  } = useGatewayConnection();

  if (!isOpen) {
    return null;
  }

  const getTitle = () => {
    if (currentlyFlow === 'EDIT_CONNECTION') {
      return t('Edit Connection');
    }

    if (currentlyFlow === 'RE_CONNECT') {
      return t('Reconnect');
    }

    if (modalSource && modalSource === 'MY_ACCOUNT') {
      return t('Add Connection');
    }

    return t('Payment Connections');
  };

  const getDescription = () => {
    if (currentlyFlow === 'EDIT_CONNECTION') {
      return t('Change your payment connection details');
    }

    if (currentlyFlow === 'RE_CONNECT') {
      return t('Update your payment connection details');
    }

    if (modalSource && modalSource === 'MY_ACCOUNT') {
      return t('Collect online payments with reusable payment connections');
    }

    return t('Collect online payments with reusable payment connections');
  };

  const getIcon = () => {
    if (currentlyFlow === 'EDIT_CONNECTION') {
      return IconPencilLineFilled;
    }

    if (currentlyFlow === 'RE_CONNECT') {
      return IconArrowsRotate;
    }

    if (modalSource && modalSource === 'MY_ACCOUNT' && (currentlyStep === 'gatewayPicker' || currentlyStep === 'gatewayConnection')) {
      return IconPlus;
    }

    return IconPlugFilled;
  };

  const handleOnClose = (event?: object | boolean) => {
    if (typeof event === 'boolean' && event === false) {
      return;
    }

    if (currentlyStep === 'gatewayConnection' && connectionState === 'connected') {
      showOrHideDiscardNewConnectionDialog('backOrClose');
      return;
    }
    closeModal();
  };

  return (
    <>
      <Modal
        ariaLabel={getTitle()}
        onClose={handleOnClose}
        open={isOpen}
        id='gateway-connection-modal-container'
        size='large'
        fitOnMobile={true}
      >
        <ModalHeader
          title={getTitle()}
          description={getDescription()}
          icon={getIcon()}
          iconColorStyle="primary"
          onClose={handleOnClose}
        />
        <ModalBody tabIndex={0}>
          <ModalStepsRenderer />
        </ModalBody>
        <ModalActions>
          <ModalFooter />
        </ModalActions>
      </Modal>
      <DiscardProcessInformation />
    </>
  );
};
