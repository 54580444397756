import React, { useMemo } from 'react';
import { string } from 'prop-types';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/magnet';
import { useDispatch } from 'react-redux';
import { IconCreditCardFilled } from '@jotforminc/svg-icons';
import { ITEM_TYPES } from '../../../../../constants/itemTypes';
import {
  RightPanelTitles,
  CUSTOM_PANEL_TITLE_ELEMENTS,
  RightPanelModes
} from '../constants';
import { getWidgetTitle } from './utils';
import { checkMobileOrTablet } from '../../../../../utils';
import { openRightPanelWithModeAction, trackEventAction } from '../../../../../store/actionCreators';

// TODO remove redundant itemType, itemTitle props
const RightPanelTitleRenderer = ({
  rightPanelMode: mode, itemType, itemTitle, buttonRole
}) => {
  const dispatch = useDispatch();
  if (!mode) return '';
  if (!CUSTOM_PANEL_TITLE_ELEMENTS.includes(mode)) {
    return t(RightPanelTitles[mode]);
  }

  if (!itemType) return '';

  const type = ITEM_TYPES[itemType];
  const hasSettingsButton = type === ITEM_TYPES.PRODUCT_LIST;
  const isMobileorTablet = checkMobileOrTablet();

  const title = useMemo(() => {
    switch (mode) {
      case RightPanelModes.APP_ITEM:
        switch (type) {
          case ITEM_TYPES.FORM:
            return itemTitle;
          case ITEM_TYPES.TABLE_LINK:
            return `${t('{itemTitle} Table').replace('{itemTitle}', itemTitle)}`;
          case ITEM_TYPES.REPORT_LINK:
            return `${t('{itemTitle} Report').replace('{itemTitle}', itemTitle)}`;
          case ITEM_TYPES.SENTBOX_LINK:
            return `${t('{itemTitle} Sentbox').replace('{itemTitle}', itemTitle)}`;
          case ITEM_TYPES.SIGN_LINK:
            return `${t('{itemTitle} Sign Document').replace('{itemTitle}', itemTitle)}`;
          case ITEM_TYPES.PRODUCT_LIST:
            return t('Products');
          case ITEM_TYPES.DONATION:
            return t('Donation Box');
          case ITEM_TYPES.BUTTON:
            return buttonRole === 'share' ? t('Share Button') : t('Button');
          case ITEM_TYPES.CARD_ITEM:
            return t('Card');
          case ITEM_TYPES.LIST:
            return t('List');
          default:
            return t(`${type.charAt(0)}${type.slice(1).toLowerCase()}`);
        }
      case RightPanelModes.APP_WIDGET:
        return t(getWidgetTitle(itemTitle));
      default:
        return '';
    }
  }, [mode, itemType, itemTitle, buttonRole]);

  const handleOnStoreSettingsClick = () => dispatch(openRightPanelWithModeAction(RightPanelModes.PAYMENT_SETTINGS));

  const StoreSettingsButton = () => (
    <Button
      className='mr-12'
      startIcon={IconCreditCardFilled}
      size='small'
      colorStyle='success'
      onClick={handleOnStoreSettingsClick}
      showTextOnly='md'
      rounded={isMobileorTablet ? true : false}
    >
      {t('Store Properties')}
    </Button>
  );

  const handleOnDonationSettingsClick = () => {
    dispatch(openRightPanelWithModeAction(RightPanelModes.DONATION_PAYMENT_SETTINGS));
    dispatch(trackEventAction({ action: 'paymentPropertiesButtonOnTheRightPanelClicked' }));
  };

  const DonationSettingsButton = () => (
    <Button
      className='mr-12'
      startIcon={IconCreditCardFilled}
      size='small'
      colorStyle='success'
      onClick={handleOnDonationSettingsClick}
      showTextOnly='md'
      rounded={isMobileorTablet ? true : false}
    >
      {t('Payment Properties')}
    </Button>
  );

  const panelTitle = !hasSettingsButton ? `${title} ${t('Properties')}` : title;

  return (
    <>
      <div className="rp-formName">{panelTitle}</div>
      {type === ITEM_TYPES.PRODUCT_LIST && <StoreSettingsButton />}
      {type === ITEM_TYPES.DONATION && <DonationSettingsButton />}
    </>
  );
};

RightPanelTitleRenderer.propTypes = {
  rightPanelMode: string,
  itemType: string,
  itemTitle: string,
  buttonRole: string
};

RightPanelTitleRenderer.defaultProps = {
  rightPanelMode: '',
  itemType: '',
  itemTitle: '',
  buttonRole: ''
};

export default RightPanelTitleRenderer;
