import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Chargify gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlChargify: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_chargify: {
    ccTitle: {
      title: 'Credit Card Label Text',
      type: 'input',
      default: 'Credit Card',
      resources: ['FORM', 'APP']
    },
    billingAddress: {
      title: 'Billing Address Field',
      type: 'dropdown',
      subType: 'formQuestion',
      questionTypesAsOption: ['control_address'],
      default: 'none',
      resources: ['FORM'],
      renderCondition: formQuestions => Array.isArray(formQuestions) && formQuestions.filter(q => ['control_address'].includes(q.type)).length > 0
    },
    customerPhone: {
      title: 'Customer Phone',
      type: 'dropdown',
      subType: 'formQuestion',
      questionTypesAsOption: ['control_phone'],
      default: 'none',
      resources: ['FORM'],
      renderCondition: formQuestions => Array.isArray(formQuestions) && formQuestions.filter(q => ['control_phone'].includes(q.type)).length > 0
    }
  }
};

export default controlChargify;
