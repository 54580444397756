/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import React from 'react';
import ReactDOM from 'react-dom';
import {
  string, func, bool, object, arrayOf
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { classNames } from '@jotforminc/utils';
import { Portal } from '@jotforminc/magnet';

import Button from '../Button';
import Loader from '../Loader';
import CloseButton from '../Button/CloseButton';

import texts from '../../core/texts';
import { stateClass } from '../../core/utils';
import { templateConstants } from '../StandaloneTemplates/templateConstants';
import { customModalConstants } from '../StandaloneTemplates/customModalConstants';
import { getPreviewID } from '../StandaloneTemplates/utils';
import ModalControls from '../StandaloneTemplates/components/PreviewModalContents/ModalControls';

export default class TemplateModal extends React.Component {
  constructor(props) {
    super(props);
    this.wizardModalBodyRef = React.createRef();

    this.state = {
      isCloningTemplate: false,
      modalContentLoading: true,
      additionalWrapperClasses: [],
      customHandleCloseFunction: null
    };

    this.modalWrapperRef = React.createRef();
    this.useTemplate = this.useTemplate.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleEscapePressed = this.handleEscapePressed.bind(this);
    this.setCloneTemplateLoading = this.setCloneTemplateLoading.bind(this);
    this.listenForEmbedFormLoader = this.listenForEmbedFormLoader.bind(this);
    this.hideFormLoaderIndicators = this.hideFormLoaderIndicators.bind(this);
    this.handlePrevNextTemplateHandler = this.handlePrevNextTemplateHandler.bind(this);

    this.isNativeMobileApp = global.navigator.userAgent.indexOf('JotForm Mobile') > -1 || global.navigator.userAgent.indexOf('JFCEMobile') > -1;
  }

  componentDidMount() {
    this.listenForEmbedFormLoader();
    window.addEventListener('keydown', this.handlePrevNextTemplateHandler);
  }

  componentDidUpdate() {
    const { isVisible } = this.props;

    stateClass(isVisible ? 'add' : 'remove', 'jfWizard--isModalVisible');
  }

  componentWillUnmount() {
    if (global.Embed && global.Embed.hideFormLoaderIndicators === this.hideFormLoaderIndicators) {
      delete global.Embed.hideFormLoaderIndicators;
    }
    window.removeEventListener('keydown', this.handlePrevNextTemplateHandler);
  }

  static getDerivedStateFromProps(props) {
    const { isVisible } = props;

    if (!isVisible) {
      return { modalContentLoading: true };
    }
  }

  handlePrevNextTemplateHandler(event) {
    const {
      standaloneTemplates = [],
      template: previewedTemplate,
      isVisible: previewModalVisible
    } = this.props;
    if (!previewModalVisible) return false;

    if (standaloneTemplates.length === 0) return;

    const { key } = event;
    const keyboardEvents = ['ArrowLeft', 'ArrowRight'];
    if (!keyboardEvents.includes(key)) return;

    const { parentTemplateID } = previewedTemplate;
    const previewTemplateID = parentTemplateID || getPreviewID(previewedTemplate);
    let currentTemplateOrder = standaloneTemplates.findIndex(template => previewTemplateID === getPreviewID(template));

    if (currentTemplateOrder === -1) {
      return;
    }

    const isArrowLeft = key === 'ArrowLeft';
    if ((currentTemplateOrder === 0 && isArrowLeft) || (currentTemplateOrder === standaloneTemplates.length - 1 && !isArrowLeft)) {
      currentTemplateOrder = isArrowLeft ? standaloneTemplates.length : -1;
    }

    this.setState({ modalContentLoading: true });

    const navigatedTemplate = standaloneTemplates[isArrowLeft ? currentTemplateOrder - 1 : currentTemplateOrder + 1];
    if (navigatedTemplate) {
      global.setSelectedTemplate(navigatedTemplate);
    }
  }

  handleClose() {
    const { customHandleCloseFunction } = this.state;
    if (customHandleCloseFunction !== null) {
      customHandleCloseFunction();
      return;
    }
    const { onClose, actionLogger } = this.props;

    this.setState({
      modalContentLoading: true
    });

    if (actionLogger) {
      actionLogger({
        action: 'useTemplateModalCloseClick',
        target: '.jfWizard-button.forModalClose'
      });
    }

    if (typeof onClose === 'function') {
      onClose();
    }
    this.setState({
      additionalWrapperClasses: []
    });
  }

  handleEscapePressed(e) {
    const { isVisible } = this.props;
    if (isVisible) {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.handleClose();
    }
  }

  setCloneTemplateLoading(isCloningTemplate) {
    this.setState({
      isCloningTemplate
    });
  }

  useTemplate() {
    const {
      id, theme, actionLogger, onTemplateClone, username, template
    } = this.props;

    if (actionLogger) {
      actionLogger({
        action: 'useTemplateButtonClick',
        target: '.jfWizard-button.forUseTemplate'
      });
    }
    if (typeof window.standaloneTemplatesLogger === 'function') {
      window.standaloneTemplatesLogger({
        actor: username,
        target: id,
        action: `${window.innerWidth <= 780 ? 'mobile' : 'desktop'}:useTemplate`
      });
    }

    this.setCloneTemplateLoading(true);
    const { isNewStandalone, frameSrc, source } = this.props;
    const templateType = frameSrc.split('/')[1];

    let onTemplateCloneReqParams = { formID: id, theme };
    if (isNewStandalone) {
      const { getCloneTemplatePayload } = templateConstants[templateType];
      onTemplateCloneReqParams = getCloneTemplatePayload(
        {},
        { ...template, ...(templateType === 'form-templates' ? { theme } : {}) },
        source
      )?.payload;
    }
    onTemplateClone(onTemplateCloneReqParams)?.then(() => this.setCloneTemplateLoading(false));
  }

  listenForEmbedFormLoader() {
    if (typeof global.Embed !== 'object') {
      global.Embed = {};
    }

    global.Embed.hideFormLoaderIndicators = this.hideFormLoaderIndicators;
  }

  hideFormLoaderIndicators() {
    this.setState({
      modalContentLoading: false
    });
  }

  render() {
    const {
      title, previewURL, isVisible, frameSrc, similarTemplates, DOM, onTemplateClone,
      id = '', template = {}, onClose, categoryData = {}, username, theme, useMagnetPortal
    } = this.props;
    const { signable = false } = template;

    const {
      isCloningTemplate,
      modalContentLoading,
      additionalWrapperClasses
    } = this.state;

    // const isFormTemplates = isNewStandalone && frameSrc.includes('form-templates');

    const templateType = frameSrc.split('/')[1];
    const { customPreviewModalClass = '' } = templateConstants[templateType] || {};
    const { CustomPreviewModalBody } = customModalConstants[templateType] || {};
    const modalBodyRenderer = () => {
      const setAdditionalWrapperClasses = newClassList => this.setState({ additionalWrapperClasses: newClassList });
      const setCustomHandleCloseFunction = handleCloseFunc => this.setState({ customHandleCloseFunction: handleCloseFunc });
      const previewModalProps = {
        id,
        DOM,
        theme,
        previewURL,
        username,
        template,
        frameSrc,
        categoryData,
        onTemplateClone,
        isCloningTemplate,
        similarTemplates,
        modalContentLoading,
        closePreviewModal: onClose,
        setAdditionalWrapperClasses,
        setCustomHandleCloseFunction,
        useTemplate: this.useTemplate,
        modalBodyRef: this.wizardModalBodyRef,
        modalWrapperRef: this.modalWrapperRef,
        handlePrevNextTemplateHandler: this.handlePrevNextTemplateHandler,
        setModalContentLoading: stillLoading => this.setState({ modalContentLoading: stillLoading })
      };
      if (CustomPreviewModalBody) {
        if (Object.keys(template).length === 0) return null;
        return <CustomPreviewModalBody {...previewModalProps} />;
      }
      return (
        <>
          <Loader />
          <object
            type="text/html"
            id='formPreviewArea'
            className="jfWizard-modal-frame"
            data={isVisible ? previewURL : ''}
          >
            {t('Form Preview Area')}
          </object>
        </>
      );
    };

    const { PLATFORM_ENV = '' } = window;
    const modalWrapperRenderer = () => (
      <div
        ref={this.modalWrapperRef}
        className={
      classNames(
        'jfWizard-modal-wrapper',
        customPreviewModalClass,
        isVisible && 'isVisible',
        (!modalContentLoading || PLATFORM_ENV !== '') && 'isFrameContentReady',
        signable === '1' ? 'sign-templates' : templateType,
        additionalWrapperClasses.join(' ')
      )
    }
      >
        <div className="jfWizard-modal-backdrop" onClick={this.handleClose} onKeyUp={null} />
        <div className="jfWizard-modal">
          <div className="jfWizard-modal-header">
            <div className="jfWizard-modal-header-column forLeft">
              {/* {isFormTemplates && <h2 className="jfWizard-modal-header-preview">{t(texts.PREVIEW_FORM)}</h2>} */}
              { title && <h1 className="jfWizard-modal-header-title">{title}</h1> }
            </div>
            <div className="jfWizard-modal-header-column forMiddle">
              <Button disabled={isCloningTemplate && 'disabled'} className={classNames('forUseTemplate', isCloningTemplate && 'isCloningTemplate')} onClick={this.useTemplate}>
                {isCloningTemplate ? t(texts.PLEASE_WAIT) : t(texts.USE_TEMPLATE)}
              </Button>
            </div>
            <div className="jfWizard-modal-header-column forRight">
              <CloseButton className="forModalClose" onClick={this.handleClose} />
            </div>
          </div>
          <div ref={this.wizardModalBodyRef} className="jfWizard-modal-body">
            {isVisible && modalBodyRenderer()}
          </div>
          {
            !this.isNativeMobileApp && (
              <ModalControls
                className='hide lg:flex'
                useTemplate={this.useTemplate}
                isCloningTemplate={isCloningTemplate}
                handlePrevNextTemplateHandler={this.handlePrevNextTemplateHandler}
              />
            )
          }
        </div>
      </div>
    );
    return useMagnetPortal ? <Portal>{modalWrapperRenderer()}</Portal> : ReactDOM.createPortal(modalWrapperRenderer(), global.document.body);
  }
}

TemplateModal.propTypes = {
  id: string,
  DOM: string,
  title: string,
  source: string,
  frameSrc: string,
  username: string,
  previewURL: string,
  theme: string,
  onClose: func,
  isVisible: bool,
  template: object,
  categoryData: object,
  similarTemplates: arrayOf(object),
  actionLogger: func,
  onTemplateClone: func,
  isNewStandalone: bool,
  standaloneTemplates: arrayOf(object),
  useMagnetPortal: bool
};

TemplateModal.defaultProps = {
  DOM: '',
  id: null,
  source: '',
  title: null,
  username: '',
  template: undefined,
  categoryData: undefined,
  similarTemplates: [],
  frameSrc: '',
  isNewStandalone: false,
  previewURL: null,
  theme: null,
  onClose: f => f,
  isVisible: false,
  actionLogger: null,
  standaloneTemplates: [],
  onTemplateClone: f => f,
  useMagnetPortal: false
};
