/* eslint-disable */
import '@jotforminc/router-bridge/init';
import '@jotforminc/tracking/init';
/* eslint-enable */
// Open to use WDYR
// import './wdyr';
import '@jotforminc/jotform.css';
// eslint-disable-next-line
import '@jotforminc/jotform.css/reset.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
