import Ajv from 'ajv';
import AjvMergePatch from 'ajv-merge-patch';
import AllSchemas from './schemas';

const getAJV = ({ useDefaults = false, addUsedSchema = true }) => {
  const ajv = new Ajv({ useDefaults, addUsedSchema, code: { source: true } });
  AjvMergePatch(ajv);
  Object.entries(AllSchemas).forEach(([key, schema]) => {
    ajv.addSchema(schema, key);
  });

  return ajv;
};

const ajv = getAJV({ useDefaults: true, addUsedSchema: false });

export const generateDefaults = (schema, rest) => {
  const data = {};
  const extendWithDefaults = ajv.compile(schema);
  extendWithDefaults(data);
  return {
    ...data,
    ...rest
  };
};
