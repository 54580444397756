import React, {
  Suspense, useEffect, useState
} from 'react';
import { useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import { Redirect } from '@reach/router';
import { useOfflineMode } from '@jotforminc/jotform-common';
import { safeLazy } from '@jotforminc/safe-lazy-import';
import { initWidgetsBuilder } from '@jotforminc/widgets-js-sdk';
import { toast } from 'react-toastify';
import CoreAppRouter from './CoreAppRouter';

import APP_CONFIG from './constants/appConfig';

import { GlobalStyle } from './styles/global';
import CreateNewPortal from './modules/Owner/CreateNewPortal';
import withStore from './withStore';

import Modals from './components/Modals';
import Toaster from './components/Toaster';
import { Loading } from './components/Loading';
import BuilderLoading from './components/BuilderLoading';
import PublicAppLoading from './components/PublicAppLoading';
import ClonePreventedFormsNotification from './components/ClonePreventedFormsNotification';
import {
  toggleNetworkStatusAction, setActiveItem, setAppLocation
} from './store/actionCreators';
import Settings from './modules/Builder/components/Settings';
import Publish from './modules/Builder/components/Publish';
import withFeatureToggles from './withFeatureToggles';
import { FEATURE_NAMES } from './constants/features';
import { isFeatureEnabled } from './utils/features/helper';
import { initLiveChat } from './utils/initLiveChat';

initWidgetsBuilder();

const NotFound = safeLazy(() => import(/* webpackChunkName: "NotFound" */'./components/NotFound'));
const Owner = safeLazy(() => import(/* webpackChunkName: "Owner" */'./modules/Owner'));
const PublicAppContainer = safeLazy(() => import(/* webpackChunkName: "PublicAppContainer" */'./modules/containers/PublicAppContainer'));

const Builder = safeLazy(() => import(/* webpackChunkName: "Builder" */'./modules/Builder'));
const OldPublish = safeLazy(() => import(/* webpackChunkName: "OldPublish" */'./modules/Builder/components/OldPublish'));
const HomePage = safeLazy(() => import(/* webpackChunkName: "HomePage" */'./modules/Builder/components/HomePage'));
const CreateNewPortalWithForm = safeLazy(() => import(/* webpackChunkName: "CreateNewPortalWithForm" */'./modules/Owner/CreateNewPortalWithForm'));
const CreateNewPortalWithWidget = safeLazy(() => import(/* webpackChunkName: "CreateNewPortalWithWidget" */'./modules/Owner/CreateNewPortalWithWidget'));
const CreateNewPortalWithStoreBuilder = safeLazy(() => import(/* webpackChunkName: "CreateNewPortalWithStoreBuilder" */'./modules/Owner/CreateNewPortalWithStoreBuilder'));
const CreateNewPortalWithTemplate = safeLazy(() => import(/* webpackChunkName: "CreateNewPortalWithTemplate" */'./modules/Owner/CreateNewPortalWithTemplate'));
const CreateNewPortalWithDonationBuilder = safeLazy(() => import(/* webpackChunkName: "CreateNewPortalWithStoreBuilder" */'./modules/Owner/CreateNewPortalWithDonationBuilder'));
const BuildWithForm = safeLazy(() => import(/* webpackChunkName: "BuildWithForm" */'./modules/Owner/BuildWithForm'));
const AppIdentifierSettingsContainer = safeLazy(() => import(/* webpackChunkName: "AppIdentifierSettingsContainer" */'./modules/Builder/components/Settings/AppIdentifierSettings'));
const AppSplashSettings = safeLazy(() => import(/* webpackChunkName: "AppSplashSettings" */'./modules/Builder/components/Settings/AppSplashSettings'));
const PushNotificationSettings = safeLazy(() => import(/* webpackChunkName: "AppSplashSettings" */'./modules/Builder/components/Settings/PushNotificationSettings'));
const AppSettings = safeLazy(() => import(/* webpackChunkName: "AppSettings" */'./modules/Builder/components/Settings/AppSettings'));
const Embed = safeLazy(() => import(/* webpackChunkName: "Embed" */'./modules/Builder/components/Publish/Embed'));
const QuickShare = safeLazy(() => import(/* webpackChunkName: "QuickShare" */'./modules/Builder/components/Publish/QuickShare'));

window.React = React; // Builder repo requires it
window.ReactDOM = ReactDOM; // Builder repo requires it

const App = () => {
  const dispatch = useDispatch();
  const onOffline = () => dispatch(toggleNetworkStatusAction(false));
  const onOnline = () => dispatch(toggleNetworkStatusAction(true));
  useOfflineMode({ onOffline, onOnline });

  const [AppConfig, setConfig] = useState(APP_CONFIG);

  const handleLocationChange = pathname => {
    dispatch(setAppLocation(pathname));
    dispatch(setActiveItem());
  };

  useEffect(() => {
    setConfig(APP_CONFIG);
  }, [APP_CONFIG]);

  const GlobalLoading = window.isStandalone ? PublicAppLoading : BuilderLoading;

  const handleClosePreventedFormsToast = () => {
    window.sessionStorage.removeItem('clonePreventedForms');
  };

  useEffect(() => {
    if (window.sessionStorage.getItem('clonePreventedForms')) {
      toast(<ClonePreventedFormsNotification onClick={handleClosePreventedFormsToast} />,
        {
          delay: 3000,
          autoClose: 10000,
          className: 'clonePreventedFormsToast',
          onClose: handleClosePreventedFormsToast
        });
    }
    initLiveChat();
  }, []);

  return (
    <div className='portalApp isMobileView'>
      <GlobalStyle />
      <Suspense fallback={<GlobalLoading />} path='/'>
        <CoreAppRouter basepath={AppConfig.basePath} onLocationChange={pathname => handleLocationChange(pathname)}>
          {!window.isStandaloneWithSlug && <CreateNewPortal path="/" exact />}
          <CreateNewPortalWithForm path="/create/:formIDs" exact />
          <CreateNewPortalWithTemplate path="/template/:appID" exact />
          <CreateNewPortalWithWidget path="/createWithWidget/:widgetID" exact />
          <CreateNewPortalWithStoreBuilder path="/createWithStoreBuilder" exact />
          <CreateNewPortalWithDonationBuilder path="/createWithDonationBuilder" exact />
          <BuildWithForm path="/buildWith/:formID" exact />
          <Owner path="/build">
            <Builder path="/:appID">
              <HomePage default path="/" />
              {isFeatureEnabled(FEATURE_NAMES.Embed) ? (
                <Publish path="/publish">
                  <Suspense fallback={<Loading />} path="/">
                    <QuickShare default path="/link" />
                    <QuickShare
                      path="/link/shareSettings"
                      panel="shareSettings"
                      exact
                    />
                    <Embed path="/embed" />
                  </Suspense>
                </Publish>
              ) : (<OldPublish path="/publish" />)}
              <Settings path='/settings'>
                <Suspense fallback={<Loading />} path="/">
                  <AppSettings default path="/details" />
                  <AppIdentifierSettingsContainer path="/appName" />
                  <AppSplashSettings path="/splash" />
                  {isFeatureEnabled(FEATURE_NAMES.PushNotification) && <PushNotificationSettings path="/pushNotification" />}
                </Suspense>
              </Settings>
            </Builder>
            <NotFound default />
          </Owner>
          {window.isStandaloneWithSlug && window.appID ? (
            <PublicAppContainer appID={window.appID} path="/*" />
          ) : (
            <>
              <PublicAppContainer path=":appID/*" />
              <Redirect from="/" to="build" />
            </>
          )}
        </CoreAppRouter>
      </Suspense>
      <Toaster />
      <Modals />
    </div>
  );
};

export default withFeatureToggles()(withStore(App));
