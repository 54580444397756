import React from 'react';
import {
  array, func, string, number
} from 'prop-types';
import { getDomainURL } from '@jotforminc/request-layer';

const MultiplePreview = ({
  onLoad,
  currentThemeId,
  previewQueryParams,
  themesWithLoadAllowed
}) => {
  return (
    <>
      {themesWithLoadAllowed.map((theme, index) => {
        if (!theme.allowLoading) return null;
        return (
          <object
            key={theme.id}
            title="App Preview"
            className={`${currentThemeId === theme.id ? 'active' : ''}`}
            data={`${getDomainURL()}/app/${theme.id}?${previewQueryParams}`}
            onLoad={event => onLoad(event, theme, index)}
          />
        );
      })}
    </>
  );
};

MultiplePreview.propTypes = {
  themesWithLoadAllowed: array.isRequired,
  previewQueryParams: string.isRequired,
  currentThemeId: number,
  onLoad: func
};

MultiplePreview.defaultProps = {
  onLoad: f => f,
  currentThemeId: 0
};

export default MultiplePreview;
