/* eslint-disable max-len */
export const FORM_LIMIT = 'form limit';
export const SUBMISSION_LIMIT = 'submission limit';
export const WORKFLOW_RUNS_LIMIT = 'workflow runs limit';
export const FORMS = 'forms';
export const SUBMISSIONS = 'submissions';
export const WORKFLOW_RUNS = 'workflow runs';
export const YOU_HAVE_REACHED_YOUR_LIMIT = 'You have reached your [1[{limitType}]]';
export const ACCOUNTS_LIMITED_TO = '[1[{planType}]] accounts are limited to [2[{limit}]] [3[{limitType}]] per month.';
export const UPGRADE_CTA_SUBMISSIONS = '[1[Upgrade]] your account to see all your submissions.';
export const UPGRADE_CTA_FORM_COUNT = '[1[{planType}]] accounts are limited to [2[{limit}]] forms. Delete an existing form or [3[Upgrade]] to increase your form limit.';
export const UPGRADE_CTA_WORKFLOW_RUNS = '[1[Upgrade]] your account to run your workflow.';
export const NEWUSER_CAMPAIGN_CTA = '[1[Today ONLY]] Save 50%';
export const YOU_HAVE_REACHED_YOUR_WORKFLOW_RUNS_LIMIT = 'You have reached your workflow runs limit';
export const YOU_HAVE_REACHED_YOU_FORM_LIMIT = 'You have reached your form limit';

// Builder Overquota Warning Texts
export const CURRENTLY_UNAVAILABLE = 'This form is currently unavailable to form fillers';
export const UPGRADE_YOUR_ACCOUNT = 'As a starter account user, you’ve reached your [1[{limitType}]] limit of [2[{limitValue}]]. [3[Upgrade]] your account to increase your [1[{limitType}]].';
