import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Paymentwall gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlPaymentwall: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_paymentwall: {}
};

export default controlPaymentwall;
