/** types */
import type { GATEWAY_SUPPORTED_RESOURCES, PAYMENT_FIELDS } from '@jotforminc/payment-constants';
import type { CONNECTION_LIST_DATA } from '../../types';

/** api */
import { getPaymentConnections } from '../../api/getPaymentConnections';

/**
 * Checks if there is a gateway connection with the given request parameters.
 *
 * @param {Object} params - The request parameters.
 * @param {GATEWAY_SUPPORTED_RESOURCES} params.resourceType - The type of the resource.
 * @param {string} params.resourceId - The ID of the resource.
 * @param {PAYMENT_FIELDS} params.gatewayType - The type of the gateway.
 *
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating if there is a gateway connection.
 *
 * @throws {Error} - If an error occurs while checking the gateway connection.
 */
export const hasGatewayConnectionWithRequest = async ({
  resourceType,
  resourceId,
  gatewayType
}: {
  resourceType: GATEWAY_SUPPORTED_RESOURCES;
  resourceId: string;
  gatewayType: PAYMENT_FIELDS;
}): Promise<boolean> => {
  try {
    const connections = await getPaymentConnections({
      resourceType,
      resourceId
    });

    if (connections && !Array.isArray(connections) && typeof connections === 'object') {
      const List = Object.values(connections) as CONNECTION_LIST_DATA[];

      const hasGatewayConnection = List.some((connection: CONNECTION_LIST_DATA) => {
        return connection.gateway === gatewayType || connection.parent_gateway === gatewayType;
      });

      return hasGatewayConnection;
    }

    return false;
  } catch (error) {
    console.error('Error in hasGatewayConnectionWithRequest:', error);
    return false;
  }
};
