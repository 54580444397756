import type { GATEWAY_RESOURCE_PROPS_TYPE } from '../../types';

/**
 * Control Payu Money gateway resource properties.
 * @type {Array<COMPONENT_PROPERTIES>}
 */

const controlPayuMoney: GATEWAY_RESOURCE_PROPS_TYPE = {
  control_payuMoney: {
    askBillingInformation: {
      title: 'Ask Billing Information to Customer',
      type: 'toggle',
      selectedCondition: settings => settings.askBillingInformation === 'Yes',
      valueTrue: 'Yes',
      valueFalse: 'No',
      default: 'No',
      resources: ['FORM']
    },
    billingAddress: {
      title: 'Use Field as Billing Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      addressSubfields: 'state|zip|st1|city|st2',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    shippingAddress: {
      title: 'Use Field as Shipping Address',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_address'],
      questionTypeToAdd: 'control_address',
      addressSubfields: 'state|zip|st1|city|st2',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    billingName: {
      title: 'Billing Name',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_fullname', 'control_textbox'],
      questionTypeToAdd: 'control_fullname',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    emailField: {
      title: 'Customer Email Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_email'],
      questionTypeToAdd: 'control_email',
      addressSubfields: 'state|zip|st1|city|st2',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    },
    phoneField: {
      title: 'Customer Phone Field',
      type: 'dropdown',
      subType: 'addQuestion',
      questionTypesAsOption: ['control_phone'],
      questionTypeToAdd: 'control_phone',
      default: 'none',
      resources: ['FORM'],
      renderCondition: settings => !Array.isArray(settings) && typeof settings === 'object' && settings.askBillingInformation === 'Yes',
      autoSave: true
    }
  }
};

export default controlPayuMoney;
