/* eslint-disable max-len */
import React from 'react';
import { string } from 'prop-types';

const MobileBorder = ({ splashBgColor }) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 407 844"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.32 31.67C0 44.07 0 60.32 0 92.8v658.4c0 32.48 0 48.72 6.32 61.13a58 58 0 0 0 25.35 25.35C44.07 844 60.32 844 92.8 844h221.4c32.48 0 48.73 0 61.13-6.32a57.99 57.99 0 0 0 25.35-25.35c6.32-12.4 6.32-28.65 6.32-61.13V92.8c0-32.48 0-48.72-6.32-61.13a58 58 0 0 0-25.35-25.35C362.93 0 346.68 0 314.2 0H92.8C60.32 0 44.08 0 31.67 6.32A58 58 0 0 0 6.32 31.67Zm14.47 8.35C16 49.42 16 61.74 16 86.38v671.24c0 24.64 0 36.95 4.79 46.36a43.97 43.97 0 0 0 19.2 19.23C49.4 828 61.72 828 86.33 828h234.36c24.61 0 36.92 0 46.32-4.8a43.97 43.97 0 0 0 19.21-19.22c4.79-9.4 4.79-21.72 4.79-46.36V86.38c0-24.64 0-36.95-4.79-46.36a43.97 43.97 0 0 0-19.2-19.23c-8.86-4.51-20.28-4.77-42.13-4.79-.95 0-1.43 0-1.81.13-.72.24-1.29.8-1.53 1.53-.13.38-.13.85-.13 1.8 0 7.58 0 11.37-1.04 14.43a19.54 19.54 0 0 1-12.22 12.23c-3.05 1.04-6.84 1.04-14.41 1.04H113.26c-7.57 0-11.36 0-14.42-1.04a19.54 19.54 0 0 1-12.21-12.23c-1.04-3.06-1.04-6.85-1.04-14.43 0-.95 0-1.42-.13-1.8a2.44 2.44 0 0 0-1.53-1.53 6.13 6.13 0 0 0-1.8-.13c-21.85.02-33.28.28-42.13 4.8a43.97 43.97 0 0 0-19.21 19.22Z"
        fill="#F3F3FE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M314.2 8H92.8c-16.37 0-28.17 0-37.44.76-9.18.75-15.17 2.2-20.06 4.69A50 50 0 0 0 13.45 35.3c-2.5 4.89-3.94 10.88-4.69 20.06C8.01 64.63 8 76.43 8 92.8v658.4c0 16.37 0 28.17.76 37.44.75 9.18 2.2 15.17 4.69 20.06a50 50 0 0 0 21.85 21.85c4.89 2.5 10.88 3.94 20.06 4.69 9.27.75 21.07.76 37.44.76h221.4c16.37 0 28.17 0 37.44-.76 9.18-.75 15.17-2.2 20.06-4.69a50 50 0 0 0 21.85-21.85c2.5-4.89 3.94-10.88 4.69-20.06.75-9.27.76-21.07.76-37.44V92.8c0-16.37 0-28.17-.76-37.44-.75-9.18-2.2-15.17-4.69-20.06a50 50 0 0 0-21.85-21.85c-4.89-2.5-10.88-3.94-20.06-4.69-9.27-.75-21.07-.76-37.44-.76ZM6.32 31.67C0 44.07 0 60.32 0 92.8v658.4c0 32.48 0 48.72 6.32 61.13a58 58 0 0 0 25.35 25.35C44.07 844 60.32 844 92.8 844h221.4c32.48 0 48.73 0 61.13-6.32a57.99 57.99 0 0 0 25.35-25.35c6.32-12.4 6.32-28.65 6.32-61.13V92.8c0-32.48 0-48.72-6.32-61.13a58 58 0 0 0-25.35-25.35C362.93 0 346.68 0 314.2 0H92.8C60.32 0 44.08 0 31.67 6.32A58 58 0 0 0 6.32 31.67Z"
        fill={splashBgColor}
      />
      <path
        d="M164 19a4 4 0 0 0 0 8h56a4 4 0 0 0 0-8h-56ZM244 19a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
        fill="#C8CEED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6 57.57c-.6 7.28-.6 16.47-.6 28.8v671.25c0 12.34 0 21.52.6 28.81.59 7.27 1.77 12.56 4.08 17.1a42.97 42.97 0 0 0 18.77 18.79c4.54 2.3 9.82 3.49 17.09 4.08 7.28.6 16.46.6 28.78.6h234.36c12.32 0 21.5 0 28.78-.6 7.27-.59 12.55-1.77 17.09-4.08a42.96 42.96 0 0 0 18.77-18.8c2.31-4.53 3.5-9.82 4.08-17.09.6-7.28.6-16.47.6-28.81V86.38c0-12.34 0-21.53-.6-28.81-.59-7.27-1.77-12.56-4.08-17.1a42.97 42.97 0 0 0-18.77-18.79c-4.27-2.17-9.2-3.35-15.83-3.97-6.64-.63-14.9-.7-25.84-.71-1.04 0-1.3.01-1.49.08-.42.14-.76.47-.9.9-.07.19-.08.45-.08 1.48v.27c0 7.33 0 11.27-1.09 14.48a20.54 20.54 0 0 1-12.84 12.86c-3.21 1.09-7.15 1.09-14.48 1.09H113c-7.33 0-11.27 0-14.48-1.1a20.54 20.54 0 0 1-12.84-12.85c-1.1-3.21-1.1-7.15-1.09-14.48v-.27c0-1.03-.01-1.3-.08-1.48a1.44 1.44 0 0 0-.9-.9 5.72 5.72 0 0 0-1.49-.08c-10.93 0-19.2.08-25.84.7-6.63.63-11.56 1.8-15.83 3.98a42.97 42.97 0 0 0-18.77 18.79c-2.31 4.54-3.5 9.83-4.08 17.1ZM16 86.37c0-24.63 0-36.94 4.79-46.35a43.97 43.97 0 0 1 19.2-19.23c8.86-4.51 20.29-4.77 42.13-4.79.95 0 1.43 0 1.81.13.72.24 1.29.8 1.53 1.53.13.38.13.85.13 1.8 0 7.58 0 11.37 1.04 14.43a19.54 19.54 0 0 0 12.21 12.23c3.06 1.04 6.85 1.04 14.42 1.04h180.48c7.57 0 11.36 0 14.41-1.04a19.54 19.54 0 0 0 12.22-12.23c1.04-3.06 1.04-6.85 1.04-14.43 0-.95 0-1.42.13-1.8.24-.72.81-1.29 1.53-1.53.38-.13.86-.13 1.8-.13 21.86.02 33.28.28 42.13 4.8a43.97 43.97 0 0 1 19.21 19.22C391 49.42 391 61.74 391 86.38v671.24c0 24.64 0 36.95-4.79 46.36a43.97 43.97 0 0 1-19.2 19.23C357.6 828 345.28 828 320.67 828H86.32c-24.61 0-36.92 0-46.32-4.8a43.97 43.97 0 0 1-19.21-19.22C16 794.58 16 782.26 16 757.62V86.38Z"
        fill="#C8CEED"
      />
    </svg>
  );
};

MobileBorder.propTypes = { splashBgColor: string.isRequired };

export default MobileBorder;
