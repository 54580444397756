import React from 'react';
import { bool, string } from 'prop-types';
import { t } from '@jotforminc/translation';
import '@jotforminc/jotform.css';
import classNames from 'classnames';
import { IconGridDotsVertical, IconStarFilled, IconArrowUpRightFromSquareSm } from '@jotforminc/svg-icons';
import { Checkbox, Indicator, Link } from '@jotforminc/magnet';
import { getIconType, getShortMonthName } from '../../../utils/utils';

import FormItemWrapper from './styled';

import { formProperties } from '../helpers/propTypes';

// eslint-disable-next-line complexity
const FormItem = ({
  isSelected,
  isMultiSelect,
  isDraggable,
  isEditable,
  showFavorite,
  showFormId,
  submissionsKeyword,
  showPreview,
  option: {
    title,
    count: submissionCount,
    new: newSubmissionCount,
    updated_at: updatedAt,
    created_at: createdAt,
    id: formID,
    isFavorite,
    type,
    isSelectionDisabled,
    disableClass = 'itemDisabled',
    disableReason = 'This form is disabled.',
    draftCount,
    submission_date: submissionDate,
    paymentProps = null
  },
  isNewBranding,
  productType
}) => {
  const hasDraft = draftCount && draftCount > 0;

  const getReadableDate = dateString => {
    if (!dateString) {
      console.error('Date string is not defined.');
      return '';
    }
    const splittedDateString = dateString.split(' ')[0].split('-');
    const month = parseInt(splittedDateString[1], 10);
    const monthName = month > 12 || month < 1 ? null : getShortMonthName(month);
    if (!monthName) {
      return '';
    }
    return `${monthName} ${splittedDateString[2]}, ${splittedDateString[0]}`;
  };

  let lastActionDate;
  if (draftCount !== undefined) {
    lastActionDate = submissionDate;
  } else {
    lastActionDate = updatedAt ? updatedAt : createdAt;
  }

  const readableLastActionDate = getReadableDate(lastActionDate);

  let lastActionDateText;
  if (hasDraft && submissionCount < 1) {
    lastActionDateText = 'Draft saved on {date}.';
  } else if (draftCount !== undefined) {
    lastActionDateText = 'Last submission on {date}.';
  } else {
    lastActionDateText = 'Modified on {date}.';
  }

  const draftCountText = `${draftCount} ${t(`Draft${draftCount > 1 ? 's. ' : '. '}`)}`;
  return (
    <FormItemWrapper
      tabIndex="0"
      className={classNames('', {
        isDisabled: isSelectionDisabled,
        group: showPreview
      })}
    >
      <div className="itemInf flex items-center gap-2 mr-4">
        {isMultiSelect && <Checkbox checked={isSelected} className='mr-2' />}
        {showFavorite && (<IconStarFilled className={`w-6 h-6 shrink-0 ${!isFavorite ? 'color-yellow-300' : 'color-navy-100'}`} />)}
        <div className={`itemInfo-formType relative shrink-0 ${getIconType(productType, type, isNewBranding, paymentProps)}`}>
          {!!(newSubmissionCount !== '0' && newSubmissionCount) && (
            <Indicator
              className="absolute right-1.5 top-.5 transform translate-x-6/12 -translate-y-6/12"
              colorStyle="error"
              size="small"
              title={newSubmissionCount && `${newSubmissionCount} Unread`}
            >
              {newSubmissionCount}
            </Indicator>
          )}
        </div>
      </div>
      <div className="itemContent">
        <h2 className="itemTitle">
          {title}
        </h2>
        <p className="itemDesc">
          {hasDraft ? <span>{draftCountText}</span> : null}
          {`${showFormId ? `${formID}. ` : ''}`}
          {submissionCount === undefined || (hasDraft && submissionCount < 1) ? '' : `${submissionCount} ${t(submissionsKeyword)}. `}
          {readableLastActionDate !== '' ? (`${t(lastActionDateText).replace('{date}', readableLastActionDate)}`) : <></>}
        </p>
      </div>
      {showPreview && (
        <Link
          colorStyle="primary"
          className={classNames('hover:no-underline group-hover:opacity-100 opacity-0 flex align-self-center gap-1 items-center text-sm font-medium line-height-xs', {
            'opacity-0': isSelected
          })}
          style={{ alignSelf: 'center', color: '#0099FF' }}
          target="_blank"
          href={`/${formID}`}
        >
          {t('View')}
          <IconArrowUpRightFromSquareSm className='fill-blue-400 w-4 h-4' />
        </Link>
      )}
      {isEditable && (
        <div className="itemActions shrink-0 mx-3 my-2">
          <a
            className="itemEdit text-xs h-6 px-3 flex items-center color-white bg-blue-500 radius-xl no-underline"
            target="_blank"
            href={`/build/${formID}`}
            onClickCapture={e => {
              e.stopPropagation();
            }}
          >
            Edit Form
          </a>
        </div>
      )}
      {isDraggable && (
        <div className="itemDragHandle">
          <IconGridDotsVertical className="w-8 h-8 color-navy-300" />
        </div>
      )}
      <div className={disableClass} aria-hidden={!isSelectionDisabled}>
        {disableReason}
      </div>
    </FormItemWrapper>
  );
};

FormItem.propTypes = {
  isMultiSelect: bool,
  isSelected: bool.isRequired,
  option: formProperties.isRequired,
  isDraggable: bool,
  isEditable: bool,
  showFavorite: bool,
  showPreview: bool,
  showFormId: bool,
  submissionsKeyword: string,
  isNewBranding: bool,
  productType: string
};

FormItem.defaultProps = {
  isMultiSelect: true,
  isDraggable: false,
  isEditable: false,
  showFavorite: false,
  showPreview: false,
  showFormId: false,
  isNewBranding: false,
  submissionsKeyword: 'Submissions',
  productType: ''
};

export default FormItem;
