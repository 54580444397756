import React from 'react';
import { object, func, bool } from 'prop-types';
import { t } from '@jotforminc/translation';

import { placeholderTableImage } from '../../constantImages';
import { onImageError } from '../../onImageError';
import PreviewHint from './PreviewHint';

const TableTemplateItem = ({
  template, imageLoaded, handlePreviewTemplate,
  setImageLoaded, templateCloneWrapper
}) => {
  const {
    _slug,
    _title,
    thumbnailBackgroundColor
    // _imageUrl,
  } = template;
  const isSkeleton = _title === 'skeleton';
  const screenshotURL = _slug ? `https://cdn.jotfor.ms/templates/screenshot/table-templates/${_slug}?f=png&w=930` : placeholderTableImage;
  return (
    <div className={`item table-item${imageLoaded && !isSkeleton ? ' image-loaded' : ''}`}>
      <div className="image" style={{ borderColor: thumbnailBackgroundColor }} onClick={() => handlePreviewTemplate('viewTemplateFromImg')}>
        <div className='bgColorItem' style={{ backgroundColor: thumbnailBackgroundColor }} />
        <PreviewHint />
        <img
          alt={_title}
          onError={onImageError}
          className='table-main'
          onLoad={() => setImageLoaded(true)}
          src={!isSkeleton ? (screenshotURL) : placeholderTableImage}
        />
        <img
          className='table-blank'
          alt={_title}
          src={placeholderTableImage}
        />
        <div className="animBg" />
      </div>
      <h3 onClick={() => handlePreviewTemplate('viewTemplateFromTitle')}><span>{_title}</span></h3>
      <button
        className="useTemplate"
        type="button"
        onClick={event => templateCloneWrapper(event)}
      >
        <span>{t('Use Template')}</span>
      </button>
      <button
        className="previewCta"
        type="button"
        onClick={() => handlePreviewTemplate('viewTemplateFromPreviewCta')}
      >
        <span>{t('Preview')}</span>
      </button>
    </div>
  );
};

export default TableTemplateItem;
TableTemplateItem.defaultProps = {
};
TableTemplateItem.propTypes = {
  template: object.isRequired,
  imageLoaded: bool.isRequired,
  setImageLoaded: func.isRequired,
  templateCloneWrapper: func.isRequired,
  handlePreviewTemplate: func.isRequired
};
