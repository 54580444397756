import { put, select, takeLatest } from 'redux-saga/effects';
import * as ACTION_TYPES from '../actionTypes';
import * as ACTION_CREATORS from '../actionCreators';
import { safeWorker } from '../utils';
import { checkMobilePhone } from '../../utils';
import { MODALS } from '../../constants/modals';
import { DESTINATION_TYPES } from '../../constants/navigation';
import { getFormIDByItemID, getProductsOfProductListByID } from '../selectors/productSelectors';
import { UI_PROPS } from '../../constants';

function* watchProductItemDetails({ payload: { itemID, productProps } }) {
  const formID = yield select(getFormIDByItemID(itemID));
  const products = yield select(getProductsOfProductListByID(formID));
  const { productID, optionsValue, quantity } = productProps;
  let finalQuantity;
  const product = products.find(_product => _product.pid === productID);
  const { options = [] } = product;
  const quantityOptionIndex = options.findIndex(option => option.type === 'quantity');
  if (quantityOptionIndex !== -1) {
    if (optionsValue && optionsValue[quantityOptionIndex]) {
      finalQuantity = optionsValue[quantityOptionIndex];
    } else {
      finalQuantity = quantity;
    }
  }
  yield put(ACTION_CREATORS.addOrUpdateCartAction(formID, productID, { options: optionsValue, ...finalQuantity && { quantity: finalQuantity } }));
}

function* watchOpenProductDetail({ payload: { productID, itemID } }) {
  if (!checkMobilePhone()) {
    yield put(ACTION_CREATORS.showGenericModalAction({ name: MODALS.PRODUCT_ITEM_DETAIL_MODAL, itemID, productID }));
  } else {
    yield put(ACTION_CREATORS.setUIProp(UI_PROPS.activeProductDetail, { itemID, productID }));
    yield put(ACTION_CREATORS.navigateToAction({
      to: DESTINATION_TYPES.SPECIAL_PAGE, pageID: 'product', itemID, productID
    }));
  }
}

export default function* () {
  yield takeLatest(ACTION_TYPES.PRODUCT_ITEM_DETAIL.REQUEST, safeWorker(watchProductItemDetails));
  yield takeLatest(ACTION_TYPES.OPEN_PRODUCT_DETAIL, watchOpenProductDetail);
}
