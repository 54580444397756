import sortBy from 'lodash/sortBy';
import { getDomainURL } from '@jotforminc/request-layer';
import { t } from '@jotforminc/translation';
import { put, call } from 'redux-saga/effects';
import { toastAction } from './actionCreators';

export const getOrderedItemList = (existingItems, removedItem, isPage = false) => {
  const orderProperty = isPage ? 'pageOrder' : 'portalOrder';
  let nextOrder = parseInt(removedItem[orderProperty], 10);
  // update other item orders
  const itemsToUpdate = existingItems.filter(({ id }) => (id !== removedItem.id));
  const newItemList = [];
  itemsToUpdate.forEach(({ [orderProperty]: order, ...props }) => {
    const shouldUpdate = parseInt(order, 10) >= nextOrder;
    const newOrder = shouldUpdate ? nextOrder.toString() : order;
    newItemList.push({ ...props, ...{ [orderProperty]: newOrder } });
    if (shouldUpdate) nextOrder++;
  });
  return sortBy(newItemList, p => parseInt(p[orderProperty], 10));
};

export const sanitizeSVGIconURLs = url => {
  const domainURL = getDomainURL();
  return url.replace(domainURL, '');
};

export const isGuestUser = user => {
  const { account_type: accountType } = user || {};
  return accountType === 'GUEST' || accountType?.name === 'GUEST';
};

export function safeWorker(worker) {
  return function* (action) {
    const { type: actionType } = action;
    const reloadPage = () => global.location.reload();
    try {
      return yield call(worker, action);
    } catch (e) {
      const errorAction = actionType.replace(/(UNDOABLE|REQUEST)/i, 'ERROR');
      yield put({ type: errorAction, error: e });
      yield put(toastAction({
        message: t('Oops! Something went wrong. Refresh the page to try again.'),
        type: 'error',
        backdrop: false,
        buttonText: 'Refresh page',
        onButtonClick: reloadPage
      }));
    }
  };
}

export const includeJotformFonts = font => {
  const linkURL = `https://cdn.jotfor.ms/fonts/?display=swap&family=${font.replace(/ /g, '+')}`;

  const linkEl = document.createElement('link');
  linkEl.setAttribute('rel', 'stylesheet');
  linkEl.setAttribute('href', linkURL);
  document.head.appendChild(linkEl);
};
