import React from 'react';
import usePaymentPropsPanel from '../Context/usePaymentProperties';
import ActionFooter from './ActionFooter';

const FooterContainer = (): JSX.Element => {
  const {
    handleSave,
    changedProps,
    resource
  } = usePaymentPropsPanel();
  return (
    <ActionFooter
      testIdSave="paymentPropertiesSave"
      disableConditionSave={Object.keys(changedProps).length === 0}
      saveBtnClass='xs:w-24 w-full'
      handleSave={handleSave}
      resource={resource}
    />
  );
};

export default FooterContainer;
