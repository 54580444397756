import React, {
  useCallback, useEffect, useImperativeHandle, useState
} from 'react';
import { ResourcePicker, RESOURCE_TYPES } from '@jotforminc/resource-picker';
import { t } from '@jotforminc/translation';
import {
  arrayOf, bool, func, object, string
} from 'prop-types';
import ResourcePickerControlBar, { RESOURCES_WITH_CONTROL_BAR } from '../ResourcePickerControlBar';
import { getTeamIDOfAsset, getTeamResources } from '../../modules/api';
import { isTeamResourcePicker } from '../../utils';
import { Loading } from '../Loading';
import EmptyListPlaceholder from './EmptyListPlaceholder';
import { TEAM_ID } from '../../constants/team';

const ResourcePickerWithControlBar = React.forwardRef(({
  resourceType, usedResourceIDs, onSelectionChange, allowOnlyOwnTeam, isChangeFormAction, initialSelectedForm, fetcherConfig, ...rest
}, ref) => {
  const { params: fetcherParams = {}, filters: fetcherFilters = {} } = fetcherConfig;
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState({});

  const { [resourceType]: { resourceFetcher, optionValue } } = RESOURCES_WITH_CONTROL_BAR;

  const [selectedOption, setSelectedOption] = useState(isTeamResourcePicker() ? TEAM_ID : optionValue);

  const isTeamSelected = selectedOption !== optionValue;

  const getFormFetcher = useCallback(searchTerm => {
    if (isTeamSelected) {
      return getTeamResources({
        teamID: selectedOption, resourceType, filters: { fullText: searchTerm, ...fetcherFilters }, params: { ...fetcherParams }
      });
    }
    return resourceFetcher({ fullText: searchTerm, ...fetcherFilters }, { ...fetcherParams });
  }, [selectedOption]);

  const onSearch = useCallback(async (searchTerm = '') => {
    return getFormFetcher(searchTerm).then(result => result.filter(resource => !usedResourceIDs.includes(resource.id)));
  }, [getFormFetcher]);

  const DataProvider = useCallback(() => ({ onSearch }), [onSearch]);

  useEffect(() => {
    onSearch().then(setResources).finally(() => setLoading(false));
  }, [onSearch]);

  useEffect(() => {
    if (isChangeFormAction && isTeamResourcePicker()) {
      getTeamIDOfAsset({ resourceID: initialSelectedForm, resourceType: resourceType.toUpperCase() }).then(teamID => {
        setSelectedOption(teamID ?? TEAM_ID);
      });
    }
  }, []);

  const handleSelectionChange = selections => {
    if (resourceType === 'form') {
      onSelectionChange(selections);
      return;
    }
    const selectedOptionItems = resources.filter(({ id }) => selections.includes(id)).map(s => ({ ...s, isTeamAsset: isTeamSelected }));
    const _selectedItems = { ...selectedItems, [selectedOption]: selectedOptionItems };
    setSelectedItems(_selectedItems); // keep resources belongs to different list
    onSelectionChange([...Object.values(_selectedItems)].reduce((prev, items) => [...prev, ...items], []));
  };

  useImperativeHandle(ref, () => ({
    getResources: () => resources
  }));

  const ControlBarRenderer = useCallback(props => (
    <ResourcePickerControlBar
      {...props}
      type={resourceType}
      onChangeOption={setSelectedOption}
      selectedOptionValue={selectedOption}
      allowOnlyOwnTeam={allowOnlyOwnTeam}
    />
  ), [selectedOption, resourceType]);

  return loading ? <Loading /> : (
    <ResourcePicker
      {...isTeamResourcePicker() ? { ControlBarRenderer, searchPlaceholder: t('Search') } : {}}
      resourceType={resourceType}
      DataProvider={DataProvider}
      searchOnBackend={true}
      multipleManage={false}
      onSelectionChange={handleSelectionChange}
      emptyListPlaceholder={() => <EmptyListPlaceholder isTeamSelected={isTeamSelected} />}
      showNotFoundListPlaceholder={resources.length === 0 ? false : true}
      {...rest}
      resources={resources}
    />
  );
});

ResourcePickerWithControlBar.propTypes = {
  appOwner: string,
  resourceType: string,
  usedResourceIDs: arrayOf(string),
  onSelectionChange: func,
  allowOnlyOwnTeam: bool,
  isChangeFormAction: bool,
  initialSelectedForm: string,
  fetcherConfig: object
};

ResourcePickerWithControlBar.defaultProps = {
  appOwner: '',
  resourceType: RESOURCE_TYPES.FORM,
  usedResourceIDs: [],
  onSelectionChange: f => f,
  allowOnlyOwnTeam: false,
  isChangeFormAction: false,
  initialSelectedForm: '',
  fetcherConfig: {}
};

export default ResourcePickerWithControlBar;
