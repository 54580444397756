import React, {
  FC,
  useState,
  useEffect
} from 'react';
import { gatewayResourceSupportedPaymentTypes, supportedPaymentTypesWithLabel } from '@jotforminc/payment-constants';
import type { GATEWAY_PAYMENT_TYPES, GATEWAY_SUPPORTED_RESOURCES, PAYMENT_FIELDS } from '@jotforminc/payment-constants';
import { t } from '@jotforminc/translation';
import usePaymentPropsPanel from '../Context/usePaymentProperties';
import { TBuilderDropdown } from '../../../../types/common';
import PaymentTypeDialog from './PaymentTypeDialog';

interface IPaymentTypeDropdown {
  resource: GATEWAY_SUPPORTED_RESOURCES;
  BuilderDropdown: TBuilderDropdown;
}

const PaymentTypeDropdown: FC<IPaymentTypeDropdown> = ({
  BuilderDropdown,
  resource
}) => {
  const {
    gatewayProperties: {
      paymentType,
      nonprofit
    },
    gatewayType,
    isParentAPM,
    childType
  } = usePaymentPropsPanel();
  const type: PAYMENT_FIELDS = isParentAPM ? childType : gatewayType;
  const optionArray = gatewayResourceSupportedPaymentTypes[type][resource];
  const value = (paymentType === 'donation' && nonprofit === 'No') ? 'custom' : paymentType;
  const [newValue, setNewValue] = useState<GATEWAY_PAYMENT_TYPES | null>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  useEffect(() => {
    if (newValue && value !== newValue) { setShowDialog(true); }
  }, [newValue]);

  const parseOptionsForDropdown = () => {
    return [
      { id: '', key: '', text: 'Please select' },
      ...optionArray.map((option: GATEWAY_PAYMENT_TYPES) => { return { id: option, key: option, text: t(supportedPaymentTypesWithLabel[option]) }; })
    ];
  };

  return (
    <div className="mx-4 mb-4">
      <span className='color-white text-sm line-height-md tracking-lg font-bold inline-block mb-2'>
        {t('Payment Type')}
      </span>
      <BuilderDropdown
        paymentTypeDropdown
        disabled={parseOptionsForDropdown().length === 2}
        className="m-dropdown paymentTypeDropdown"
        testId='paymentType'
        options={parseOptionsForDropdown()}
        value={value}
        onChange={(e: any) => {
          if (e.target.value === '') { return; }
          setNewValue(e.target.value);
        }}
      />
      {
        showDialog && (
          <PaymentTypeDialog
            showDialog={showDialog}
            onClose={() => setShowDialog(false)}
            prevPaymentType={value}
            newPaymentType={newValue}
            resetNewPaymentType={() => setNewValue(null)}
          />
        )
      }
    </div>
  );
};

export default PaymentTypeDropdown;
