import { createSelector } from 'reselect';
import { DS_ITEM_LOAD_TYPES } from '../reducers/dataSource/constants';

export const getDataSource = state => state.dataSource;
export const dsGetItemSourceByItemID = itemID => createSelector(getDataSource, ds => ds.items[itemID]);
export const dsGetPage = pageID => createSelector(getDataSource, ds => ds.pages?.[pageID] ?? {});

export const dsGetColumnsByResourceID = ({ resourceID, viewID }) => createSelector(getDataSource, ds => ds.resources[viewID ? viewID : resourceID] ?? {});

export const dsGetRowsByItemID = itemID => createSelector(dsGetItemSourceByItemID(itemID), dsItem => dsItem?.data ?? []);
export const dsGetItemIsLoading = (itemID, loadType = DS_ITEM_LOAD_TYPES.DEFAULT) => createSelector(dsGetItemSourceByItemID(itemID), dsItem => dsItem?.[loadType]);
export const dsGetActiveListRowID = itemID => createSelector(dsGetItemSourceByItemID(itemID), dsItem => dsItem?.activeRowID ?? dsItem?.data?.[0]?.sid);
export const dsGetThereIsMoreRow = itemID => createSelector(dsGetItemSourceByItemID(itemID), dsItem => dsItem?.hasMore ?? true);
export const dsGetSourceItemOffset = itemID => createSelector(dsGetItemSourceByItemID(itemID), dsItem => dsItem?.offset);

export const dsGetDetailPageData = pageID => createSelector(dsGetPage(pageID), dsPage => dsPage?.data ?? {});
export const dsGetIsPageLoading = pageID => createSelector(dsGetPage(pageID), dsPage => dsPage?.isLoading);
