import moment from 'moment';

export const operators = [
  { name: 'matches', text: 'includes' },
  { name: 'eq', text: 'equals' },
  { name: 'ne', text: 'not equal to' },
  { name: 'lt', text: 'less than' },
  { name: 'lte', text: 'less than or equals to' },
  { name: 'gt', text: 'greater than' },
  { name: 'gte', text: 'greater than or equals to' }
  // { name: 'emp', text: 'Is Empty' },
  // { name: 'nemp', text: 'Is Filled' }
];

export const dateOperators = [
  { name: 'matches', text: 'includes' },
  { name: 'lt', text: 'less than' },
  { name: 'lte', text: 'less than or equals to' },
  { name: 'gt', text: 'greater than' },
  { name: 'gte', text: 'greater than or equals to' }
];

export const genericTypesMap = {
  assignee: ['control_assignee'],
  dropdown: ['control_dropdown', 'control_radio', 'control_checkbox'],
  workflow: ['control_workflowStatus'],
  numeric: ['control_number', 'control_spinner'],
  scale: ['control_rating', 'control_scale'],
  date: ['control_submissionDate', 'control_datetime', 'control_birthdate'],
  text: ['control_fullname', 'control_email', 'control_address', 'control_phone', 'control_textbox', 'control_textarea'],
  bool: ['control_sheetbox'],
  eq: ['control_eq']
};

export const emptyValueOperators = ['emp', 'nemp'];

export const emptyValueOperatorsMap = {
  emp: 'eq',
  nemp: 'ne'
};

export const UTILITY_FIELDS = ['created_at', 'updated_at', 'ip', 'id', 'submitter', 'submittedVia'];
export const UTILITY_FIELDS_WITH_Q = ['qcreated_at', 'qupdated_at', 'qip', 'qid'];
export const UTILITY_QUESTIONS = [
  'control_workflowStatus',
  'control_documentID',
  'control_documentStatus',
  'control_documentCreationTime',
  'control_documentModificationTime',
  'control_eq'
];

export const UNSUPPORTED_QUESTIONS = [
  'control_documentExpirationDate'
];

export const defaultFilter = {
  field: '',
  operator: 'matches',
  value: '',
  isFilled: false,
  isValueDisabled: false,
  lock: '0',
  id: 0
};

export const boolOptions = [
  { value: '0', text: 'unchecked' },
  { value: '1', text: 'checked' }
];

export const defaultFilterGroups = [
  { value: 'all', text: 'All Submissions' },
  { value: 'unread', text: 'Unread' },
  { value: 'read', text: 'Read' },
  { value: 'flag', text: 'Starred' },
  { value: 'unflag', text: 'Unstarred' }
];

export const defaultFormat = 'D/M/YYYY';
export const defaultStorageFormat = 'YYYY-MM-DD HH:mm:ss';

export const startFormat = 'YYYY-MM-DD 00:00:00';
export const endFormat = 'YYYY-MM-DD 23:59:59';

export const startAllTime = moment('2006-01-01 00:00:00', startFormat);
export const lessThanStr = 'created_at:lte:datetime';
export const greaterThanStr = 'created_at:gte:datetime';
export const oldLessThanStr = 'created_at:lte:submissionDate';
export const oldGreaterThanStr = 'created_at:gte:submissionDate';

export const customStr = 'Custom dates';
export const allTimeStr = 'All time';

export const timeOptionList = [
  allTimeStr,
  'Today',
  'Last 3 days',
  'Last 7 days',
  'Last 30 days',
  'Last 1 year',
  'Previous week',
  'Previous month',
  'This year',
  'Previous year',
  customStr
];

export const whiteListedFilters = [
  'datetime',
  'appointment'
];

export const ICON_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

export const FORMAT_MAP = {
  'MM/DD/YYYY': 'MM/dd/yyyy',
  'DD/MM/YYYY': 'dd/MM/yyyy',
  'MMM D, YYYY': 'MMM d, yyyy',
  'MMMM D, YYYY': 'MMMM d, yyyy',
  'ddd, MMM D, YYYY': 'eee, MMM d, yyyy',
  'dddd, MMMM D, YYYY': 'eeee, MMMM d, yyyy',
  'MMM D, YYYY HH:mm': 'MMM d, yyyy HH:mm',
  'MMMM D, YYYY HH:mm': 'MMMM d, yyyy HH:mm',
  'ddd, MMM D, YYYY HH:mm': 'eee, MMM d, yyyy HH:mm',
  'dddd, MMMM D, YYYY HH:mm': 'eeee, MMMM d, yyyy HH:mm',
  'MMM D, YYYY hh:mm A': 'MMM d, yyyy hh:mm a',
  'MMMM D, YYYY hh:mm A': 'MMMM d, yyyy hh:mm a',
  'ddd, MMM D, YYYY hh:mm A': 'eee, MMM d, yyyy hh:mm a',
  'dddd, MMMM D, YYYY hh:mm A': 'eeee, MMMM d, yyyy hh:mm a'
};

export const DATE_FORMATS_WITHOUT_TIME = [
  'MM/DD/YYYY',
  'DD/MM/YYYY',
  'MMM D, YYYY',
  'MMMM D, YYYY',
  'ddd, MMM D, YYYY',
  'dddd, MMMM D, YYYY'
];

export const DAY_ABBREVIATIONS = {
  Sunday: 'Sun',
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat'
};
