import React from 'react';
import { Button } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import { IconPlugFilled } from '@jotforminc/svg-icons';
import usePaymentPropsPanel from '../../Context/usePaymentProperties';
import { PLUG_NOCONNECTION } from '../../Constants';

interface I_CONNECTIONMODALBTN {
  disabledBtnClass: string;
}

const ConnectionModalButton = ({ disabledBtnClass }: I_CONNECTIONMODALBTN): JSX.Element => {
  const {
    hasConnection,
    isCollaborator,
    togglePaymentConnectionModal,
    checkConnection,
    isJotformerAccessingUserResource
  } = usePaymentPropsPanel();
  const isPlugBtnDisabled = isCollaborator || isJotformerAccessingUserResource;

  return (
    <div className='inline-block group relative'>
      <Button
        startIcon={() => <IconPlugFilled className={`min-w-4 min-h-4 ${!hasConnection ? PLUG_NOCONNECTION.iconClass : ''}`} />}
        className={`max-w-8 max-h-8 mr-2 ${!hasConnection ? PLUG_NOCONNECTION.btnClass : ''} ${isPlugBtnDisabled ? disabledBtnClass : ''}`}
        onClick={async () => {
          if (isPlugBtnDisabled) { return; }
          await checkConnection();
          togglePaymentConnectionModal(true);
        }}
        data-test-id='payment-connection-attach-btn'
      />
      {
        isPlugBtnDisabled && (
          <span
            className='group-hover:block hiddenjf absolute bg-gray-800 shadow-dark-md radius-lg top-9 xs:min-w-92 min-w-80 -right-4 p-3 color-white text-sm line-height-xs z-9'
          >
            {t('Only the form owner can add/remove connections.')}
          </span>
        )
      }
    </div>
  );
};

export default ConnectionModalButton;
